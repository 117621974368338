import { Component, OnInit, Input } from '@angular/core';
import { SupportSubjects } from '../../graphql'; 
import { trigger, style, animate, transition } from '@angular/animations';
import { DEFAULT_USER } from '../../helpers/constants';
import { IUserModel } from '../../models/user.model';
import { ChatStateService } from '../../services/chat-state.service';
import { IChatStateModel } from '../../models/chat-state.model';
import { IUserViewModel } from '../../models/user-view.model';
import { IInitStateModel } from '../../models/init-state.model';



@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ transform: 'scale(0.3)', opacity: 0, transformOrigin: 'bottom right' }),
            animate('0.4s', style({ opacity: 1, transform: 'scale(1)' }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1, transform: 'scale(1)' }),
            animate('0.4s', style({ opacity: 0, transform: 'scale(0)', transformOrigin: 'bottom right' })),
          ]
        )
      ]
    )
  ]
})
export class ChatComponent implements OnInit {
  @Input() set userId(value: string) {
      if(!value) { 
          const _userId = <string>this.defaultUser.Id;
          this.initModel.userId = _userId;
          this.init();

          return;
      }
      
      this.initModel.userId = value;
      this.init();
  }

  @Input() set projectGroupId(value: string) {
    if(!value) return;

    this.initModel.projectId = value;
    this.init();
  }

  public isChatOpened: boolean;
  public isChatButtonDisplayed: boolean;
  public plantName: string;
  public supportSubjects: SupportSubjects[] = [];
  public chatState: IChatStateModel;

  private defaultUser: IUserModel = DEFAULT_USER;
  private initModel: IInitStateModel = {
    userId: '',
    projectId: ''
  };

  constructor(private chatStateService: ChatStateService) {}

  ngOnInit(): void {
    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;
    });

    this.isChatButtonDisplayed = true;
  }

  public openChat(): void {
    this.isChatOpened = !this.isChatOpened;
  }

  public closeChat(value: boolean): void {
    this.isChatOpened = value;
  }

  public get getUser(): IUserViewModel|undefined {
    return this.chatState.user;
  }

  private init(): void {
    if(this.initModel.userId && this.initModel.projectId) {
      this.chatStateService.init(this.initModel);
    }
  }
}
