import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request)
    if(request.url.startsWith(environment.wep.host)) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Token ${environment.wep.token}`,
          'Content-Type': 'application/json'
        }
      });
      return next.handle(request);
    }
    else {
      return from(this.auth.getAccessToken())
        .pipe(switchMap(token => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
            return next.handle(request);
          }));
    }
  }
}
