import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { ProjectService } from '../project/project.service';
import { AuthService } from '../auth/auth.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { InsertLaboScheduleStatesGQL, UpdateLaboScheduleStatesGQL, LaboScheduleStatesWhereGQL, LaboScheduleStatesInsertInput, InsertLaboScheduleStatesPlanningGQL, DeleteLaboScheduleStatesPlanningGQL, LaboScheduleStatesPlanningWhereGQL, LaboScheduleStatesPlanningInsertInput, OrderBy, DeleteLaboScheduleStatesGQL } from 'app/graphql';
import { LaboScheduleState } from 'app/lib/interfaces/laboScheduleState.interface';
import { LaboScheduleStatePlanning } from 'app/lib/interfaces/laboScheduleStatePlanning.interface';

@Injectable({
    providedIn: 'root'
})
export class LaboScheduleService {

    states: any;
    onStatesUpdated: Subject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _projectService: ProjectService,
        private _authService: AuthService,
        private _insertLaboScheduleStatesGQL: InsertLaboScheduleStatesGQL,
        private _updateLaboScheduleStatesGQL: UpdateLaboScheduleStatesGQL,
        private _deleteLaboScheduleStatesGQL: DeleteLaboScheduleStatesGQL,
        private _LaboScheduleStatesWhereGQL: LaboScheduleStatesWhereGQL,
        private _insertLaboScheduleStatesPlanningGQL: InsertLaboScheduleStatesPlanningGQL,
        private _deleteLaboScheduleStatesPlanningGQL: DeleteLaboScheduleStatesPlanningGQL,
        private _LaboScheduleStatesPlanningWhereGQL: LaboScheduleStatesPlanningWhereGQL
    ) {
        // Set the defaults
        this.onStatesUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            resolve();
            // Promise.all([
            //     // this.getStates()
            // ]).then(
            //     ([states]: [any]) => {
            //         resolve();
            //     },
            //     reject
            // );
        });
    }

    /**
     * Get states
     *
     * @returns {Promise<any>}
     */
    getLaboScheduleStates(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._LaboScheduleStatesWhereGQL.fetch({
                LaboScheduleStates_bool_exp: {
                    ProjectId: {
                        _eq: this._projectService.cachedProject.code
                    }
                }
            }, {
                fetchPolicy: "no-cache"
            }).toPromise().then(result => {
                // console.log(`_LaboScheduleStatesWhereGQL`, result);

                const states: LaboScheduleState[] = [];

                result.data.LaboScheduleStates.forEach(state => {
                    const laboScheduleState: LaboScheduleState = {
                        id: state.Id,
                        name: state.Name,
                        projectId: state.ProjectId,
                        createdAt: state.CreatedAt,
                        deletedAt: state.DeletedAt,
                        deleted: state.Deleted,
                        schedule: state.Schedule
                    };

                    states.push(laboScheduleState);
                });

                this.states = states;
                this.onStatesUpdated.next(this.states);
                resolve(this.states);
            })
                .catch(err => {
                    console.log(`err: `, err);
                    reject(err);
                });
        });
    }

    /**
     * Get current state planning
     *
     * @returns {Promise<any>}
     */
    getPlanningForDate(date): Promise<any> {
        return new Promise((resolve, reject) => {

            const selectedDate = new Date(date);

            var weekday = new Array(7);
            weekday[0] = "sunday";
            weekday[1] = "monday";
            weekday[2] = "tuesday";
            weekday[3] = "wednesday";
            weekday[4] = "thursday";
            weekday[5] = "friday";
            weekday[6] = "saturday";

            console.log(`day_of_week ` + selectedDate.getDay() + ' ' + weekday[selectedDate.getDay()]);

            this._LaboScheduleStatesPlanningWhereGQL.fetch({
                Limit: 1,
                LaboScheduleStatesPlanning_bool_exp: {
                    ProjectId: {
                        _eq: this._projectService.cachedProject.code
                    },
                    Planned: {
                        _lte: date
                    }
                },
                LaboScheduleStatesPlanning_order_by: [
                    {
                        Planned: OrderBy.DescNullsLast
                    }
                ]
            }, {
                fetchPolicy: "no-cache"
            }).toPromise().then(result => {

                console.log(`getPlanningForDate:`, result);

                if (result.data.LaboScheduleStatesPlanning.length > 0) {
                    // return requested data for day of the week
                    resolve(result.data.LaboScheduleStatesPlanning[0].LaboScheduleState.Schedule[weekday[selectedDate.getDay()]]);
                } else {
                    resolve(null);
                }
            })
                .catch(err => {
                    console.log(`err: `, err);
                    reject(err);
                });
        });
    }

    /**
     * Get current state planning
     *
     * @returns {Promise<any>}
     */
    getCurrentState(date): Promise<any> {
        return new Promise((resolve, reject) => {

            this._LaboScheduleStatesPlanningWhereGQL.fetch({
                Limit: 1,
                LaboScheduleStatesPlanning_bool_exp: {
                    ProjectId: {
                        _eq: this._projectService.cachedProject.code
                    },
                    Planned: {
                        _lte: date
                    }
                },
                LaboScheduleStatesPlanning_order_by: [
                    {
                        Planned: OrderBy.DescNullsLast
                    }
                ]
            }, {
                fetchPolicy: "no-cache"
            }).toPromise().then(result => {

                if (result.data.LaboScheduleStatesPlanning.length > 0) {
                    // return requested data for day of the week
                    resolve(result.data.LaboScheduleStatesPlanning[0].LaboScheduleState);
                } else {
                    resolve(null);
                }
            })
                .catch(err => {
                    console.log(`err: `, err);
                    reject(err);
                });
        });
    }

    /**
     * Add state
     *
     * @param state
     * @returns {Promise<any>}
     */
    addState(state: LaboScheduleState): Promise<any> {

        return new Promise((resolve, reject) => {

            const input: LaboScheduleStatesInsertInput = {
                Id: state.id,
                Name: state.name,
                ProjectId: state.projectId,
                CreatedAt: state.createdAt,
                DeletedAt: state.deletedAt,
                Deleted: state.deleted,
                Schedule: state.schedule,
            };


            this._insertLaboScheduleStatesGQL.mutate({ LaboScheduleStates_insert_input: [input] }).subscribe(result => {
                console.log(`_insertLaboScheduleStatesGQL: `, result);
                resolve();
            }, reject);

        });
    }

    /**
     * Add state
     *
     * @param state
     * @returns {Promise<any>}
     */
    addStatePlanning(planning: LaboScheduleStatePlanning): Promise<any> {

        return new Promise((resolve, reject) => {

            const input: LaboScheduleStatesPlanningInsertInput = {
                Id: planning.id,
                StateId: planning.stateId,
                Planned: planning.planned,
                ProjectId: this._projectService.cachedProject.code
            };

            this._insertLaboScheduleStatesPlanningGQL.mutate({ LaboScheduleStatesPlanning_insert_input: [input] }).subscribe(result => {
                console.log(`_insertLaboScheduleStatesPlanningGQL: `, result);
                resolve();
            }, reject);

        });
    }

    /**
    * Get states
    *
    * @returns {Promise<any>}
    */
    getPlanningForStateId(stateId): Promise<any> {
        return new Promise((resolve, reject) => {

            this._LaboScheduleStatesPlanningWhereGQL.fetch({
                Limit: 5000,
                LaboScheduleStatesPlanning_order_by: [{
                    Planned: OrderBy.AscNullsLast
                }],
                LaboScheduleStatesPlanning_bool_exp: {
                    StateId: {
                        _eq: stateId
                    },
                }
            }, {
                fetchPolicy: "no-cache"
            }).toPromise().then(result => {
                // console.log(`_LaboScheduleStatesPlanningWhereGQL`, result);

                const plannings: LaboScheduleStatePlanning[] = [];

                result.data.LaboScheduleStatesPlanning.forEach(planning => {
                    const laboScheduleState: LaboScheduleStatePlanning = {
                        id: planning.Id,
                        stateId: planning.StateId,
                        planned: planning.Planned
                    };

                    plannings.push(laboScheduleState);
                });

                resolve(plannings);
            })
                .catch(err => {
                    console.log(`err: `, err);
                    reject(err);
                });
        });
    }

    /**
     * Update state
     *
     * @param state
     * @returns {Promise<any>}
     */
    updateState(state: LaboScheduleState): Promise<any> {

        return new Promise((resolve, reject) => {

            const input: LaboScheduleStatesInsertInput = {
                Id: state.id,
                Name: state.name,
                ProjectId: state.projectId,
                CreatedAt: state.createdAt,
                DeletedAt: state.deletedAt,
                Deleted: state.deleted,
                Schedule: state.schedule,
            };


            this._updateLaboScheduleStatesGQL.mutate({
                LaboScheduleStates_set_input: input,
                LaboScheduleStates_bool_exp: {
                    Id: {
                        _eq: input.Id
                    }
                }
            }).subscribe(result => { // todo: better error handling
                resolve(result);
            });

        });
    }

    deleteState(state: LaboScheduleState): Promise<any> {

        // TODO: Check if this should become a soft delete (the database is prepared)

        return new Promise((resolve, reject) => {

            this._deleteLaboScheduleStatesGQL.mutate({
                LaboScheduleStates_bool_exp: {
                    Id: {
                        _eq: state.id
                    }
                }
            }).subscribe(result => { // todo: better error handling
                resolve(result);
            });

        });
    }

    deleteStatePlanning(id: string): Promise<any> {

        return new Promise((resolve, reject) => {

            this._deleteLaboScheduleStatesPlanningGQL.mutate({
                LaboScheduleStatesPlanning_bool_exp: {
                    Id: {
                        _eq: id
                    }
                }
            }).subscribe(result => { // todo: better error handling
                resolve(result);
            });

        });
    }
}
