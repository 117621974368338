<mat-table #table [dataSource]="projects">
    <!-- Button Column -->
    <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button mat-icon-button class="secondary-text" (click)="openProject(row)">
                <mat-icon class="s-20 project-list-mat-icon">arrow_right_alt</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <mat-header-cell ngi *matHeaderCellDef>
            <ng-container *ngIf="!brandingService.brand.MyPlantsTableTitle || brandingService.brand.MyPlantsTableTitle === ''">
                Plant
            </ng-container>
            <ng-container *ngIf="brandingService.brand.MyPlantsTableTitle && brandingService.brand.MyPlantsTableTitle !== ''">
                {{ brandingService.brand.MyPlantsTableTitle }}
            </ng-container>
        </mat-header-cell>
        
        <mat-cell *matCellDef="let row">
            <p class="text-truncate font-weight-600"><strong>{{row.name}}</strong></p>
        </mat-cell>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Location</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
            <p class="text-truncate">{{row.locations[0].city}}, {{row.locations[0].country}}</p>
        </mat-cell>
    </ng-container>

    <!-- Type Column -->
    <!-- <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Type</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
            <p class="text-truncate">{{row.type}}</p>
        </mat-cell>
    </ng-container> -->

        <!-- KPI Column -->
    <!-- <ng-container matColumnDef="kpi">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>KPI</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
            <kpi-indicator [project]="row" [showDot]="true"></kpi-indicator>
        </mat-cell>
    </ng-container> -->

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             matRipple>
    </mat-row>
</mat-table>