import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IChatStateModel } from '../../models/chat-state.model';
import { ChatStateService } from '../../services/chat-state.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  public searchInput = new FormControl();
  public chatState: IChatStateModel;

  private _startSearchLength = 3;

  constructor(private chatService: ChatService, private chatStateService: ChatStateService, private el: ElementRef) { }

  ngOnInit(): void {
    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;

      if(this.chatState.isSearchOpened) {
        const input = this.el.nativeElement.getElementsByClassName('search-message-input')[0];
        input.focus();
      }
    });

    this.searchInput.valueChanges.pipe(debounceTime(1000))
    .subscribe((res) => {

      if(res.length >= this._startSearchLength) {
        this.chatService.setSearchValue(res);

        this.chatState.isSearchOpened = true;
        this.chatStateService.state = this.chatState;
      }
    });
  }

  public closeSearch(): void {
    this.chatState.isSearchOpened = false;
    this.chatState.isShowSearchMessagesWhenSubjectClosed = false;

    this.chatStateService.state = this.chatState;
    this.searchInput.setValue('');
    this.chatService.setSearchValue('');
  }

}
