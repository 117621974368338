export type Maybe<T> = T | null;

/** Ordering options when selecting data from "Branding". */
export interface BrandingOrderBy {
    AlertsEnabled?: Maybe<OrderBy>;

    AlertsTitle?: Maybe<OrderBy>;

    AllowedProjects?: Maybe<OrderBy>;

    CalendarEnabled?: Maybe<OrderBy>;

    CalendarTitle?: Maybe<OrderBy>;

    Domain?: Maybe<OrderBy>;

    EnableBigLogo?: Maybe<OrderBy>;

    FavIcon?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    LaboratoryEnabled?: Maybe<OrderBy>;

    LaboratoryTitle?: Maybe<OrderBy>;

    LogbookEnabled?: Maybe<OrderBy>;

    LogbookTitle?: Maybe<OrderBy>;

    Logo?: Maybe<OrderBy>;

    MaintenanceEnabled?: Maybe<OrderBy>;

    MaintenanceTitle?: Maybe<OrderBy>;

    MyPlantsTableTitle?: Maybe<OrderBy>;

    MyPlantsTitle?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    OperatorEnabled?: Maybe<OrderBy>;

    OperatorTitle?: Maybe<OrderBy>;

    PlantEnabled?: Maybe<OrderBy>;

    PlantOverrideWithPFDUnitId?: Maybe<OrderBy>;

    PlantTitle?: Maybe<OrderBy>;

    PrimaryColor?: Maybe<OrderBy>;

    ReportsEnabled?: Maybe<OrderBy>;

    ReportsTitle?: Maybe<OrderBy>;

    SecondaryColor?: Maybe<OrderBy>;

    TechnologiesEnabled?: Maybe<OrderBy>;

    TechnologiesTitle?: Maybe<OrderBy>;

    UtilitiesEnabled?: Maybe<OrderBy>;

    UtilitiesTitle?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "Branding". All fields are combined with a logical 'AND'. */
export interface BrandingBoolExp {
    AlertsEnabled?: Maybe<BooleanComparisonExp>;

    AlertsTitle?: Maybe<StringComparisonExp>;

    AllowedProjects?: Maybe<JsonbComparisonExp>;

    CalendarEnabled?: Maybe<BooleanComparisonExp>;

    CalendarTitle?: Maybe<StringComparisonExp>;

    Domain?: Maybe<StringComparisonExp>;

    EnableBigLogo?: Maybe<BooleanComparisonExp>;

    FavIcon?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    LaboratoryEnabled?: Maybe<BooleanComparisonExp>;

    LaboratoryTitle?: Maybe<StringComparisonExp>;

    LogbookEnabled?: Maybe<BooleanComparisonExp>;

    LogbookTitle?: Maybe<StringComparisonExp>;

    Logo?: Maybe<StringComparisonExp>;

    MaintenanceEnabled?: Maybe<BooleanComparisonExp>;

    MaintenanceTitle?: Maybe<StringComparisonExp>;

    MyPlantsTableTitle?: Maybe<StringComparisonExp>;

    MyPlantsTitle?: Maybe<StringComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    OperatorEnabled?: Maybe<BooleanComparisonExp>;

    OperatorTitle?: Maybe<StringComparisonExp>;

    PlantEnabled?: Maybe<BooleanComparisonExp>;

    PlantOverrideWithPFDUnitId?: Maybe<StringComparisonExp>;

    PlantTitle?: Maybe<StringComparisonExp>;

    PrimaryColor?: Maybe<StringComparisonExp>;

    ReportsEnabled?: Maybe<BooleanComparisonExp>;

    ReportsTitle?: Maybe<StringComparisonExp>;

    SecondaryColor?: Maybe<StringComparisonExp>;

    TechnologiesEnabled?: Maybe<BooleanComparisonExp>;

    TechnologiesTitle?: Maybe<StringComparisonExp>;

    UtilitiesEnabled?: Maybe<BooleanComparisonExp>;

    UtilitiesTitle?: Maybe<StringComparisonExp>;

    _and?: Maybe<BrandingBoolExp[]>;

    _not?: Maybe<BrandingBoolExp>;

    _or?: Maybe<BrandingBoolExp[]>;
}
/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface BooleanComparisonExp {
    _eq?: Maybe<boolean>;

    _gt?: Maybe<boolean>;

    _gte?: Maybe<boolean>;

    _in?: Maybe<boolean[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<boolean>;

    _lte?: Maybe<boolean>;

    _neq?: Maybe<boolean>;

    _nin?: Maybe<boolean[]>;
}
/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface StringComparisonExp {
    _eq?: Maybe<string>;

    _gt?: Maybe<string>;

    _gte?: Maybe<string>;
    /** does the column match the given case-insensitive pattern */
    _ilike?: Maybe<string>;

    _in?: Maybe<string[]>;
    /** does the column match the given POSIX regular expression, case insensitive */
    _iregex?: Maybe<string>;

    _is_null?: Maybe<boolean>;
    /** does the column match the given pattern */
    _like?: Maybe<string>;

    _lt?: Maybe<string>;

    _lte?: Maybe<string>;

    _neq?: Maybe<string>;
    /** does the column NOT match the given case-insensitive pattern */
    _nilike?: Maybe<string>;

    _nin?: Maybe<string[]>;
    /** does the column NOT match the given POSIX regular expression, case insensitive */
    _niregex?: Maybe<string>;
    /** does the column NOT match the given pattern */
    _nlike?: Maybe<string>;
    /** does the column NOT match the given POSIX regular expression, case sensitive */
    _nregex?: Maybe<string>;
    /** does the column NOT match the given SQL regular expression */
    _nsimilar?: Maybe<string>;
    /** does the column match the given POSIX regular expression, case sensitive */
    _regex?: Maybe<string>;
    /** does the column match the given SQL regular expression */
    _similar?: Maybe<string>;
}
/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface JsonbComparisonExp {
    /** is the column contained in the given json value */
    _contained_in?: Maybe<Jsonb>;
    /** does the column contain the given json value at the top level */
    _contains?: Maybe<Jsonb>;

    _eq?: Maybe<Jsonb>;

    _gt?: Maybe<Jsonb>;

    _gte?: Maybe<Jsonb>;
    /** does the string exist as a top-level key in the column */
    _has_key?: Maybe<string>;
    /** do all of these strings exist as top-level keys in the column */
    _has_keys_all?: Maybe<string[]>;
    /** do any of these strings exist as top-level keys in the column */
    _has_keys_any?: Maybe<string[]>;

    _in?: Maybe<Jsonb[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Jsonb>;

    _lte?: Maybe<Jsonb>;

    _neq?: Maybe<Jsonb>;

    _nin?: Maybe<Jsonb[]>;
}
/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface UuidComparisonExp {
    _eq?: Maybe<Uuid>;

    _gt?: Maybe<Uuid>;

    _gte?: Maybe<Uuid>;

    _in?: Maybe<Uuid[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Uuid>;

    _lte?: Maybe<Uuid>;

    _neq?: Maybe<Uuid>;

    _nin?: Maybe<Uuid[]>;
}
/** Ordering options when selecting data from "CalendarEvents". */
export interface CalendarEventsOrderBy {
    AllDay?: Maybe<OrderBy>;

    ColorPrimary?: Maybe<OrderBy>;

    ColorSecondary?: Maybe<OrderBy>;

    Draggable?: Maybe<OrderBy>;

    End?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MetaLocation?: Maybe<OrderBy>;

    MetaNotes?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ResizableAfterEnd?: Maybe<OrderBy>;

    ResizableBeforeStart?: Maybe<OrderBy>;

    Start?: Maybe<OrderBy>;

    Title?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "CalendarEvents". All fields are combined with a logical 'AND'. */
export interface CalendarEventsBoolExp {
    AllDay?: Maybe<BooleanComparisonExp>;

    ColorPrimary?: Maybe<StringComparisonExp>;

    ColorSecondary?: Maybe<StringComparisonExp>;

    Draggable?: Maybe<BooleanComparisonExp>;

    End?: Maybe<TimestamptzComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    MetaLocation?: Maybe<StringComparisonExp>;

    MetaNotes?: Maybe<StringComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    ResizableAfterEnd?: Maybe<BooleanComparisonExp>;

    ResizableBeforeStart?: Maybe<BooleanComparisonExp>;

    Start?: Maybe<TimestamptzComparisonExp>;

    Title?: Maybe<StringComparisonExp>;

    UserId?: Maybe<StringComparisonExp>;

    _and?: Maybe<CalendarEventsBoolExp[]>;

    _not?: Maybe<CalendarEventsBoolExp>;

    _or?: Maybe<CalendarEventsBoolExp[]>;
}
/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface TimestamptzComparisonExp {
    _eq?: Maybe<Timestamptz>;

    _gt?: Maybe<Timestamptz>;

    _gte?: Maybe<Timestamptz>;

    _in?: Maybe<Timestamptz[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Timestamptz>;

    _lte?: Maybe<Timestamptz>;

    _neq?: Maybe<Timestamptz>;

    _nin?: Maybe<Timestamptz[]>;
}
/** Ordering options when selecting data from "ClientGroups". */
export interface ClientGroupsOrderBy {
    ClientGroupsSmartlabUsers_aggregate?: Maybe<
        ClientGroupsSmartlabUsersAggregateOrderBy
    >;

    ClientIds?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    WepId?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ClientGroupsSmartlabUsersMaxOrderBy>;

    min?: Maybe<ClientGroupsSmartlabUsersMinOrderBy>;
}
/** order by max() on columns of table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersMaxOrderBy {
    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersMinOrderBy {
    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "ClientGroups". All fields are combined with a logical 'AND'. */
export interface ClientGroupsBoolExp {
    ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsersBoolExp>;

    ClientIds?: Maybe<_TextComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    WepId?: Maybe<IntComparisonExp>;

    _and?: Maybe<ClientGroupsBoolExp[]>;

    _not?: Maybe<ClientGroupsBoolExp>;

    _or?: Maybe<ClientGroupsBoolExp[]>;
}
/** Boolean expression to filter rows from the table "ClientGroupsSmartlabUsers". All fields are combined with a logical 'AND'. */
export interface ClientGroupsSmartlabUsersBoolExp {
    ClientGroup?: Maybe<ClientGroupsBoolExp>;

    ClientGroupId?: Maybe<UuidComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    SmartlabUser?: Maybe<SmartlabUsersBoolExp>;

    SmartlabUserId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<ClientGroupsSmartlabUsersBoolExp[]>;

    _not?: Maybe<ClientGroupsSmartlabUsersBoolExp>;

    _or?: Maybe<ClientGroupsSmartlabUsersBoolExp[]>;
}
/** Boolean expression to filter rows from the table "SmartlabUsers". All fields are combined with a logical 'AND'. */
export interface SmartlabUsersBoolExp {
    ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsersBoolExp>;

    Email?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    NameFirst?: Maybe<StringComparisonExp>;

    NameLast?: Maybe<StringComparisonExp>;

    ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;

    SupportChatMembers?: Maybe<SupportChatMembersBoolExp>;

    UserId?: Maybe<StringComparisonExp>;

    _and?: Maybe<SmartlabUsersBoolExp[]>;

    _not?: Maybe<SmartlabUsersBoolExp>;

    _or?: Maybe<SmartlabUsersBoolExp[]>;
}
/** Boolean expression to filter rows from the table "ProjectGroupsSmartlabUsers". All fields are combined with a logical 'AND'. */
export interface ProjectGroupsSmartlabUsersBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    ProjectGroup?: Maybe<ProjectGroupsBoolExp>;

    ProjectGroupId?: Maybe<UuidComparisonExp>;

    SmartlabUser?: Maybe<SmartlabUsersBoolExp>;

    SmartlabUserId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<ProjectGroupsSmartlabUsersBoolExp[]>;

    _not?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;

    _or?: Maybe<ProjectGroupsSmartlabUsersBoolExp[]>;
}
/** Boolean expression to filter rows from the table "ProjectGroups". All fields are combined with a logical 'AND'. */
export interface ProjectGroupsBoolExp {
    ClientGroup?: Maybe<ClientGroupsBoolExp>;

    ClientGroupId?: Maybe<UuidComparisonExp>;

    Code?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    PfdProject?: Maybe<PfdProjectsBoolExp>;

    PfdProjectId?: Maybe<UuidComparisonExp>;

    ProjectCodes?: Maybe<_TextComparisonExp>;

    ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;

    SupportChats?: Maybe<SupportChatsBoolExp>;

    WepId?: Maybe<IntComparisonExp>;

    _and?: Maybe<ProjectGroupsBoolExp[]>;

    _not?: Maybe<ProjectGroupsBoolExp>;

    _or?: Maybe<ProjectGroupsBoolExp[]>;
}
/** Boolean expression to filter rows from the table "PfdProjects". All fields are combined with a logical 'AND'. */
export interface PfdProjectsBoolExp {
    Address?: Maybe<StringComparisonExp>;

    City?: Maybe<StringComparisonExp>;

    Code?: Maybe<StringComparisonExp>;

    Coordinates?: Maybe<StringComparisonExp>;

    Country?: Maybe<StringComparisonExp>;

    ErrorType?: Maybe<IntComparisonExp>;

    Errors?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    TimeZone?: Maybe<StringComparisonExp>;

    Type?: Maybe<StringComparisonExp>;

    WepClientId?: Maybe<IntComparisonExp>;

    WepId?: Maybe<IntComparisonExp>;

    _and?: Maybe<PfdProjectsBoolExp[]>;

    _not?: Maybe<PfdProjectsBoolExp>;

    _or?: Maybe<PfdProjectsBoolExp[]>;
}
/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IntComparisonExp {
    _eq?: Maybe<number>;

    _gt?: Maybe<number>;

    _gte?: Maybe<number>;

    _in?: Maybe<number[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<number>;

    _lte?: Maybe<number>;

    _neq?: Maybe<number>;

    _nin?: Maybe<number[]>;
}
/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export interface _TextComparisonExp {
    _eq?: Maybe<_Text>;

    _gt?: Maybe<_Text>;

    _gte?: Maybe<_Text>;

    _in?: Maybe<_Text[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<_Text>;

    _lte?: Maybe<_Text>;

    _neq?: Maybe<_Text>;

    _nin?: Maybe<_Text[]>;
}
/** Boolean expression to filter rows from the table "SupportChats". All fields are combined with a logical 'AND'. */
export interface SupportChatsBoolExp {
    AuthorInfo?: Maybe<StringComparisonExp>;

    ClosedBy?: Maybe<StringComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ProjectGroup?: Maybe<ProjectGroupsBoolExp>;

    ProjectGroupId?: Maybe<UuidComparisonExp>;

    StartDate?: Maybe<TimestamptzComparisonExp>;

    StopDate?: Maybe<TimestamptzComparisonExp>;

    SupportChatMembers?: Maybe<SupportChatMembersBoolExp>;

    SupportChatMessages?: Maybe<SupportChatMessagesBoolExp>;

    SupportSubject?: Maybe<SupportSubjectsBoolExp>;

    SupportSubjectId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<SupportChatsBoolExp[]>;

    _not?: Maybe<SupportChatsBoolExp>;

    _or?: Maybe<SupportChatsBoolExp[]>;
}
/** Boolean expression to filter rows from the table "SupportChatMembers". All fields are combined with a logical 'AND'. */
export interface SupportChatMembersBoolExp {
    Deleted?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsActive?: Maybe<BooleanComparisonExp>;

    SmartlabUser?: Maybe<SmartlabUsersBoolExp>;

    SmartlabUserId?: Maybe<UuidComparisonExp>;

    SupportChat?: Maybe<SupportChatsBoolExp>;

    SupportChatId?: Maybe<UuidComparisonExp>;

    SupportChatMessageViewers?: Maybe<SupportChatMessageViewersBoolExp>;

    SupportChatMessages?: Maybe<SupportChatMessagesBoolExp>;

    _and?: Maybe<SupportChatMembersBoolExp[]>;

    _not?: Maybe<SupportChatMembersBoolExp>;

    _or?: Maybe<SupportChatMembersBoolExp[]>;
}
/** Boolean expression to filter rows from the table "SupportChatMessageViewers". All fields are combined with a logical 'AND'. */
export interface SupportChatMessageViewersBoolExp {
    Deleted?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    MessageId?: Maybe<UuidComparisonExp>;

    SupportChatMember?: Maybe<SupportChatMembersBoolExp>;

    SupportChatMessage?: Maybe<SupportChatMessagesBoolExp>;

    ViewedDate?: Maybe<TimestamptzComparisonExp>;

    ViewerId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<SupportChatMessageViewersBoolExp[]>;

    _not?: Maybe<SupportChatMessageViewersBoolExp>;

    _or?: Maybe<SupportChatMessageViewersBoolExp[]>;
}
/** Boolean expression to filter rows from the table "SupportChatMessages". All fields are combined with a logical 'AND'. */
export interface SupportChatMessagesBoolExp {
    AuthorId?: Maybe<UuidComparisonExp>;

    AuthorRole?: Maybe<StringComparisonExp>;

    CreatedDate?: Maybe<TimestamptzComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    FileItem?: Maybe<FileItemsBoolExp>;

    FileItemId?: Maybe<BigintComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Notifications?: Maybe<NotificationsBoolExp>;

    SupportChat?: Maybe<SupportChatsBoolExp>;

    SupportChatId?: Maybe<UuidComparisonExp>;

    SupportChatMember?: Maybe<SupportChatMembersBoolExp>;

    SupportChatMessageViewers?: Maybe<SupportChatMessageViewersBoolExp>;

    TextValue?: Maybe<StringComparisonExp>;

    ValueType?: Maybe<SmallintComparisonExp>;

    _and?: Maybe<SupportChatMessagesBoolExp[]>;

    _not?: Maybe<SupportChatMessagesBoolExp>;

    _or?: Maybe<SupportChatMessagesBoolExp[]>;
}
/** Boolean expression to filter rows from the table "FileItems". All fields are combined with a logical 'AND'. */
export interface FileItemsBoolExp {
    FileName?: Maybe<StringComparisonExp>;

    FileReference?: Maybe<StringComparisonExp>;

    FileTypeId?: Maybe<IntComparisonExp>;

    Id?: Maybe<BigintComparisonExp>;

    IsDeleted?: Maybe<BooleanComparisonExp>;

    SupportChatMessages?: Maybe<SupportChatMessagesBoolExp>;

    _and?: Maybe<FileItemsBoolExp[]>;

    _not?: Maybe<FileItemsBoolExp>;

    _or?: Maybe<FileItemsBoolExp[]>;
}
/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface BigintComparisonExp {
    _eq?: Maybe<Bigint>;

    _gt?: Maybe<Bigint>;

    _gte?: Maybe<Bigint>;

    _in?: Maybe<Bigint[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Bigint>;

    _lte?: Maybe<Bigint>;

    _neq?: Maybe<Bigint>;

    _nin?: Maybe<Bigint[]>;
}
/** Boolean expression to filter rows from the table "Notifications". All fields are combined with a logical 'AND'. */
export interface NotificationsBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    MessageId?: Maybe<UuidComparisonExp>;

    NotificationTypeId?: Maybe<UuidComparisonExp>;

    ProjectId?: Maybe<UuidComparisonExp>;

    SubjectId?: Maybe<UuidComparisonExp>;

    UserFromId?: Maybe<UuidComparisonExp>;

    UserToId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<NotificationsBoolExp[]>;

    _not?: Maybe<NotificationsBoolExp>;

    _or?: Maybe<NotificationsBoolExp[]>;
}
/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export interface SmallintComparisonExp {
    _eq?: Maybe<Smallint>;

    _gt?: Maybe<Smallint>;

    _gte?: Maybe<Smallint>;

    _in?: Maybe<Smallint[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Smallint>;

    _lte?: Maybe<Smallint>;

    _neq?: Maybe<Smallint>;

    _nin?: Maybe<Smallint[]>;
}
/** Boolean expression to filter rows from the table "SupportSubjects". All fields are combined with a logical 'AND'. */
export interface SupportSubjectsBoolExp {
    Children?: Maybe<SupportSubjectsBoolExp>;

    ContainsPFDUnitLinksForCurrentProject?: Maybe<BooleanComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsActive?: Maybe<BooleanComparisonExp>;

    IsEnabledForOperatorChat?: Maybe<BooleanComparisonExp>;

    Order?: Maybe<IntComparisonExp>;

    Parent?: Maybe<SupportSubjectsBoolExp>;

    ParentId?: Maybe<UuidComparisonExp>;

    SupportChats?: Maybe<SupportChatsBoolExp>;

    Title?: Maybe<StringComparisonExp>;

    _and?: Maybe<SupportSubjectsBoolExp[]>;

    _not?: Maybe<SupportSubjectsBoolExp>;

    _or?: Maybe<SupportSubjectsBoolExp[]>;
}
/** Ordering options when selecting data from "ClientGroupsSmartlabUsers". */
export interface ClientGroupsSmartlabUsersOrderBy {
    ClientGroup?: Maybe<ClientGroupsOrderBy>;

    ClientGroupId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SmartlabUser?: Maybe<SmartlabUsersOrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "SmartlabUsers". */
export interface SmartlabUsersOrderBy {
    ClientGroupsSmartlabUsers_aggregate?: Maybe<
        ClientGroupsSmartlabUsersAggregateOrderBy
    >;

    Email?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    NameFirst?: Maybe<OrderBy>;

    NameLast?: Maybe<OrderBy>;

    ProjectGroupsSmartlabUsers_aggregate?: Maybe<
        ProjectGroupsSmartlabUsersAggregateOrderBy
    >;

    SupportChatMembers_aggregate?: Maybe<SupportChatMembersAggregateOrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ProjectGroupsSmartlabUsersMaxOrderBy>;

    min?: Maybe<ProjectGroupsSmartlabUsersMinOrderBy>;
}
/** order by max() on columns of table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersMaxOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersMinOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** order by aggregate values of table "SupportChatMembers" */
export interface SupportChatMembersAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<SupportChatMembersMaxOrderBy>;

    min?: Maybe<SupportChatMembersMinOrderBy>;
}
/** order by max() on columns of table "SupportChatMembers" */
export interface SupportChatMembersMaxOrderBy {
    Id?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;

    SupportChatId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SupportChatMembers" */
export interface SupportChatMembersMinOrderBy {
    Id?: Maybe<OrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;

    SupportChatId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "ProjectGroupsSmartlabUsers". */
export interface ProjectGroupsSmartlabUsersOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectGroup?: Maybe<ProjectGroupsOrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    SmartlabUser?: Maybe<SmartlabUsersOrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "ProjectGroups". */
export interface ProjectGroupsOrderBy {
    ClientGroup?: Maybe<ClientGroupsOrderBy>;

    ClientGroupId?: Maybe<OrderBy>;

    Code?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    PfdProject?: Maybe<PfdProjectsOrderBy>;

    PfdProjectId?: Maybe<OrderBy>;

    ProjectCodes?: Maybe<OrderBy>;

    ProjectGroupsSmartlabUsers_aggregate?: Maybe<
        ProjectGroupsSmartlabUsersAggregateOrderBy
    >;

    SupportChats_aggregate?: Maybe<SupportChatsAggregateOrderBy>;

    WepId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "PfdProjects". */
export interface PfdProjectsOrderBy {
    Address?: Maybe<OrderBy>;

    City?: Maybe<OrderBy>;

    Code?: Maybe<OrderBy>;

    Coordinates?: Maybe<OrderBy>;

    Country?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    TimeZone?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;

    WepClientId?: Maybe<OrderBy>;

    WepId?: Maybe<OrderBy>;
}
/** order by aggregate values of table "SupportChats" */
export interface SupportChatsAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<SupportChatsMaxOrderBy>;

    min?: Maybe<SupportChatsMinOrderBy>;
}
/** order by max() on columns of table "SupportChats" */
export interface SupportChatsMaxOrderBy {
    AuthorInfo?: Maybe<OrderBy>;

    ClosedBy?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    StartDate?: Maybe<OrderBy>;

    StopDate?: Maybe<OrderBy>;

    SupportSubjectId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SupportChats" */
export interface SupportChatsMinOrderBy {
    AuthorInfo?: Maybe<OrderBy>;

    ClosedBy?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    StartDate?: Maybe<OrderBy>;

    StopDate?: Maybe<OrderBy>;

    SupportSubjectId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "SupportChats". */
export interface SupportChatsOrderBy {
    AuthorInfo?: Maybe<OrderBy>;

    ClosedBy?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectGroup?: Maybe<ProjectGroupsOrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    StartDate?: Maybe<OrderBy>;

    StopDate?: Maybe<OrderBy>;

    SupportChatMembers_aggregate?: Maybe<SupportChatMembersAggregateOrderBy>;

    SupportChatMessages_aggregate?: Maybe<SupportChatMessagesAggregateOrderBy>;

    SupportSubject?: Maybe<SupportSubjectsOrderBy>;

    SupportSubjectId?: Maybe<OrderBy>;
}
/** order by aggregate values of table "SupportChatMessages" */
export interface SupportChatMessagesAggregateOrderBy {
    avg?: Maybe<SupportChatMessagesAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<SupportChatMessagesMaxOrderBy>;

    min?: Maybe<SupportChatMessagesMinOrderBy>;

    stddev?: Maybe<SupportChatMessagesStddevOrderBy>;

    stddev_pop?: Maybe<SupportChatMessagesStddevPopOrderBy>;

    stddev_samp?: Maybe<SupportChatMessagesStddevSampOrderBy>;

    sum?: Maybe<SupportChatMessagesSumOrderBy>;

    var_pop?: Maybe<SupportChatMessagesVarPopOrderBy>;

    var_samp?: Maybe<SupportChatMessagesVarSampOrderBy>;

    variance?: Maybe<SupportChatMessagesVarianceOrderBy>;
}
/** order by avg() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesAvgOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by max() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesMaxOrderBy {
    AuthorId?: Maybe<OrderBy>;

    AuthorRole?: Maybe<OrderBy>;

    CreatedDate?: Maybe<OrderBy>;

    FileItemId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SupportChatId?: Maybe<OrderBy>;

    TextValue?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesMinOrderBy {
    AuthorId?: Maybe<OrderBy>;

    AuthorRole?: Maybe<OrderBy>;

    CreatedDate?: Maybe<OrderBy>;

    FileItemId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    SupportChatId?: Maybe<OrderBy>;

    TextValue?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesStddevOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesStddevPopOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesStddevSampOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesSumOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesVarPopOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesVarSampOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "SupportChatMessages" */
export interface SupportChatMessagesVarianceOrderBy {
    FileItemId?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "SupportSubjects". */
export interface SupportSubjectsOrderBy {
    Children_aggregate?: Maybe<SupportSubjectsAggregateOrderBy>;

    ContainsPFDUnitLinksForCurrentProject?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsActive?: Maybe<OrderBy>;

    IsEnabledForOperatorChat?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    Parent?: Maybe<SupportSubjectsOrderBy>;

    ParentId?: Maybe<OrderBy>;

    SupportChats_aggregate?: Maybe<SupportChatsAggregateOrderBy>;

    Title?: Maybe<OrderBy>;
}
/** order by aggregate values of table "SupportSubjects" */
export interface SupportSubjectsAggregateOrderBy {
    avg?: Maybe<SupportSubjectsAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<SupportSubjectsMaxOrderBy>;

    min?: Maybe<SupportSubjectsMinOrderBy>;

    stddev?: Maybe<SupportSubjectsStddevOrderBy>;

    stddev_pop?: Maybe<SupportSubjectsStddevPopOrderBy>;

    stddev_samp?: Maybe<SupportSubjectsStddevSampOrderBy>;

    sum?: Maybe<SupportSubjectsSumOrderBy>;

    var_pop?: Maybe<SupportSubjectsVarPopOrderBy>;

    var_samp?: Maybe<SupportSubjectsVarSampOrderBy>;

    variance?: Maybe<SupportSubjectsVarianceOrderBy>;
}
/** order by avg() on columns of table "SupportSubjects" */
export interface SupportSubjectsAvgOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by max() on columns of table "SupportSubjects" */
export interface SupportSubjectsMaxOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    ParentId?: Maybe<OrderBy>;

    Title?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SupportSubjects" */
export interface SupportSubjectsMinOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    ParentId?: Maybe<OrderBy>;

    Title?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "SupportSubjects" */
export interface SupportSubjectsStddevOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "SupportSubjects" */
export interface SupportSubjectsStddevPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "SupportSubjects" */
export interface SupportSubjectsStddevSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "SupportSubjects" */
export interface SupportSubjectsSumOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "SupportSubjects" */
export interface SupportSubjectsVarPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "SupportSubjects" */
export interface SupportSubjectsVarSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "SupportSubjects" */
export interface SupportSubjectsVarianceOrderBy {
    Order?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "SupportChatMembers". */
export interface SupportChatMembersOrderBy {
    Deleted?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsActive?: Maybe<OrderBy>;

    SmartlabUser?: Maybe<SmartlabUsersOrderBy>;

    SmartlabUserId?: Maybe<OrderBy>;

    SupportChat?: Maybe<SupportChatsOrderBy>;

    SupportChatId?: Maybe<OrderBy>;

    SupportChatMessageViewers_aggregate?: Maybe<
        SupportChatMessageViewersAggregateOrderBy
    >;

    SupportChatMessages_aggregate?: Maybe<SupportChatMessagesAggregateOrderBy>;
}
/** order by aggregate values of table "SupportChatMessageViewers" */
export interface SupportChatMessageViewersAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<SupportChatMessageViewersMaxOrderBy>;

    min?: Maybe<SupportChatMessageViewersMinOrderBy>;
}
/** order by max() on columns of table "SupportChatMessageViewers" */
export interface SupportChatMessageViewersMaxOrderBy {
    Id?: Maybe<OrderBy>;

    MessageId?: Maybe<OrderBy>;

    ViewedDate?: Maybe<OrderBy>;

    ViewerId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SupportChatMessageViewers" */
export interface SupportChatMessageViewersMinOrderBy {
    Id?: Maybe<OrderBy>;

    MessageId?: Maybe<OrderBy>;

    ViewedDate?: Maybe<OrderBy>;

    ViewerId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "SupportChatMessageViewers". */
export interface SupportChatMessageViewersOrderBy {
    Deleted?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MessageId?: Maybe<OrderBy>;

    SupportChatMember?: Maybe<SupportChatMembersOrderBy>;

    SupportChatMessage?: Maybe<SupportChatMessagesOrderBy>;

    ViewedDate?: Maybe<OrderBy>;

    ViewerId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "SupportChatMessages". */
export interface SupportChatMessagesOrderBy {
    AuthorId?: Maybe<OrderBy>;

    AuthorRole?: Maybe<OrderBy>;

    CreatedDate?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    FileItem?: Maybe<FileItemsOrderBy>;

    FileItemId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Notifications_aggregate?: Maybe<NotificationsAggregateOrderBy>;

    SupportChat?: Maybe<SupportChatsOrderBy>;

    SupportChatId?: Maybe<OrderBy>;

    SupportChatMember?: Maybe<SupportChatMembersOrderBy>;

    SupportChatMessageViewers_aggregate?: Maybe<
        SupportChatMessageViewersAggregateOrderBy
    >;

    TextValue?: Maybe<OrderBy>;

    ValueType?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "FileItems". */
export interface FileItemsOrderBy {
    FileName?: Maybe<OrderBy>;

    FileReference?: Maybe<OrderBy>;

    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsDeleted?: Maybe<OrderBy>;

    SupportChatMessages_aggregate?: Maybe<SupportChatMessagesAggregateOrderBy>;
}
/** order by aggregate values of table "Notifications" */
export interface NotificationsAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<NotificationsMaxOrderBy>;

    min?: Maybe<NotificationsMinOrderBy>;
}
/** order by max() on columns of table "Notifications" */
export interface NotificationsMaxOrderBy {
    Id?: Maybe<OrderBy>;

    MessageId?: Maybe<OrderBy>;

    NotificationTypeId?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    SubjectId?: Maybe<OrderBy>;

    UserFromId?: Maybe<OrderBy>;

    UserToId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "Notifications" */
export interface NotificationsMinOrderBy {
    Id?: Maybe<OrderBy>;

    MessageId?: Maybe<OrderBy>;

    NotificationTypeId?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    SubjectId?: Maybe<OrderBy>;

    UserFromId?: Maybe<OrderBy>;

    UserToId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "Notifications". */
export interface NotificationsOrderBy {
    Id?: Maybe<OrderBy>;

    MessageId?: Maybe<OrderBy>;

    NotificationTypeId?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    SubjectId?: Maybe<OrderBy>;

    UserFromId?: Maybe<OrderBy>;

    UserToId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "CumulDashboards". */
export interface CumulDashboardsOrderBy {
    DashboardId?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedAt?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "CumulDashboards". All fields are combined with a logical 'AND'. */
export interface CumulDashboardsBoolExp {
    DashboardId?: Maybe<StringComparisonExp>;

    Errors?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ModifiedAt?: Maybe<TimestampComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    _and?: Maybe<CumulDashboardsBoolExp[]>;

    _not?: Maybe<CumulDashboardsBoolExp>;

    _or?: Maybe<CumulDashboardsBoolExp[]>;
}
/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface TimestampComparisonExp {
    _eq?: Maybe<Timestamp>;

    _gt?: Maybe<Timestamp>;

    _gte?: Maybe<Timestamp>;

    _in?: Maybe<Timestamp[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Timestamp>;

    _lte?: Maybe<Timestamp>;

    _neq?: Maybe<Timestamp>;

    _nin?: Maybe<Timestamp[]>;
}
/** Ordering options when selecting data from "CumulTokens". */
export interface CumulTokensOrderBy {
    AuthorizationId?: Maybe<OrderBy>;

    AuthorizationToken?: Maybe<OrderBy>;

    CreatedAt?: Maybe<OrderBy>;

    HashedRequestSignature?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Revoked?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;

    ValidUntil?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "CumulTokens". All fields are combined with a logical 'AND'. */
export interface CumulTokensBoolExp {
    AuthorizationId?: Maybe<StringComparisonExp>;

    AuthorizationToken?: Maybe<StringComparisonExp>;

    CreatedAt?: Maybe<TimestampComparisonExp>;

    HashedRequestSignature?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Revoked?: Maybe<BooleanComparisonExp>;

    UserId?: Maybe<StringComparisonExp>;

    ValidUntil?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<CumulTokensBoolExp[]>;

    _not?: Maybe<CumulTokensBoolExp>;

    _or?: Maybe<CumulTokensBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcConfig". */
export interface DataProcConfigOrderBy {
    DataConnectionDescription?: Maybe<OrderBy>;

    DataConnectionFlags?: Maybe<OrderBy>;

    DataProcProjectState?: Maybe<DataProcProjectStateOrderBy>;

    DataProcTagStates_aggregate?: Maybe<DataProcTagStateAggregateOrderBy>;

    Enable?: Maybe<OrderBy>;

    EnableDataProcessing?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    MeasurementCutoffDate?: Maybe<OrderBy>;

    MeasurementDatabase?: Maybe<DatapointDatabaseOrderBy>;

    MeasurementDatabaseId?: Maybe<OrderBy>;

    MeasurementPullConfigurations_aggregate?: Maybe<
        DataProcConfigMeasurementPullAggregateOrderBy
    >;

    PfdProject?: Maybe<PfdProjectsOrderBy>;

    PfdProjectId?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ServerId?: Maybe<OrderBy>;

    SignalDatabase?: Maybe<DatapointDatabaseOrderBy>;

    SignalDatabaseId?: Maybe<OrderBy>;

    UpdatedServer?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DataProcProjectState". */
export interface DataProcProjectStateOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    EndedDate?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsLatestMeasurementDateOld?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    KpiDate?: Maybe<OrderBy>;

    KpiValue?: Maybe<OrderBy>;

    LatestMeasurementDate?: Maybe<OrderBy>;

    StartedDate?: Maybe<OrderBy>;

    TagProcessingEndedDate?: Maybe<OrderBy>;

    TagProcessingErrorMessage?: Maybe<OrderBy>;

    TagProcessingPointsProcessed?: Maybe<OrderBy>;

    TagProcessingStartedDate?: Maybe<OrderBy>;

    TagProcessingTagsDoneProcessing?: Maybe<OrderBy>;

    TagProcessingTagsProcessed?: Maybe<OrderBy>;

    TagProcessingTotalPointsProcessed?: Maybe<OrderBy>;

    TotalState?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcTagState" */
export interface DataProcTagStateAggregateOrderBy {
    avg?: Maybe<DataProcTagStateAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcTagStateMaxOrderBy>;

    min?: Maybe<DataProcTagStateMinOrderBy>;

    stddev?: Maybe<DataProcTagStateStddevOrderBy>;

    stddev_pop?: Maybe<DataProcTagStateStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcTagStateStddevSampOrderBy>;

    sum?: Maybe<DataProcTagStateSumOrderBy>;

    var_pop?: Maybe<DataProcTagStateVarPopOrderBy>;

    var_samp?: Maybe<DataProcTagStateVarSampOrderBy>;

    variance?: Maybe<DataProcTagStateVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcTagState" */
export interface DataProcTagStateAvgOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcTagState" */
export interface DataProcTagStateMaxOrderBy {
    BufferId?: Maybe<OrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    FirstPendingDate?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    LastDependencyDate?: Maybe<OrderBy>;

    LastPendingDate?: Maybe<OrderBy>;

    LastProcessedDate?: Maybe<OrderBy>;

    MeasurementId?: Maybe<OrderBy>;

    ParentWithOldestLastDatapointId?: Maybe<OrderBy>;

    PfdTagId?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    ProcessedRangeDone?: Maybe<OrderBy>;

    ProcessedRangeFrom?: Maybe<OrderBy>;

    ProcessedRangeTo?: Maybe<OrderBy>;

    ProcessingEndedDate?: Maybe<OrderBy>;

    ProcessingStartedDate?: Maybe<OrderBy>;

    SignalId?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcTagState" */
export interface DataProcTagStateMinOrderBy {
    BufferId?: Maybe<OrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    FirstPendingDate?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    LastDependencyDate?: Maybe<OrderBy>;

    LastPendingDate?: Maybe<OrderBy>;

    LastProcessedDate?: Maybe<OrderBy>;

    MeasurementId?: Maybe<OrderBy>;

    ParentWithOldestLastDatapointId?: Maybe<OrderBy>;

    PfdTagId?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    ProcessedRangeDone?: Maybe<OrderBy>;

    ProcessedRangeFrom?: Maybe<OrderBy>;

    ProcessedRangeTo?: Maybe<OrderBy>;

    ProcessingEndedDate?: Maybe<OrderBy>;

    ProcessingStartedDate?: Maybe<OrderBy>;

    SignalId?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcTagState" */
export interface DataProcTagStateStddevOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcTagState" */
export interface DataProcTagStateStddevPopOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcTagState" */
export interface DataProcTagStateStddevSampOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcTagState" */
export interface DataProcTagStateSumOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcTagState" */
export interface DataProcTagStateVarPopOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcTagState" */
export interface DataProcTagStateVarSampOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcTagState" */
export interface DataProcTagStateVarianceOrderBy {
    ErrorType?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DatapointDatabase". */
export interface DatapointDatabaseOrderBy {
    ConfigPathCredentials?: Maybe<OrderBy>;

    Config_aggregate?: Maybe<DataProcConfigAggregateOrderBy>;

    CreateAllGenericTables?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Host?: Maybe<DatapointHostOrderBy>;

    HostId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ParentId?: Maybe<OrderBy>;

    Schema?: Maybe<OrderBy>;

    SignalConfig_aggregate?: Maybe<DataProcConfigAggregateOrderBy>;
}
/** order by aggregate values of table "DataProcConfig" */
export interface DataProcConfigAggregateOrderBy {
    avg?: Maybe<DataProcConfigAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfigMaxOrderBy>;

    min?: Maybe<DataProcConfigMinOrderBy>;

    stddev?: Maybe<DataProcConfigStddevOrderBy>;

    stddev_pop?: Maybe<DataProcConfigStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcConfigStddevSampOrderBy>;

    sum?: Maybe<DataProcConfigSumOrderBy>;

    var_pop?: Maybe<DataProcConfigVarPopOrderBy>;

    var_samp?: Maybe<DataProcConfigVarSampOrderBy>;

    variance?: Maybe<DataProcConfigVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcConfig" */
export interface DataProcConfigAvgOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcConfig" */
export interface DataProcConfigMaxOrderBy {
    DataConnectionDescription?: Maybe<OrderBy>;

    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    MeasurementCutoffDate?: Maybe<OrderBy>;

    MeasurementDatabaseId?: Maybe<OrderBy>;

    PfdProjectId?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ServerId?: Maybe<OrderBy>;

    SignalDatabaseId?: Maybe<OrderBy>;

    UpdatedServer?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfig" */
export interface DataProcConfigMinOrderBy {
    DataConnectionDescription?: Maybe<OrderBy>;

    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    MeasurementCutoffDate?: Maybe<OrderBy>;

    MeasurementDatabaseId?: Maybe<OrderBy>;

    PfdProjectId?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ServerId?: Maybe<OrderBy>;

    SignalDatabaseId?: Maybe<OrderBy>;

    UpdatedServer?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcConfig" */
export interface DataProcConfigStddevOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcConfig" */
export interface DataProcConfigStddevPopOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcConfig" */
export interface DataProcConfigStddevSampOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcConfig" */
export interface DataProcConfigSumOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcConfig" */
export interface DataProcConfigVarPopOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcConfig" */
export interface DataProcConfigVarSampOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcConfig" */
export interface DataProcConfigVarianceOrderBy {
    DataConnectionFlags?: Maybe<OrderBy>;

    FrequencyMinutes?: Maybe<OrderBy>;

    MaxMeasurementAgeMinutes?: Maybe<OrderBy>;

    ProcessorVersion?: Maybe<OrderBy>;

    WorkersPriorityHigh?: Maybe<OrderBy>;

    WorkersPriorityLow?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DatapointHost". */
export interface DatapointHostOrderBy {
    Alias?: Maybe<OrderBy>;

    ConfigPathCredentials?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Host?: Maybe<OrderBy>;

    HostType?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsTest?: Maybe<OrderBy>;

    Parameters?: Maybe<OrderBy>;

    Port?: Maybe<OrderBy>;

    Portal?: Maybe<OrderBy>;
}
/** order by aggregate values of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullAggregateOrderBy {
    avg?: Maybe<DataProcConfigMeasurementPullAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcConfigMeasurementPullMaxOrderBy>;

    min?: Maybe<DataProcConfigMeasurementPullMinOrderBy>;

    stddev?: Maybe<DataProcConfigMeasurementPullStddevOrderBy>;

    stddev_pop?: Maybe<DataProcConfigMeasurementPullStddevPopOrderBy>;

    stddev_samp?: Maybe<DataProcConfigMeasurementPullStddevSampOrderBy>;

    sum?: Maybe<DataProcConfigMeasurementPullSumOrderBy>;

    var_pop?: Maybe<DataProcConfigMeasurementPullVarPopOrderBy>;

    var_samp?: Maybe<DataProcConfigMeasurementPullVarSampOrderBy>;

    variance?: Maybe<DataProcConfigMeasurementPullVarianceOrderBy>;
}
/** order by avg() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullAvgOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** order by max() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullMaxOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    DatabaseId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Json?: Maybe<OrderBy>;

    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;

    ServiceType?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullMinOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    DatabaseId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Json?: Maybe<OrderBy>;

    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;

    ServiceType?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullStddevOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullStddevPopOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullStddevSampOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullSumOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullVarPopOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullVarSampOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullVarianceOrderBy {
    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcConfig". All fields are combined with a logical 'AND'. */
export interface DataProcConfigBoolExp {
    DataConnectionDescription?: Maybe<StringComparisonExp>;

    DataConnectionFlags?: Maybe<IntComparisonExp>;

    DataProcProjectState?: Maybe<DataProcProjectStateBoolExp>;

    DataProcTagStates?: Maybe<DataProcTagStateBoolExp>;

    Enable?: Maybe<BooleanComparisonExp>;

    EnableDataProcessing?: Maybe<BooleanComparisonExp>;

    FrequencyMinutes?: Maybe<BigintComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    MaxMeasurementAgeMinutes?: Maybe<BigintComparisonExp>;

    MeasurementCutoffDate?: Maybe<TimestampComparisonExp>;

    MeasurementDatabase?: Maybe<DatapointDatabaseBoolExp>;

    MeasurementDatabaseId?: Maybe<UuidComparisonExp>;

    MeasurementPullConfigurations?: Maybe<DataProcConfigMeasurementPullBoolExp>;

    PfdProject?: Maybe<PfdProjectsBoolExp>;

    PfdProjectId?: Maybe<UuidComparisonExp>;

    ProcessorVersion?: Maybe<IntComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    ServerId?: Maybe<UuidComparisonExp>;

    SignalDatabase?: Maybe<DatapointDatabaseBoolExp>;

    SignalDatabaseId?: Maybe<UuidComparisonExp>;

    UpdatedServer?: Maybe<TimestampComparisonExp>;

    WorkersPriorityHigh?: Maybe<IntComparisonExp>;

    WorkersPriorityLow?: Maybe<IntComparisonExp>;

    _and?: Maybe<DataProcConfigBoolExp[]>;

    _not?: Maybe<DataProcConfigBoolExp>;

    _or?: Maybe<DataProcConfigBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DataProcProjectState". All fields are combined with a logical 'AND'. */
export interface DataProcProjectStateBoolExp {
    ConfigurationId?: Maybe<UuidComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    EndedDate?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsLatestMeasurementDateOld?: Maybe<BooleanComparisonExp>;

    JobNr?: Maybe<NumericComparisonExp>;

    KpiDate?: Maybe<TimestampComparisonExp>;

    KpiValue?: Maybe<Float8ComparisonExp>;

    LatestMeasurementDate?: Maybe<TimestampComparisonExp>;

    StartedDate?: Maybe<TimestampComparisonExp>;

    TagProcessingEndedDate?: Maybe<TimestampComparisonExp>;

    TagProcessingErrorMessage?: Maybe<StringComparisonExp>;

    TagProcessingPointsProcessed?: Maybe<BigintComparisonExp>;

    TagProcessingStartedDate?: Maybe<TimestampComparisonExp>;

    TagProcessingTagsDoneProcessing?: Maybe<BigintComparisonExp>;

    TagProcessingTagsProcessed?: Maybe<BigintComparisonExp>;

    TagProcessingTotalPointsProcessed?: Maybe<NumericComparisonExp>;

    TotalState?: Maybe<IntComparisonExp>;

    Updated?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<DataProcProjectStateBoolExp[]>;

    _not?: Maybe<DataProcProjectStateBoolExp>;

    _or?: Maybe<DataProcProjectStateBoolExp[]>;
}
/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface NumericComparisonExp {
    _eq?: Maybe<Numeric>;

    _gt?: Maybe<Numeric>;

    _gte?: Maybe<Numeric>;

    _in?: Maybe<Numeric[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Numeric>;

    _lte?: Maybe<Numeric>;

    _neq?: Maybe<Numeric>;

    _nin?: Maybe<Numeric[]>;
}
/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface Float8ComparisonExp {
    _eq?: Maybe<Float8>;

    _gt?: Maybe<Float8>;

    _gte?: Maybe<Float8>;

    _in?: Maybe<Float8[]>;

    _is_null?: Maybe<boolean>;

    _lt?: Maybe<Float8>;

    _lte?: Maybe<Float8>;

    _neq?: Maybe<Float8>;

    _nin?: Maybe<Float8[]>;
}
/** Boolean expression to filter rows from the table "DataProcTagState". All fields are combined with a logical 'AND'. */
export interface DataProcTagStateBoolExp {
    Buffer?: Maybe<DatapointSeriesBoolExp>;

    BufferId?: Maybe<UuidComparisonExp>;

    ConfigurationId?: Maybe<UuidComparisonExp>;

    Created?: Maybe<TimestampComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    ErrorType?: Maybe<IntComparisonExp>;

    Errors?: Maybe<StringComparisonExp>;

    FirstPendingDate?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsProcessing?: Maybe<BooleanComparisonExp>;

    JobNr?: Maybe<NumericComparisonExp>;

    LastDependencyDate?: Maybe<TimestampComparisonExp>;

    LastPendingDate?: Maybe<TimestampComparisonExp>;

    LastProcessedDate?: Maybe<TimestampComparisonExp>;

    Measurement?: Maybe<DatapointSeriesBoolExp>;

    MeasurementId?: Maybe<UuidComparisonExp>;

    ParentWithOldestLastDatapointId?: Maybe<UuidComparisonExp>;

    PfdTag?: Maybe<PfdTagsBoolExp>;

    PfdTagId?: Maybe<UuidComparisonExp>;

    PointsProcessed?: Maybe<BigintComparisonExp>;

    ProcessedRangeDone?: Maybe<TimestampComparisonExp>;

    ProcessedRangeFrom?: Maybe<TimestampComparisonExp>;

    ProcessedRangeTo?: Maybe<TimestampComparisonExp>;

    ProcessingEndedDate?: Maybe<TimestampComparisonExp>;

    ProcessingStartedDate?: Maybe<TimestampComparisonExp>;

    Signal?: Maybe<DatapointSeriesBoolExp>;

    SignalId?: Maybe<UuidComparisonExp>;

    Updated?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<DataProcTagStateBoolExp[]>;

    _not?: Maybe<DataProcTagStateBoolExp>;

    _or?: Maybe<DataProcTagStateBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DatapointSeries". All fields are combined with a logical 'AND'. */
export interface DatapointSeriesBoolExp {
    Alias?: Maybe<StringComparisonExp>;

    Created?: Maybe<TimestampComparisonExp>;

    Filters?: Maybe<JsonbComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    State?: Maybe<IntComparisonExp>;

    Table?: Maybe<DatapointTableBoolExp>;

    TableId?: Maybe<UuidComparisonExp>;

    TagBuffer?: Maybe<DataProcTagStateBoolExp>;

    Tags?: Maybe<DataProcTagStateBoolExp>;

    TotalRangeFrom?: Maybe<TimestampComparisonExp>;

    TotalRangeTo?: Maybe<TimestampComparisonExp>;

    Type?: Maybe<IntComparisonExp>;

    Updated?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<DatapointSeriesBoolExp[]>;

    _not?: Maybe<DatapointSeriesBoolExp>;

    _or?: Maybe<DatapointSeriesBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DatapointTable". All fields are combined with a logical 'AND'. */
export interface DatapointTableBoolExp {
    Created?: Maybe<TimestampComparisonExp>;

    DataType?: Maybe<IntComparisonExp>;

    Database?: Maybe<DatapointDatabaseBoolExp>;

    DatabaseId?: Maybe<UuidComparisonExp>;

    Filters?: Maybe<JsonbComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    TableCreated?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<DatapointTableBoolExp[]>;

    _not?: Maybe<DatapointTableBoolExp>;

    _or?: Maybe<DatapointTableBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DatapointDatabase". All fields are combined with a logical 'AND'. */
export interface DatapointDatabaseBoolExp {
    Config?: Maybe<DataProcConfigBoolExp>;

    ConfigPathCredentials?: Maybe<StringComparisonExp>;

    CreateAllGenericTables?: Maybe<BooleanComparisonExp>;

    Created?: Maybe<TimestampComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Host?: Maybe<DatapointHostBoolExp>;

    HostId?: Maybe<UuidComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    ParentId?: Maybe<UuidComparisonExp>;

    Schema?: Maybe<StringComparisonExp>;

    SignalConfig?: Maybe<DataProcConfigBoolExp>;

    _and?: Maybe<DatapointDatabaseBoolExp[]>;

    _not?: Maybe<DatapointDatabaseBoolExp>;

    _or?: Maybe<DatapointDatabaseBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DatapointHost". All fields are combined with a logical 'AND'. */
export interface DatapointHostBoolExp {
    Alias?: Maybe<StringComparisonExp>;

    ConfigPathCredentials?: Maybe<StringComparisonExp>;

    Created?: Maybe<TimestampComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Host?: Maybe<StringComparisonExp>;

    HostType?: Maybe<IntComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsTest?: Maybe<BooleanComparisonExp>;

    Parameters?: Maybe<JsonbComparisonExp>;

    Port?: Maybe<IntComparisonExp>;

    Portal?: Maybe<StringComparisonExp>;

    _and?: Maybe<DatapointHostBoolExp[]>;

    _not?: Maybe<DatapointHostBoolExp>;

    _or?: Maybe<DatapointHostBoolExp[]>;
}
/** Boolean expression to filter rows from the table "PfdTags". All fields are combined with a logical 'AND'. */
export interface PfdTagsBoolExp {
    AggregationDatapointTime?: Maybe<IntComparisonExp>;

    AggregationDatapointTimeMultiplier?: Maybe<IntComparisonExp>;

    AggregationIsFaked?: Maybe<BooleanComparisonExp>;

    AggregationTime?: Maybe<IntComparisonExp>;

    AggregationTimeMultiplier?: Maybe<IntComparisonExp>;

    AggregationTimeSource?: Maybe<IntComparisonExp>;

    AggregationType?: Maybe<IntComparisonExp>;

    Block?: Maybe<PfdBlocksBoolExp>;

    BlockId?: Maybe<UuidComparisonExp>;

    Children?: Maybe<PfdProjectTagDependencyModelBoolExp>;

    DataType?: Maybe<IntComparisonExp>;

    DefinitionId?: Maybe<UuidComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Enabled?: Maybe<BooleanComparisonExp>;

    ErrorType?: Maybe<IntComparisonExp>;

    Errors?: Maybe<StringComparisonExp>;

    Formula?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsDefinition?: Maybe<BooleanComparisonExp>;

    IsParameter?: Maybe<BooleanComparisonExp>;

    IsProjectTag?: Maybe<BooleanComparisonExp>;

    Limit_h?: Maybe<Float8ComparisonExp>;

    Limit_hh?: Maybe<Float8ComparisonExp>;

    Limit_l?: Maybe<Float8ComparisonExp>;

    Limit_ll?: Maybe<Float8ComparisonExp>;

    MeasurementName?: Maybe<StringComparisonExp>;

    MeasurementNameSF?: Maybe<StringComparisonExp>;

    MeasurementType?: Maybe<IntComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    Parents?: Maybe<PfdProjectTagDependencyModelBoolExp>;

    Project?: Maybe<PfdProjectsBoolExp>;

    ProjectId?: Maybe<UuidComparisonExp>;

    Quantity?: Maybe<StringComparisonExp>;

    ReportPfdTagLinks?: Maybe<ReportPfdTagLinksBoolExp>;

    Samplingpoint?: Maybe<PfdSamplingpointsBoolExp>;

    SamplingpointId?: Maybe<UuidComparisonExp>;

    Source?: Maybe<IntComparisonExp>;

    Substance?: Maybe<StringComparisonExp>;

    TagDefinitionId?: Maybe<IntComparisonExp>;

    TagId?: Maybe<IntComparisonExp>;

    Unit?: Maybe<StringComparisonExp>;

    _and?: Maybe<PfdTagsBoolExp[]>;

    _not?: Maybe<PfdTagsBoolExp>;

    _or?: Maybe<PfdTagsBoolExp[]>;
}
/** Boolean expression to filter rows from the table "PfdBlocks". All fields are combined with a logical 'AND'. */
export interface PfdBlocksBoolExp {
    BlockDefinitionId?: Maybe<IntComparisonExp>;

    BlockId?: Maybe<IntComparisonExp>;

    Child?: Maybe<PfdBlocksBoolExp>;

    ChildId?: Maybe<UuidComparisonExp>;

    Definition?: Maybe<PfdBlocksBoolExp>;

    DefinitionId?: Maybe<UuidComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    ErrorType?: Maybe<IntComparisonExp>;

    Errors?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    IsDefinition?: Maybe<BooleanComparisonExp>;

    IsMaster?: Maybe<BooleanComparisonExp>;

    IsStream?: Maybe<BooleanComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    NameShort?: Maybe<StringComparisonExp>;

    Order?: Maybe<IntComparisonExp>;

    Parents?: Maybe<PfdBlocksBoolExp>;

    Project?: Maybe<PfdProjectsBoolExp>;

    ProjectId?: Maybe<UuidComparisonExp>;

    SmartlabMenuEnabled?: Maybe<BooleanComparisonExp>;

    SmartlabPFDUnitLinks?: Maybe<SmartlabPfdUnitLinksBoolExp>;

    _and?: Maybe<PfdBlocksBoolExp[]>;

    _not?: Maybe<PfdBlocksBoolExp>;

    _or?: Maybe<PfdBlocksBoolExp[]>;
}
/** Boolean expression to filter rows from the table "SmartlabPFDUnitLinks". All fields are combined with a logical 'AND'. */
export interface SmartlabPfdUnitLinksBoolExp {
    Description?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Link?: Maybe<StringComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    Order?: Maybe<IntComparisonExp>;

    PFDTagLink?: Maybe<StringComparisonExp>;

    PFDUnitId?: Maybe<StringComparisonExp>;

    PfdBlock?: Maybe<PfdBlocksBoolExp>;

    PfdBlockId?: Maybe<UuidComparisonExp>;

    SkipBlocks?: Maybe<BooleanComparisonExp>;

    Targets?: Maybe<_TextComparisonExp>;

    Type?: Maybe<StringComparisonExp>;

    _and?: Maybe<SmartlabPfdUnitLinksBoolExp[]>;

    _not?: Maybe<SmartlabPfdUnitLinksBoolExp>;

    _or?: Maybe<SmartlabPfdUnitLinksBoolExp[]>;
}
/** Boolean expression to filter rows from the table "PfdProjectTagDependencyModel". All fields are combined with a logical 'AND'. */
export interface PfdProjectTagDependencyModelBoolExp {
    Child?: Maybe<PfdTagsBoolExp>;

    ChildId?: Maybe<UuidComparisonExp>;

    Parent?: Maybe<PfdTagsBoolExp>;

    ParentId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<PfdProjectTagDependencyModelBoolExp[]>;

    _not?: Maybe<PfdProjectTagDependencyModelBoolExp>;

    _or?: Maybe<PfdProjectTagDependencyModelBoolExp[]>;
}
/** Boolean expression to filter rows from the table "ReportPfdTagLinks". All fields are combined with a logical 'AND'. */
export interface ReportPfdTagLinksBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    Order?: Maybe<IntComparisonExp>;

    ProjectTemplate?: Maybe<ReportProjectTemplatesBoolExp>;

    ProjectTemplateId?: Maybe<UuidComparisonExp>;

    Tag?: Maybe<PfdTagsBoolExp>;

    TagId?: Maybe<UuidComparisonExp>;

    Template?: Maybe<ReportTemplatesBoolExp>;

    TemplateId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<ReportPfdTagLinksBoolExp[]>;

    _not?: Maybe<ReportPfdTagLinksBoolExp>;

    _or?: Maybe<ReportPfdTagLinksBoolExp[]>;
}
/** Boolean expression to filter rows from the table "ReportProjectTemplates". All fields are combined with a logical 'AND'. */
export interface ReportProjectTemplatesBoolExp {
    CreatedDate?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    DeletedDate?: Maybe<TimestampComparisonExp>;

    FileTypes?: Maybe<IntComparisonExp>;

    Frequency?: Maybe<StringComparisonExp>;

    GenerateAtHour?: Maybe<IntComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ModifiedDate?: Maybe<TimestampComparisonExp>;

    NextGenerationDate?: Maybe<TimestampComparisonExp>;

    Parameters?: Maybe<JsonbComparisonExp>;

    ReportTemplate?: Maybe<ReportTemplatesBoolExp>;

    ReportTemplateId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<ReportProjectTemplatesBoolExp[]>;

    _not?: Maybe<ReportProjectTemplatesBoolExp>;

    _or?: Maybe<ReportProjectTemplatesBoolExp[]>;

    projectCode?: Maybe<StringComparisonExp>;
}
/** Boolean expression to filter rows from the table "ReportTemplates". All fields are combined with a logical 'AND'. */
export interface ReportTemplatesBoolExp {
    CreatedDate?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    DeletedDate?: Maybe<TimestampComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ModifiedDate?: Maybe<TimestampComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    ReportGenerated?: Maybe<ReportGeneratedBoolExp>;

    ReportProjectTemplates?: Maybe<ReportProjectTemplatesBoolExp>;

    ReportTemplateBlocks?: Maybe<JsonbComparisonExp>;

    ReportType?: Maybe<IntComparisonExp>;

    Version?: Maybe<StringComparisonExp>;

    _and?: Maybe<ReportTemplatesBoolExp[]>;

    _not?: Maybe<ReportTemplatesBoolExp>;

    _or?: Maybe<ReportTemplatesBoolExp[]>;
}
/** Boolean expression to filter rows from the table "ReportGenerated". All fields are combined with a logical 'AND'. */
export interface ReportGeneratedBoolExp {
    Created?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    DocUrl?: Maybe<StringComparisonExp>;

    DynamicDataSnapshot?: Maybe<JsonbComparisonExp>;

    Errors?: Maybe<_TextComparisonExp>;

    Finished?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ProjectConfigurationSnapshot?: Maybe<JsonbComparisonExp>;

    ProjectReportTemplateId?: Maybe<UuidComparisonExp>;

    ProjectReportTemplateSnapshot?: Maybe<JsonbComparisonExp>;

    ReportDateTime?: Maybe<TimestampComparisonExp>;

    ReportTemplate?: Maybe<ReportTemplatesBoolExp>;

    ReportTemplateId?: Maybe<UuidComparisonExp>;

    State?: Maybe<StringComparisonExp>;

    Version?: Maybe<StringComparisonExp>;

    Warnings?: Maybe<_TextComparisonExp>;

    WebUrl?: Maybe<StringComparisonExp>;

    _and?: Maybe<ReportGeneratedBoolExp[]>;

    _not?: Maybe<ReportGeneratedBoolExp>;

    _or?: Maybe<ReportGeneratedBoolExp[]>;

    projectCode?: Maybe<StringComparisonExp>;
}
/** Boolean expression to filter rows from the table "PfdSamplingpoints". All fields are combined with a logical 'AND'. */
export interface PfdSamplingpointsBoolExp {
    Description?: Maybe<StringComparisonExp>;

    EnableForLab?: Maybe<BooleanComparisonExp>;

    ErrorType?: Maybe<IntComparisonExp>;

    Errors?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    Order?: Maybe<IntComparisonExp>;

    Project?: Maybe<PfdProjectsBoolExp>;

    ProjectId?: Maybe<UuidComparisonExp>;

    SamplingpointDefinitionId?: Maybe<IntComparisonExp>;

    SamplingpointId?: Maybe<IntComparisonExp>;

    _and?: Maybe<PfdSamplingpointsBoolExp[]>;

    _not?: Maybe<PfdSamplingpointsBoolExp>;

    _or?: Maybe<PfdSamplingpointsBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DataProcConfigMeasurementPull". All fields are combined with a logical 'AND'. */
export interface DataProcConfigMeasurementPullBoolExp {
    Configuration?: Maybe<DataProcConfigBoolExp>;

    ConfigurationId?: Maybe<UuidComparisonExp>;

    Database?: Maybe<DatapointDatabaseBoolExp>;

    DatabaseId?: Maybe<UuidComparisonExp>;

    Enabled?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Json?: Maybe<StringComparisonExp>;

    MaxDataIterations?: Maybe<IntComparisonExp>;

    MaxDataRowTarget?: Maybe<IntComparisonExp>;

    ServiceType?: Maybe<StringComparisonExp>;

    State?: Maybe<DataProcStateMeasurementPullBoolExp>;

    _and?: Maybe<DataProcConfigMeasurementPullBoolExp[]>;

    _not?: Maybe<DataProcConfigMeasurementPullBoolExp>;

    _or?: Maybe<DataProcConfigMeasurementPullBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DataProcStateMeasurementPull". All fields are combined with a logical 'AND'. */
export interface DataProcStateMeasurementPullBoolExp {
    Configuration?: Maybe<DataProcConfigMeasurementPullBoolExp>;

    ConfigurationId?: Maybe<UuidComparisonExp>;

    EndedDate?: Maybe<TimestampComparisonExp>;

    Json?: Maybe<StringComparisonExp>;

    Loops?: Maybe<BigintComparisonExp>;

    PointsReceived?: Maybe<NumericComparisonExp>;

    StartedDate?: Maybe<TimestampComparisonExp>;

    StateDate?: Maybe<TimestampComparisonExp>;

    StateMessage?: Maybe<StringComparisonExp>;

    StateType?: Maybe<StringComparisonExp>;

    TotalPointsReceived?: Maybe<NumericComparisonExp>;

    Updated?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<DataProcStateMeasurementPullBoolExp[]>;

    _not?: Maybe<DataProcStateMeasurementPullBoolExp>;

    _or?: Maybe<DataProcStateMeasurementPullBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcTagState". */
export interface DataProcTagStateOrderBy {
    Buffer?: Maybe<DatapointSeriesOrderBy>;

    BufferId?: Maybe<OrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    FirstPendingDate?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsProcessing?: Maybe<OrderBy>;

    JobNr?: Maybe<OrderBy>;

    LastDependencyDate?: Maybe<OrderBy>;

    LastPendingDate?: Maybe<OrderBy>;

    LastProcessedDate?: Maybe<OrderBy>;

    Measurement?: Maybe<DatapointSeriesOrderBy>;

    MeasurementId?: Maybe<OrderBy>;

    ParentWithOldestLastDatapointId?: Maybe<OrderBy>;

    PfdTag?: Maybe<PfdTagsOrderBy>;

    PfdTagId?: Maybe<OrderBy>;

    PointsProcessed?: Maybe<OrderBy>;

    ProcessedRangeDone?: Maybe<OrderBy>;

    ProcessedRangeFrom?: Maybe<OrderBy>;

    ProcessedRangeTo?: Maybe<OrderBy>;

    ProcessingEndedDate?: Maybe<OrderBy>;

    ProcessingStartedDate?: Maybe<OrderBy>;

    Signal?: Maybe<DatapointSeriesOrderBy>;

    SignalId?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DatapointSeries". */
export interface DatapointSeriesOrderBy {
    Alias?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    Filters?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    State?: Maybe<OrderBy>;

    Table?: Maybe<DatapointTableOrderBy>;

    TableId?: Maybe<OrderBy>;

    TagBuffer?: Maybe<DataProcTagStateOrderBy>;

    Tags_aggregate?: Maybe<DataProcTagStateAggregateOrderBy>;

    TotalRangeFrom?: Maybe<OrderBy>;

    TotalRangeTo?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DatapointTable". */
export interface DatapointTableOrderBy {
    Created?: Maybe<OrderBy>;

    DataType?: Maybe<OrderBy>;

    Database?: Maybe<DatapointDatabaseOrderBy>;

    DatabaseId?: Maybe<OrderBy>;

    Filters?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    TableCreated?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "PfdTags". */
export interface PfdTagsOrderBy {
    AggregationDatapointTime?: Maybe<OrderBy>;

    AggregationDatapointTimeMultiplier?: Maybe<OrderBy>;

    AggregationIsFaked?: Maybe<OrderBy>;

    AggregationTime?: Maybe<OrderBy>;

    AggregationTimeMultiplier?: Maybe<OrderBy>;

    AggregationTimeSource?: Maybe<OrderBy>;

    AggregationType?: Maybe<OrderBy>;

    Block?: Maybe<PfdBlocksOrderBy>;

    BlockId?: Maybe<OrderBy>;

    Children_aggregate?: Maybe<PfdProjectTagDependencyModelAggregateOrderBy>;

    DataType?: Maybe<OrderBy>;

    DefinitionId?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Enabled?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Formula?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsDefinition?: Maybe<OrderBy>;

    IsParameter?: Maybe<OrderBy>;

    IsProjectTag?: Maybe<OrderBy>;

    Limit_h?: Maybe<OrderBy>;

    Limit_hh?: Maybe<OrderBy>;

    Limit_l?: Maybe<OrderBy>;

    Limit_ll?: Maybe<OrderBy>;

    MeasurementName?: Maybe<OrderBy>;

    MeasurementNameSF?: Maybe<OrderBy>;

    MeasurementType?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Parents_aggregate?: Maybe<PfdProjectTagDependencyModelAggregateOrderBy>;

    Project?: Maybe<PfdProjectsOrderBy>;

    ProjectId?: Maybe<OrderBy>;

    Quantity?: Maybe<OrderBy>;

    ReportPfdTagLinks_aggregate?: Maybe<ReportPfdTagLinksAggregateOrderBy>;

    Samplingpoint?: Maybe<PfdSamplingpointsOrderBy>;

    SamplingpointId?: Maybe<OrderBy>;

    Source?: Maybe<OrderBy>;

    Substance?: Maybe<OrderBy>;

    TagDefinitionId?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;

    Unit?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "PfdBlocks". */
export interface PfdBlocksOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    Child?: Maybe<PfdBlocksOrderBy>;

    ChildId?: Maybe<OrderBy>;

    Definition?: Maybe<PfdBlocksOrderBy>;

    DefinitionId?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    IsDefinition?: Maybe<OrderBy>;

    IsMaster?: Maybe<OrderBy>;

    IsStream?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    NameShort?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    Parents_aggregate?: Maybe<PfdBlocksAggregateOrderBy>;

    Project?: Maybe<PfdProjectsOrderBy>;

    ProjectId?: Maybe<OrderBy>;

    SmartlabMenuEnabled?: Maybe<OrderBy>;

    SmartlabPFDUnitLinks_aggregate?: Maybe<
        SmartlabPfdUnitLinksAggregateOrderBy
    >;
}
/** order by aggregate values of table "PfdBlocks" */
export interface PfdBlocksAggregateOrderBy {
    avg?: Maybe<PfdBlocksAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<PfdBlocksMaxOrderBy>;

    min?: Maybe<PfdBlocksMinOrderBy>;

    stddev?: Maybe<PfdBlocksStddevOrderBy>;

    stddev_pop?: Maybe<PfdBlocksStddevPopOrderBy>;

    stddev_samp?: Maybe<PfdBlocksStddevSampOrderBy>;

    sum?: Maybe<PfdBlocksSumOrderBy>;

    var_pop?: Maybe<PfdBlocksVarPopOrderBy>;

    var_samp?: Maybe<PfdBlocksVarSampOrderBy>;

    variance?: Maybe<PfdBlocksVarianceOrderBy>;
}
/** order by avg() on columns of table "PfdBlocks" */
export interface PfdBlocksAvgOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by max() on columns of table "PfdBlocks" */
export interface PfdBlocksMaxOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ChildId?: Maybe<OrderBy>;

    DefinitionId?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    NameShort?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "PfdBlocks" */
export interface PfdBlocksMinOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ChildId?: Maybe<OrderBy>;

    DefinitionId?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    NameShort?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "PfdBlocks" */
export interface PfdBlocksStddevOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "PfdBlocks" */
export interface PfdBlocksStddevPopOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "PfdBlocks" */
export interface PfdBlocksStddevSampOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "PfdBlocks" */
export interface PfdBlocksSumOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "PfdBlocks" */
export interface PfdBlocksVarPopOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "PfdBlocks" */
export interface PfdBlocksVarSampOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "PfdBlocks" */
export interface PfdBlocksVarianceOrderBy {
    BlockDefinitionId?: Maybe<OrderBy>;

    BlockId?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;
}
/** order by aggregate values of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksAggregateOrderBy {
    avg?: Maybe<SmartlabPfdUnitLinksAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<SmartlabPfdUnitLinksMaxOrderBy>;

    min?: Maybe<SmartlabPfdUnitLinksMinOrderBy>;

    stddev?: Maybe<SmartlabPfdUnitLinksStddevOrderBy>;

    stddev_pop?: Maybe<SmartlabPfdUnitLinksStddevPopOrderBy>;

    stddev_samp?: Maybe<SmartlabPfdUnitLinksStddevSampOrderBy>;

    sum?: Maybe<SmartlabPfdUnitLinksSumOrderBy>;

    var_pop?: Maybe<SmartlabPfdUnitLinksVarPopOrderBy>;

    var_samp?: Maybe<SmartlabPfdUnitLinksVarSampOrderBy>;

    variance?: Maybe<SmartlabPfdUnitLinksVarianceOrderBy>;
}
/** order by avg() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksAvgOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by max() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksMaxOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Link?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    PFDTagLink?: Maybe<OrderBy>;

    PFDUnitId?: Maybe<OrderBy>;

    PfdBlockId?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;
}
/** order by min() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksMinOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Link?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    PFDTagLink?: Maybe<OrderBy>;

    PFDUnitId?: Maybe<OrderBy>;

    PfdBlockId?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksStddevOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksStddevPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksStddevSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksSumOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksVarPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksVarSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksVarianceOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by aggregate values of table "PfdProjectTagDependencyModel" */
export interface PfdProjectTagDependencyModelAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<PfdProjectTagDependencyModelMaxOrderBy>;

    min?: Maybe<PfdProjectTagDependencyModelMinOrderBy>;
}
/** order by max() on columns of table "PfdProjectTagDependencyModel" */
export interface PfdProjectTagDependencyModelMaxOrderBy {
    ChildId?: Maybe<OrderBy>;

    ParentId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "PfdProjectTagDependencyModel" */
export interface PfdProjectTagDependencyModelMinOrderBy {
    ChildId?: Maybe<OrderBy>;

    ParentId?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksAggregateOrderBy {
    avg?: Maybe<ReportPfdTagLinksAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<ReportPfdTagLinksMaxOrderBy>;

    min?: Maybe<ReportPfdTagLinksMinOrderBy>;

    stddev?: Maybe<ReportPfdTagLinksStddevOrderBy>;

    stddev_pop?: Maybe<ReportPfdTagLinksStddevPopOrderBy>;

    stddev_samp?: Maybe<ReportPfdTagLinksStddevSampOrderBy>;

    sum?: Maybe<ReportPfdTagLinksSumOrderBy>;

    var_pop?: Maybe<ReportPfdTagLinksVarPopOrderBy>;

    var_samp?: Maybe<ReportPfdTagLinksVarSampOrderBy>;

    variance?: Maybe<ReportPfdTagLinksVarianceOrderBy>;
}
/** order by avg() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksAvgOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by max() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksMaxOrderBy {
    Id?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    ProjectTemplateId?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;

    TemplateId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksMinOrderBy {
    Id?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    ProjectTemplateId?: Maybe<OrderBy>;

    TagId?: Maybe<OrderBy>;

    TemplateId?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksStddevOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksStddevPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksStddevSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksSumOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksVarPopOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksVarSampOrderBy {
    Order?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksVarianceOrderBy {
    Order?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "PfdSamplingpoints". */
export interface PfdSamplingpointsOrderBy {
    Description?: Maybe<OrderBy>;

    EnableForLab?: Maybe<OrderBy>;

    ErrorType?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    Project?: Maybe<PfdProjectsOrderBy>;

    ProjectId?: Maybe<OrderBy>;

    SamplingpointDefinitionId?: Maybe<OrderBy>;

    SamplingpointId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "SmartlabPFDUnitLinks". */
export interface SmartlabPfdUnitLinksOrderBy {
    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Link?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    PFDTagLink?: Maybe<OrderBy>;

    PFDUnitId?: Maybe<OrderBy>;

    PfdBlock?: Maybe<PfdBlocksOrderBy>;

    PfdBlockId?: Maybe<OrderBy>;

    SkipBlocks?: Maybe<OrderBy>;

    Targets?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "PfdProjectTagDependencyModel". */
export interface PfdProjectTagDependencyModelOrderBy {
    Child?: Maybe<PfdTagsOrderBy>;

    ChildId?: Maybe<OrderBy>;

    Parent?: Maybe<PfdTagsOrderBy>;

    ParentId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "ReportPfdTagLinks". */
export interface ReportPfdTagLinksOrderBy {
    Id?: Maybe<OrderBy>;

    Order?: Maybe<OrderBy>;

    ProjectTemplate?: Maybe<ReportProjectTemplatesOrderBy>;

    ProjectTemplateId?: Maybe<OrderBy>;

    Tag?: Maybe<PfdTagsOrderBy>;

    TagId?: Maybe<OrderBy>;

    Template?: Maybe<ReportTemplatesOrderBy>;

    TemplateId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "ReportProjectTemplates". */
export interface ReportProjectTemplatesOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    FileTypes?: Maybe<OrderBy>;

    Frequency?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    NextGenerationDate?: Maybe<OrderBy>;

    Parameters?: Maybe<OrderBy>;

    ReportTemplate?: Maybe<ReportTemplatesOrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "ReportTemplates". */
export interface ReportTemplatesOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ReportGenerated_aggregate?: Maybe<ReportGeneratedAggregateOrderBy>;

    ReportProjectTemplates_aggregate?: Maybe<
        ReportProjectTemplatesAggregateOrderBy
    >;

    ReportTemplateBlocks?: Maybe<OrderBy>;

    ReportType?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ReportGenerated" */
export interface ReportGeneratedAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<ReportGeneratedMaxOrderBy>;

    min?: Maybe<ReportGeneratedMinOrderBy>;
}
/** order by max() on columns of table "ReportGenerated" */
export interface ReportGeneratedMaxOrderBy {
    Created?: Maybe<OrderBy>;

    DocUrl?: Maybe<OrderBy>;

    Finished?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectReportTemplateId?: Maybe<OrderBy>;

    ReportDateTime?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    State?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ReportGenerated" */
export interface ReportGeneratedMinOrderBy {
    Created?: Maybe<OrderBy>;

    DocUrl?: Maybe<OrderBy>;

    Finished?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectReportTemplateId?: Maybe<OrderBy>;

    ReportDateTime?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    State?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** order by aggregate values of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesAggregateOrderBy {
    avg?: Maybe<ReportProjectTemplatesAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<ReportProjectTemplatesMaxOrderBy>;

    min?: Maybe<ReportProjectTemplatesMinOrderBy>;

    stddev?: Maybe<ReportProjectTemplatesStddevOrderBy>;

    stddev_pop?: Maybe<ReportProjectTemplatesStddevPopOrderBy>;

    stddev_samp?: Maybe<ReportProjectTemplatesStddevSampOrderBy>;

    sum?: Maybe<ReportProjectTemplatesSumOrderBy>;

    var_pop?: Maybe<ReportProjectTemplatesVarPopOrderBy>;

    var_samp?: Maybe<ReportProjectTemplatesVarSampOrderBy>;

    variance?: Maybe<ReportProjectTemplatesVarianceOrderBy>;
}
/** order by avg() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesAvgOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** order by max() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesMaxOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    FileTypes?: Maybe<OrderBy>;

    Frequency?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    NextGenerationDate?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** order by min() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesMinOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    DeletedDate?: Maybe<OrderBy>;

    FileTypes?: Maybe<OrderBy>;

    Frequency?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModifiedDate?: Maybe<OrderBy>;

    NextGenerationDate?: Maybe<OrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesStddevOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesStddevPopOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesStddevSampOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesSumOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesVarPopOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesVarSampOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "ReportProjectTemplates" */
export interface ReportProjectTemplatesVarianceOrderBy {
    FileTypes?: Maybe<OrderBy>;

    GenerateAtHour?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "ReportGenerated". */
export interface ReportGeneratedOrderBy {
    Created?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    DocUrl?: Maybe<OrderBy>;

    DynamicDataSnapshot?: Maybe<OrderBy>;

    Errors?: Maybe<OrderBy>;

    Finished?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectConfigurationSnapshot?: Maybe<OrderBy>;

    ProjectReportTemplateId?: Maybe<OrderBy>;

    ProjectReportTemplateSnapshot?: Maybe<OrderBy>;

    ReportDateTime?: Maybe<OrderBy>;

    ReportTemplate?: Maybe<ReportTemplatesOrderBy>;

    ReportTemplateId?: Maybe<OrderBy>;

    State?: Maybe<OrderBy>;

    Version?: Maybe<OrderBy>;

    Warnings?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;

    projectCode?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DataProcConfigMeasurementPull". */
export interface DataProcConfigMeasurementPullOrderBy {
    Configuration?: Maybe<DataProcConfigOrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Database?: Maybe<DatapointDatabaseOrderBy>;

    DatabaseId?: Maybe<OrderBy>;

    Enabled?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Json?: Maybe<OrderBy>;

    MaxDataIterations?: Maybe<OrderBy>;

    MaxDataRowTarget?: Maybe<OrderBy>;

    ServiceType?: Maybe<OrderBy>;

    State?: Maybe<DataProcStateMeasurementPullOrderBy>;
}
/** Ordering options when selecting data from "DataProcStateMeasurementPull". */
export interface DataProcStateMeasurementPullOrderBy {
    Configuration?: Maybe<DataProcConfigMeasurementPullOrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    EndedDate?: Maybe<OrderBy>;

    Json?: Maybe<OrderBy>;

    Loops?: Maybe<OrderBy>;

    PointsReceived?: Maybe<OrderBy>;

    StartedDate?: Maybe<OrderBy>;

    StateDate?: Maybe<OrderBy>;

    StateMessage?: Maybe<OrderBy>;

    StateType?: Maybe<OrderBy>;

    TotalPointsReceived?: Maybe<OrderBy>;

    Updated?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DataProcServer". */
export interface DataProcServerOrderBy {
    Created?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Identifier?: Maybe<OrderBy>;

    LastPing?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcServer". All fields are combined with a logical 'AND'. */
export interface DataProcServerBoolExp {
    Created?: Maybe<TimestampComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Identifier?: Maybe<StringComparisonExp>;

    LastPing?: Maybe<TimestampComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    _and?: Maybe<DataProcServerBoolExp[]>;

    _not?: Maybe<DataProcServerBoolExp>;

    _or?: Maybe<DataProcServerBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcState". */
export interface DataProcStateOrderBy {
    Id?: Maybe<OrderBy>;

    SchedulerLastRunDate?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcState". All fields are combined with a logical 'AND'. */
export interface DataProcStateBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    SchedulerLastRunDate?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<DataProcStateBoolExp[]>;

    _not?: Maybe<DataProcStateBoolExp>;

    _or?: Maybe<DataProcStateBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcStateEntityHistory". */
export interface DataProcStateEntityHistoryOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModelId?: Maybe<OrderBy>;

    ModelType?: Maybe<OrderBy>;

    StateDate?: Maybe<OrderBy>;

    StateMessage?: Maybe<OrderBy>;

    StateType?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcStateEntityHistory". All fields are combined with a logical 'AND'. */
export interface DataProcStateEntityHistoryBoolExp {
    ConfigurationId?: Maybe<UuidComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ModelId?: Maybe<UuidComparisonExp>;

    ModelType?: Maybe<StringComparisonExp>;

    StateDate?: Maybe<TimestampComparisonExp>;

    StateMessage?: Maybe<StringComparisonExp>;

    StateType?: Maybe<StringComparisonExp>;

    _and?: Maybe<DataProcStateEntityHistoryBoolExp[]>;

    _not?: Maybe<DataProcStateEntityHistoryBoolExp>;

    _or?: Maybe<DataProcStateEntityHistoryBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcStateTree". */
export interface DataProcStateTreeOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    CurrentWorkJson?: Maybe<OrderBy>;

    DataProcConfig?: Maybe<DataProcConfigOrderBy>;

    DatapointSeriesOperationLastDate?: Maybe<OrderBy>;

    PfdChangeLogLastDate?: Maybe<OrderBy>;

    StateDate?: Maybe<OrderBy>;

    StateMessage?: Maybe<OrderBy>;

    StateType?: Maybe<OrderBy>;

    TreePingDate?: Maybe<OrderBy>;

    TreeStarted?: Maybe<OrderBy>;

    TreeStopped?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcStateTree". All fields are combined with a logical 'AND'. */
export interface DataProcStateTreeBoolExp {
    ConfigurationId?: Maybe<UuidComparisonExp>;

    CurrentWorkJson?: Maybe<StringComparisonExp>;

    DataProcConfig?: Maybe<DataProcConfigBoolExp>;

    DatapointSeriesOperationLastDate?: Maybe<TimestampComparisonExp>;

    PfdChangeLogLastDate?: Maybe<TimestampComparisonExp>;

    StateDate?: Maybe<TimestampComparisonExp>;

    StateMessage?: Maybe<StringComparisonExp>;

    StateType?: Maybe<StringComparisonExp>;

    TreePingDate?: Maybe<TimestampComparisonExp>;

    TreeStarted?: Maybe<TimestampComparisonExp>;

    TreeStopped?: Maybe<TimestampComparisonExp>;

    _and?: Maybe<DataProcStateTreeBoolExp[]>;

    _not?: Maybe<DataProcStateTreeBoolExp>;

    _or?: Maybe<DataProcStateTreeBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcStateTreeCommand". */
export interface DataProcStateTreeCommandOrderBy {
    LastCommand?: Maybe<OrderBy>;

    ServerId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcStateTreeCommand". All fields are combined with a logical 'AND'. */
export interface DataProcStateTreeCommandBoolExp {
    LastCommand?: Maybe<TimestampComparisonExp>;

    ServerId?: Maybe<StringComparisonExp>;

    _and?: Maybe<DataProcStateTreeCommandBoolExp[]>;

    _not?: Maybe<DataProcStateTreeCommandBoolExp>;

    _or?: Maybe<DataProcStateTreeCommandBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcStateTreeLeaf". */
export interface DataProcStateTreeLeafOrderBy {
    DataProcTreeLeaf?: Maybe<DataProcTreeLeafOrderBy>;

    LeafId?: Maybe<OrderBy>;

    StateDate?: Maybe<OrderBy>;

    StateMessage?: Maybe<OrderBy>;

    StateType?: Maybe<OrderBy>;

    TailVersion?: Maybe<OrderBy>;

    WorkState?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DataProcTreeLeaf". */
export interface DataProcTreeLeafOrderBy {
    Children_aggregate?: Maybe<DataProcTreeLeafDependenciesAggregateOrderBy>;

    Class?: Maybe<OrderBy>;

    ClassPropertiesJson?: Maybe<OrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    DatapointSeries?: Maybe<DatapointSeriesOrderBy>;

    DatapointSeriesId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Parents_aggregate?: Maybe<DataProcTreeLeafDependenciesAggregateOrderBy>;

    SourceId?: Maybe<OrderBy>;

    SourceType?: Maybe<OrderBy>;

    State?: Maybe<DataProcStateTreeLeafOrderBy>;
}
/** order by aggregate values of table "DataProcTreeLeafDependencies" */
export interface DataProcTreeLeafDependenciesAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<DataProcTreeLeafDependenciesMaxOrderBy>;

    min?: Maybe<DataProcTreeLeafDependenciesMinOrderBy>;
}
/** order by max() on columns of table "DataProcTreeLeafDependencies" */
export interface DataProcTreeLeafDependenciesMaxOrderBy {
    ChildDependenciesId?: Maybe<OrderBy>;

    ParentDependenciesId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "DataProcTreeLeafDependencies" */
export interface DataProcTreeLeafDependenciesMinOrderBy {
    ChildDependenciesId?: Maybe<OrderBy>;

    ParentDependenciesId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcStateTreeLeaf". All fields are combined with a logical 'AND'. */
export interface DataProcStateTreeLeafBoolExp {
    DataProcTreeLeaf?: Maybe<DataProcTreeLeafBoolExp>;

    LeafId?: Maybe<UuidComparisonExp>;

    StateDate?: Maybe<TimestampComparisonExp>;

    StateMessage?: Maybe<StringComparisonExp>;

    StateType?: Maybe<StringComparisonExp>;

    TailVersion?: Maybe<JsonbComparisonExp>;

    WorkState?: Maybe<JsonbComparisonExp>;

    _and?: Maybe<DataProcStateTreeLeafBoolExp[]>;

    _not?: Maybe<DataProcStateTreeLeafBoolExp>;

    _or?: Maybe<DataProcStateTreeLeafBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DataProcTreeLeaf". All fields are combined with a logical 'AND'. */
export interface DataProcTreeLeafBoolExp {
    Children?: Maybe<DataProcTreeLeafDependenciesBoolExp>;

    Class?: Maybe<IntComparisonExp>;

    ClassPropertiesJson?: Maybe<JsonbComparisonExp>;

    ConfigurationId?: Maybe<UuidComparisonExp>;

    DatapointSeries?: Maybe<DatapointSeriesBoolExp>;

    DatapointSeriesId?: Maybe<UuidComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    Parents?: Maybe<DataProcTreeLeafDependenciesBoolExp>;

    SourceId?: Maybe<UuidComparisonExp>;

    SourceType?: Maybe<IntComparisonExp>;

    State?: Maybe<DataProcStateTreeLeafBoolExp>;

    _and?: Maybe<DataProcTreeLeafBoolExp[]>;

    _not?: Maybe<DataProcTreeLeafBoolExp>;

    _or?: Maybe<DataProcTreeLeafBoolExp[]>;
}
/** Boolean expression to filter rows from the table "DataProcTreeLeafDependencies". All fields are combined with a logical 'AND'. */
export interface DataProcTreeLeafDependenciesBoolExp {
    ChildDependenciesId?: Maybe<UuidComparisonExp>;

    ParentDependenciesId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<DataProcTreeLeafDependenciesBoolExp[]>;

    _not?: Maybe<DataProcTreeLeafDependenciesBoolExp>;

    _or?: Maybe<DataProcTreeLeafDependenciesBoolExp[]>;
}
/** Ordering options when selecting data from "DataProcTreeLeafDependencies". */
export interface DataProcTreeLeafDependenciesOrderBy {
    ChildDependenciesId?: Maybe<OrderBy>;

    ParentDependenciesId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "DataProcTreeCommand". */
export interface DataProcTreeCommandOrderBy {
    CommandType?: Maybe<OrderBy>;

    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    Data?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    StateDate?: Maybe<OrderBy>;

    StateMessage?: Maybe<OrderBy>;

    StateType?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DataProcTreeCommand". All fields are combined with a logical 'AND'. */
export interface DataProcTreeCommandBoolExp {
    CommandType?: Maybe<IntComparisonExp>;

    ConfigurationId?: Maybe<UuidComparisonExp>;

    Created?: Maybe<TimestampComparisonExp>;

    Data?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    StateDate?: Maybe<TimestampComparisonExp>;

    StateMessage?: Maybe<StringComparisonExp>;

    StateType?: Maybe<IntComparisonExp>;

    UserId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<DataProcTreeCommandBoolExp[]>;

    _not?: Maybe<DataProcTreeCommandBoolExp>;

    _or?: Maybe<DataProcTreeCommandBoolExp[]>;
}
/** Ordering options when selecting data from "DatapointSeriesOperation". */
export interface DatapointSeriesOperationOrderBy {
    ConfigurationId?: Maybe<OrderBy>;

    Created?: Maybe<OrderBy>;

    End?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Series?: Maybe<DatapointSeriesOrderBy>;

    SeriesId?: Maybe<OrderBy>;

    Start?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;

    UserId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "DatapointSeriesOperation". All fields are combined with a logical 'AND'. */
export interface DatapointSeriesOperationBoolExp {
    ConfigurationId?: Maybe<UuidComparisonExp>;

    Created?: Maybe<TimestampComparisonExp>;

    End?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Series?: Maybe<DatapointSeriesBoolExp>;

    SeriesId?: Maybe<UuidComparisonExp>;

    Start?: Maybe<TimestampComparisonExp>;

    Type?: Maybe<IntComparisonExp>;

    UserId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<DatapointSeriesOperationBoolExp[]>;

    _not?: Maybe<DatapointSeriesOperationBoolExp>;

    _or?: Maybe<DatapointSeriesOperationBoolExp[]>;
}
/** Ordering options when selecting data from "FileTypes". */
export interface FileTypesOrderBy {
    FileItems_aggregate?: Maybe<FileItemsAggregateOrderBy>;

    Id?: Maybe<OrderBy>;

    IsDeleted?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** order by aggregate values of table "FileItems" */
export interface FileItemsAggregateOrderBy {
    avg?: Maybe<FileItemsAvgOrderBy>;

    count?: Maybe<OrderBy>;

    max?: Maybe<FileItemsMaxOrderBy>;

    min?: Maybe<FileItemsMinOrderBy>;

    stddev?: Maybe<FileItemsStddevOrderBy>;

    stddev_pop?: Maybe<FileItemsStddevPopOrderBy>;

    stddev_samp?: Maybe<FileItemsStddevSampOrderBy>;

    sum?: Maybe<FileItemsSumOrderBy>;

    var_pop?: Maybe<FileItemsVarPopOrderBy>;

    var_samp?: Maybe<FileItemsVarSampOrderBy>;

    variance?: Maybe<FileItemsVarianceOrderBy>;
}
/** order by avg() on columns of table "FileItems" */
export interface FileItemsAvgOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by max() on columns of table "FileItems" */
export interface FileItemsMaxOrderBy {
    FileName?: Maybe<OrderBy>;

    FileReference?: Maybe<OrderBy>;

    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by min() on columns of table "FileItems" */
export interface FileItemsMinOrderBy {
    FileName?: Maybe<OrderBy>;

    FileReference?: Maybe<OrderBy>;

    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "FileItems" */
export interface FileItemsStddevOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "FileItems" */
export interface FileItemsStddevPopOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "FileItems" */
export interface FileItemsStddevSampOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "FileItems" */
export interface FileItemsSumOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "FileItems" */
export interface FileItemsVarPopOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "FileItems" */
export interface FileItemsVarSampOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "FileItems" */
export interface FileItemsVarianceOrderBy {
    FileTypeId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "FileTypes". All fields are combined with a logical 'AND'. */
export interface FileTypesBoolExp {
    FileItems?: Maybe<FileItemsBoolExp>;

    Id?: Maybe<IntComparisonExp>;

    IsDeleted?: Maybe<BooleanComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    _and?: Maybe<FileTypesBoolExp[]>;

    _not?: Maybe<FileTypesBoolExp>;

    _or?: Maybe<FileTypesBoolExp[]>;
}
/** Ordering options when selecting data from "LaboScheduleStates". */
export interface LaboScheduleStatesOrderBy {
    CreatedAt?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    DeletedAt?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    LaboScheduleStatesPlannings_aggregate?: Maybe<
        LaboScheduleStatesPlanningAggregateOrderBy
    >;

    Name?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    Schedule?: Maybe<OrderBy>;
}
/** order by aggregate values of table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningAggregateOrderBy {
    count?: Maybe<OrderBy>;

    max?: Maybe<LaboScheduleStatesPlanningMaxOrderBy>;

    min?: Maybe<LaboScheduleStatesPlanningMinOrderBy>;
}
/** order by max() on columns of table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningMaxOrderBy {
    Id?: Maybe<OrderBy>;

    Planned?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    StateId?: Maybe<OrderBy>;
}
/** order by min() on columns of table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningMinOrderBy {
    Id?: Maybe<OrderBy>;

    Planned?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    StateId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "LaboScheduleStates". All fields are combined with a logical 'AND'. */
export interface LaboScheduleStatesBoolExp {
    CreatedAt?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    DeletedAt?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    LaboScheduleStatesPlannings?: Maybe<LaboScheduleStatesPlanningBoolExp>;

    Name?: Maybe<StringComparisonExp>;

    ProjectId?: Maybe<StringComparisonExp>;

    Schedule?: Maybe<JsonbComparisonExp>;

    _and?: Maybe<LaboScheduleStatesBoolExp[]>;

    _not?: Maybe<LaboScheduleStatesBoolExp>;

    _or?: Maybe<LaboScheduleStatesBoolExp[]>;
}
/** Boolean expression to filter rows from the table "LaboScheduleStatesPlanning". All fields are combined with a logical 'AND'. */
export interface LaboScheduleStatesPlanningBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    LaboScheduleState?: Maybe<LaboScheduleStatesBoolExp>;

    Planned?: Maybe<TimestampComparisonExp>;

    ProjectId?: Maybe<StringComparisonExp>;

    StateId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<LaboScheduleStatesPlanningBoolExp[]>;

    _not?: Maybe<LaboScheduleStatesPlanningBoolExp>;

    _or?: Maybe<LaboScheduleStatesPlanningBoolExp[]>;
}
/** Ordering options when selecting data from "LaboScheduleStatesPlanning". */
export interface LaboScheduleStatesPlanningOrderBy {
    Id?: Maybe<OrderBy>;

    LaboScheduleState?: Maybe<LaboScheduleStatesOrderBy>;

    Planned?: Maybe<OrderBy>;

    ProjectId?: Maybe<OrderBy>;

    StateId?: Maybe<OrderBy>;
}
/** Ordering options when selecting data from "NotificationTypes". */
export interface NotificationTypesOrderBy {
    Deleted?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "NotificationTypes". All fields are combined with a logical 'AND'. */
export interface NotificationTypesBoolExp {
    Deleted?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    _and?: Maybe<NotificationTypesBoolExp[]>;

    _not?: Maybe<NotificationTypesBoolExp>;

    _or?: Maybe<NotificationTypesBoolExp[]>;
}
/** Ordering options when selecting data from "PfdChangeLogs". */
export interface PfdChangeLogsOrderBy {
    Created?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ModelFieldName?: Maybe<OrderBy>;

    ModelId?: Maybe<OrderBy>;

    ModelName?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    Project?: Maybe<PfdProjectsOrderBy>;

    ProjectId?: Maybe<OrderBy>;

    ValueNew?: Maybe<OrderBy>;

    ValueOld?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "PfdChangeLogs". All fields are combined with a logical 'AND'. */
export interface PfdChangeLogsBoolExp {
    Created?: Maybe<TimestampComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ModelFieldName?: Maybe<StringComparisonExp>;

    ModelId?: Maybe<UuidComparisonExp>;

    ModelName?: Maybe<StringComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    Project?: Maybe<PfdProjectsBoolExp>;

    ProjectId?: Maybe<UuidComparisonExp>;

    ValueNew?: Maybe<StringComparisonExp>;

    ValueOld?: Maybe<StringComparisonExp>;

    _and?: Maybe<PfdChangeLogsBoolExp[]>;

    _not?: Maybe<PfdChangeLogsBoolExp>;

    _or?: Maybe<PfdChangeLogsBoolExp[]>;
}
/** Ordering options when selecting data from "PfdProjectBlockDependencyModel". */
export interface PfdProjectBlockDependencyModelOrderBy {
    Child?: Maybe<PfdBlocksOrderBy>;

    ChildId?: Maybe<OrderBy>;

    Parent?: Maybe<PfdBlocksOrderBy>;

    ParentId?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "PfdProjectBlockDependencyModel". All fields are combined with a logical 'AND'. */
export interface PfdProjectBlockDependencyModelBoolExp {
    Child?: Maybe<PfdBlocksBoolExp>;

    ChildId?: Maybe<UuidComparisonExp>;

    Parent?: Maybe<PfdBlocksBoolExp>;

    ParentId?: Maybe<UuidComparisonExp>;

    _and?: Maybe<PfdProjectBlockDependencyModelBoolExp[]>;

    _not?: Maybe<PfdProjectBlockDependencyModelBoolExp>;

    _or?: Maybe<PfdProjectBlockDependencyModelBoolExp[]>;
}
/** Ordering options when selecting data from "ProjectConfigurations". */
export interface ProjectConfigurationsOrderBy {
    Id?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    ProjectLogo?: Maybe<OrderBy>;

    ProjectName?: Maybe<OrderBy>;

    ProjectType?: Maybe<OrderBy>;

    ReportsDrive?: Maybe<OrderBy>;

    ReportsItem?: Maybe<OrderBy>;

    ReportsSite?: Maybe<OrderBy>;

    TimeZone?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "ProjectConfigurations". All fields are combined with a logical 'AND'. */
export interface ProjectConfigurationsBoolExp {
    Id?: Maybe<UuidComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    ProjectLogo?: Maybe<StringComparisonExp>;

    ProjectName?: Maybe<StringComparisonExp>;

    ProjectType?: Maybe<StringComparisonExp>;

    ReportsDrive?: Maybe<StringComparisonExp>;

    ReportsItem?: Maybe<StringComparisonExp>;

    ReportsSite?: Maybe<StringComparisonExp>;

    TimeZone?: Maybe<StringComparisonExp>;

    _and?: Maybe<ProjectConfigurationsBoolExp[]>;

    _not?: Maybe<ProjectConfigurationsBoolExp>;

    _or?: Maybe<ProjectConfigurationsBoolExp[]>;
}
/** Ordering options when selecting data from "ReportProjectFiles". */
export interface ReportProjectFilesOrderBy {
    Created?: Maybe<OrderBy>;

    DriveItemId?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Modified?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;

    ProjectCode?: Maybe<OrderBy>;

    Size?: Maybe<OrderBy>;

    Type?: Maybe<OrderBy>;

    WebUrl?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "ReportProjectFiles". All fields are combined with a logical 'AND'. */
export interface ReportProjectFilesBoolExp {
    Created?: Maybe<TimestamptzComparisonExp>;

    DriveItemId?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Modified?: Maybe<TimestamptzComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    ProjectCode?: Maybe<StringComparisonExp>;

    Size?: Maybe<BigintComparisonExp>;

    Type?: Maybe<StringComparisonExp>;

    WebUrl?: Maybe<StringComparisonExp>;

    _and?: Maybe<ReportProjectFilesBoolExp[]>;

    _not?: Maybe<ReportProjectFilesBoolExp>;

    _or?: Maybe<ReportProjectFilesBoolExp[]>;
}
/** Ordering options when selecting data from "SmartlabFunctionalities". */
export interface SmartlabFunctionalitiesOrderBy {
    AllowedPermissions?: Maybe<OrderBy>;

    Description?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    Name?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "SmartlabFunctionalities". All fields are combined with a logical 'AND'. */
export interface SmartlabFunctionalitiesBoolExp {
    AllowedPermissions?: Maybe<JsonbComparisonExp>;

    Description?: Maybe<StringComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    Name?: Maybe<StringComparisonExp>;

    _and?: Maybe<SmartlabFunctionalitiesBoolExp[]>;

    _not?: Maybe<SmartlabFunctionalitiesBoolExp>;

    _or?: Maybe<SmartlabFunctionalitiesBoolExp[]>;
}
/** Ordering options when selecting data from "SupportChatAutoMessages". */
export interface SupportChatAutoMessagesOrderBy {
    CreatedDate?: Maybe<OrderBy>;

    Deleted?: Maybe<OrderBy>;

    Id?: Maybe<OrderBy>;

    ProjectGroupId?: Maybe<OrderBy>;

    TextValue?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "SupportChatAutoMessages". All fields are combined with a logical 'AND'. */
export interface SupportChatAutoMessagesBoolExp {
    CreatedDate?: Maybe<TimestampComparisonExp>;

    Deleted?: Maybe<BooleanComparisonExp>;

    Id?: Maybe<UuidComparisonExp>;

    ProjectGroupId?: Maybe<UuidComparisonExp>;

    TextValue?: Maybe<StringComparisonExp>;

    _and?: Maybe<SupportChatAutoMessagesBoolExp[]>;

    _not?: Maybe<SupportChatAutoMessagesBoolExp>;

    _or?: Maybe<SupportChatAutoMessagesBoolExp[]>;
}
/** input type for inserting data into table "Branding" */
export interface BrandingInsertInput {
    AlertsEnabled?: Maybe<boolean>;

    AlertsTitle?: Maybe<string>;

    AllowedProjects?: Maybe<Jsonb>;

    CalendarEnabled?: Maybe<boolean>;

    CalendarTitle?: Maybe<string>;

    Domain?: Maybe<string>;

    EnableBigLogo?: Maybe<boolean>;

    FavIcon?: Maybe<string>;

    Id?: Maybe<Uuid>;

    LaboratoryEnabled?: Maybe<boolean>;

    LaboratoryTitle?: Maybe<string>;

    LogbookEnabled?: Maybe<boolean>;

    LogbookTitle?: Maybe<string>;

    Logo?: Maybe<string>;

    MaintenanceEnabled?: Maybe<boolean>;

    MaintenanceTitle?: Maybe<string>;

    MyPlantsTableTitle?: Maybe<string>;

    MyPlantsTitle?: Maybe<string>;

    Name?: Maybe<string>;

    OperatorEnabled?: Maybe<boolean>;

    OperatorTitle?: Maybe<string>;

    PlantEnabled?: Maybe<boolean>;

    PlantOverrideWithPFDUnitId?: Maybe<string>;

    PlantTitle?: Maybe<string>;

    PrimaryColor?: Maybe<string>;

    ReportsEnabled?: Maybe<boolean>;

    ReportsTitle?: Maybe<string>;

    SecondaryColor?: Maybe<string>;

    TechnologiesEnabled?: Maybe<boolean>;

    TechnologiesTitle?: Maybe<string>;

    UtilitiesEnabled?: Maybe<boolean>;

    UtilitiesTitle?: Maybe<string>;
}
/** on_conflict condition type for table "Branding" */
export interface BrandingOnConflict {
    constraint: BrandingConstraint;

    update_columns: BrandingUpdateColumn[];

    where?: Maybe<BrandingBoolExp>;
}
/** input type for inserting data into table "CalendarEvents" */
export interface CalendarEventsInsertInput {
    AllDay?: Maybe<boolean>;

    ColorPrimary?: Maybe<string>;

    ColorSecondary?: Maybe<string>;

    Draggable?: Maybe<boolean>;

    End?: Maybe<Timestamptz>;

    Id?: Maybe<Uuid>;

    MetaLocation?: Maybe<string>;

    MetaNotes?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    ResizableAfterEnd?: Maybe<boolean>;

    ResizableBeforeStart?: Maybe<boolean>;

    Start?: Maybe<Timestamptz>;

    Title?: Maybe<string>;

    UserId?: Maybe<string>;
}
/** on_conflict condition type for table "CalendarEvents" */
export interface CalendarEventsOnConflict {
    constraint: CalendarEventsConstraint;

    update_columns: CalendarEventsUpdateColumn[];

    where?: Maybe<CalendarEventsBoolExp>;
}
/** input type for inserting data into table "ClientGroups" */
export interface ClientGroupsInsertInput {
    ClientGroupsSmartlabUsers?: Maybe<
        ClientGroupsSmartlabUsersArrRelInsertInput
    >;

    ClientIds?: Maybe<_Text>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    WepId?: Maybe<number>;
}
/** input type for inserting array relation for remote table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersArrRelInsertInput {
    data: ClientGroupsSmartlabUsersInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<ClientGroupsSmartlabUsersOnConflict>;
}
/** input type for inserting data into table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersInsertInput {
    ClientGroup?: Maybe<ClientGroupsObjRelInsertInput>;

    ClientGroupId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    SmartlabUser?: Maybe<SmartlabUsersObjRelInsertInput>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "ClientGroups" */
export interface ClientGroupsObjRelInsertInput {
    data: ClientGroupsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<ClientGroupsOnConflict>;
}
/** on_conflict condition type for table "ClientGroups" */
export interface ClientGroupsOnConflict {
    constraint: ClientGroupsConstraint;

    update_columns: ClientGroupsUpdateColumn[];

    where?: Maybe<ClientGroupsBoolExp>;
}
/** input type for inserting object relation for remote table "SmartlabUsers" */
export interface SmartlabUsersObjRelInsertInput {
    data: SmartlabUsersInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<SmartlabUsersOnConflict>;
}
/** input type for inserting data into table "SmartlabUsers" */
export interface SmartlabUsersInsertInput {
    ClientGroupsSmartlabUsers?: Maybe<
        ClientGroupsSmartlabUsersArrRelInsertInput
    >;

    Email?: Maybe<string>;

    Id?: Maybe<Uuid>;

    NameFirst?: Maybe<string>;

    NameLast?: Maybe<string>;

    ProjectGroupsSmartlabUsers?: Maybe<
        ProjectGroupsSmartlabUsersArrRelInsertInput
    >;

    SupportChatMembers?: Maybe<SupportChatMembersArrRelInsertInput>;

    UserId?: Maybe<string>;
}
/** input type for inserting array relation for remote table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersArrRelInsertInput {
    data: ProjectGroupsSmartlabUsersInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<ProjectGroupsSmartlabUsersOnConflict>;
}
/** input type for inserting data into table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersInsertInput {
    Id?: Maybe<Uuid>;

    ProjectGroup?: Maybe<ProjectGroupsObjRelInsertInput>;

    ProjectGroupId?: Maybe<Uuid>;

    SmartlabUser?: Maybe<SmartlabUsersObjRelInsertInput>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "ProjectGroups" */
export interface ProjectGroupsObjRelInsertInput {
    data: ProjectGroupsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<ProjectGroupsOnConflict>;
}
/** input type for inserting data into table "ProjectGroups" */
export interface ProjectGroupsInsertInput {
    ClientGroup?: Maybe<ClientGroupsObjRelInsertInput>;

    ClientGroupId?: Maybe<Uuid>;

    Code?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    PfdProject?: Maybe<PfdProjectsObjRelInsertInput>;

    PfdProjectId?: Maybe<Uuid>;

    ProjectCodes?: Maybe<_Text>;

    ProjectGroupsSmartlabUsers?: Maybe<
        ProjectGroupsSmartlabUsersArrRelInsertInput
    >;

    SupportChats?: Maybe<SupportChatsArrRelInsertInput>;

    WepId?: Maybe<number>;
}
/** input type for inserting object relation for remote table "PfdProjects" */
export interface PfdProjectsObjRelInsertInput {
    data: PfdProjectsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<PfdProjectsOnConflict>;
}
/** input type for inserting data into table "PfdProjects" */
export interface PfdProjectsInsertInput {
    Address?: Maybe<string>;

    City?: Maybe<string>;

    Code?: Maybe<string>;

    Coordinates?: Maybe<string>;

    Country?: Maybe<string>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    TimeZone?: Maybe<string>;

    Type?: Maybe<string>;

    WepClientId?: Maybe<number>;

    WepId?: Maybe<number>;
}
/** on_conflict condition type for table "PfdProjects" */
export interface PfdProjectsOnConflict {
    constraint: PfdProjectsConstraint;

    update_columns: PfdProjectsUpdateColumn[];

    where?: Maybe<PfdProjectsBoolExp>;
}
/** input type for inserting array relation for remote table "SupportChats" */
export interface SupportChatsArrRelInsertInput {
    data: SupportChatsInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<SupportChatsOnConflict>;
}
/** input type for inserting data into table "SupportChats" */
export interface SupportChatsInsertInput {
    AuthorInfo?: Maybe<string>;

    ClosedBy?: Maybe<string>;

    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    ProjectGroup?: Maybe<ProjectGroupsObjRelInsertInput>;

    ProjectGroupId?: Maybe<Uuid>;

    StartDate?: Maybe<Timestamptz>;

    StopDate?: Maybe<Timestamptz>;

    SupportChatMembers?: Maybe<SupportChatMembersArrRelInsertInput>;

    SupportChatMessages?: Maybe<SupportChatMessagesArrRelInsertInput>;

    SupportSubject?: Maybe<SupportSubjectsObjRelInsertInput>;

    SupportSubjectId?: Maybe<Uuid>;
}
/** input type for inserting array relation for remote table "SupportChatMembers" */
export interface SupportChatMembersArrRelInsertInput {
    data: SupportChatMembersInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<SupportChatMembersOnConflict>;
}
/** input type for inserting data into table "SupportChatMembers" */
export interface SupportChatMembersInsertInput {
    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    IsActive?: Maybe<boolean>;

    SmartlabUser?: Maybe<SmartlabUsersObjRelInsertInput>;

    SmartlabUserId?: Maybe<Uuid>;

    SupportChat?: Maybe<SupportChatsObjRelInsertInput>;

    SupportChatId?: Maybe<Uuid>;

    SupportChatMessageViewers?: Maybe<
        SupportChatMessageViewersArrRelInsertInput
    >;

    SupportChatMessages?: Maybe<SupportChatMessagesArrRelInsertInput>;
}
/** input type for inserting object relation for remote table "SupportChats" */
export interface SupportChatsObjRelInsertInput {
    data: SupportChatsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<SupportChatsOnConflict>;
}
/** on_conflict condition type for table "SupportChats" */
export interface SupportChatsOnConflict {
    constraint: SupportChatsConstraint;

    update_columns: SupportChatsUpdateColumn[];

    where?: Maybe<SupportChatsBoolExp>;
}
/** input type for inserting array relation for remote table "SupportChatMessageViewers" */
export interface SupportChatMessageViewersArrRelInsertInput {
    data: SupportChatMessageViewersInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<SupportChatMessageViewersOnConflict>;
}
/** input type for inserting data into table "SupportChatMessageViewers" */
export interface SupportChatMessageViewersInsertInput {
    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    MessageId?: Maybe<Uuid>;

    SupportChatMember?: Maybe<SupportChatMembersObjRelInsertInput>;

    SupportChatMessage?: Maybe<SupportChatMessagesObjRelInsertInput>;

    ViewedDate?: Maybe<Timestamptz>;

    ViewerId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "SupportChatMembers" */
export interface SupportChatMembersObjRelInsertInput {
    data: SupportChatMembersInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<SupportChatMembersOnConflict>;
}
/** on_conflict condition type for table "SupportChatMembers" */
export interface SupportChatMembersOnConflict {
    constraint: SupportChatMembersConstraint;

    update_columns: SupportChatMembersUpdateColumn[];

    where?: Maybe<SupportChatMembersBoolExp>;
}
/** input type for inserting object relation for remote table "SupportChatMessages" */
export interface SupportChatMessagesObjRelInsertInput {
    data: SupportChatMessagesInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<SupportChatMessagesOnConflict>;
}
/** input type for inserting data into table "SupportChatMessages" */
export interface SupportChatMessagesInsertInput {
    AuthorId?: Maybe<Uuid>;

    AuthorRole?: Maybe<string>;

    CreatedDate?: Maybe<Timestamptz>;

    Deleted?: Maybe<boolean>;

    FileItem?: Maybe<FileItemsObjRelInsertInput>;

    FileItemId?: Maybe<Bigint>;

    Id?: Maybe<Uuid>;

    Notifications?: Maybe<NotificationsArrRelInsertInput>;

    SupportChat?: Maybe<SupportChatsObjRelInsertInput>;

    SupportChatId?: Maybe<Uuid>;

    SupportChatMember?: Maybe<SupportChatMembersObjRelInsertInput>;

    SupportChatMessageViewers?: Maybe<
        SupportChatMessageViewersArrRelInsertInput
    >;

    TextValue?: Maybe<string>;

    ValueType?: Maybe<Smallint>;
}
/** input type for inserting object relation for remote table "FileItems" */
export interface FileItemsObjRelInsertInput {
    data: FileItemsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<FileItemsOnConflict>;
}
/** input type for inserting data into table "FileItems" */
export interface FileItemsInsertInput {
    FileName?: Maybe<string>;

    FileReference?: Maybe<string>;

    FileTypeId?: Maybe<number>;

    Id?: Maybe<Bigint>;

    IsDeleted?: Maybe<boolean>;

    SupportChatMessages?: Maybe<SupportChatMessagesArrRelInsertInput>;
}
/** input type for inserting array relation for remote table "SupportChatMessages" */
export interface SupportChatMessagesArrRelInsertInput {
    data: SupportChatMessagesInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<SupportChatMessagesOnConflict>;
}
/** on_conflict condition type for table "SupportChatMessages" */
export interface SupportChatMessagesOnConflict {
    constraint: SupportChatMessagesConstraint;

    update_columns: SupportChatMessagesUpdateColumn[];

    where?: Maybe<SupportChatMessagesBoolExp>;
}
/** on_conflict condition type for table "FileItems" */
export interface FileItemsOnConflict {
    constraint: FileItemsConstraint;

    update_columns: FileItemsUpdateColumn[];

    where?: Maybe<FileItemsBoolExp>;
}
/** input type for inserting array relation for remote table "Notifications" */
export interface NotificationsArrRelInsertInput {
    data: NotificationsInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<NotificationsOnConflict>;
}
/** input type for inserting data into table "Notifications" */
export interface NotificationsInsertInput {
    Id?: Maybe<Uuid>;

    MessageId?: Maybe<Uuid>;

    NotificationTypeId?: Maybe<Uuid>;

    ProjectId?: Maybe<Uuid>;

    SubjectId?: Maybe<Uuid>;

    UserFromId?: Maybe<Uuid>;

    UserToId?: Maybe<Uuid>;
}
/** on_conflict condition type for table "Notifications" */
export interface NotificationsOnConflict {
    constraint: NotificationsConstraint;

    update_columns: NotificationsUpdateColumn[];

    where?: Maybe<NotificationsBoolExp>;
}
/** on_conflict condition type for table "SupportChatMessageViewers" */
export interface SupportChatMessageViewersOnConflict {
    constraint: SupportChatMessageViewersConstraint;

    update_columns: SupportChatMessageViewersUpdateColumn[];

    where?: Maybe<SupportChatMessageViewersBoolExp>;
}
/** input type for inserting object relation for remote table "SupportSubjects" */
export interface SupportSubjectsObjRelInsertInput {
    data: SupportSubjectsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<SupportSubjectsOnConflict>;
}
/** input type for inserting data into table "SupportSubjects" */
export interface SupportSubjectsInsertInput {
    Children?: Maybe<SupportSubjectsArrRelInsertInput>;

    ContainsPFDUnitLinksForCurrentProject?: Maybe<boolean>;

    Deleted?: Maybe<boolean>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    IsActive?: Maybe<boolean>;

    IsEnabledForOperatorChat?: Maybe<boolean>;

    Order?: Maybe<number>;

    Parent?: Maybe<SupportSubjectsObjRelInsertInput>;

    ParentId?: Maybe<Uuid>;

    SupportChats?: Maybe<SupportChatsArrRelInsertInput>;

    Title?: Maybe<string>;
}
/** input type for inserting array relation for remote table "SupportSubjects" */
export interface SupportSubjectsArrRelInsertInput {
    data: SupportSubjectsInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<SupportSubjectsOnConflict>;
}
/** on_conflict condition type for table "SupportSubjects" */
export interface SupportSubjectsOnConflict {
    constraint: SupportSubjectsConstraint;

    update_columns: SupportSubjectsUpdateColumn[];

    where?: Maybe<SupportSubjectsBoolExp>;
}
/** on_conflict condition type for table "ProjectGroups" */
export interface ProjectGroupsOnConflict {
    constraint: ProjectGroupsConstraint;

    update_columns: ProjectGroupsUpdateColumn[];

    where?: Maybe<ProjectGroupsBoolExp>;
}
/** on_conflict condition type for table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersOnConflict {
    constraint: ProjectGroupsSmartlabUsersConstraint;

    update_columns: ProjectGroupsSmartlabUsersUpdateColumn[];

    where?: Maybe<ProjectGroupsSmartlabUsersBoolExp>;
}
/** on_conflict condition type for table "SmartlabUsers" */
export interface SmartlabUsersOnConflict {
    constraint: SmartlabUsersConstraint;

    update_columns: SmartlabUsersUpdateColumn[];

    where?: Maybe<SmartlabUsersBoolExp>;
}
/** on_conflict condition type for table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersOnConflict {
    constraint: ClientGroupsSmartlabUsersConstraint;

    update_columns: ClientGroupsSmartlabUsersUpdateColumn[];

    where?: Maybe<ClientGroupsSmartlabUsersBoolExp>;
}
/** input type for inserting data into table "CumulDashboards" */
export interface CumulDashboardsInsertInput {
    DashboardId?: Maybe<string>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    ModifiedAt?: Maybe<Timestamp>;

    Name?: Maybe<string>;
}
/** on_conflict condition type for table "CumulDashboards" */
export interface CumulDashboardsOnConflict {
    constraint: CumulDashboardsConstraint;

    update_columns: CumulDashboardsUpdateColumn[];

    where?: Maybe<CumulDashboardsBoolExp>;
}
/** input type for inserting data into table "CumulTokens" */
export interface CumulTokensInsertInput {
    AuthorizationId?: Maybe<string>;

    AuthorizationToken?: Maybe<string>;

    CreatedAt?: Maybe<Timestamp>;

    HashedRequestSignature?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Revoked?: Maybe<boolean>;

    UserId?: Maybe<string>;

    ValidUntil?: Maybe<Timestamp>;
}
/** on_conflict condition type for table "CumulTokens" */
export interface CumulTokensOnConflict {
    constraint: CumulTokensConstraint;

    update_columns: CumulTokensUpdateColumn[];

    where?: Maybe<CumulTokensBoolExp>;
}
/** input type for inserting data into table "DataProcConfig" */
export interface DataProcConfigInsertInput {
    DataConnectionDescription?: Maybe<string>;

    DataConnectionFlags?: Maybe<number>;

    DataProcProjectState?: Maybe<DataProcProjectStateObjRelInsertInput>;

    DataProcTagStates?: Maybe<DataProcTagStateArrRelInsertInput>;

    Enable?: Maybe<boolean>;

    EnableDataProcessing?: Maybe<boolean>;

    FrequencyMinutes?: Maybe<Bigint>;

    Id?: Maybe<Uuid>;

    MaxMeasurementAgeMinutes?: Maybe<Bigint>;

    MeasurementCutoffDate?: Maybe<Timestamp>;

    MeasurementDatabase?: Maybe<DatapointDatabaseObjRelInsertInput>;

    MeasurementDatabaseId?: Maybe<Uuid>;

    MeasurementPullConfigurations?: Maybe<
        DataProcConfigMeasurementPullArrRelInsertInput
    >;

    PfdProject?: Maybe<PfdProjectsObjRelInsertInput>;

    PfdProjectId?: Maybe<Uuid>;

    ProcessorVersion?: Maybe<number>;

    ProjectCode?: Maybe<string>;

    ServerId?: Maybe<Uuid>;

    SignalDatabase?: Maybe<DatapointDatabaseObjRelInsertInput>;

    SignalDatabaseId?: Maybe<Uuid>;

    UpdatedServer?: Maybe<Timestamp>;

    WorkersPriorityHigh?: Maybe<number>;

    WorkersPriorityLow?: Maybe<number>;
}
/** input type for inserting object relation for remote table "DataProcProjectState" */
export interface DataProcProjectStateObjRelInsertInput {
    data: DataProcProjectStateInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DataProcProjectStateOnConflict>;
}
/** input type for inserting data into table "DataProcProjectState" */
export interface DataProcProjectStateInsertInput {
    ConfigurationId?: Maybe<Uuid>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    EndedDate?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    IsLatestMeasurementDateOld?: Maybe<boolean>;

    JobNr?: Maybe<Numeric>;

    KpiDate?: Maybe<Timestamp>;

    KpiValue?: Maybe<Float8>;

    LatestMeasurementDate?: Maybe<Timestamp>;

    StartedDate?: Maybe<Timestamp>;

    TagProcessingEndedDate?: Maybe<Timestamp>;

    TagProcessingErrorMessage?: Maybe<string>;

    TagProcessingPointsProcessed?: Maybe<Bigint>;

    TagProcessingStartedDate?: Maybe<Timestamp>;

    TagProcessingTagsDoneProcessing?: Maybe<Bigint>;

    TagProcessingTagsProcessed?: Maybe<Bigint>;

    TagProcessingTotalPointsProcessed?: Maybe<Numeric>;

    TotalState?: Maybe<number>;

    Updated?: Maybe<Timestamp>;
}
/** input type for inserting object relation for remote table "DataProcConfig" */
export interface DataProcConfigObjRelInsertInput {
    data: DataProcConfigInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DataProcConfigOnConflict>;
}
/** on_conflict condition type for table "DataProcConfig" */
export interface DataProcConfigOnConflict {
    constraint: DataProcConfigConstraint;

    update_columns: DataProcConfigUpdateColumn[];

    where?: Maybe<DataProcConfigBoolExp>;
}
/** on_conflict condition type for table "DataProcProjectState" */
export interface DataProcProjectStateOnConflict {
    constraint: DataProcProjectStateConstraint;

    update_columns: DataProcProjectStateUpdateColumn[];

    where?: Maybe<DataProcProjectStateBoolExp>;
}
/** input type for inserting array relation for remote table "DataProcTagState" */
export interface DataProcTagStateArrRelInsertInput {
    data: DataProcTagStateInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<DataProcTagStateOnConflict>;
}
/** input type for inserting data into table "DataProcTagState" */
export interface DataProcTagStateInsertInput {
    Buffer?: Maybe<DatapointSeriesObjRelInsertInput>;

    BufferId?: Maybe<Uuid>;

    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    FirstPendingDate?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    IsProcessing?: Maybe<boolean>;

    JobNr?: Maybe<Numeric>;

    LastDependencyDate?: Maybe<Timestamp>;

    LastPendingDate?: Maybe<Timestamp>;

    LastProcessedDate?: Maybe<Timestamp>;

    Measurement?: Maybe<DatapointSeriesObjRelInsertInput>;

    MeasurementId?: Maybe<Uuid>;

    ParentWithOldestLastDatapointId?: Maybe<Uuid>;

    PfdTag?: Maybe<PfdTagsObjRelInsertInput>;

    PfdTagId?: Maybe<Uuid>;

    PointsProcessed?: Maybe<Bigint>;

    ProcessedRangeDone?: Maybe<Timestamp>;

    ProcessedRangeFrom?: Maybe<Timestamp>;

    ProcessedRangeTo?: Maybe<Timestamp>;

    ProcessingEndedDate?: Maybe<Timestamp>;

    ProcessingStartedDate?: Maybe<Timestamp>;

    Signal?: Maybe<DatapointSeriesObjRelInsertInput>;

    SignalId?: Maybe<Uuid>;

    Updated?: Maybe<Timestamp>;
}
/** input type for inserting object relation for remote table "DatapointSeries" */
export interface DatapointSeriesObjRelInsertInput {
    data: DatapointSeriesInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DatapointSeriesOnConflict>;
}
/** input type for inserting data into table "DatapointSeries" */
export interface DatapointSeriesInsertInput {
    Alias?: Maybe<string>;

    Created?: Maybe<Timestamp>;

    Filters?: Maybe<Jsonb>;

    Id?: Maybe<Uuid>;

    State?: Maybe<number>;

    Table?: Maybe<DatapointTableObjRelInsertInput>;

    TableId?: Maybe<Uuid>;

    TagBuffer?: Maybe<DataProcTagStateObjRelInsertInput>;

    Tags?: Maybe<DataProcTagStateArrRelInsertInput>;

    TotalRangeFrom?: Maybe<Timestamp>;

    TotalRangeTo?: Maybe<Timestamp>;

    Type?: Maybe<number>;

    Updated?: Maybe<Timestamp>;
}
/** input type for inserting object relation for remote table "DatapointTable" */
export interface DatapointTableObjRelInsertInput {
    data: DatapointTableInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DatapointTableOnConflict>;
}
/** input type for inserting data into table "DatapointTable" */
export interface DatapointTableInsertInput {
    Created?: Maybe<Timestamp>;

    DataType?: Maybe<number>;

    Database?: Maybe<DatapointDatabaseObjRelInsertInput>;

    DatabaseId?: Maybe<Uuid>;

    Filters?: Maybe<Jsonb>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    TableCreated?: Maybe<Timestamp>;
}
/** input type for inserting object relation for remote table "DatapointDatabase" */
export interface DatapointDatabaseObjRelInsertInput {
    data: DatapointDatabaseInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DatapointDatabaseOnConflict>;
}
/** input type for inserting data into table "DatapointDatabase" */
export interface DatapointDatabaseInsertInput {
    Config?: Maybe<DataProcConfigArrRelInsertInput>;

    ConfigPathCredentials?: Maybe<string>;

    CreateAllGenericTables?: Maybe<boolean>;

    Created?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Host?: Maybe<DatapointHostObjRelInsertInput>;

    HostId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    ParentId?: Maybe<Uuid>;

    Schema?: Maybe<string>;

    SignalConfig?: Maybe<DataProcConfigArrRelInsertInput>;
}
/** input type for inserting array relation for remote table "DataProcConfig" */
export interface DataProcConfigArrRelInsertInput {
    data: DataProcConfigInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<DataProcConfigOnConflict>;
}
/** input type for inserting object relation for remote table "DatapointHost" */
export interface DatapointHostObjRelInsertInput {
    data: DatapointHostInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DatapointHostOnConflict>;
}
/** input type for inserting data into table "DatapointHost" */
export interface DatapointHostInsertInput {
    Alias?: Maybe<string>;

    ConfigPathCredentials?: Maybe<string>;

    Created?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Host?: Maybe<string>;

    HostType?: Maybe<number>;

    Id?: Maybe<Uuid>;

    IsTest?: Maybe<boolean>;

    Parameters?: Maybe<Jsonb>;

    Port?: Maybe<number>;

    Portal?: Maybe<string>;
}
/** on_conflict condition type for table "DatapointHost" */
export interface DatapointHostOnConflict {
    constraint: DatapointHostConstraint;

    update_columns: DatapointHostUpdateColumn[];

    where?: Maybe<DatapointHostBoolExp>;
}
/** on_conflict condition type for table "DatapointDatabase" */
export interface DatapointDatabaseOnConflict {
    constraint: DatapointDatabaseConstraint;

    update_columns: DatapointDatabaseUpdateColumn[];

    where?: Maybe<DatapointDatabaseBoolExp>;
}
/** on_conflict condition type for table "DatapointTable" */
export interface DatapointTableOnConflict {
    constraint: DatapointTableConstraint;

    update_columns: DatapointTableUpdateColumn[];

    where?: Maybe<DatapointTableBoolExp>;
}
/** input type for inserting object relation for remote table "DataProcTagState" */
export interface DataProcTagStateObjRelInsertInput {
    data: DataProcTagStateInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DataProcTagStateOnConflict>;
}
/** on_conflict condition type for table "DataProcTagState" */
export interface DataProcTagStateOnConflict {
    constraint: DataProcTagStateConstraint;

    update_columns: DataProcTagStateUpdateColumn[];

    where?: Maybe<DataProcTagStateBoolExp>;
}
/** on_conflict condition type for table "DatapointSeries" */
export interface DatapointSeriesOnConflict {
    constraint: DatapointSeriesConstraint;

    update_columns: DatapointSeriesUpdateColumn[];

    where?: Maybe<DatapointSeriesBoolExp>;
}
/** input type for inserting object relation for remote table "PfdTags" */
export interface PfdTagsObjRelInsertInput {
    data: PfdTagsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<PfdTagsOnConflict>;
}
/** input type for inserting data into table "PfdTags" */
export interface PfdTagsInsertInput {
    AggregationDatapointTime?: Maybe<number>;

    AggregationDatapointTimeMultiplier?: Maybe<number>;

    AggregationIsFaked?: Maybe<boolean>;

    AggregationTime?: Maybe<number>;

    AggregationTimeMultiplier?: Maybe<number>;

    AggregationTimeSource?: Maybe<number>;

    AggregationType?: Maybe<number>;

    Block?: Maybe<PfdBlocksObjRelInsertInput>;

    BlockId?: Maybe<Uuid>;

    Children?: Maybe<PfdProjectTagDependencyModelArrRelInsertInput>;

    DataType?: Maybe<number>;

    DefinitionId?: Maybe<Uuid>;

    Description?: Maybe<string>;

    Enabled?: Maybe<boolean>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Formula?: Maybe<string>;

    Id?: Maybe<Uuid>;

    IsDefinition?: Maybe<boolean>;

    IsParameter?: Maybe<boolean>;

    IsProjectTag?: Maybe<boolean>;

    Limit_h?: Maybe<Float8>;

    Limit_hh?: Maybe<Float8>;

    Limit_l?: Maybe<Float8>;

    Limit_ll?: Maybe<Float8>;

    MeasurementName?: Maybe<string>;

    MeasurementNameSF?: Maybe<string>;

    MeasurementType?: Maybe<number>;

    Name?: Maybe<string>;

    Parents?: Maybe<PfdProjectTagDependencyModelArrRelInsertInput>;

    Project?: Maybe<PfdProjectsObjRelInsertInput>;

    ProjectId?: Maybe<Uuid>;

    Quantity?: Maybe<string>;

    ReportPfdTagLinks?: Maybe<ReportPfdTagLinksArrRelInsertInput>;

    Samplingpoint?: Maybe<PfdSamplingpointsObjRelInsertInput>;

    SamplingpointId?: Maybe<Uuid>;

    Source?: Maybe<number>;

    Substance?: Maybe<string>;

    TagDefinitionId?: Maybe<number>;

    TagId?: Maybe<number>;

    Unit?: Maybe<string>;
}
/** input type for inserting object relation for remote table "PfdBlocks" */
export interface PfdBlocksObjRelInsertInput {
    data: PfdBlocksInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<PfdBlocksOnConflict>;
}
/** input type for inserting data into table "PfdBlocks" */
export interface PfdBlocksInsertInput {
    BlockDefinitionId?: Maybe<number>;

    BlockId?: Maybe<number>;

    Child?: Maybe<PfdBlocksObjRelInsertInput>;

    ChildId?: Maybe<Uuid>;

    Definition?: Maybe<PfdBlocksObjRelInsertInput>;

    DefinitionId?: Maybe<Uuid>;

    Description?: Maybe<string>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    IsDefinition?: Maybe<boolean>;

    IsMaster?: Maybe<boolean>;

    IsStream?: Maybe<boolean>;

    Name?: Maybe<string>;

    NameShort?: Maybe<string>;

    Order?: Maybe<number>;

    Parents?: Maybe<PfdBlocksArrRelInsertInput>;

    Project?: Maybe<PfdProjectsObjRelInsertInput>;

    ProjectId?: Maybe<Uuid>;

    SmartlabMenuEnabled?: Maybe<boolean>;

    SmartlabPFDUnitLinks?: Maybe<SmartlabPfdUnitLinksArrRelInsertInput>;
}
/** input type for inserting array relation for remote table "PfdBlocks" */
export interface PfdBlocksArrRelInsertInput {
    data: PfdBlocksInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<PfdBlocksOnConflict>;
}
/** on_conflict condition type for table "PfdBlocks" */
export interface PfdBlocksOnConflict {
    constraint: PfdBlocksConstraint;

    update_columns: PfdBlocksUpdateColumn[];

    where?: Maybe<PfdBlocksBoolExp>;
}
/** input type for inserting array relation for remote table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksArrRelInsertInput {
    data: SmartlabPfdUnitLinksInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<SmartlabPfdUnitLinksOnConflict>;
}
/** input type for inserting data into table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksInsertInput {
    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Link?: Maybe<string>;

    Name?: Maybe<string>;

    Order?: Maybe<number>;

    PFDTagLink?: Maybe<string>;

    PFDUnitId?: Maybe<string>;

    PfdBlock?: Maybe<PfdBlocksObjRelInsertInput>;

    PfdBlockId?: Maybe<Uuid>;

    SkipBlocks?: Maybe<boolean>;

    Targets?: Maybe<_Text>;

    Type?: Maybe<string>;
}
/** on_conflict condition type for table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksOnConflict {
    constraint: SmartlabPfdUnitLinksConstraint;

    update_columns: SmartlabPfdUnitLinksUpdateColumn[];

    where?: Maybe<SmartlabPfdUnitLinksBoolExp>;
}
/** input type for inserting array relation for remote table "PfdProjectTagDependencyModel" */
export interface PfdProjectTagDependencyModelArrRelInsertInput {
    data: PfdProjectTagDependencyModelInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<PfdProjectTagDependencyModelOnConflict>;
}
/** input type for inserting data into table "PfdProjectTagDependencyModel" */
export interface PfdProjectTagDependencyModelInsertInput {
    Child?: Maybe<PfdTagsObjRelInsertInput>;

    ChildId?: Maybe<Uuid>;

    Parent?: Maybe<PfdTagsObjRelInsertInput>;

    ParentId?: Maybe<Uuid>;
}
/** on_conflict condition type for table "PfdProjectTagDependencyModel" */
export interface PfdProjectTagDependencyModelOnConflict {
    constraint: PfdProjectTagDependencyModelConstraint;

    update_columns: PfdProjectTagDependencyModelUpdateColumn[];

    where?: Maybe<PfdProjectTagDependencyModelBoolExp>;
}
/** input type for inserting array relation for remote table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksArrRelInsertInput {
    data: ReportPfdTagLinksInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<ReportPfdTagLinksOnConflict>;
}
/** input type for inserting data into table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksInsertInput {
    Id?: Maybe<Uuid>;

    Order?: Maybe<number>;

    ProjectTemplate?: Maybe<ReportProjectTemplatesObjRelInsertInput>;

    ProjectTemplateId?: Maybe<Uuid>;

    Tag?: Maybe<PfdTagsObjRelInsertInput>;

    TagId?: Maybe<Uuid>;

    Template?: Maybe<ReportTemplatesObjRelInsertInput>;

    TemplateId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "ReportProjectTemplates" */
export interface ReportProjectTemplatesObjRelInsertInput {
    data: ReportProjectTemplatesInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<ReportProjectTemplatesOnConflict>;
}
/** input type for inserting data into table "ReportProjectTemplates" */
export interface ReportProjectTemplatesInsertInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    FileTypes?: Maybe<number>;

    Frequency?: Maybe<string>;

    GenerateAtHour?: Maybe<number>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    NextGenerationDate?: Maybe<Timestamp>;

    Parameters?: Maybe<Jsonb>;

    ReportTemplate?: Maybe<ReportTemplatesObjRelInsertInput>;

    ReportTemplateId?: Maybe<Uuid>;

    projectCode?: Maybe<string>;
}
/** input type for inserting object relation for remote table "ReportTemplates" */
export interface ReportTemplatesObjRelInsertInput {
    data: ReportTemplatesInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<ReportTemplatesOnConflict>;
}
/** input type for inserting data into table "ReportTemplates" */
export interface ReportTemplatesInsertInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    Name?: Maybe<string>;

    ReportGenerated?: Maybe<ReportGeneratedArrRelInsertInput>;

    ReportProjectTemplates?: Maybe<ReportProjectTemplatesArrRelInsertInput>;

    ReportTemplateBlocks?: Maybe<Jsonb>;

    ReportType?: Maybe<number>;

    Version?: Maybe<string>;
}
/** input type for inserting array relation for remote table "ReportGenerated" */
export interface ReportGeneratedArrRelInsertInput {
    data: ReportGeneratedInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<ReportGeneratedOnConflict>;
}
/** input type for inserting data into table "ReportGenerated" */
export interface ReportGeneratedInsertInput {
    Created?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DocUrl?: Maybe<string>;

    DynamicDataSnapshot?: Maybe<Jsonb>;

    Errors?: Maybe<_Text>;

    Finished?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateId?: Maybe<Uuid>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;

    ReportDateTime?: Maybe<Timestamp>;

    ReportTemplate?: Maybe<ReportTemplatesObjRelInsertInput>;

    ReportTemplateId?: Maybe<Uuid>;

    State?: Maybe<string>;

    Version?: Maybe<string>;

    Warnings?: Maybe<_Text>;

    WebUrl?: Maybe<string>;

    projectCode?: Maybe<string>;
}
/** on_conflict condition type for table "ReportGenerated" */
export interface ReportGeneratedOnConflict {
    constraint: ReportGeneratedConstraint;

    update_columns: ReportGeneratedUpdateColumn[];

    where?: Maybe<ReportGeneratedBoolExp>;
}
/** input type for inserting array relation for remote table "ReportProjectTemplates" */
export interface ReportProjectTemplatesArrRelInsertInput {
    data: ReportProjectTemplatesInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<ReportProjectTemplatesOnConflict>;
}
/** on_conflict condition type for table "ReportProjectTemplates" */
export interface ReportProjectTemplatesOnConflict {
    constraint: ReportProjectTemplatesConstraint;

    update_columns: ReportProjectTemplatesUpdateColumn[];

    where?: Maybe<ReportProjectTemplatesBoolExp>;
}
/** on_conflict condition type for table "ReportTemplates" */
export interface ReportTemplatesOnConflict {
    constraint: ReportTemplatesConstraint;

    update_columns: ReportTemplatesUpdateColumn[];

    where?: Maybe<ReportTemplatesBoolExp>;
}
/** on_conflict condition type for table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksOnConflict {
    constraint: ReportPfdTagLinksConstraint;

    update_columns: ReportPfdTagLinksUpdateColumn[];

    where?: Maybe<ReportPfdTagLinksBoolExp>;
}
/** input type for inserting object relation for remote table "PfdSamplingpoints" */
export interface PfdSamplingpointsObjRelInsertInput {
    data: PfdSamplingpointsInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<PfdSamplingpointsOnConflict>;
}
/** input type for inserting data into table "PfdSamplingpoints" */
export interface PfdSamplingpointsInsertInput {
    Description?: Maybe<string>;

    EnableForLab?: Maybe<boolean>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    Order?: Maybe<number>;

    Project?: Maybe<PfdProjectsObjRelInsertInput>;

    ProjectId?: Maybe<Uuid>;

    SamplingpointDefinitionId?: Maybe<number>;

    SamplingpointId?: Maybe<number>;
}
/** on_conflict condition type for table "PfdSamplingpoints" */
export interface PfdSamplingpointsOnConflict {
    constraint: PfdSamplingpointsConstraint;

    update_columns: PfdSamplingpointsUpdateColumn[];

    where?: Maybe<PfdSamplingpointsBoolExp>;
}
/** on_conflict condition type for table "PfdTags" */
export interface PfdTagsOnConflict {
    constraint: PfdTagsConstraint;

    update_columns: PfdTagsUpdateColumn[];

    where?: Maybe<PfdTagsBoolExp>;
}
/** input type for inserting array relation for remote table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullArrRelInsertInput {
    data: DataProcConfigMeasurementPullInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<DataProcConfigMeasurementPullOnConflict>;
}
/** input type for inserting data into table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullInsertInput {
    Configuration?: Maybe<DataProcConfigObjRelInsertInput>;

    ConfigurationId?: Maybe<Uuid>;

    Database?: Maybe<DatapointDatabaseObjRelInsertInput>;

    DatabaseId?: Maybe<Uuid>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    Json?: Maybe<string>;

    MaxDataIterations?: Maybe<number>;

    MaxDataRowTarget?: Maybe<number>;

    ServiceType?: Maybe<string>;

    State?: Maybe<DataProcStateMeasurementPullObjRelInsertInput>;
}
/** input type for inserting object relation for remote table "DataProcStateMeasurementPull" */
export interface DataProcStateMeasurementPullObjRelInsertInput {
    data: DataProcStateMeasurementPullInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DataProcStateMeasurementPullOnConflict>;
}
/** input type for inserting data into table "DataProcStateMeasurementPull" */
export interface DataProcStateMeasurementPullInsertInput {
    Configuration?: Maybe<DataProcConfigMeasurementPullObjRelInsertInput>;

    ConfigurationId?: Maybe<Uuid>;

    EndedDate?: Maybe<Timestamp>;

    Json?: Maybe<string>;

    Loops?: Maybe<Bigint>;

    PointsReceived?: Maybe<Numeric>;

    StartedDate?: Maybe<Timestamp>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;

    TotalPointsReceived?: Maybe<Numeric>;

    Updated?: Maybe<Timestamp>;
}
/** input type for inserting object relation for remote table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullObjRelInsertInput {
    data: DataProcConfigMeasurementPullInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DataProcConfigMeasurementPullOnConflict>;
}
/** on_conflict condition type for table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullOnConflict {
    constraint: DataProcConfigMeasurementPullConstraint;

    update_columns: DataProcConfigMeasurementPullUpdateColumn[];

    where?: Maybe<DataProcConfigMeasurementPullBoolExp>;
}
/** on_conflict condition type for table "DataProcStateMeasurementPull" */
export interface DataProcStateMeasurementPullOnConflict {
    constraint: DataProcStateMeasurementPullConstraint;

    update_columns: DataProcStateMeasurementPullUpdateColumn[];

    where?: Maybe<DataProcStateMeasurementPullBoolExp>;
}
/** input type for inserting data into table "DataProcServer" */
export interface DataProcServerInsertInput {
    Created?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Identifier?: Maybe<string>;

    LastPing?: Maybe<Timestamp>;

    Name?: Maybe<string>;
}
/** on_conflict condition type for table "DataProcServer" */
export interface DataProcServerOnConflict {
    constraint: DataProcServerConstraint;

    update_columns: DataProcServerUpdateColumn[];

    where?: Maybe<DataProcServerBoolExp>;
}
/** input type for inserting data into table "DataProcState" */
export interface DataProcStateInsertInput {
    Id?: Maybe<Uuid>;

    SchedulerLastRunDate?: Maybe<Timestamp>;
}
/** on_conflict condition type for table "DataProcState" */
export interface DataProcStateOnConflict {
    constraint: DataProcStateConstraint;

    update_columns: DataProcStateUpdateColumn[];

    where?: Maybe<DataProcStateBoolExp>;
}
/** input type for inserting data into table "DataProcStateEntityHistory" */
export interface DataProcStateEntityHistoryInsertInput {
    ConfigurationId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    ModelId?: Maybe<Uuid>;

    ModelType?: Maybe<string>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;
}
/** on_conflict condition type for table "DataProcStateEntityHistory" */
export interface DataProcStateEntityHistoryOnConflict {
    constraint: DataProcStateEntityHistoryConstraint;

    update_columns: DataProcStateEntityHistoryUpdateColumn[];

    where?: Maybe<DataProcStateEntityHistoryBoolExp>;
}
/** input type for inserting data into table "DataProcStateTree" */
export interface DataProcStateTreeInsertInput {
    ConfigurationId?: Maybe<Uuid>;

    CurrentWorkJson?: Maybe<string>;

    DataProcConfig?: Maybe<DataProcConfigObjRelInsertInput>;

    DatapointSeriesOperationLastDate?: Maybe<Timestamp>;

    PfdChangeLogLastDate?: Maybe<Timestamp>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;

    TreePingDate?: Maybe<Timestamp>;

    TreeStarted?: Maybe<Timestamp>;

    TreeStopped?: Maybe<Timestamp>;
}
/** on_conflict condition type for table "DataProcStateTree" */
export interface DataProcStateTreeOnConflict {
    constraint: DataProcStateTreeConstraint;

    update_columns: DataProcStateTreeUpdateColumn[];

    where?: Maybe<DataProcStateTreeBoolExp>;
}
/** input type for inserting data into table "DataProcStateTreeCommand" */
export interface DataProcStateTreeCommandInsertInput {
    LastCommand?: Maybe<Timestamp>;

    ServerId?: Maybe<string>;
}
/** on_conflict condition type for table "DataProcStateTreeCommand" */
export interface DataProcStateTreeCommandOnConflict {
    constraint: DataProcStateTreeCommandConstraint;

    update_columns: DataProcStateTreeCommandUpdateColumn[];

    where?: Maybe<DataProcStateTreeCommandBoolExp>;
}
/** input type for inserting data into table "DataProcStateTreeLeaf" */
export interface DataProcStateTreeLeafInsertInput {
    DataProcTreeLeaf?: Maybe<DataProcTreeLeafObjRelInsertInput>;

    LeafId?: Maybe<Uuid>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;

    TailVersion?: Maybe<Jsonb>;

    WorkState?: Maybe<Jsonb>;
}
/** input type for inserting object relation for remote table "DataProcTreeLeaf" */
export interface DataProcTreeLeafObjRelInsertInput {
    data: DataProcTreeLeafInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DataProcTreeLeafOnConflict>;
}
/** input type for inserting data into table "DataProcTreeLeaf" */
export interface DataProcTreeLeafInsertInput {
    Children?: Maybe<DataProcTreeLeafDependenciesArrRelInsertInput>;

    Class?: Maybe<number>;

    ClassPropertiesJson?: Maybe<Jsonb>;

    ConfigurationId?: Maybe<Uuid>;

    DatapointSeries?: Maybe<DatapointSeriesObjRelInsertInput>;

    DatapointSeriesId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    Parents?: Maybe<DataProcTreeLeafDependenciesArrRelInsertInput>;

    SourceId?: Maybe<Uuid>;

    SourceType?: Maybe<number>;

    State?: Maybe<DataProcStateTreeLeafObjRelInsertInput>;
}
/** input type for inserting array relation for remote table "DataProcTreeLeafDependencies" */
export interface DataProcTreeLeafDependenciesArrRelInsertInput {
    data: DataProcTreeLeafDependenciesInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<DataProcTreeLeafDependenciesOnConflict>;
}
/** input type for inserting data into table "DataProcTreeLeafDependencies" */
export interface DataProcTreeLeafDependenciesInsertInput {
    ChildDependenciesId?: Maybe<Uuid>;

    ParentDependenciesId?: Maybe<Uuid>;
}
/** on_conflict condition type for table "DataProcTreeLeafDependencies" */
export interface DataProcTreeLeafDependenciesOnConflict {
    constraint: DataProcTreeLeafDependenciesConstraint;

    update_columns: DataProcTreeLeafDependenciesUpdateColumn[];

    where?: Maybe<DataProcTreeLeafDependenciesBoolExp>;
}
/** input type for inserting object relation for remote table "DataProcStateTreeLeaf" */
export interface DataProcStateTreeLeafObjRelInsertInput {
    data: DataProcStateTreeLeafInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<DataProcStateTreeLeafOnConflict>;
}
/** on_conflict condition type for table "DataProcStateTreeLeaf" */
export interface DataProcStateTreeLeafOnConflict {
    constraint: DataProcStateTreeLeafConstraint;

    update_columns: DataProcStateTreeLeafUpdateColumn[];

    where?: Maybe<DataProcStateTreeLeafBoolExp>;
}
/** on_conflict condition type for table "DataProcTreeLeaf" */
export interface DataProcTreeLeafOnConflict {
    constraint: DataProcTreeLeafConstraint;

    update_columns: DataProcTreeLeafUpdateColumn[];

    where?: Maybe<DataProcTreeLeafBoolExp>;
}
/** input type for inserting data into table "DataProcTreeCommand" */
export interface DataProcTreeCommandInsertInput {
    CommandType?: Maybe<number>;

    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    Data?: Maybe<string>;

    Id?: Maybe<Uuid>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<number>;

    UserId?: Maybe<Uuid>;
}
/** on_conflict condition type for table "DataProcTreeCommand" */
export interface DataProcTreeCommandOnConflict {
    constraint: DataProcTreeCommandConstraint;

    update_columns: DataProcTreeCommandUpdateColumn[];

    where?: Maybe<DataProcTreeCommandBoolExp>;
}
/** input type for inserting data into table "DatapointSeriesOperation" */
export interface DatapointSeriesOperationInsertInput {
    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    End?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    Series?: Maybe<DatapointSeriesObjRelInsertInput>;

    SeriesId?: Maybe<Uuid>;

    Start?: Maybe<Timestamp>;

    Type?: Maybe<number>;

    UserId?: Maybe<Uuid>;
}
/** on_conflict condition type for table "DatapointSeriesOperation" */
export interface DatapointSeriesOperationOnConflict {
    constraint: DatapointSeriesOperationConstraint;

    update_columns: DatapointSeriesOperationUpdateColumn[];

    where?: Maybe<DatapointSeriesOperationBoolExp>;
}
/** input type for inserting data into table "FileTypes" */
export interface FileTypesInsertInput {
    FileItems?: Maybe<FileItemsArrRelInsertInput>;

    Id?: Maybe<number>;

    IsDeleted?: Maybe<boolean>;

    Name?: Maybe<string>;
}
/** input type for inserting array relation for remote table "FileItems" */
export interface FileItemsArrRelInsertInput {
    data: FileItemsInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<FileItemsOnConflict>;
}
/** on_conflict condition type for table "FileTypes" */
export interface FileTypesOnConflict {
    constraint: FileTypesConstraint;

    update_columns: FileTypesUpdateColumn[];

    where?: Maybe<FileTypesBoolExp>;
}
/** input type for inserting data into table "LaboScheduleStates" */
export interface LaboScheduleStatesInsertInput {
    CreatedAt?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedAt?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    LaboScheduleStatesPlannings?: Maybe<
        LaboScheduleStatesPlanningArrRelInsertInput
    >;

    Name?: Maybe<string>;

    ProjectId?: Maybe<string>;

    Schedule?: Maybe<Jsonb>;
}
/** input type for inserting array relation for remote table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningArrRelInsertInput {
    data: LaboScheduleStatesPlanningInsertInput[];
    /** upsert condition */
    on_conflict?: Maybe<LaboScheduleStatesPlanningOnConflict>;
}
/** input type for inserting data into table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningInsertInput {
    Id?: Maybe<Uuid>;

    LaboScheduleState?: Maybe<LaboScheduleStatesObjRelInsertInput>;

    Planned?: Maybe<Timestamp>;

    ProjectId?: Maybe<string>;

    StateId?: Maybe<Uuid>;
}
/** input type for inserting object relation for remote table "LaboScheduleStates" */
export interface LaboScheduleStatesObjRelInsertInput {
    data: LaboScheduleStatesInsertInput;
    /** upsert condition */
    on_conflict?: Maybe<LaboScheduleStatesOnConflict>;
}
/** on_conflict condition type for table "LaboScheduleStates" */
export interface LaboScheduleStatesOnConflict {
    constraint: LaboScheduleStatesConstraint;

    update_columns: LaboScheduleStatesUpdateColumn[];

    where?: Maybe<LaboScheduleStatesBoolExp>;
}
/** on_conflict condition type for table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningOnConflict {
    constraint: LaboScheduleStatesPlanningConstraint;

    update_columns: LaboScheduleStatesPlanningUpdateColumn[];

    where?: Maybe<LaboScheduleStatesPlanningBoolExp>;
}
/** input type for inserting data into table "NotificationTypes" */
export interface NotificationTypesInsertInput {
    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** on_conflict condition type for table "NotificationTypes" */
export interface NotificationTypesOnConflict {
    constraint: NotificationTypesConstraint;

    update_columns: NotificationTypesUpdateColumn[];

    where?: Maybe<NotificationTypesBoolExp>;
}
/** input type for inserting data into table "PfdChangeLogs" */
export interface PfdChangeLogsInsertInput {
    Created?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    ModelFieldName?: Maybe<string>;

    ModelId?: Maybe<Uuid>;

    ModelName?: Maybe<string>;

    Name?: Maybe<string>;

    Project?: Maybe<PfdProjectsObjRelInsertInput>;

    ProjectId?: Maybe<Uuid>;

    ValueNew?: Maybe<string>;

    ValueOld?: Maybe<string>;
}
/** on_conflict condition type for table "PfdChangeLogs" */
export interface PfdChangeLogsOnConflict {
    constraint: PfdChangeLogsConstraint;

    update_columns: PfdChangeLogsUpdateColumn[];

    where?: Maybe<PfdChangeLogsBoolExp>;
}
/** input type for inserting data into table "PfdProjectBlockDependencyModel" */
export interface PfdProjectBlockDependencyModelInsertInput {
    Child?: Maybe<PfdBlocksObjRelInsertInput>;

    ChildId?: Maybe<Uuid>;

    Parent?: Maybe<PfdBlocksObjRelInsertInput>;

    ParentId?: Maybe<Uuid>;
}
/** on_conflict condition type for table "PfdProjectBlockDependencyModel" */
export interface PfdProjectBlockDependencyModelOnConflict {
    constraint: PfdProjectBlockDependencyModelConstraint;

    update_columns: PfdProjectBlockDependencyModelUpdateColumn[];

    where?: Maybe<PfdProjectBlockDependencyModelBoolExp>;
}
/** input type for inserting data into table "ProjectConfigurations" */
export interface ProjectConfigurationsInsertInput {
    Id?: Maybe<Uuid>;

    ProjectCode?: Maybe<string>;

    ProjectLogo?: Maybe<string>;

    ProjectName?: Maybe<string>;

    ProjectType?: Maybe<string>;

    ReportsDrive?: Maybe<string>;

    ReportsItem?: Maybe<string>;

    ReportsSite?: Maybe<string>;

    TimeZone?: Maybe<string>;
}
/** on_conflict condition type for table "ProjectConfigurations" */
export interface ProjectConfigurationsOnConflict {
    constraint: ProjectConfigurationsConstraint;

    update_columns: ProjectConfigurationsUpdateColumn[];

    where?: Maybe<ProjectConfigurationsBoolExp>;
}
/** input type for inserting data into table "ReportProjectFiles" */
export interface ReportProjectFilesInsertInput {
    Created?: Maybe<Timestamptz>;

    DriveItemId?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Modified?: Maybe<Timestamptz>;

    Name?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    Size?: Maybe<Bigint>;

    Type?: Maybe<string>;

    WebUrl?: Maybe<string>;
}
/** on_conflict condition type for table "ReportProjectFiles" */
export interface ReportProjectFilesOnConflict {
    constraint: ReportProjectFilesConstraint;

    update_columns: ReportProjectFilesUpdateColumn[];

    where?: Maybe<ReportProjectFilesBoolExp>;
}
/** input type for inserting data into table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesInsertInput {
    AllowedPermissions?: Maybe<Jsonb>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** on_conflict condition type for table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesOnConflict {
    constraint: SmartlabFunctionalitiesConstraint;

    update_columns: SmartlabFunctionalitiesUpdateColumn[];

    where?: Maybe<SmartlabFunctionalitiesBoolExp>;
}
/** input type for inserting data into table "SupportChatAutoMessages" */
export interface SupportChatAutoMessagesInsertInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    ProjectGroupId?: Maybe<Uuid>;

    TextValue?: Maybe<string>;
}
/** on_conflict condition type for table "SupportChatAutoMessages" */
export interface SupportChatAutoMessagesOnConflict {
    constraint: SupportChatAutoMessagesConstraint;

    update_columns: SupportChatAutoMessagesUpdateColumn[];

    where?: Maybe<SupportChatAutoMessagesBoolExp>;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface BrandingAppendInput {
    AllowedProjects?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface BrandingDeleteAtPathInput {
    AllowedProjects?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface BrandingDeleteElemInput {
    AllowedProjects?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface BrandingDeleteKeyInput {
    AllowedProjects?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface BrandingPrependInput {
    AllowedProjects?: Maybe<Jsonb>;
}
/** input type for updating data in table "Branding" */
export interface BrandingSetInput {
    AlertsEnabled?: Maybe<boolean>;

    AlertsTitle?: Maybe<string>;

    AllowedProjects?: Maybe<Jsonb>;

    CalendarEnabled?: Maybe<boolean>;

    CalendarTitle?: Maybe<string>;

    Domain?: Maybe<string>;

    EnableBigLogo?: Maybe<boolean>;

    FavIcon?: Maybe<string>;

    Id?: Maybe<Uuid>;

    LaboratoryEnabled?: Maybe<boolean>;

    LaboratoryTitle?: Maybe<string>;

    LogbookEnabled?: Maybe<boolean>;

    LogbookTitle?: Maybe<string>;

    Logo?: Maybe<string>;

    MaintenanceEnabled?: Maybe<boolean>;

    MaintenanceTitle?: Maybe<string>;

    MyPlantsTableTitle?: Maybe<string>;

    MyPlantsTitle?: Maybe<string>;

    Name?: Maybe<string>;

    OperatorEnabled?: Maybe<boolean>;

    OperatorTitle?: Maybe<string>;

    PlantEnabled?: Maybe<boolean>;

    PlantOverrideWithPFDUnitId?: Maybe<string>;

    PlantTitle?: Maybe<string>;

    PrimaryColor?: Maybe<string>;

    ReportsEnabled?: Maybe<boolean>;

    ReportsTitle?: Maybe<string>;

    SecondaryColor?: Maybe<string>;

    TechnologiesEnabled?: Maybe<boolean>;

    TechnologiesTitle?: Maybe<string>;

    UtilitiesEnabled?: Maybe<boolean>;

    UtilitiesTitle?: Maybe<string>;
}
/** primary key columns input for table: Branding */
export interface BrandingPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "CalendarEvents" */
export interface CalendarEventsSetInput {
    AllDay?: Maybe<boolean>;

    ColorPrimary?: Maybe<string>;

    ColorSecondary?: Maybe<string>;

    Draggable?: Maybe<boolean>;

    End?: Maybe<Timestamptz>;

    Id?: Maybe<Uuid>;

    MetaLocation?: Maybe<string>;

    MetaNotes?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    ResizableAfterEnd?: Maybe<boolean>;

    ResizableBeforeStart?: Maybe<boolean>;

    Start?: Maybe<Timestamptz>;

    Title?: Maybe<string>;

    UserId?: Maybe<string>;
}
/** primary key columns input for table: CalendarEvents */
export interface CalendarEventsPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "ClientGroups" */
export interface ClientGroupsIncInput {
    WepId?: Maybe<number>;
}
/** input type for updating data in table "ClientGroups" */
export interface ClientGroupsSetInput {
    ClientIds?: Maybe<_Text>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    WepId?: Maybe<number>;
}
/** input type for updating data in table "ClientGroupsSmartlabUsers" */
export interface ClientGroupsSmartlabUsersSetInput {
    ClientGroupId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** primary key columns input for table: ClientGroupsSmartlabUsers */
export interface ClientGroupsSmartlabUsersPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: ClientGroups */
export interface ClientGroupsPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "CumulDashboards" */
export interface CumulDashboardsSetInput {
    DashboardId?: Maybe<string>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    ModifiedAt?: Maybe<Timestamp>;

    Name?: Maybe<string>;
}
/** primary key columns input for table: CumulDashboards */
export interface CumulDashboardsPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "CumulTokens" */
export interface CumulTokensSetInput {
    AuthorizationId?: Maybe<string>;

    AuthorizationToken?: Maybe<string>;

    CreatedAt?: Maybe<Timestamp>;

    HashedRequestSignature?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Revoked?: Maybe<boolean>;

    UserId?: Maybe<string>;

    ValidUntil?: Maybe<Timestamp>;
}
/** primary key columns input for table: CumulTokens */
export interface CumulTokensPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "DataProcConfig" */
export interface DataProcConfigIncInput {
    DataConnectionFlags?: Maybe<number>;

    FrequencyMinutes?: Maybe<Bigint>;

    MaxMeasurementAgeMinutes?: Maybe<Bigint>;

    ProcessorVersion?: Maybe<number>;

    WorkersPriorityHigh?: Maybe<number>;

    WorkersPriorityLow?: Maybe<number>;
}
/** input type for updating data in table "DataProcConfig" */
export interface DataProcConfigSetInput {
    DataConnectionDescription?: Maybe<string>;

    DataConnectionFlags?: Maybe<number>;

    Enable?: Maybe<boolean>;

    EnableDataProcessing?: Maybe<boolean>;

    FrequencyMinutes?: Maybe<Bigint>;

    Id?: Maybe<Uuid>;

    MaxMeasurementAgeMinutes?: Maybe<Bigint>;

    MeasurementCutoffDate?: Maybe<Timestamp>;

    MeasurementDatabaseId?: Maybe<Uuid>;

    PfdProjectId?: Maybe<Uuid>;

    ProcessorVersion?: Maybe<number>;

    ProjectCode?: Maybe<string>;

    ServerId?: Maybe<Uuid>;

    SignalDatabaseId?: Maybe<Uuid>;

    UpdatedServer?: Maybe<Timestamp>;

    WorkersPriorityHigh?: Maybe<number>;

    WorkersPriorityLow?: Maybe<number>;
}
/** input type for incrementing numeric columns in table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullIncInput {
    MaxDataIterations?: Maybe<number>;

    MaxDataRowTarget?: Maybe<number>;
}
/** input type for updating data in table "DataProcConfigMeasurementPull" */
export interface DataProcConfigMeasurementPullSetInput {
    ConfigurationId?: Maybe<Uuid>;

    DatabaseId?: Maybe<Uuid>;

    Enabled?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    Json?: Maybe<string>;

    MaxDataIterations?: Maybe<number>;

    MaxDataRowTarget?: Maybe<number>;

    ServiceType?: Maybe<string>;
}
/** primary key columns input for table: DataProcConfigMeasurementPull */
export interface DataProcConfigMeasurementPullPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: DataProcConfig */
export interface DataProcConfigPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "DataProcProjectState" */
export interface DataProcProjectStateIncInput {
    JobNr?: Maybe<Numeric>;

    KpiValue?: Maybe<Float8>;

    TagProcessingPointsProcessed?: Maybe<Bigint>;

    TagProcessingTagsDoneProcessing?: Maybe<Bigint>;

    TagProcessingTagsProcessed?: Maybe<Bigint>;

    TagProcessingTotalPointsProcessed?: Maybe<Numeric>;

    TotalState?: Maybe<number>;
}
/** input type for updating data in table "DataProcProjectState" */
export interface DataProcProjectStateSetInput {
    ConfigurationId?: Maybe<Uuid>;

    EndedDate?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    IsLatestMeasurementDateOld?: Maybe<boolean>;

    JobNr?: Maybe<Numeric>;

    KpiDate?: Maybe<Timestamp>;

    KpiValue?: Maybe<Float8>;

    LatestMeasurementDate?: Maybe<Timestamp>;

    StartedDate?: Maybe<Timestamp>;

    TagProcessingEndedDate?: Maybe<Timestamp>;

    TagProcessingErrorMessage?: Maybe<string>;

    TagProcessingPointsProcessed?: Maybe<Bigint>;

    TagProcessingStartedDate?: Maybe<Timestamp>;

    TagProcessingTagsDoneProcessing?: Maybe<Bigint>;

    TagProcessingTagsProcessed?: Maybe<Bigint>;

    TagProcessingTotalPointsProcessed?: Maybe<Numeric>;

    TotalState?: Maybe<number>;

    Updated?: Maybe<Timestamp>;
}
/** primary key columns input for table: DataProcProjectState */
export interface DataProcProjectStatePkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "DataProcServer" */
export interface DataProcServerSetInput {
    Created?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Identifier?: Maybe<string>;

    LastPing?: Maybe<Timestamp>;

    Name?: Maybe<string>;
}
/** primary key columns input for table: DataProcServer */
export interface DataProcServerPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "DataProcState" */
export interface DataProcStateSetInput {
    Id?: Maybe<Uuid>;

    SchedulerLastRunDate?: Maybe<Timestamp>;
}
/** input type for updating data in table "DataProcStateEntityHistory" */
export interface DataProcStateEntityHistorySetInput {
    ConfigurationId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    ModelId?: Maybe<Uuid>;

    ModelType?: Maybe<string>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;
}
/** primary key columns input for table: DataProcStateEntityHistory */
export interface DataProcStateEntityHistoryPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "DataProcStateMeasurementPull" */
export interface DataProcStateMeasurementPullIncInput {
    Loops?: Maybe<Bigint>;

    PointsReceived?: Maybe<Numeric>;

    TotalPointsReceived?: Maybe<Numeric>;
}
/** input type for updating data in table "DataProcStateMeasurementPull" */
export interface DataProcStateMeasurementPullSetInput {
    ConfigurationId?: Maybe<Uuid>;

    EndedDate?: Maybe<Timestamp>;

    Json?: Maybe<string>;

    Loops?: Maybe<Bigint>;

    PointsReceived?: Maybe<Numeric>;

    StartedDate?: Maybe<Timestamp>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;

    TotalPointsReceived?: Maybe<Numeric>;

    Updated?: Maybe<Timestamp>;
}
/** primary key columns input for table: DataProcStateMeasurementPull */
export interface DataProcStateMeasurementPullPkColumnsInput {
    ConfigurationId: Uuid;
}
/** input type for updating data in table "DataProcStateTree" */
export interface DataProcStateTreeSetInput {
    ConfigurationId?: Maybe<Uuid>;

    CurrentWorkJson?: Maybe<string>;

    DatapointSeriesOperationLastDate?: Maybe<Timestamp>;

    PfdChangeLogLastDate?: Maybe<Timestamp>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;

    TreePingDate?: Maybe<Timestamp>;

    TreeStarted?: Maybe<Timestamp>;

    TreeStopped?: Maybe<Timestamp>;
}
/** input type for updating data in table "DataProcStateTreeCommand" */
export interface DataProcStateTreeCommandSetInput {
    LastCommand?: Maybe<Timestamp>;

    ServerId?: Maybe<string>;
}
/** primary key columns input for table: DataProcStateTreeCommand */
export interface DataProcStateTreeCommandPkColumnsInput {
    ServerId: string;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface DataProcStateTreeLeafAppendInput {
    TailVersion?: Maybe<Jsonb>;

    WorkState?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface DataProcStateTreeLeafDeleteAtPathInput {
    TailVersion?: Maybe<string[]>;

    WorkState?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface DataProcStateTreeLeafDeleteElemInput {
    TailVersion?: Maybe<number>;

    WorkState?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface DataProcStateTreeLeafDeleteKeyInput {
    TailVersion?: Maybe<string>;

    WorkState?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface DataProcStateTreeLeafPrependInput {
    TailVersion?: Maybe<Jsonb>;

    WorkState?: Maybe<Jsonb>;
}
/** input type for updating data in table "DataProcStateTreeLeaf" */
export interface DataProcStateTreeLeafSetInput {
    LeafId?: Maybe<Uuid>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<string>;

    TailVersion?: Maybe<Jsonb>;

    WorkState?: Maybe<Jsonb>;
}
/** primary key columns input for table: DataProcStateTreeLeaf */
export interface DataProcStateTreeLeafPkColumnsInput {
    LeafId: Uuid;
}
/** primary key columns input for table: DataProcStateTree */
export interface DataProcStateTreePkColumnsInput {
    ConfigurationId: Uuid;
}
/** primary key columns input for table: DataProcState */
export interface DataProcStatePkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "DataProcTagState" */
export interface DataProcTagStateIncInput {
    ErrorType?: Maybe<number>;

    JobNr?: Maybe<Numeric>;

    PointsProcessed?: Maybe<Bigint>;
}
/** input type for updating data in table "DataProcTagState" */
export interface DataProcTagStateSetInput {
    BufferId?: Maybe<Uuid>;

    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    FirstPendingDate?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    IsProcessing?: Maybe<boolean>;

    JobNr?: Maybe<Numeric>;

    LastDependencyDate?: Maybe<Timestamp>;

    LastPendingDate?: Maybe<Timestamp>;

    LastProcessedDate?: Maybe<Timestamp>;

    MeasurementId?: Maybe<Uuid>;

    ParentWithOldestLastDatapointId?: Maybe<Uuid>;

    PfdTagId?: Maybe<Uuid>;

    PointsProcessed?: Maybe<Bigint>;

    ProcessedRangeDone?: Maybe<Timestamp>;

    ProcessedRangeFrom?: Maybe<Timestamp>;

    ProcessedRangeTo?: Maybe<Timestamp>;

    ProcessingEndedDate?: Maybe<Timestamp>;

    ProcessingStartedDate?: Maybe<Timestamp>;

    SignalId?: Maybe<Uuid>;

    Updated?: Maybe<Timestamp>;
}
/** primary key columns input for table: DataProcTagState */
export interface DataProcTagStatePkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "DataProcTreeCommand" */
export interface DataProcTreeCommandIncInput {
    CommandType?: Maybe<number>;

    StateType?: Maybe<number>;
}
/** input type for updating data in table "DataProcTreeCommand" */
export interface DataProcTreeCommandSetInput {
    CommandType?: Maybe<number>;

    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    Data?: Maybe<string>;

    Id?: Maybe<Uuid>;

    StateDate?: Maybe<Timestamp>;

    StateMessage?: Maybe<string>;

    StateType?: Maybe<number>;

    UserId?: Maybe<Uuid>;
}
/** primary key columns input for table: DataProcTreeCommand */
export interface DataProcTreeCommandPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface DataProcTreeLeafAppendInput {
    ClassPropertiesJson?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface DataProcTreeLeafDeleteAtPathInput {
    ClassPropertiesJson?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface DataProcTreeLeafDeleteElemInput {
    ClassPropertiesJson?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface DataProcTreeLeafDeleteKeyInput {
    ClassPropertiesJson?: Maybe<string>;
}
/** input type for incrementing numeric columns in table "DataProcTreeLeaf" */
export interface DataProcTreeLeafIncInput {
    Class?: Maybe<number>;

    SourceType?: Maybe<number>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface DataProcTreeLeafPrependInput {
    ClassPropertiesJson?: Maybe<Jsonb>;
}
/** input type for updating data in table "DataProcTreeLeaf" */
export interface DataProcTreeLeafSetInput {
    Class?: Maybe<number>;

    ClassPropertiesJson?: Maybe<Jsonb>;

    ConfigurationId?: Maybe<Uuid>;

    DatapointSeriesId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    SourceId?: Maybe<Uuid>;

    SourceType?: Maybe<number>;
}
/** input type for updating data in table "DataProcTreeLeafDependencies" */
export interface DataProcTreeLeafDependenciesSetInput {
    ChildDependenciesId?: Maybe<Uuid>;

    ParentDependenciesId?: Maybe<Uuid>;
}
/** primary key columns input for table: DataProcTreeLeafDependencies */
export interface DataProcTreeLeafDependenciesPkColumnsInput {
    ChildDependenciesId: Uuid;

    ParentDependenciesId: Uuid;
}
/** primary key columns input for table: DataProcTreeLeaf */
export interface DataProcTreeLeafPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "DatapointDatabase" */
export interface DatapointDatabaseSetInput {
    ConfigPathCredentials?: Maybe<string>;

    CreateAllGenericTables?: Maybe<boolean>;

    Created?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    HostId?: Maybe<Uuid>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    ParentId?: Maybe<Uuid>;

    Schema?: Maybe<string>;
}
/** primary key columns input for table: DatapointDatabase */
export interface DatapointDatabasePkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface DatapointHostAppendInput {
    Parameters?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface DatapointHostDeleteAtPathInput {
    Parameters?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface DatapointHostDeleteElemInput {
    Parameters?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface DatapointHostDeleteKeyInput {
    Parameters?: Maybe<string>;
}
/** input type for incrementing numeric columns in table "DatapointHost" */
export interface DatapointHostIncInput {
    HostType?: Maybe<number>;

    Port?: Maybe<number>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface DatapointHostPrependInput {
    Parameters?: Maybe<Jsonb>;
}
/** input type for updating data in table "DatapointHost" */
export interface DatapointHostSetInput {
    Alias?: Maybe<string>;

    ConfigPathCredentials?: Maybe<string>;

    Created?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Host?: Maybe<string>;

    HostType?: Maybe<number>;

    Id?: Maybe<Uuid>;

    IsTest?: Maybe<boolean>;

    Parameters?: Maybe<Jsonb>;

    Port?: Maybe<number>;

    Portal?: Maybe<string>;
}
/** primary key columns input for table: DatapointHost */
export interface DatapointHostPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface DatapointSeriesAppendInput {
    Filters?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface DatapointSeriesDeleteAtPathInput {
    Filters?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface DatapointSeriesDeleteElemInput {
    Filters?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface DatapointSeriesDeleteKeyInput {
    Filters?: Maybe<string>;
}
/** input type for incrementing numeric columns in table "DatapointSeries" */
export interface DatapointSeriesIncInput {
    State?: Maybe<number>;

    Type?: Maybe<number>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface DatapointSeriesPrependInput {
    Filters?: Maybe<Jsonb>;
}
/** input type for updating data in table "DatapointSeries" */
export interface DatapointSeriesSetInput {
    Alias?: Maybe<string>;

    Created?: Maybe<Timestamp>;

    Filters?: Maybe<Jsonb>;

    Id?: Maybe<Uuid>;

    State?: Maybe<number>;

    TableId?: Maybe<Uuid>;

    TotalRangeFrom?: Maybe<Timestamp>;

    TotalRangeTo?: Maybe<Timestamp>;

    Type?: Maybe<number>;

    Updated?: Maybe<Timestamp>;
}
/** input type for incrementing numeric columns in table "DatapointSeriesOperation" */
export interface DatapointSeriesOperationIncInput {
    Type?: Maybe<number>;
}
/** input type for updating data in table "DatapointSeriesOperation" */
export interface DatapointSeriesOperationSetInput {
    ConfigurationId?: Maybe<Uuid>;

    Created?: Maybe<Timestamp>;

    End?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    SeriesId?: Maybe<Uuid>;

    Start?: Maybe<Timestamp>;

    Type?: Maybe<number>;

    UserId?: Maybe<Uuid>;
}
/** primary key columns input for table: DatapointSeriesOperation */
export interface DatapointSeriesOperationPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: DatapointSeries */
export interface DatapointSeriesPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface DatapointTableAppendInput {
    Filters?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface DatapointTableDeleteAtPathInput {
    Filters?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface DatapointTableDeleteElemInput {
    Filters?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface DatapointTableDeleteKeyInput {
    Filters?: Maybe<string>;
}
/** input type for incrementing numeric columns in table "DatapointTable" */
export interface DatapointTableIncInput {
    DataType?: Maybe<number>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface DatapointTablePrependInput {
    Filters?: Maybe<Jsonb>;
}
/** input type for updating data in table "DatapointTable" */
export interface DatapointTableSetInput {
    Created?: Maybe<Timestamp>;

    DataType?: Maybe<number>;

    DatabaseId?: Maybe<Uuid>;

    Filters?: Maybe<Jsonb>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    TableCreated?: Maybe<Timestamp>;
}
/** primary key columns input for table: DatapointTable */
export interface DatapointTablePkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "FileItems" */
export interface FileItemsIncInput {
    FileTypeId?: Maybe<number>;

    Id?: Maybe<Bigint>;
}
/** input type for updating data in table "FileItems" */
export interface FileItemsSetInput {
    FileName?: Maybe<string>;

    FileReference?: Maybe<string>;

    FileTypeId?: Maybe<number>;

    Id?: Maybe<Bigint>;

    IsDeleted?: Maybe<boolean>;
}
/** primary key columns input for table: FileItems */
export interface FileItemsPkColumnsInput {
    Id: Bigint;
}
/** input type for incrementing numeric columns in table "FileTypes" */
export interface FileTypesIncInput {
    Id?: Maybe<number>;
}
/** input type for updating data in table "FileTypes" */
export interface FileTypesSetInput {
    Id?: Maybe<number>;

    IsDeleted?: Maybe<boolean>;

    Name?: Maybe<string>;
}
/** primary key columns input for table: FileTypes */
export interface FileTypesPkColumnsInput {
    Id: number;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface LaboScheduleStatesAppendInput {
    Schedule?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface LaboScheduleStatesDeleteAtPathInput {
    Schedule?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface LaboScheduleStatesDeleteElemInput {
    Schedule?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface LaboScheduleStatesDeleteKeyInput {
    Schedule?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface LaboScheduleStatesPrependInput {
    Schedule?: Maybe<Jsonb>;
}
/** input type for updating data in table "LaboScheduleStates" */
export interface LaboScheduleStatesSetInput {
    CreatedAt?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedAt?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    ProjectId?: Maybe<string>;

    Schedule?: Maybe<Jsonb>;
}
/** input type for updating data in table "LaboScheduleStatesPlanning" */
export interface LaboScheduleStatesPlanningSetInput {
    Id?: Maybe<Uuid>;

    Planned?: Maybe<Timestamp>;

    ProjectId?: Maybe<string>;

    StateId?: Maybe<Uuid>;
}
/** primary key columns input for table: LaboScheduleStatesPlanning */
export interface LaboScheduleStatesPlanningPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: LaboScheduleStates */
export interface LaboScheduleStatesPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "NotificationTypes" */
export interface NotificationTypesSetInput {
    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** primary key columns input for table: NotificationTypes */
export interface NotificationTypesPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "Notifications" */
export interface NotificationsSetInput {
    Id?: Maybe<Uuid>;

    MessageId?: Maybe<Uuid>;

    NotificationTypeId?: Maybe<Uuid>;

    ProjectId?: Maybe<Uuid>;

    SubjectId?: Maybe<Uuid>;

    UserFromId?: Maybe<Uuid>;

    UserToId?: Maybe<Uuid>;
}
/** primary key columns input for table: Notifications */
export interface NotificationsPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "PfdBlocks" */
export interface PfdBlocksIncInput {
    BlockDefinitionId?: Maybe<number>;

    BlockId?: Maybe<number>;

    ErrorType?: Maybe<number>;

    Order?: Maybe<number>;
}
/** input type for updating data in table "PfdBlocks" */
export interface PfdBlocksSetInput {
    BlockDefinitionId?: Maybe<number>;

    BlockId?: Maybe<number>;

    ChildId?: Maybe<Uuid>;

    DefinitionId?: Maybe<Uuid>;

    Description?: Maybe<string>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    IsDefinition?: Maybe<boolean>;

    IsMaster?: Maybe<boolean>;

    IsStream?: Maybe<boolean>;

    Name?: Maybe<string>;

    NameShort?: Maybe<string>;

    Order?: Maybe<number>;

    ProjectId?: Maybe<Uuid>;

    SmartlabMenuEnabled?: Maybe<boolean>;
}
/** primary key columns input for table: PfdBlocks */
export interface PfdBlocksPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "PfdChangeLogs" */
export interface PfdChangeLogsSetInput {
    Created?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    ModelFieldName?: Maybe<string>;

    ModelId?: Maybe<Uuid>;

    ModelName?: Maybe<string>;

    Name?: Maybe<string>;

    ProjectId?: Maybe<Uuid>;

    ValueNew?: Maybe<string>;

    ValueOld?: Maybe<string>;
}
/** primary key columns input for table: PfdChangeLogs */
export interface PfdChangeLogsPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "PfdProjectBlockDependencyModel" */
export interface PfdProjectBlockDependencyModelSetInput {
    ChildId?: Maybe<Uuid>;

    ParentId?: Maybe<Uuid>;
}
/** primary key columns input for table: PfdProjectBlockDependencyModel */
export interface PfdProjectBlockDependencyModelPkColumnsInput {
    ChildId: Uuid;

    ParentId: Uuid;
}
/** input type for updating data in table "PfdProjectTagDependencyModel" */
export interface PfdProjectTagDependencyModelSetInput {
    ChildId?: Maybe<Uuid>;

    ParentId?: Maybe<Uuid>;
}
/** primary key columns input for table: PfdProjectTagDependencyModel */
export interface PfdProjectTagDependencyModelPkColumnsInput {
    ChildId: Uuid;

    ParentId: Uuid;
}
/** input type for incrementing numeric columns in table "PfdProjects" */
export interface PfdProjectsIncInput {
    ErrorType?: Maybe<number>;

    WepClientId?: Maybe<number>;

    WepId?: Maybe<number>;
}
/** input type for updating data in table "PfdProjects" */
export interface PfdProjectsSetInput {
    Address?: Maybe<string>;

    City?: Maybe<string>;

    Code?: Maybe<string>;

    Coordinates?: Maybe<string>;

    Country?: Maybe<string>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    TimeZone?: Maybe<string>;

    Type?: Maybe<string>;

    WepClientId?: Maybe<number>;

    WepId?: Maybe<number>;
}
/** primary key columns input for table: PfdProjects */
export interface PfdProjectsPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "PfdSamplingpoints" */
export interface PfdSamplingpointsIncInput {
    ErrorType?: Maybe<number>;

    Order?: Maybe<number>;

    SamplingpointDefinitionId?: Maybe<number>;

    SamplingpointId?: Maybe<number>;
}
/** input type for updating data in table "PfdSamplingpoints" */
export interface PfdSamplingpointsSetInput {
    Description?: Maybe<string>;

    EnableForLab?: Maybe<boolean>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    Order?: Maybe<number>;

    ProjectId?: Maybe<Uuid>;

    SamplingpointDefinitionId?: Maybe<number>;

    SamplingpointId?: Maybe<number>;
}
/** primary key columns input for table: PfdSamplingpoints */
export interface PfdSamplingpointsPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "PfdTags" */
export interface PfdTagsIncInput {
    AggregationDatapointTime?: Maybe<number>;

    AggregationDatapointTimeMultiplier?: Maybe<number>;

    AggregationTime?: Maybe<number>;

    AggregationTimeMultiplier?: Maybe<number>;

    AggregationTimeSource?: Maybe<number>;

    AggregationType?: Maybe<number>;

    DataType?: Maybe<number>;

    ErrorType?: Maybe<number>;

    Limit_h?: Maybe<Float8>;

    Limit_hh?: Maybe<Float8>;

    Limit_l?: Maybe<Float8>;

    Limit_ll?: Maybe<Float8>;

    MeasurementType?: Maybe<number>;

    Source?: Maybe<number>;

    TagDefinitionId?: Maybe<number>;

    TagId?: Maybe<number>;
}
/** input type for updating data in table "PfdTags" */
export interface PfdTagsSetInput {
    AggregationDatapointTime?: Maybe<number>;

    AggregationDatapointTimeMultiplier?: Maybe<number>;

    AggregationIsFaked?: Maybe<boolean>;

    AggregationTime?: Maybe<number>;

    AggregationTimeMultiplier?: Maybe<number>;

    AggregationTimeSource?: Maybe<number>;

    AggregationType?: Maybe<number>;

    BlockId?: Maybe<Uuid>;

    DataType?: Maybe<number>;

    DefinitionId?: Maybe<Uuid>;

    Description?: Maybe<string>;

    Enabled?: Maybe<boolean>;

    ErrorType?: Maybe<number>;

    Errors?: Maybe<string>;

    Formula?: Maybe<string>;

    Id?: Maybe<Uuid>;

    IsDefinition?: Maybe<boolean>;

    IsParameter?: Maybe<boolean>;

    IsProjectTag?: Maybe<boolean>;

    Limit_h?: Maybe<Float8>;

    Limit_hh?: Maybe<Float8>;

    Limit_l?: Maybe<Float8>;

    Limit_ll?: Maybe<Float8>;

    MeasurementName?: Maybe<string>;

    MeasurementNameSF?: Maybe<string>;

    MeasurementType?: Maybe<number>;

    Name?: Maybe<string>;

    ProjectId?: Maybe<Uuid>;

    Quantity?: Maybe<string>;

    SamplingpointId?: Maybe<Uuid>;

    Source?: Maybe<number>;

    Substance?: Maybe<string>;

    TagDefinitionId?: Maybe<number>;

    TagId?: Maybe<number>;

    Unit?: Maybe<string>;
}
/** primary key columns input for table: PfdTags */
export interface PfdTagsPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "ProjectConfigurations" */
export interface ProjectConfigurationsSetInput {
    Id?: Maybe<Uuid>;

    ProjectCode?: Maybe<string>;

    ProjectLogo?: Maybe<string>;

    ProjectName?: Maybe<string>;

    ProjectType?: Maybe<string>;

    ReportsDrive?: Maybe<string>;

    ReportsItem?: Maybe<string>;

    ReportsSite?: Maybe<string>;

    TimeZone?: Maybe<string>;
}
/** primary key columns input for table: ProjectConfigurations */
export interface ProjectConfigurationsPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "ProjectGroups" */
export interface ProjectGroupsIncInput {
    WepId?: Maybe<number>;
}
/** input type for updating data in table "ProjectGroups" */
export interface ProjectGroupsSetInput {
    ClientGroupId?: Maybe<Uuid>;

    Code?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;

    PfdProjectId?: Maybe<Uuid>;

    ProjectCodes?: Maybe<_Text>;

    WepId?: Maybe<number>;
}
/** input type for updating data in table "ProjectGroupsSmartlabUsers" */
export interface ProjectGroupsSmartlabUsersSetInput {
    Id?: Maybe<Uuid>;

    ProjectGroupId?: Maybe<Uuid>;

    SmartlabUserId?: Maybe<Uuid>;
}
/** primary key columns input for table: ProjectGroupsSmartlabUsers */
export interface ProjectGroupsSmartlabUsersPkColumnsInput {
    Id: Uuid;
}
/** primary key columns input for table: ProjectGroups */
export interface ProjectGroupsPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface ReportGeneratedAppendInput {
    DynamicDataSnapshot?: Maybe<Jsonb>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ReportGeneratedDeleteAtPathInput {
    DynamicDataSnapshot?: Maybe<string[]>;

    ProjectConfigurationSnapshot?: Maybe<string[]>;

    ProjectReportTemplateSnapshot?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ReportGeneratedDeleteElemInput {
    DynamicDataSnapshot?: Maybe<number>;

    ProjectConfigurationSnapshot?: Maybe<number>;

    ProjectReportTemplateSnapshot?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ReportGeneratedDeleteKeyInput {
    DynamicDataSnapshot?: Maybe<string>;

    ProjectConfigurationSnapshot?: Maybe<string>;

    ProjectReportTemplateSnapshot?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ReportGeneratedPrependInput {
    DynamicDataSnapshot?: Maybe<Jsonb>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;
}
/** input type for updating data in table "ReportGenerated" */
export interface ReportGeneratedSetInput {
    Created?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DocUrl?: Maybe<string>;

    DynamicDataSnapshot?: Maybe<Jsonb>;

    Errors?: Maybe<_Text>;

    Finished?: Maybe<Timestamp>;

    Id?: Maybe<Uuid>;

    ProjectConfigurationSnapshot?: Maybe<Jsonb>;

    ProjectReportTemplateId?: Maybe<Uuid>;

    ProjectReportTemplateSnapshot?: Maybe<Jsonb>;

    ReportDateTime?: Maybe<Timestamp>;

    ReportTemplateId?: Maybe<Uuid>;

    State?: Maybe<string>;

    Version?: Maybe<string>;

    Warnings?: Maybe<_Text>;

    WebUrl?: Maybe<string>;

    projectCode?: Maybe<string>;
}
/** primary key columns input for table: ReportGenerated */
export interface ReportGeneratedPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksIncInput {
    Order?: Maybe<number>;
}
/** input type for updating data in table "ReportPfdTagLinks" */
export interface ReportPfdTagLinksSetInput {
    Id?: Maybe<Uuid>;

    Order?: Maybe<number>;

    ProjectTemplateId?: Maybe<Uuid>;

    TagId?: Maybe<Uuid>;

    TemplateId?: Maybe<Uuid>;
}
/** primary key columns input for table: ReportPfdTagLinks */
export interface ReportPfdTagLinksPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "ReportProjectFiles" */
export interface ReportProjectFilesIncInput {
    Size?: Maybe<Bigint>;
}
/** input type for updating data in table "ReportProjectFiles" */
export interface ReportProjectFilesSetInput {
    Created?: Maybe<Timestamptz>;

    DriveItemId?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Modified?: Maybe<Timestamptz>;

    Name?: Maybe<string>;

    ProjectCode?: Maybe<string>;

    Size?: Maybe<Bigint>;

    Type?: Maybe<string>;

    WebUrl?: Maybe<string>;
}
/** primary key columns input for table: ReportProjectFiles */
export interface ReportProjectFilesPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface ReportProjectTemplatesAppendInput {
    Parameters?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ReportProjectTemplatesDeleteAtPathInput {
    Parameters?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ReportProjectTemplatesDeleteElemInput {
    Parameters?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ReportProjectTemplatesDeleteKeyInput {
    Parameters?: Maybe<string>;
}
/** input type for incrementing numeric columns in table "ReportProjectTemplates" */
export interface ReportProjectTemplatesIncInput {
    FileTypes?: Maybe<number>;

    GenerateAtHour?: Maybe<number>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ReportProjectTemplatesPrependInput {
    Parameters?: Maybe<Jsonb>;
}
/** input type for updating data in table "ReportProjectTemplates" */
export interface ReportProjectTemplatesSetInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    FileTypes?: Maybe<number>;

    Frequency?: Maybe<string>;

    GenerateAtHour?: Maybe<number>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    NextGenerationDate?: Maybe<Timestamp>;

    Parameters?: Maybe<Jsonb>;

    ReportTemplateId?: Maybe<Uuid>;

    projectCode?: Maybe<string>;
}
/** primary key columns input for table: ReportProjectTemplates */
export interface ReportProjectTemplatesPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface ReportTemplatesAppendInput {
    ReportTemplateBlocks?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface ReportTemplatesDeleteAtPathInput {
    ReportTemplateBlocks?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface ReportTemplatesDeleteElemInput {
    ReportTemplateBlocks?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface ReportTemplatesDeleteKeyInput {
    ReportTemplateBlocks?: Maybe<string>;
}
/** input type for incrementing numeric columns in table "ReportTemplates" */
export interface ReportTemplatesIncInput {
    ReportType?: Maybe<number>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface ReportTemplatesPrependInput {
    ReportTemplateBlocks?: Maybe<Jsonb>;
}
/** input type for updating data in table "ReportTemplates" */
export interface ReportTemplatesSetInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    DeletedDate?: Maybe<Timestamp>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    ModifiedDate?: Maybe<Timestamp>;

    Name?: Maybe<string>;

    ReportTemplateBlocks?: Maybe<Jsonb>;

    ReportType?: Maybe<number>;

    Version?: Maybe<string>;
}
/** primary key columns input for table: ReportTemplates */
export interface ReportTemplatesPkColumnsInput {
    Id: Uuid;
}
/** append existing jsonb value of filtered columns with new jsonb value */
export interface SmartlabFunctionalitiesAppendInput {
    AllowedPermissions?: Maybe<Jsonb>;
}
/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface SmartlabFunctionalitiesDeleteAtPathInput {
    AllowedPermissions?: Maybe<string[]>;
}
/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface SmartlabFunctionalitiesDeleteElemInput {
    AllowedPermissions?: Maybe<number>;
}
/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface SmartlabFunctionalitiesDeleteKeyInput {
    AllowedPermissions?: Maybe<string>;
}
/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface SmartlabFunctionalitiesPrependInput {
    AllowedPermissions?: Maybe<Jsonb>;
}
/** input type for updating data in table "SmartlabFunctionalities" */
export interface SmartlabFunctionalitiesSetInput {
    AllowedPermissions?: Maybe<Jsonb>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Name?: Maybe<string>;
}
/** primary key columns input for table: SmartlabFunctionalities */
export interface SmartlabFunctionalitiesPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksIncInput {
    Order?: Maybe<number>;
}
/** input type for updating data in table "SmartlabPFDUnitLinks" */
export interface SmartlabPfdUnitLinksSetInput {
    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    Link?: Maybe<string>;

    Name?: Maybe<string>;

    Order?: Maybe<number>;

    PFDTagLink?: Maybe<string>;

    PFDUnitId?: Maybe<string>;

    PfdBlockId?: Maybe<Uuid>;

    SkipBlocks?: Maybe<boolean>;

    Targets?: Maybe<_Text>;

    Type?: Maybe<string>;
}
/** primary key columns input for table: SmartlabPFDUnitLinks */
export interface SmartlabPfdUnitLinksPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "SmartlabUsers" */
export interface SmartlabUsersSetInput {
    Email?: Maybe<string>;

    Id?: Maybe<Uuid>;

    NameFirst?: Maybe<string>;

    NameLast?: Maybe<string>;

    UserId?: Maybe<string>;
}
/** primary key columns input for table: SmartlabUsers */
export interface SmartlabUsersPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "SupportChatAutoMessages" */
export interface SupportChatAutoMessagesSetInput {
    CreatedDate?: Maybe<Timestamp>;

    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    ProjectGroupId?: Maybe<Uuid>;

    TextValue?: Maybe<string>;
}
/** primary key columns input for table: SupportChatAutoMessages */
export interface SupportChatAutoMessagesPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "SupportChatMembers" */
export interface SupportChatMembersSetInput {
    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    IsActive?: Maybe<boolean>;

    SmartlabUserId?: Maybe<Uuid>;

    SupportChatId?: Maybe<Uuid>;
}
/** primary key columns input for table: SupportChatMembers */
export interface SupportChatMembersPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "SupportChatMessageViewers" */
export interface SupportChatMessageViewersSetInput {
    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    MessageId?: Maybe<Uuid>;

    ViewedDate?: Maybe<Timestamptz>;

    ViewerId?: Maybe<Uuid>;
}
/** primary key columns input for table: SupportChatMessageViewers */
export interface SupportChatMessageViewersPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "SupportChatMessages" */
export interface SupportChatMessagesIncInput {
    FileItemId?: Maybe<Bigint>;

    ValueType?: Maybe<Smallint>;
}
/** input type for updating data in table "SupportChatMessages" */
export interface SupportChatMessagesSetInput {
    AuthorId?: Maybe<Uuid>;

    AuthorRole?: Maybe<string>;

    CreatedDate?: Maybe<Timestamptz>;

    Deleted?: Maybe<boolean>;

    FileItemId?: Maybe<Bigint>;

    Id?: Maybe<Uuid>;

    SupportChatId?: Maybe<Uuid>;

    TextValue?: Maybe<string>;

    ValueType?: Maybe<Smallint>;
}
/** primary key columns input for table: SupportChatMessages */
export interface SupportChatMessagesPkColumnsInput {
    Id: Uuid;
}
/** input type for updating data in table "SupportChats" */
export interface SupportChatsSetInput {
    AuthorInfo?: Maybe<string>;

    ClosedBy?: Maybe<string>;

    Deleted?: Maybe<boolean>;

    Id?: Maybe<Uuid>;

    ProjectGroupId?: Maybe<Uuid>;

    StartDate?: Maybe<Timestamptz>;

    StopDate?: Maybe<Timestamptz>;

    SupportSubjectId?: Maybe<Uuid>;
}
/** primary key columns input for table: SupportChats */
export interface SupportChatsPkColumnsInput {
    Id: Uuid;
}
/** input type for incrementing numeric columns in table "SupportSubjects" */
export interface SupportSubjectsIncInput {
    Order?: Maybe<number>;
}
/** input type for updating data in table "SupportSubjects" */
export interface SupportSubjectsSetInput {
    ContainsPFDUnitLinksForCurrentProject?: Maybe<boolean>;

    Deleted?: Maybe<boolean>;

    Description?: Maybe<string>;

    Id?: Maybe<Uuid>;

    IsActive?: Maybe<boolean>;

    IsEnabledForOperatorChat?: Maybe<boolean>;

    Order?: Maybe<number>;

    ParentId?: Maybe<Uuid>;

    Title?: Maybe<string>;
}
/** primary key columns input for table: SupportSubjects */
export interface SupportSubjectsPkColumnsInput {
    Id: Uuid;
}
/** select columns of table "Branding" */
export enum BrandingSelectColumn {
    AlertsEnabled = "AlertsEnabled",
    AlertsTitle = "AlertsTitle",
    AllowedProjects = "AllowedProjects",
    CalendarEnabled = "CalendarEnabled",
    CalendarTitle = "CalendarTitle",
    Domain = "Domain",
    EnableBigLogo = "EnableBigLogo",
    FavIcon = "FavIcon",
    Id = "Id",
    LaboratoryEnabled = "LaboratoryEnabled",
    LaboratoryTitle = "LaboratoryTitle",
    LogbookEnabled = "LogbookEnabled",
    LogbookTitle = "LogbookTitle",
    Logo = "Logo",
    MaintenanceEnabled = "MaintenanceEnabled",
    MaintenanceTitle = "MaintenanceTitle",
    MyPlantsTableTitle = "MyPlantsTableTitle",
    MyPlantsTitle = "MyPlantsTitle",
    Name = "Name",
    OperatorEnabled = "OperatorEnabled",
    OperatorTitle = "OperatorTitle",
    PlantEnabled = "PlantEnabled",
    PlantOverrideWithPfdUnitId = "PlantOverrideWithPFDUnitId",
    PlantTitle = "PlantTitle",
    PrimaryColor = "PrimaryColor",
    ReportsEnabled = "ReportsEnabled",
    ReportsTitle = "ReportsTitle",
    SecondaryColor = "SecondaryColor",
    TechnologiesEnabled = "TechnologiesEnabled",
    TechnologiesTitle = "TechnologiesTitle",
    UtilitiesEnabled = "UtilitiesEnabled",
    UtilitiesTitle = "UtilitiesTitle"
}
/** column ordering options */
export enum OrderBy {
    Asc = "asc",
    AscNullsFirst = "asc_nulls_first",
    AscNullsLast = "asc_nulls_last",
    Desc = "desc",
    DescNullsFirst = "desc_nulls_first",
    DescNullsLast = "desc_nulls_last"
}
/** select columns of table "CalendarEvents" */
export enum CalendarEventsSelectColumn {
    AllDay = "AllDay",
    ColorPrimary = "ColorPrimary",
    ColorSecondary = "ColorSecondary",
    Draggable = "Draggable",
    End = "End",
    Id = "Id",
    MetaLocation = "MetaLocation",
    MetaNotes = "MetaNotes",
    ProjectCode = "ProjectCode",
    ResizableAfterEnd = "ResizableAfterEnd",
    ResizableBeforeStart = "ResizableBeforeStart",
    Start = "Start",
    Title = "Title",
    UserId = "UserId"
}
/** select columns of table "ClientGroups" */
export enum ClientGroupsSelectColumn {
    ClientIds = "ClientIds",
    Id = "Id",
    Name = "Name",
    WepId = "WepId"
}
/** select columns of table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsersSelectColumn {
    ClientGroupId = "ClientGroupId",
    Id = "Id",
    SmartlabUserId = "SmartlabUserId"
}
/** select columns of table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsersSelectColumn {
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    SmartlabUserId = "SmartlabUserId"
}
/** select columns of table "SupportChats" */
export enum SupportChatsSelectColumn {
    AuthorInfo = "AuthorInfo",
    ClosedBy = "ClosedBy",
    Deleted = "Deleted",
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    StartDate = "StartDate",
    StopDate = "StopDate",
    SupportSubjectId = "SupportSubjectId"
}
/** select columns of table "SupportChatMembers" */
export enum SupportChatMembersSelectColumn {
    Deleted = "Deleted",
    Id = "Id",
    IsActive = "IsActive",
    SmartlabUserId = "SmartlabUserId",
    SupportChatId = "SupportChatId"
}
/** select columns of table "SupportChatMessageViewers" */
export enum SupportChatMessageViewersSelectColumn {
    Deleted = "Deleted",
    Id = "Id",
    MessageId = "MessageId",
    ViewedDate = "ViewedDate",
    ViewerId = "ViewerId"
}
/** select columns of table "SupportChatMessages" */
export enum SupportChatMessagesSelectColumn {
    AuthorId = "AuthorId",
    AuthorRole = "AuthorRole",
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    FileItemId = "FileItemId",
    Id = "Id",
    SupportChatId = "SupportChatId",
    TextValue = "TextValue",
    ValueType = "ValueType"
}
/** select columns of table "Notifications" */
export enum NotificationsSelectColumn {
    Id = "Id",
    MessageId = "MessageId",
    NotificationTypeId = "NotificationTypeId",
    ProjectId = "ProjectId",
    SubjectId = "SubjectId",
    UserFromId = "UserFromId",
    UserToId = "UserToId"
}
/** select columns of table "SupportSubjects" */
export enum SupportSubjectsSelectColumn {
    ContainsPfdUnitLinksForCurrentProject = "ContainsPFDUnitLinksForCurrentProject",
    Deleted = "Deleted",
    Description = "Description",
    Id = "Id",
    IsActive = "IsActive",
    IsEnabledForOperatorChat = "IsEnabledForOperatorChat",
    Order = "Order",
    ParentId = "ParentId",
    Title = "Title"
}
/** select columns of table "CumulDashboards" */
export enum CumulDashboardsSelectColumn {
    DashboardId = "DashboardId",
    Errors = "Errors",
    Id = "Id",
    ModifiedAt = "ModifiedAt",
    Name = "Name"
}
/** select columns of table "CumulTokens" */
export enum CumulTokensSelectColumn {
    AuthorizationId = "AuthorizationId",
    AuthorizationToken = "AuthorizationToken",
    CreatedAt = "CreatedAt",
    HashedRequestSignature = "HashedRequestSignature",
    Id = "Id",
    Revoked = "Revoked",
    UserId = "UserId",
    ValidUntil = "ValidUntil"
}
/** select columns of table "DataProcConfig" */
export enum DataProcConfigSelectColumn {
    DataConnectionDescription = "DataConnectionDescription",
    DataConnectionFlags = "DataConnectionFlags",
    Enable = "Enable",
    EnableDataProcessing = "EnableDataProcessing",
    FrequencyMinutes = "FrequencyMinutes",
    Id = "Id",
    MaxMeasurementAgeMinutes = "MaxMeasurementAgeMinutes",
    MeasurementCutoffDate = "MeasurementCutoffDate",
    MeasurementDatabaseId = "MeasurementDatabaseId",
    PfdProjectId = "PfdProjectId",
    ProcessorVersion = "ProcessorVersion",
    ProjectCode = "ProjectCode",
    ServerId = "ServerId",
    SignalDatabaseId = "SignalDatabaseId",
    UpdatedServer = "UpdatedServer",
    WorkersPriorityHigh = "WorkersPriorityHigh",
    WorkersPriorityLow = "WorkersPriorityLow"
}
/** select columns of table "DataProcTagState" */
export enum DataProcTagStateSelectColumn {
    BufferId = "BufferId",
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    ErrorType = "ErrorType",
    Errors = "Errors",
    FirstPendingDate = "FirstPendingDate",
    Id = "Id",
    IsProcessing = "IsProcessing",
    JobNr = "JobNr",
    LastDependencyDate = "LastDependencyDate",
    LastPendingDate = "LastPendingDate",
    LastProcessedDate = "LastProcessedDate",
    MeasurementId = "MeasurementId",
    ParentWithOldestLastDatapointId = "ParentWithOldestLastDatapointId",
    PfdTagId = "PfdTagId",
    PointsProcessed = "PointsProcessed",
    ProcessedRangeDone = "ProcessedRangeDone",
    ProcessedRangeFrom = "ProcessedRangeFrom",
    ProcessedRangeTo = "ProcessedRangeTo",
    ProcessingEndedDate = "ProcessingEndedDate",
    ProcessingStartedDate = "ProcessingStartedDate",
    SignalId = "SignalId",
    Updated = "Updated"
}
/** select columns of table "PfdBlocks" */
export enum PfdBlocksSelectColumn {
    BlockDefinitionId = "BlockDefinitionId",
    BlockId = "BlockId",
    ChildId = "ChildId",
    DefinitionId = "DefinitionId",
    Description = "Description",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Id = "Id",
    IsDefinition = "IsDefinition",
    IsMaster = "IsMaster",
    IsStream = "IsStream",
    Name = "Name",
    NameShort = "NameShort",
    Order = "Order",
    ProjectId = "ProjectId",
    SmartlabMenuEnabled = "SmartlabMenuEnabled"
}
/** select columns of table "SmartlabPFDUnitLinks" */
export enum SmartlabPfdUnitLinksSelectColumn {
    Description = "Description",
    Id = "Id",
    Link = "Link",
    Name = "Name",
    Order = "Order",
    PfdTagLink = "PFDTagLink",
    PfdUnitId = "PFDUnitId",
    PfdBlockId = "PfdBlockId",
    SkipBlocks = "SkipBlocks",
    Targets = "Targets",
    Type = "Type"
}
/** select columns of table "PfdProjectTagDependencyModel" */
export enum PfdProjectTagDependencyModelSelectColumn {
    ChildId = "ChildId",
    ParentId = "ParentId"
}
/** select columns of table "ReportPfdTagLinks" */
export enum ReportPfdTagLinksSelectColumn {
    Id = "Id",
    Order = "Order",
    ProjectTemplateId = "ProjectTemplateId",
    TagId = "TagId",
    TemplateId = "TemplateId"
}
/** select columns of table "ReportGenerated" */
export enum ReportGeneratedSelectColumn {
    Created = "Created",
    Deleted = "Deleted",
    DocUrl = "DocUrl",
    DynamicDataSnapshot = "DynamicDataSnapshot",
    Errors = "Errors",
    Finished = "Finished",
    Id = "Id",
    ProjectConfigurationSnapshot = "ProjectConfigurationSnapshot",
    ProjectReportTemplateId = "ProjectReportTemplateId",
    ProjectReportTemplateSnapshot = "ProjectReportTemplateSnapshot",
    ReportDateTime = "ReportDateTime",
    ReportTemplateId = "ReportTemplateId",
    State = "State",
    Version = "Version",
    Warnings = "Warnings",
    WebUrl = "WebUrl",
    ProjectCode = "projectCode"
}
/** select columns of table "ReportProjectTemplates" */
export enum ReportProjectTemplatesSelectColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    FileTypes = "FileTypes",
    Frequency = "Frequency",
    GenerateAtHour = "GenerateAtHour",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    NextGenerationDate = "NextGenerationDate",
    Parameters = "Parameters",
    ReportTemplateId = "ReportTemplateId",
    ProjectCode = "projectCode"
}
/** select columns of table "DataProcConfigMeasurementPull" */
export enum DataProcConfigMeasurementPullSelectColumn {
    ConfigurationId = "ConfigurationId",
    DatabaseId = "DatabaseId",
    Enabled = "Enabled",
    Id = "Id",
    Json = "Json",
    MaxDataIterations = "MaxDataIterations",
    MaxDataRowTarget = "MaxDataRowTarget",
    ServiceType = "ServiceType"
}
/** select columns of table "DataProcProjectState" */
export enum DataProcProjectStateSelectColumn {
    ConfigurationId = "ConfigurationId",
    EndedDate = "EndedDate",
    Id = "Id",
    IsLatestMeasurementDateOld = "IsLatestMeasurementDateOld",
    JobNr = "JobNr",
    KpiDate = "KpiDate",
    KpiValue = "KpiValue",
    LatestMeasurementDate = "LatestMeasurementDate",
    StartedDate = "StartedDate",
    TagProcessingEndedDate = "TagProcessingEndedDate",
    TagProcessingErrorMessage = "TagProcessingErrorMessage",
    TagProcessingPointsProcessed = "TagProcessingPointsProcessed",
    TagProcessingStartedDate = "TagProcessingStartedDate",
    TagProcessingTagsDoneProcessing = "TagProcessingTagsDoneProcessing",
    TagProcessingTagsProcessed = "TagProcessingTagsProcessed",
    TagProcessingTotalPointsProcessed = "TagProcessingTotalPointsProcessed",
    TotalState = "TotalState",
    Updated = "Updated"
}
/** select columns of table "DataProcServer" */
export enum DataProcServerSelectColumn {
    Created = "Created",
    Description = "Description",
    Id = "Id",
    Identifier = "Identifier",
    LastPing = "LastPing",
    Name = "Name"
}
/** select columns of table "DataProcState" */
export enum DataProcStateSelectColumn {
    Id = "Id",
    SchedulerLastRunDate = "SchedulerLastRunDate"
}
/** select columns of table "DataProcStateEntityHistory" */
export enum DataProcStateEntityHistorySelectColumn {
    ConfigurationId = "ConfigurationId",
    Id = "Id",
    ModelId = "ModelId",
    ModelType = "ModelType",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType"
}
/** select columns of table "DataProcStateMeasurementPull" */
export enum DataProcStateMeasurementPullSelectColumn {
    ConfigurationId = "ConfigurationId",
    EndedDate = "EndedDate",
    Json = "Json",
    Loops = "Loops",
    PointsReceived = "PointsReceived",
    StartedDate = "StartedDate",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    TotalPointsReceived = "TotalPointsReceived",
    Updated = "Updated"
}
/** select columns of table "DataProcStateTree" */
export enum DataProcStateTreeSelectColumn {
    ConfigurationId = "ConfigurationId",
    CurrentWorkJson = "CurrentWorkJson",
    DatapointSeriesOperationLastDate = "DatapointSeriesOperationLastDate",
    PfdChangeLogLastDate = "PfdChangeLogLastDate",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    TreePingDate = "TreePingDate",
    TreeStarted = "TreeStarted",
    TreeStopped = "TreeStopped"
}
/** select columns of table "DataProcStateTreeCommand" */
export enum DataProcStateTreeCommandSelectColumn {
    LastCommand = "LastCommand",
    ServerId = "ServerId"
}
/** select columns of table "DataProcStateTreeLeaf" */
export enum DataProcStateTreeLeafSelectColumn {
    LeafId = "LeafId",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    TailVersion = "TailVersion",
    WorkState = "WorkState"
}
/** select columns of table "DataProcTreeLeafDependencies" */
export enum DataProcTreeLeafDependenciesSelectColumn {
    ChildDependenciesId = "ChildDependenciesId",
    ParentDependenciesId = "ParentDependenciesId"
}
/** select columns of table "DataProcTreeCommand" */
export enum DataProcTreeCommandSelectColumn {
    CommandType = "CommandType",
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    Data = "Data",
    Id = "Id",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    UserId = "UserId"
}
/** select columns of table "DataProcTreeLeaf" */
export enum DataProcTreeLeafSelectColumn {
    Class = "Class",
    ClassPropertiesJson = "ClassPropertiesJson",
    ConfigurationId = "ConfigurationId",
    DatapointSeriesId = "DatapointSeriesId",
    Id = "Id",
    Name = "Name",
    SourceId = "SourceId",
    SourceType = "SourceType"
}
/** select columns of table "DatapointDatabase" */
export enum DatapointDatabaseSelectColumn {
    ConfigPathCredentials = "ConfigPathCredentials",
    CreateAllGenericTables = "CreateAllGenericTables",
    Created = "Created",
    Description = "Description",
    HostId = "HostId",
    Id = "Id",
    Name = "Name",
    ParentId = "ParentId",
    Schema = "Schema"
}
/** select columns of table "DatapointHost" */
export enum DatapointHostSelectColumn {
    Alias = "Alias",
    ConfigPathCredentials = "ConfigPathCredentials",
    Created = "Created",
    Description = "Description",
    Host = "Host",
    HostType = "HostType",
    Id = "Id",
    IsTest = "IsTest",
    Parameters = "Parameters",
    Port = "Port",
    Portal = "Portal"
}
/** select columns of table "DatapointSeries" */
export enum DatapointSeriesSelectColumn {
    Alias = "Alias",
    Created = "Created",
    Filters = "Filters",
    Id = "Id",
    State = "State",
    TableId = "TableId",
    TotalRangeFrom = "TotalRangeFrom",
    TotalRangeTo = "TotalRangeTo",
    Type = "Type",
    Updated = "Updated"
}
/** select columns of table "DatapointSeriesOperation" */
export enum DatapointSeriesOperationSelectColumn {
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    End = "End",
    Id = "Id",
    SeriesId = "SeriesId",
    Start = "Start",
    Type = "Type",
    UserId = "UserId"
}
/** select columns of table "DatapointTable" */
export enum DatapointTableSelectColumn {
    Created = "Created",
    DataType = "DataType",
    DatabaseId = "DatabaseId",
    Filters = "Filters",
    Id = "Id",
    Name = "Name",
    TableCreated = "TableCreated"
}
/** select columns of table "FileItems" */
export enum FileItemsSelectColumn {
    FileName = "FileName",
    FileReference = "FileReference",
    FileTypeId = "FileTypeId",
    Id = "Id",
    IsDeleted = "IsDeleted"
}
/** select columns of table "FileTypes" */
export enum FileTypesSelectColumn {
    Id = "Id",
    IsDeleted = "IsDeleted",
    Name = "Name"
}
/** select columns of table "LaboScheduleStates" */
export enum LaboScheduleStatesSelectColumn {
    CreatedAt = "CreatedAt",
    Deleted = "Deleted",
    DeletedAt = "DeletedAt",
    Id = "Id",
    Name = "Name",
    ProjectId = "ProjectId",
    Schedule = "Schedule"
}
/** select columns of table "LaboScheduleStatesPlanning" */
export enum LaboScheduleStatesPlanningSelectColumn {
    Id = "Id",
    Planned = "Planned",
    ProjectId = "ProjectId",
    StateId = "StateId"
}
/** select columns of table "NotificationTypes" */
export enum NotificationTypesSelectColumn {
    Deleted = "Deleted",
    Id = "Id",
    Name = "Name"
}
/** select columns of table "PfdChangeLogs" */
export enum PfdChangeLogsSelectColumn {
    Created = "Created",
    Id = "Id",
    ModelFieldName = "ModelFieldName",
    ModelId = "ModelId",
    ModelName = "ModelName",
    Name = "Name",
    ProjectId = "ProjectId",
    ValueNew = "ValueNew",
    ValueOld = "ValueOld"
}
/** select columns of table "PfdProjectBlockDependencyModel" */
export enum PfdProjectBlockDependencyModelSelectColumn {
    ChildId = "ChildId",
    ParentId = "ParentId"
}
/** select columns of table "PfdProjects" */
export enum PfdProjectsSelectColumn {
    Address = "Address",
    City = "City",
    Code = "Code",
    Coordinates = "Coordinates",
    Country = "Country",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Id = "Id",
    Name = "Name",
    TimeZone = "TimeZone",
    Type = "Type",
    WepClientId = "WepClientId",
    WepId = "WepId"
}
/** select columns of table "PfdSamplingpoints" */
export enum PfdSamplingpointsSelectColumn {
    Description = "Description",
    EnableForLab = "EnableForLab",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Id = "Id",
    Name = "Name",
    Order = "Order",
    ProjectId = "ProjectId",
    SamplingpointDefinitionId = "SamplingpointDefinitionId",
    SamplingpointId = "SamplingpointId"
}
/** select columns of table "PfdTags" */
export enum PfdTagsSelectColumn {
    AggregationDatapointTime = "AggregationDatapointTime",
    AggregationDatapointTimeMultiplier = "AggregationDatapointTimeMultiplier",
    AggregationIsFaked = "AggregationIsFaked",
    AggregationTime = "AggregationTime",
    AggregationTimeMultiplier = "AggregationTimeMultiplier",
    AggregationTimeSource = "AggregationTimeSource",
    AggregationType = "AggregationType",
    BlockId = "BlockId",
    DataType = "DataType",
    DefinitionId = "DefinitionId",
    Description = "Description",
    Enabled = "Enabled",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Formula = "Formula",
    Id = "Id",
    IsDefinition = "IsDefinition",
    IsParameter = "IsParameter",
    IsProjectTag = "IsProjectTag",
    LimitH = "Limit_h",
    LimitHh = "Limit_hh",
    LimitL = "Limit_l",
    LimitLl = "Limit_ll",
    MeasurementName = "MeasurementName",
    MeasurementNameSf = "MeasurementNameSF",
    MeasurementType = "MeasurementType",
    Name = "Name",
    ProjectId = "ProjectId",
    Quantity = "Quantity",
    SamplingpointId = "SamplingpointId",
    Source = "Source",
    Substance = "Substance",
    TagDefinitionId = "TagDefinitionId",
    TagId = "TagId",
    Unit = "Unit"
}
/** select columns of table "ProjectConfigurations" */
export enum ProjectConfigurationsSelectColumn {
    Id = "Id",
    ProjectCode = "ProjectCode",
    ProjectLogo = "ProjectLogo",
    ProjectName = "ProjectName",
    ProjectType = "ProjectType",
    ReportsDrive = "ReportsDrive",
    ReportsItem = "ReportsItem",
    ReportsSite = "ReportsSite",
    TimeZone = "TimeZone"
}
/** select columns of table "ProjectGroups" */
export enum ProjectGroupsSelectColumn {
    ClientGroupId = "ClientGroupId",
    Code = "Code",
    Id = "Id",
    Name = "Name",
    PfdProjectId = "PfdProjectId",
    ProjectCodes = "ProjectCodes",
    WepId = "WepId"
}
/** select columns of table "ReportProjectFiles" */
export enum ReportProjectFilesSelectColumn {
    Created = "Created",
    DriveItemId = "DriveItemId",
    Id = "Id",
    Modified = "Modified",
    Name = "Name",
    ProjectCode = "ProjectCode",
    Size = "Size",
    Type = "Type",
    WebUrl = "WebUrl"
}
/** select columns of table "ReportTemplates" */
export enum ReportTemplatesSelectColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    Description = "Description",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    Name = "Name",
    ReportTemplateBlocks = "ReportTemplateBlocks",
    ReportType = "ReportType",
    Version = "Version"
}
/** select columns of table "SmartlabFunctionalities" */
export enum SmartlabFunctionalitiesSelectColumn {
    AllowedPermissions = "AllowedPermissions",
    Description = "Description",
    Id = "Id",
    Name = "Name"
}
/** select columns of table "SmartlabUsers" */
export enum SmartlabUsersSelectColumn {
    Email = "Email",
    Id = "Id",
    NameFirst = "NameFirst",
    NameLast = "NameLast",
    UserId = "UserId"
}
/** select columns of table "SupportChatAutoMessages" */
export enum SupportChatAutoMessagesSelectColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    TextValue = "TextValue"
}
/** unique or primary key constraints on table "Branding" */
export enum BrandingConstraint {
    PkBranding = "PK_Branding"
}
/** update columns of table "Branding" */
export enum BrandingUpdateColumn {
    AlertsEnabled = "AlertsEnabled",
    AlertsTitle = "AlertsTitle",
    AllowedProjects = "AllowedProjects",
    CalendarEnabled = "CalendarEnabled",
    CalendarTitle = "CalendarTitle",
    Domain = "Domain",
    EnableBigLogo = "EnableBigLogo",
    FavIcon = "FavIcon",
    Id = "Id",
    LaboratoryEnabled = "LaboratoryEnabled",
    LaboratoryTitle = "LaboratoryTitle",
    LogbookEnabled = "LogbookEnabled",
    LogbookTitle = "LogbookTitle",
    Logo = "Logo",
    MaintenanceEnabled = "MaintenanceEnabled",
    MaintenanceTitle = "MaintenanceTitle",
    MyPlantsTableTitle = "MyPlantsTableTitle",
    MyPlantsTitle = "MyPlantsTitle",
    Name = "Name",
    OperatorEnabled = "OperatorEnabled",
    OperatorTitle = "OperatorTitle",
    PlantEnabled = "PlantEnabled",
    PlantOverrideWithPfdUnitId = "PlantOverrideWithPFDUnitId",
    PlantTitle = "PlantTitle",
    PrimaryColor = "PrimaryColor",
    ReportsEnabled = "ReportsEnabled",
    ReportsTitle = "ReportsTitle",
    SecondaryColor = "SecondaryColor",
    TechnologiesEnabled = "TechnologiesEnabled",
    TechnologiesTitle = "TechnologiesTitle",
    UtilitiesEnabled = "UtilitiesEnabled",
    UtilitiesTitle = "UtilitiesTitle"
}
/** unique or primary key constraints on table "CalendarEvents" */
export enum CalendarEventsConstraint {
    PkCalendarEvents = "PK_CalendarEvents"
}
/** update columns of table "CalendarEvents" */
export enum CalendarEventsUpdateColumn {
    AllDay = "AllDay",
    ColorPrimary = "ColorPrimary",
    ColorSecondary = "ColorSecondary",
    Draggable = "Draggable",
    End = "End",
    Id = "Id",
    MetaLocation = "MetaLocation",
    MetaNotes = "MetaNotes",
    ProjectCode = "ProjectCode",
    ResizableAfterEnd = "ResizableAfterEnd",
    ResizableBeforeStart = "ResizableBeforeStart",
    Start = "Start",
    Title = "Title",
    UserId = "UserId"
}
/** unique or primary key constraints on table "ClientGroups" */
export enum ClientGroupsConstraint {
    PkClientGroups = "PK_ClientGroups"
}
/** update columns of table "ClientGroups" */
export enum ClientGroupsUpdateColumn {
    ClientIds = "ClientIds",
    Id = "Id",
    Name = "Name",
    WepId = "WepId"
}
/** unique or primary key constraints on table "PfdProjects" */
export enum PfdProjectsConstraint {
    IxPfdProjectsCode = "IX_PfdProjects_Code",
    PkPfdProjects = "PK_PfdProjects"
}
/** update columns of table "PfdProjects" */
export enum PfdProjectsUpdateColumn {
    Address = "Address",
    City = "City",
    Code = "Code",
    Coordinates = "Coordinates",
    Country = "Country",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Id = "Id",
    Name = "Name",
    TimeZone = "TimeZone",
    Type = "Type",
    WepClientId = "WepClientId",
    WepId = "WepId"
}
/** unique or primary key constraints on table "SupportChats" */
export enum SupportChatsConstraint {
    PkSupportChats = "PK_SupportChats"
}
/** update columns of table "SupportChats" */
export enum SupportChatsUpdateColumn {
    AuthorInfo = "AuthorInfo",
    ClosedBy = "ClosedBy",
    Deleted = "Deleted",
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    StartDate = "StartDate",
    StopDate = "StopDate",
    SupportSubjectId = "SupportSubjectId"
}
/** unique or primary key constraints on table "SupportChatMembers" */
export enum SupportChatMembersConstraint {
    PkSupportChatMembers = "PK_SupportChatMembers"
}
/** update columns of table "SupportChatMembers" */
export enum SupportChatMembersUpdateColumn {
    Deleted = "Deleted",
    Id = "Id",
    IsActive = "IsActive",
    SmartlabUserId = "SmartlabUserId",
    SupportChatId = "SupportChatId"
}
/** unique or primary key constraints on table "SupportChatMessages" */
export enum SupportChatMessagesConstraint {
    PkSupportChatMessages = "PK_SupportChatMessages"
}
/** update columns of table "SupportChatMessages" */
export enum SupportChatMessagesUpdateColumn {
    AuthorId = "AuthorId",
    AuthorRole = "AuthorRole",
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    FileItemId = "FileItemId",
    Id = "Id",
    SupportChatId = "SupportChatId",
    TextValue = "TextValue",
    ValueType = "ValueType"
}
/** unique or primary key constraints on table "FileItems" */
export enum FileItemsConstraint {
    PkFileItems = "PK_FileItems"
}
/** update columns of table "FileItems" */
export enum FileItemsUpdateColumn {
    FileName = "FileName",
    FileReference = "FileReference",
    FileTypeId = "FileTypeId",
    Id = "Id",
    IsDeleted = "IsDeleted"
}
/** unique or primary key constraints on table "Notifications" */
export enum NotificationsConstraint {
    PkNotifications = "PK_Notifications"
}
/** update columns of table "Notifications" */
export enum NotificationsUpdateColumn {
    Id = "Id",
    MessageId = "MessageId",
    NotificationTypeId = "NotificationTypeId",
    ProjectId = "ProjectId",
    SubjectId = "SubjectId",
    UserFromId = "UserFromId",
    UserToId = "UserToId"
}
/** unique or primary key constraints on table "SupportChatMessageViewers" */
export enum SupportChatMessageViewersConstraint {
    PkSupportChatMessageViewers = "PK_SupportChatMessageViewers"
}
/** update columns of table "SupportChatMessageViewers" */
export enum SupportChatMessageViewersUpdateColumn {
    Deleted = "Deleted",
    Id = "Id",
    MessageId = "MessageId",
    ViewedDate = "ViewedDate",
    ViewerId = "ViewerId"
}
/** unique or primary key constraints on table "SupportSubjects" */
export enum SupportSubjectsConstraint {
    PkSupportSubjects = "PK_SupportSubjects"
}
/** update columns of table "SupportSubjects" */
export enum SupportSubjectsUpdateColumn {
    ContainsPfdUnitLinksForCurrentProject = "ContainsPFDUnitLinksForCurrentProject",
    Deleted = "Deleted",
    Description = "Description",
    Id = "Id",
    IsActive = "IsActive",
    IsEnabledForOperatorChat = "IsEnabledForOperatorChat",
    Order = "Order",
    ParentId = "ParentId",
    Title = "Title"
}
/** unique or primary key constraints on table "ProjectGroups" */
export enum ProjectGroupsConstraint {
    IxProjectGroupsCode = "IX_ProjectGroups_Code",
    PkProjectGroups = "PK_ProjectGroups"
}
/** update columns of table "ProjectGroups" */
export enum ProjectGroupsUpdateColumn {
    ClientGroupId = "ClientGroupId",
    Code = "Code",
    Id = "Id",
    Name = "Name",
    PfdProjectId = "PfdProjectId",
    ProjectCodes = "ProjectCodes",
    WepId = "WepId"
}
/** unique or primary key constraints on table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsersConstraint {
    PkProjectGroupsSmartlabUsers = "PK_ProjectGroupsSmartlabUsers"
}
/** update columns of table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsersUpdateColumn {
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    SmartlabUserId = "SmartlabUserId"
}
/** unique or primary key constraints on table "SmartlabUsers" */
export enum SmartlabUsersConstraint {
    IxSmartlabUsersUserId = "IX_SmartlabUsers_UserId",
    PkSmartlabUsers = "PK_SmartlabUsers",
    SmartlabUsersUserIdKey = "SmartlabUsers_UserId_key"
}
/** update columns of table "SmartlabUsers" */
export enum SmartlabUsersUpdateColumn {
    Email = "Email",
    Id = "Id",
    NameFirst = "NameFirst",
    NameLast = "NameLast",
    UserId = "UserId"
}
/** unique or primary key constraints on table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsersConstraint {
    PkClientGroupsSmartlabUsers = "PK_ClientGroupsSmartlabUsers"
}
/** update columns of table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsersUpdateColumn {
    ClientGroupId = "ClientGroupId",
    Id = "Id",
    SmartlabUserId = "SmartlabUserId"
}
/** unique or primary key constraints on table "CumulDashboards" */
export enum CumulDashboardsConstraint {
    IxCumulDashboardsDashboardId = "IX_CumulDashboards_DashboardId",
    PkCumulDashboards = "PK_CumulDashboards"
}
/** update columns of table "CumulDashboards" */
export enum CumulDashboardsUpdateColumn {
    DashboardId = "DashboardId",
    Errors = "Errors",
    Id = "Id",
    ModifiedAt = "ModifiedAt",
    Name = "Name"
}
/** unique or primary key constraints on table "CumulTokens" */
export enum CumulTokensConstraint {
    PkCumulTokens = "PK_CumulTokens"
}
/** update columns of table "CumulTokens" */
export enum CumulTokensUpdateColumn {
    AuthorizationId = "AuthorizationId",
    AuthorizationToken = "AuthorizationToken",
    CreatedAt = "CreatedAt",
    HashedRequestSignature = "HashedRequestSignature",
    Id = "Id",
    Revoked = "Revoked",
    UserId = "UserId",
    ValidUntil = "ValidUntil"
}
/** unique or primary key constraints on table "DataProcConfig" */
export enum DataProcConfigConstraint {
    PkDataProcConfig = "PK_DataProcConfig"
}
/** update columns of table "DataProcConfig" */
export enum DataProcConfigUpdateColumn {
    DataConnectionDescription = "DataConnectionDescription",
    DataConnectionFlags = "DataConnectionFlags",
    Enable = "Enable",
    EnableDataProcessing = "EnableDataProcessing",
    FrequencyMinutes = "FrequencyMinutes",
    Id = "Id",
    MaxMeasurementAgeMinutes = "MaxMeasurementAgeMinutes",
    MeasurementCutoffDate = "MeasurementCutoffDate",
    MeasurementDatabaseId = "MeasurementDatabaseId",
    PfdProjectId = "PfdProjectId",
    ProcessorVersion = "ProcessorVersion",
    ProjectCode = "ProjectCode",
    ServerId = "ServerId",
    SignalDatabaseId = "SignalDatabaseId",
    UpdatedServer = "UpdatedServer",
    WorkersPriorityHigh = "WorkersPriorityHigh",
    WorkersPriorityLow = "WorkersPriorityLow"
}
/** unique or primary key constraints on table "DataProcProjectState" */
export enum DataProcProjectStateConstraint {
    PkDataProcState = "PK_DataProcState"
}
/** update columns of table "DataProcProjectState" */
export enum DataProcProjectStateUpdateColumn {
    ConfigurationId = "ConfigurationId",
    EndedDate = "EndedDate",
    Id = "Id",
    IsLatestMeasurementDateOld = "IsLatestMeasurementDateOld",
    JobNr = "JobNr",
    KpiDate = "KpiDate",
    KpiValue = "KpiValue",
    LatestMeasurementDate = "LatestMeasurementDate",
    StartedDate = "StartedDate",
    TagProcessingEndedDate = "TagProcessingEndedDate",
    TagProcessingErrorMessage = "TagProcessingErrorMessage",
    TagProcessingPointsProcessed = "TagProcessingPointsProcessed",
    TagProcessingStartedDate = "TagProcessingStartedDate",
    TagProcessingTagsDoneProcessing = "TagProcessingTagsDoneProcessing",
    TagProcessingTagsProcessed = "TagProcessingTagsProcessed",
    TagProcessingTotalPointsProcessed = "TagProcessingTotalPointsProcessed",
    TotalState = "TotalState",
    Updated = "Updated"
}
/** unique or primary key constraints on table "DatapointHost" */
export enum DatapointHostConstraint {
    IxDatapointHostAlias = "IX_DatapointHost_Alias",
    IxDatapointHostHostPort = "IX_DatapointHost_Host_Port",
    PkDatapointHost = "PK_DatapointHost"
}
/** update columns of table "DatapointHost" */
export enum DatapointHostUpdateColumn {
    Alias = "Alias",
    ConfigPathCredentials = "ConfigPathCredentials",
    Created = "Created",
    Description = "Description",
    Host = "Host",
    HostType = "HostType",
    Id = "Id",
    IsTest = "IsTest",
    Parameters = "Parameters",
    Port = "Port",
    Portal = "Portal"
}
/** unique or primary key constraints on table "DatapointDatabase" */
export enum DatapointDatabaseConstraint {
    IxDatapointDatabaseHostIdSchemaName = "IX_DatapointDatabase_HostId_Schema_Name",
    PkDatapointDatabase = "PK_DatapointDatabase"
}
/** update columns of table "DatapointDatabase" */
export enum DatapointDatabaseUpdateColumn {
    ConfigPathCredentials = "ConfigPathCredentials",
    CreateAllGenericTables = "CreateAllGenericTables",
    Created = "Created",
    Description = "Description",
    HostId = "HostId",
    Id = "Id",
    Name = "Name",
    ParentId = "ParentId",
    Schema = "Schema"
}
/** unique or primary key constraints on table "DatapointTable" */
export enum DatapointTableConstraint {
    IxDatapointTableDatabaseIdName = "IX_DatapointTable_DatabaseId_Name",
    PkDatapointTable = "PK_DatapointTable"
}
/** update columns of table "DatapointTable" */
export enum DatapointTableUpdateColumn {
    Created = "Created",
    DataType = "DataType",
    DatabaseId = "DatabaseId",
    Filters = "Filters",
    Id = "Id",
    Name = "Name",
    TableCreated = "TableCreated"
}
/** unique or primary key constraints on table "DataProcTagState" */
export enum DataProcTagStateConstraint {
    IxDataProcTagStateConfigurationIdPfdTagId = "IX_DataProcTagState_ConfigurationId_PfdTagId",
    PkDataProcTagState = "PK_DataProcTagState"
}
/** update columns of table "DataProcTagState" */
export enum DataProcTagStateUpdateColumn {
    BufferId = "BufferId",
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    ErrorType = "ErrorType",
    Errors = "Errors",
    FirstPendingDate = "FirstPendingDate",
    Id = "Id",
    IsProcessing = "IsProcessing",
    JobNr = "JobNr",
    LastDependencyDate = "LastDependencyDate",
    LastPendingDate = "LastPendingDate",
    LastProcessedDate = "LastProcessedDate",
    MeasurementId = "MeasurementId",
    ParentWithOldestLastDatapointId = "ParentWithOldestLastDatapointId",
    PfdTagId = "PfdTagId",
    PointsProcessed = "PointsProcessed",
    ProcessedRangeDone = "ProcessedRangeDone",
    ProcessedRangeFrom = "ProcessedRangeFrom",
    ProcessedRangeTo = "ProcessedRangeTo",
    ProcessingEndedDate = "ProcessingEndedDate",
    ProcessingStartedDate = "ProcessingStartedDate",
    SignalId = "SignalId",
    Updated = "Updated"
}
/** unique or primary key constraints on table "DatapointSeries" */
export enum DatapointSeriesConstraint {
    IxDatapointSeriesTableIdAlias = "IX_DatapointSeries_TableId_Alias",
    PkDatapointSeries = "PK_DatapointSeries"
}
/** update columns of table "DatapointSeries" */
export enum DatapointSeriesUpdateColumn {
    Alias = "Alias",
    Created = "Created",
    Filters = "Filters",
    Id = "Id",
    State = "State",
    TableId = "TableId",
    TotalRangeFrom = "TotalRangeFrom",
    TotalRangeTo = "TotalRangeTo",
    Type = "Type",
    Updated = "Updated"
}
/** unique or primary key constraints on table "PfdBlocks" */
export enum PfdBlocksConstraint {
    IxPfdBlocksBlockId = "IX_PfdBlocks_BlockId",
    PkPfdBlocks = "PK_PfdBlocks"
}
/** update columns of table "PfdBlocks" */
export enum PfdBlocksUpdateColumn {
    BlockDefinitionId = "BlockDefinitionId",
    BlockId = "BlockId",
    ChildId = "ChildId",
    DefinitionId = "DefinitionId",
    Description = "Description",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Id = "Id",
    IsDefinition = "IsDefinition",
    IsMaster = "IsMaster",
    IsStream = "IsStream",
    Name = "Name",
    NameShort = "NameShort",
    Order = "Order",
    ProjectId = "ProjectId",
    SmartlabMenuEnabled = "SmartlabMenuEnabled"
}
/** unique or primary key constraints on table "SmartlabPFDUnitLinks" */
export enum SmartlabPfdUnitLinksConstraint {
    PkSmartlabPfdUnitLinks = "PK_SmartlabPFDUnitLinks"
}
/** update columns of table "SmartlabPFDUnitLinks" */
export enum SmartlabPfdUnitLinksUpdateColumn {
    Description = "Description",
    Id = "Id",
    Link = "Link",
    Name = "Name",
    Order = "Order",
    PfdTagLink = "PFDTagLink",
    PfdUnitId = "PFDUnitId",
    PfdBlockId = "PfdBlockId",
    SkipBlocks = "SkipBlocks",
    Targets = "Targets",
    Type = "Type"
}
/** unique or primary key constraints on table "PfdProjectTagDependencyModel" */
export enum PfdProjectTagDependencyModelConstraint {
    PkPfdProjectTagDependencyModel = "PK_PfdProjectTagDependencyModel"
}
/** update columns of table "PfdProjectTagDependencyModel" */
export enum PfdProjectTagDependencyModelUpdateColumn {
    ChildId = "ChildId",
    ParentId = "ParentId"
}
/** unique or primary key constraints on table "ReportGenerated" */
export enum ReportGeneratedConstraint {
    PkReportGenerated = "PK_ReportGenerated"
}
/** update columns of table "ReportGenerated" */
export enum ReportGeneratedUpdateColumn {
    Created = "Created",
    Deleted = "Deleted",
    DocUrl = "DocUrl",
    DynamicDataSnapshot = "DynamicDataSnapshot",
    Errors = "Errors",
    Finished = "Finished",
    Id = "Id",
    ProjectConfigurationSnapshot = "ProjectConfigurationSnapshot",
    ProjectReportTemplateId = "ProjectReportTemplateId",
    ProjectReportTemplateSnapshot = "ProjectReportTemplateSnapshot",
    ReportDateTime = "ReportDateTime",
    ReportTemplateId = "ReportTemplateId",
    State = "State",
    Version = "Version",
    Warnings = "Warnings",
    WebUrl = "WebUrl",
    ProjectCode = "projectCode"
}
/** unique or primary key constraints on table "ReportProjectTemplates" */
export enum ReportProjectTemplatesConstraint {
    PkReportProjectTemplates = "PK_ReportProjectTemplates"
}
/** update columns of table "ReportProjectTemplates" */
export enum ReportProjectTemplatesUpdateColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    FileTypes = "FileTypes",
    Frequency = "Frequency",
    GenerateAtHour = "GenerateAtHour",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    NextGenerationDate = "NextGenerationDate",
    Parameters = "Parameters",
    ReportTemplateId = "ReportTemplateId",
    ProjectCode = "projectCode"
}
/** unique or primary key constraints on table "ReportTemplates" */
export enum ReportTemplatesConstraint {
    PkReportTemplates = "PK_ReportTemplates"
}
/** update columns of table "ReportTemplates" */
export enum ReportTemplatesUpdateColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    DeletedDate = "DeletedDate",
    Description = "Description",
    Id = "Id",
    ModifiedDate = "ModifiedDate",
    Name = "Name",
    ReportTemplateBlocks = "ReportTemplateBlocks",
    ReportType = "ReportType",
    Version = "Version"
}
/** unique or primary key constraints on table "ReportPfdTagLinks" */
export enum ReportPfdTagLinksConstraint {
    IxReportPfdTagLinksProjectTemplateIdTagId = "IX_ReportPfdTagLinks_ProjectTemplateId_TagId",
    IxReportPfdTagLinksTemplateIdTagId = "IX_ReportPfdTagLinks_TemplateId_TagId",
    PkReportPfdTagLinks = "PK_ReportPfdTagLinks"
}
/** update columns of table "ReportPfdTagLinks" */
export enum ReportPfdTagLinksUpdateColumn {
    Id = "Id",
    Order = "Order",
    ProjectTemplateId = "ProjectTemplateId",
    TagId = "TagId",
    TemplateId = "TemplateId"
}
/** unique or primary key constraints on table "PfdSamplingpoints" */
export enum PfdSamplingpointsConstraint {
    IxPfdSamplingpointsSamplingpointId = "IX_PfdSamplingpoints_SamplingpointId",
    PkPfdSamplingpoints = "PK_PfdSamplingpoints"
}
/** update columns of table "PfdSamplingpoints" */
export enum PfdSamplingpointsUpdateColumn {
    Description = "Description",
    EnableForLab = "EnableForLab",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Id = "Id",
    Name = "Name",
    Order = "Order",
    ProjectId = "ProjectId",
    SamplingpointDefinitionId = "SamplingpointDefinitionId",
    SamplingpointId = "SamplingpointId"
}
/** unique or primary key constraints on table "PfdTags" */
export enum PfdTagsConstraint {
    IxPfdTagsIsDefinitionTagIdAggregationTypeAggregationTime = "IX_PfdTags_IsDefinition_TagId_AggregationType_AggregationTime",
    PkPfdTags = "PK_PfdTags"
}
/** update columns of table "PfdTags" */
export enum PfdTagsUpdateColumn {
    AggregationDatapointTime = "AggregationDatapointTime",
    AggregationDatapointTimeMultiplier = "AggregationDatapointTimeMultiplier",
    AggregationIsFaked = "AggregationIsFaked",
    AggregationTime = "AggregationTime",
    AggregationTimeMultiplier = "AggregationTimeMultiplier",
    AggregationTimeSource = "AggregationTimeSource",
    AggregationType = "AggregationType",
    BlockId = "BlockId",
    DataType = "DataType",
    DefinitionId = "DefinitionId",
    Description = "Description",
    Enabled = "Enabled",
    ErrorType = "ErrorType",
    Errors = "Errors",
    Formula = "Formula",
    Id = "Id",
    IsDefinition = "IsDefinition",
    IsParameter = "IsParameter",
    IsProjectTag = "IsProjectTag",
    LimitH = "Limit_h",
    LimitHh = "Limit_hh",
    LimitL = "Limit_l",
    LimitLl = "Limit_ll",
    MeasurementName = "MeasurementName",
    MeasurementNameSf = "MeasurementNameSF",
    MeasurementType = "MeasurementType",
    Name = "Name",
    ProjectId = "ProjectId",
    Quantity = "Quantity",
    SamplingpointId = "SamplingpointId",
    Source = "Source",
    Substance = "Substance",
    TagDefinitionId = "TagDefinitionId",
    TagId = "TagId",
    Unit = "Unit"
}
/** unique or primary key constraints on table "DataProcConfigMeasurementPull" */
export enum DataProcConfigMeasurementPullConstraint {
    PkDataProcConfigMeasurementPull = "PK_DataProcConfigMeasurementPull"
}
/** update columns of table "DataProcConfigMeasurementPull" */
export enum DataProcConfigMeasurementPullUpdateColumn {
    ConfigurationId = "ConfigurationId",
    DatabaseId = "DatabaseId",
    Enabled = "Enabled",
    Id = "Id",
    Json = "Json",
    MaxDataIterations = "MaxDataIterations",
    MaxDataRowTarget = "MaxDataRowTarget",
    ServiceType = "ServiceType"
}
/** unique or primary key constraints on table "DataProcStateMeasurementPull" */
export enum DataProcStateMeasurementPullConstraint {
    PkDataProcStateMeasurementPull = "PK_DataProcStateMeasurementPull"
}
/** update columns of table "DataProcStateMeasurementPull" */
export enum DataProcStateMeasurementPullUpdateColumn {
    ConfigurationId = "ConfigurationId",
    EndedDate = "EndedDate",
    Json = "Json",
    Loops = "Loops",
    PointsReceived = "PointsReceived",
    StartedDate = "StartedDate",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    TotalPointsReceived = "TotalPointsReceived",
    Updated = "Updated"
}
/** unique or primary key constraints on table "DataProcServer" */
export enum DataProcServerConstraint {
    PkDataProcServer = "PK_DataProcServer"
}
/** update columns of table "DataProcServer" */
export enum DataProcServerUpdateColumn {
    Created = "Created",
    Description = "Description",
    Id = "Id",
    Identifier = "Identifier",
    LastPing = "LastPing",
    Name = "Name"
}
/** unique or primary key constraints on table "DataProcState" */
export enum DataProcStateConstraint {
    PkDataProcGeneralState = "PK_DataProcGeneralState"
}
/** update columns of table "DataProcState" */
export enum DataProcStateUpdateColumn {
    Id = "Id",
    SchedulerLastRunDate = "SchedulerLastRunDate"
}
/** unique or primary key constraints on table "DataProcStateEntityHistory" */
export enum DataProcStateEntityHistoryConstraint {
    PkDataProcStateEntityHistory = "PK_DataProcStateEntityHistory"
}
/** update columns of table "DataProcStateEntityHistory" */
export enum DataProcStateEntityHistoryUpdateColumn {
    ConfigurationId = "ConfigurationId",
    Id = "Id",
    ModelId = "ModelId",
    ModelType = "ModelType",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType"
}
/** unique or primary key constraints on table "DataProcStateTree" */
export enum DataProcStateTreeConstraint {
    PkDataProcStateTree = "PK_DataProcStateTree"
}
/** update columns of table "DataProcStateTree" */
export enum DataProcStateTreeUpdateColumn {
    ConfigurationId = "ConfigurationId",
    CurrentWorkJson = "CurrentWorkJson",
    DatapointSeriesOperationLastDate = "DatapointSeriesOperationLastDate",
    PfdChangeLogLastDate = "PfdChangeLogLastDate",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    TreePingDate = "TreePingDate",
    TreeStarted = "TreeStarted",
    TreeStopped = "TreeStopped"
}
/** unique or primary key constraints on table "DataProcStateTreeCommand" */
export enum DataProcStateTreeCommandConstraint {
    PkDataProcStateTreeCommand = "PK_DataProcStateTreeCommand"
}
/** update columns of table "DataProcStateTreeCommand" */
export enum DataProcStateTreeCommandUpdateColumn {
    LastCommand = "LastCommand",
    ServerId = "ServerId"
}
/** unique or primary key constraints on table "DataProcTreeLeafDependencies" */
export enum DataProcTreeLeafDependenciesConstraint {
    PkDataProcTreeLeafDependencies = "PK_DataProcTreeLeafDependencies"
}
/** update columns of table "DataProcTreeLeafDependencies" */
export enum DataProcTreeLeafDependenciesUpdateColumn {
    ChildDependenciesId = "ChildDependenciesId",
    ParentDependenciesId = "ParentDependenciesId"
}
/** unique or primary key constraints on table "DataProcStateTreeLeaf" */
export enum DataProcStateTreeLeafConstraint {
    PkDataProcStateTreeLeaf = "PK_DataProcStateTreeLeaf"
}
/** update columns of table "DataProcStateTreeLeaf" */
export enum DataProcStateTreeLeafUpdateColumn {
    LeafId = "LeafId",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    TailVersion = "TailVersion",
    WorkState = "WorkState"
}
/** unique or primary key constraints on table "DataProcTreeLeaf" */
export enum DataProcTreeLeafConstraint {
    PkDataProcTreeLeaf = "PK_DataProcTreeLeaf"
}
/** update columns of table "DataProcTreeLeaf" */
export enum DataProcTreeLeafUpdateColumn {
    Class = "Class",
    ClassPropertiesJson = "ClassPropertiesJson",
    ConfigurationId = "ConfigurationId",
    DatapointSeriesId = "DatapointSeriesId",
    Id = "Id",
    Name = "Name",
    SourceId = "SourceId",
    SourceType = "SourceType"
}
/** unique or primary key constraints on table "DataProcTreeCommand" */
export enum DataProcTreeCommandConstraint {
    PkDataProcTreeCommand = "PK_DataProcTreeCommand"
}
/** update columns of table "DataProcTreeCommand" */
export enum DataProcTreeCommandUpdateColumn {
    CommandType = "CommandType",
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    Data = "Data",
    Id = "Id",
    StateDate = "StateDate",
    StateMessage = "StateMessage",
    StateType = "StateType",
    UserId = "UserId"
}
/** unique or primary key constraints on table "DatapointSeriesOperation" */
export enum DatapointSeriesOperationConstraint {
    PkDatapointSeriesOperation = "PK_DatapointSeriesOperation"
}
/** update columns of table "DatapointSeriesOperation" */
export enum DatapointSeriesOperationUpdateColumn {
    ConfigurationId = "ConfigurationId",
    Created = "Created",
    End = "End",
    Id = "Id",
    SeriesId = "SeriesId",
    Start = "Start",
    Type = "Type",
    UserId = "UserId"
}
/** unique or primary key constraints on table "FileTypes" */
export enum FileTypesConstraint {
    PkFileTypes = "PK_FileTypes"
}
/** update columns of table "FileTypes" */
export enum FileTypesUpdateColumn {
    Id = "Id",
    IsDeleted = "IsDeleted",
    Name = "Name"
}
/** unique or primary key constraints on table "LaboScheduleStates" */
export enum LaboScheduleStatesConstraint {
    PkLaboScheduleStates = "PK_LaboScheduleStates"
}
/** update columns of table "LaboScheduleStates" */
export enum LaboScheduleStatesUpdateColumn {
    CreatedAt = "CreatedAt",
    Deleted = "Deleted",
    DeletedAt = "DeletedAt",
    Id = "Id",
    Name = "Name",
    ProjectId = "ProjectId",
    Schedule = "Schedule"
}
/** unique or primary key constraints on table "LaboScheduleStatesPlanning" */
export enum LaboScheduleStatesPlanningConstraint {
    PkLaboScheduleStatesPlanning = "PK_LaboScheduleStatesPlanning"
}
/** update columns of table "LaboScheduleStatesPlanning" */
export enum LaboScheduleStatesPlanningUpdateColumn {
    Id = "Id",
    Planned = "Planned",
    ProjectId = "ProjectId",
    StateId = "StateId"
}
/** unique or primary key constraints on table "NotificationTypes" */
export enum NotificationTypesConstraint {
    PkNotificationTypes = "PK_NotificationTypes"
}
/** update columns of table "NotificationTypes" */
export enum NotificationTypesUpdateColumn {
    Deleted = "Deleted",
    Id = "Id",
    Name = "Name"
}
/** unique or primary key constraints on table "PfdChangeLogs" */
export enum PfdChangeLogsConstraint {
    PkPfdChangeLogs = "PK_PfdChangeLogs"
}
/** update columns of table "PfdChangeLogs" */
export enum PfdChangeLogsUpdateColumn {
    Created = "Created",
    Id = "Id",
    ModelFieldName = "ModelFieldName",
    ModelId = "ModelId",
    ModelName = "ModelName",
    Name = "Name",
    ProjectId = "ProjectId",
    ValueNew = "ValueNew",
    ValueOld = "ValueOld"
}
/** unique or primary key constraints on table "PfdProjectBlockDependencyModel" */
export enum PfdProjectBlockDependencyModelConstraint {
    PkPfdProjectBlockDependencyModel = "PK_PfdProjectBlockDependencyModel"
}
/** update columns of table "PfdProjectBlockDependencyModel" */
export enum PfdProjectBlockDependencyModelUpdateColumn {
    ChildId = "ChildId",
    ParentId = "ParentId"
}
/** unique or primary key constraints on table "ProjectConfigurations" */
export enum ProjectConfigurationsConstraint {
    PkProjectConfigurations = "PK_ProjectConfigurations"
}
/** update columns of table "ProjectConfigurations" */
export enum ProjectConfigurationsUpdateColumn {
    Id = "Id",
    ProjectCode = "ProjectCode",
    ProjectLogo = "ProjectLogo",
    ProjectName = "ProjectName",
    ProjectType = "ProjectType",
    ReportsDrive = "ReportsDrive",
    ReportsItem = "ReportsItem",
    ReportsSite = "ReportsSite",
    TimeZone = "TimeZone"
}
/** unique or primary key constraints on table "ReportProjectFiles" */
export enum ReportProjectFilesConstraint {
    PkReportProjectFiles = "PK_ReportProjectFiles"
}
/** update columns of table "ReportProjectFiles" */
export enum ReportProjectFilesUpdateColumn {
    Created = "Created",
    DriveItemId = "DriveItemId",
    Id = "Id",
    Modified = "Modified",
    Name = "Name",
    ProjectCode = "ProjectCode",
    Size = "Size",
    Type = "Type",
    WebUrl = "WebUrl"
}
/** unique or primary key constraints on table "SmartlabFunctionalities" */
export enum SmartlabFunctionalitiesConstraint {
    PkSmartlabFunctionalities = "PK_SmartlabFunctionalities"
}
/** update columns of table "SmartlabFunctionalities" */
export enum SmartlabFunctionalitiesUpdateColumn {
    AllowedPermissions = "AllowedPermissions",
    Description = "Description",
    Id = "Id",
    Name = "Name"
}
/** unique or primary key constraints on table "SupportChatAutoMessages" */
export enum SupportChatAutoMessagesConstraint {
    PkSupportChatAutoMessages = "PK_SupportChatAutoMessages"
}
/** update columns of table "SupportChatAutoMessages" */
export enum SupportChatAutoMessagesUpdateColumn {
    CreatedDate = "CreatedDate",
    Deleted = "Deleted",
    Id = "Id",
    ProjectGroupId = "ProjectGroupId",
    TextValue = "TextValue"
}

export type Jsonb = any;

export type Uuid = any;

export type Timestamptz = any;

export type _Text = any;

export type Bigint = any;

export type Smallint = any;

export type Timestamp = any;

export type Numeric = any;

export type Float8 = any;

// ====================================================
// Documents
// ====================================================

export namespace DeleteCalendarEvents {
    export type Variables = {
        CalendarEvents_bool_exp: CalendarEventsBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        delete_CalendarEvents: Maybe<DeleteCalendarEvents>;
    };

    export type DeleteCalendarEvents = {
        __typename?: "CalendarEvents_mutation_response";

        affected_rows: number;
    };
}

export namespace DeleteLaboScheduleStates {
    export type Variables = {
        LaboScheduleStates_bool_exp: LaboScheduleStatesBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        delete_LaboScheduleStates: Maybe<DeleteLaboScheduleStates>;
    };

    export type DeleteLaboScheduleStates = {
        __typename?: "LaboScheduleStates_mutation_response";

        affected_rows: number;
    };
}

export namespace DeleteLaboScheduleStatesPlanning {
    export type Variables = {
        LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanningBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        delete_LaboScheduleStatesPlanning: Maybe<
            DeleteLaboScheduleStatesPlanning
        >;
    };

    export type DeleteLaboScheduleStatesPlanning = {
        __typename?: "LaboScheduleStatesPlanning_mutation_response";

        affected_rows: number;
    };
}

export namespace InsertCalendarEvents {
    export type Variables = {
        CalendarEvents_insert_input: CalendarEventsInsertInput[];
    };

    export type Mutation = {
        __typename?: "Mutation";

        insert_CalendarEvents: Maybe<InsertCalendarEvents>;
    };

    export type InsertCalendarEvents = {
        __typename?: "CalendarEvents_mutation_response";

        returning: Returning[];
    };

    export type Returning = {
        __typename?: "CalendarEvents";

        UserId: Maybe<string>;

        Title: Maybe<string>;

        Start: Timestamptz;

        ResizableBeforeStart: boolean;

        ResizableAfterEnd: boolean;

        ProjectCode: Maybe<string>;

        MetaNotes: Maybe<string>;

        MetaLocation: Maybe<string>;

        Id: Uuid;

        End: Timestamptz;

        Draggable: boolean;

        ColorSecondary: Maybe<string>;

        ColorPrimary: Maybe<string>;

        AllDay: boolean;
    };
}

export namespace InsertLaboScheduleStates {
    export type Variables = {
        LaboScheduleStates_insert_input: LaboScheduleStatesInsertInput[];
    };

    export type Mutation = {
        __typename?: "Mutation";

        insert_LaboScheduleStates: Maybe<InsertLaboScheduleStates>;
    };

    export type InsertLaboScheduleStates = {
        __typename?: "LaboScheduleStates_mutation_response";

        returning: Returning[];
    };

    export type Returning = {
        __typename?: "LaboScheduleStates";

        Schedule: Maybe<Jsonb>;

        ProjectId: string;

        Name: string;

        Id: Uuid;

        DeletedAt: Timestamp;

        Deleted: boolean;

        CreatedAt: Timestamp;
    };
}

export namespace InsertLaboScheduleStatesPlanning {
    export type Variables = {
        LaboScheduleStatesPlanning_insert_input: LaboScheduleStatesPlanningInsertInput[];
    };

    export type Mutation = {
        __typename?: "Mutation";

        insert_LaboScheduleStatesPlanning: Maybe<
            InsertLaboScheduleStatesPlanning
        >;
    };

    export type InsertLaboScheduleStatesPlanning = {
        __typename?: "LaboScheduleStatesPlanning_mutation_response";

        returning: Returning[];
    };

    export type Returning = {
        __typename?: "LaboScheduleStatesPlanning";

        Id: Uuid;

        Planned: Timestamp;

        StateId: Uuid;

        ProjectId: string;
    };
}

export namespace UpdateCalendarEvents {
    export type Variables = {
        CalendarEvents_set_input: CalendarEventsSetInput;
        CalendarEvents_bool_exp: CalendarEventsBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        update_CalendarEvents: Maybe<UpdateCalendarEvents>;
    };

    export type UpdateCalendarEvents = {
        __typename?: "CalendarEvents_mutation_response";

        affected_rows: number;
    };
}

export namespace UpdateLaboScheduleStates {
    export type Variables = {
        LaboScheduleStates_set_input: LaboScheduleStatesSetInput;
        LaboScheduleStates_bool_exp: LaboScheduleStatesBoolExp;
    };

    export type Mutation = {
        __typename?: "Mutation";

        update_LaboScheduleStates: Maybe<UpdateLaboScheduleStates>;
    };

    export type UpdateLaboScheduleStates = {
        __typename?: "LaboScheduleStates_mutation_response";

        affected_rows: number;
    };
}

export namespace BrandingWhere {
    export type Variables = {
        Branding_bool_exp: BrandingBoolExp;
        Branding_order_by?: Maybe<BrandingOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        Branding: Branding[];
    };

    export type Branding = {
        __typename?: "Branding";

        AlertsEnabled: boolean;

        AlertsTitle: Maybe<string>;

        CalendarEnabled: boolean;

        CalendarTitle: Maybe<string>;

        Domain: string;

        LaboratoryEnabled: boolean;

        LaboratoryTitle: Maybe<string>;

        LogbookEnabled: boolean;

        LogbookTitle: Maybe<string>;

        Logo: Maybe<string>;

        EnableBigLogo: boolean;

        MaintenanceEnabled: boolean;

        MaintenanceTitle: Maybe<string>;

        MyPlantsTitle: Maybe<string>;

        MyPlantsTableTitle: Maybe<string>;

        Name: string;

        OperatorEnabled: boolean;

        OperatorTitle: Maybe<string>;

        PlantEnabled: boolean;

        PlantTitle: Maybe<string>;

        PrimaryColor: Maybe<string>;

        ReportsEnabled: boolean;

        ReportsTitle: Maybe<string>;

        SecondaryColor: Maybe<string>;

        TechnologiesEnabled: boolean;

        TechnologiesTitle: Maybe<string>;

        UtilitiesEnabled: boolean;

        UtilitiesTitle: Maybe<string>;

        PlantOverrideWithPFDUnitId: Maybe<string>;

        FavIcon: Maybe<string>;

        AllowedProjects: Maybe<Jsonb>;
    };
}

export namespace CalendarEventsWhere {
    export type Variables = {
        CalendarEvents_bool_exp: CalendarEventsBoolExp;
        CalendarEvents_order_by?: Maybe<CalendarEventsOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        CalendarEvents: CalendarEvents[];
    };

    export type CalendarEvents = {
        __typename?: "CalendarEvents";

        UserId: Maybe<string>;

        Title: Maybe<string>;

        Start: Timestamptz;

        ResizableBeforeStart: boolean;

        ResizableAfterEnd: boolean;

        ProjectCode: Maybe<string>;

        MetaNotes: Maybe<string>;

        MetaLocation: Maybe<string>;

        Id: Uuid;

        End: Timestamptz;

        Draggable: boolean;

        ColorSecondary: Maybe<string>;

        ColorPrimary: Maybe<string>;

        AllDay: boolean;
    };
}

export namespace ClientGroupsSmartlabUsersWhere {
    export type Variables = {
        ClientGroupsSmartlabUsers_bool_exp: ClientGroupsSmartlabUsersBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        ClientGroupsSmartlabUsers: ClientGroupsSmartlabUsers[];
    };

    export type ClientGroupsSmartlabUsers = {
        __typename?: "ClientGroupsSmartlabUsers";

        ClientGroup: ClientGroup;
    };

    export type ClientGroup = {
        __typename?: "ClientGroups";

        Id: Uuid;

        Name: Maybe<string>;
    };
}

export namespace LaboScheduleStatesPlanningWhere {
    export type Variables = {
        Limit: number;
        LaboScheduleStatesPlanning_order_by?: Maybe<
            LaboScheduleStatesPlanningOrderBy[]
        >;
        LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanningBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        LaboScheduleStatesPlanning: LaboScheduleStatesPlanning[];
    };

    export type LaboScheduleStatesPlanning = {
        __typename?: "LaboScheduleStatesPlanning";

        Id: Uuid;

        Planned: Timestamp;

        StateId: Uuid;

        ProjectId: string;

        LaboScheduleState: LaboScheduleState;
    };

    export type LaboScheduleState = {
        __typename?: "LaboScheduleStates";

        Schedule: Maybe<Jsonb>;

        Id: Uuid;

        Name: string;
    };
}

export namespace LaboScheduleStatesWhere {
    export type Variables = {
        LaboScheduleStates_bool_exp: LaboScheduleStatesBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        LaboScheduleStates: LaboScheduleStates[];
    };

    export type LaboScheduleStates = {
        __typename?: "LaboScheduleStates";

        Schedule: Maybe<Jsonb>;

        ProjectId: string;

        Name: string;

        Id: Uuid;

        DeletedAt: Timestamp;

        Deleted: boolean;

        CreatedAt: Timestamp;
    };
}

export namespace ProjectGroupsSmartlabUsersWhere {
    export type Variables = {
        ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsersBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        ProjectGroupsSmartlabUsers: ProjectGroupsSmartlabUsers[];
    };

    export type ProjectGroupsSmartlabUsers = {
        __typename?: "ProjectGroupsSmartlabUsers";

        ProjectGroup: ProjectGroup;
    };

    export type ProjectGroup = {
        __typename?: "ProjectGroups";

        Id: Uuid;

        Name: Maybe<string>;
    };
}

export namespace ProjectGroupsWhere {
    export type Variables = {
        ProjectGroups_bool_exp: ProjectGroupsBoolExp;
    };

    export type Query = {
        __typename?: "Query";

        ProjectGroups: ProjectGroups[];
    };

    export type ProjectGroups = {
        __typename?: "ProjectGroups";

        Id: Uuid;

        Name: Maybe<string>;
    };
}

export namespace ReportProjectFilesWhere {
    export type Variables = {
        ReportProjectFiles_bool_exp: ReportProjectFilesBoolExp;
        ReportProjectFiles_order_by?: Maybe<ReportProjectFilesOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        ReportProjectFiles: ReportProjectFiles[];
    };

    export type ReportProjectFiles = {
        __typename?: "ReportProjectFiles";

        Created: Maybe<Timestamptz>;

        DriveItemId: Maybe<string>;

        Modified: Maybe<Timestamptz>;

        Name: Maybe<string>;

        ProjectCode: Maybe<string>;

        Size: Maybe<Bigint>;

        Type: Maybe<string>;

        WebUrl: Maybe<string>;
    };
}

export namespace SmartlabPfdUnitLinksWhere {
    export type Variables = {
        SmartlabPFDUnitLinks_bool_exp: SmartlabPfdUnitLinksBoolExp;
        SmartlabPFDUnitLinks_order_by?: Maybe<SmartlabPfdUnitLinksOrderBy[]>;
    };

    export type Query = {
        __typename?: "Query";

        SmartlabPFDUnitLinks: SmartlabPfdUnitLinks[];
    };

    export type SmartlabPfdUnitLinks = {
        __typename?: "SmartlabPFDUnitLinks";

        Type: Maybe<string>;

        PFDUnitId: string;

        Name: Maybe<string>;

        Id: Uuid;

        Description: Maybe<string>;

        Link: Maybe<string>;

        Order: number;

        Targets: Maybe<_Text>;

        PFDTagLink: Maybe<string>;

        SkipBlocks: boolean;
    };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";

import gql from "graphql-tag";

// ====================================================
// Apollo Services
// ====================================================

@Injectable({
    providedIn: "root"
})
export class DeleteCalendarEventsGQL extends Apollo.Mutation<
    DeleteCalendarEvents.Mutation,
    DeleteCalendarEvents.Variables
> {
    document: any = gql`
        mutation delete_CalendarEvents(
            $CalendarEvents_bool_exp: CalendarEvents_bool_exp!
        ) {
            __typename
            delete_CalendarEvents(where: $CalendarEvents_bool_exp) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class DeleteLaboScheduleStatesGQL extends Apollo.Mutation<
    DeleteLaboScheduleStates.Mutation,
    DeleteLaboScheduleStates.Variables
> {
    document: any = gql`
        mutation delete_LaboScheduleStates(
            $LaboScheduleStates_bool_exp: LaboScheduleStates_bool_exp!
        ) {
            __typename
            delete_LaboScheduleStates(where: $LaboScheduleStates_bool_exp) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class DeleteLaboScheduleStatesPlanningGQL extends Apollo.Mutation<
    DeleteLaboScheduleStatesPlanning.Mutation,
    DeleteLaboScheduleStatesPlanning.Variables
> {
    document: any = gql`
        mutation delete_LaboScheduleStatesPlanning(
            $LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanning_bool_exp!
        ) {
            __typename
            delete_LaboScheduleStatesPlanning(
                where: $LaboScheduleStatesPlanning_bool_exp
            ) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class InsertCalendarEventsGQL extends Apollo.Mutation<
    InsertCalendarEvents.Mutation,
    InsertCalendarEvents.Variables
> {
    document: any = gql`
        mutation insert_CalendarEvents(
            $CalendarEvents_insert_input: [CalendarEvents_insert_input!]!
        ) {
            __typename
            insert_CalendarEvents(objects: $CalendarEvents_insert_input) {
                returning {
                    UserId
                    Title
                    Start
                    ResizableBeforeStart
                    ResizableAfterEnd
                    ProjectCode
                    MetaNotes
                    MetaLocation
                    Id
                    End
                    Draggable
                    ColorSecondary
                    ColorPrimary
                    AllDay
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class InsertLaboScheduleStatesGQL extends Apollo.Mutation<
    InsertLaboScheduleStates.Mutation,
    InsertLaboScheduleStates.Variables
> {
    document: any = gql`
        mutation insert_LaboScheduleStates(
            $LaboScheduleStates_insert_input: [LaboScheduleStates_insert_input!]!
        ) {
            __typename
            insert_LaboScheduleStates(
                objects: $LaboScheduleStates_insert_input
            ) {
                returning {
                    Schedule
                    ProjectId
                    Name
                    Id
                    DeletedAt
                    Deleted
                    CreatedAt
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class InsertLaboScheduleStatesPlanningGQL extends Apollo.Mutation<
    InsertLaboScheduleStatesPlanning.Mutation,
    InsertLaboScheduleStatesPlanning.Variables
> {
    document: any = gql`
        mutation insert_LaboScheduleStatesPlanning(
            $LaboScheduleStatesPlanning_insert_input: [LaboScheduleStatesPlanning_insert_input!]!
        ) {
            __typename
            insert_LaboScheduleStatesPlanning(
                objects: $LaboScheduleStatesPlanning_insert_input
            ) {
                returning {
                    Id
                    Planned
                    StateId
                    ProjectId
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class UpdateCalendarEventsGQL extends Apollo.Mutation<
    UpdateCalendarEvents.Mutation,
    UpdateCalendarEvents.Variables
> {
    document: any = gql`
        mutation update_CalendarEvents(
            $CalendarEvents_set_input: CalendarEvents_set_input!
            $CalendarEvents_bool_exp: CalendarEvents_bool_exp!
        ) {
            __typename
            update_CalendarEvents(
                _set: $CalendarEvents_set_input
                where: $CalendarEvents_bool_exp
            ) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class UpdateLaboScheduleStatesGQL extends Apollo.Mutation<
    UpdateLaboScheduleStates.Mutation,
    UpdateLaboScheduleStates.Variables
> {
    document: any = gql`
        mutation update_LaboScheduleStates(
            $LaboScheduleStates_set_input: LaboScheduleStates_set_input!
            $LaboScheduleStates_bool_exp: LaboScheduleStates_bool_exp!
        ) {
            __typename
            update_LaboScheduleStates(
                _set: $LaboScheduleStates_set_input
                where: $LaboScheduleStates_bool_exp
            ) {
                affected_rows
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class BrandingWhereGQL extends Apollo.Query<
    BrandingWhere.Query,
    BrandingWhere.Variables
> {
    document: any = gql`
        query BrandingWhere(
            $Branding_bool_exp: Branding_bool_exp!
            $Branding_order_by: [Branding_order_by!]
        ) {
            __typename
            Branding(where: $Branding_bool_exp, order_by: $Branding_order_by) {
                AlertsEnabled
                AlertsTitle
                CalendarEnabled
                CalendarTitle
                Domain
                LaboratoryEnabled
                LaboratoryTitle
                LogbookEnabled
                LogbookTitle
                Logo
                EnableBigLogo
                MaintenanceEnabled
                MaintenanceTitle
                MyPlantsTitle
                MyPlantsTableTitle
                Name
                OperatorEnabled
                OperatorTitle
                PlantEnabled
                PlantTitle
                PrimaryColor
                ReportsEnabled
                ReportsTitle
                SecondaryColor
                TechnologiesEnabled
                TechnologiesTitle
                UtilitiesEnabled
                UtilitiesTitle
                PlantOverrideWithPFDUnitId
                FavIcon
                AllowedProjects
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class CalendarEventsWhereGQL extends Apollo.Query<
    CalendarEventsWhere.Query,
    CalendarEventsWhere.Variables
> {
    document: any = gql`
        query CalendarEventsWhere(
            $CalendarEvents_bool_exp: CalendarEvents_bool_exp!
            $CalendarEvents_order_by: [CalendarEvents_order_by!]
        ) {
            __typename
            CalendarEvents(
                where: $CalendarEvents_bool_exp
                order_by: $CalendarEvents_order_by
            ) {
                UserId
                Title
                Start
                ResizableBeforeStart
                ResizableAfterEnd
                ProjectCode
                MetaNotes
                MetaLocation
                Id
                End
                Draggable
                ColorSecondary
                ColorPrimary
                AllDay
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class ClientGroupsSmartlabUsersWhereGQL extends Apollo.Query<
    ClientGroupsSmartlabUsersWhere.Query,
    ClientGroupsSmartlabUsersWhere.Variables
> {
    document: any = gql`
        query ClientGroupsSmartlabUsersWhere(
            $ClientGroupsSmartlabUsers_bool_exp: ClientGroupsSmartlabUsers_bool_exp!
        ) {
            __typename
            ClientGroupsSmartlabUsers(
                where: $ClientGroupsSmartlabUsers_bool_exp
            ) {
                ClientGroup {
                    Id
                    Name
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class LaboScheduleStatesPlanningWhereGQL extends Apollo.Query<
    LaboScheduleStatesPlanningWhere.Query,
    LaboScheduleStatesPlanningWhere.Variables
> {
    document: any = gql`
        query LaboScheduleStatesPlanningWhere(
            $Limit: Int!
            $LaboScheduleStatesPlanning_order_by: [LaboScheduleStatesPlanning_order_by!]
            $LaboScheduleStatesPlanning_bool_exp: LaboScheduleStatesPlanning_bool_exp!
        ) {
            __typename
            LaboScheduleStatesPlanning(
                limit: $Limit
                order_by: $LaboScheduleStatesPlanning_order_by
                where: $LaboScheduleStatesPlanning_bool_exp
            ) {
                Id
                Planned
                StateId
                ProjectId
                LaboScheduleState {
                    Schedule
                    Id
                    Name
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class LaboScheduleStatesWhereGQL extends Apollo.Query<
    LaboScheduleStatesWhere.Query,
    LaboScheduleStatesWhere.Variables
> {
    document: any = gql`
        query laboScheduleStatesWhere(
            $LaboScheduleStates_bool_exp: LaboScheduleStates_bool_exp!
        ) {
            __typename
            LaboScheduleStates(where: $LaboScheduleStates_bool_exp) {
                Schedule
                ProjectId
                Name
                Id
                DeletedAt
                Deleted
                CreatedAt
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class ProjectGroupsSmartlabUsersWhereGQL extends Apollo.Query<
    ProjectGroupsSmartlabUsersWhere.Query,
    ProjectGroupsSmartlabUsersWhere.Variables
> {
    document: any = gql`
        query ProjectGroupsSmartlabUsersWhere(
            $ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsers_bool_exp!
        ) {
            __typename
            ProjectGroupsSmartlabUsers(
                where: $ProjectGroupsSmartlabUsers_bool_exp
            ) {
                ProjectGroup {
                    Id
                    Name
                }
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class ProjectGroupsWhereGQL extends Apollo.Query<
    ProjectGroupsWhere.Query,
    ProjectGroupsWhere.Variables
> {
    document: any = gql`
        query ProjectGroupsWhere(
            $ProjectGroups_bool_exp: ProjectGroups_bool_exp!
        ) {
            __typename
            ProjectGroups(where: $ProjectGroups_bool_exp) {
                Id
                Name
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class ReportProjectFilesWhereGQL extends Apollo.Query<
    ReportProjectFilesWhere.Query,
    ReportProjectFilesWhere.Variables
> {
    document: any = gql`
        query ReportProjectFilesWhere(
            $ReportProjectFiles_bool_exp: ReportProjectFiles_bool_exp!
            $ReportProjectFiles_order_by: [ReportProjectFiles_order_by!]
        ) {
            __typename
            ReportProjectFiles(
                where: $ReportProjectFiles_bool_exp
                order_by: $ReportProjectFiles_order_by
            ) {
                Created
                DriveItemId
                Modified
                Name
                ProjectCode
                Size
                Type
                WebUrl
            }
        }
    `;
}
@Injectable({
    providedIn: "root"
})
export class SmartlabPfdUnitLinksWhereGQL extends Apollo.Query<
    SmartlabPfdUnitLinksWhere.Query,
    SmartlabPfdUnitLinksWhere.Variables
> {
    document: any = gql`
        query SmartlabPFDUnitLinksWhere(
            $SmartlabPFDUnitLinks_bool_exp: SmartlabPFDUnitLinks_bool_exp!
            $SmartlabPFDUnitLinks_order_by: [SmartlabPFDUnitLinks_order_by!]
        ) {
            __typename
            SmartlabPFDUnitLinks(
                where: $SmartlabPFDUnitLinks_bool_exp
                order_by: $SmartlabPFDUnitLinks_order_by
            ) {
                Type
                PFDUnitId
                Name
                Id
                Description
                Link
                Order
                Targets
                PFDTagLink
                SkipBlocks
            }
        }
    `;
}

// ====================================================
// END: Apollo Angular template
// ====================================================
