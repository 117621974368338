export const locale = {
    lang: 'nl',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'ADMIN': {
                'TITLE': 'Admin',
            },
            'LOG': {
                'TITLE': 'Log',
            },
            'REQUESTS': {
                'TITLE': 'Aanvragen',
            },
            'PLANT': {
                'TITLE': 'Plant',
            },
            'TECHNOLOGIES': {
                'TITLE': 'Technologieën',
            },
            'BUFFERING': {
                'TITLE': 'Buffering',
            },
            'UASB': {
                'TITLE': 'UASB',
            },
            'BIOGAS': {
                'TITLE': 'Biogas',
            },
            'LUCAS3': {
                'TITLE': 'LUCAS-3',
            },
            'SLUDGE': {
                'TITLE': 'Sludge',
            },
            'UF': {
                'TITLE': 'Aquality UF',
            },
            'UTILITIES': {
                'TITLE': 'Utilities',
            },
            'MAINTENANCE': {
                'TITLE': 'Onderhoud',
            },
            'LABORATORY': {
                'TITLE': 'Labo',
            },
            'WTLOPERATOR': {
                'TITLE': 'WTL operator',
            },
            'REPORTS': {
                'TITLE': 'Rapporten',
            },
            'ALERTS': {
                'TITLE': 'Waarschuwingen',
            },
            'TOOLS': {
                'TITLE': 'Tools',
            },
            'CALENDAR': {
                'TITLE': 'Kalender',
            },
            'MAIL': {
                'TITLE': 'Mail',
            },
            'CONTACTS': {
                'TITLE': 'Contacten',
            },
            'TODO': {
                'TITLE': 'Logbook',
            },
            'PROJECTS': {
                'TITLE': 'Mijn Plants',
            },
        }
    }
};
