import { Auth0Service } from './../auth0/auth0.service';
import { LoginRenewer } from './../login-renewer';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { Subscription, of, timer, ReplaySubject, BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';
import { Injectable, Inject } from '@angular/core';
import { LoginState } from '../auth.service';

@Injectable()
export class FirebaseAuth0Service extends AuthService {
  // Track Login status
  private loggedInSubject: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState>(LoginState.LOGGED_OUT);
  private loginRenewer: LoginRenewer;
  private auth0Service: Auth0Service;
  // Subscribe to the Firebase token stream
  private firebaseGetTokenSub: Subscription;

  constructor(@Inject('config') private config: any,
              private router: Router,
              private afAuth: AngularFireAuth,
              private http: HttpClient) {
    super();
    this.auth0Service = new Auth0Service(config, this.router);

    this.loginRenewer = new LoginRenewer(() => {
      console.log('Firebase token expired; fetching a new one');
      this._getFirebaseToken();
      return ((3600 - 10) * 1000) + new Date().getTime();
    });

    this.auth0Service.getLoginStateSubject().subscribe((loginState) => {
      if (loginState === LoginState.LOGGED_IN) {
        this.loggedInSubject.next(LoginState.LOGGING_IN);
        this._getFirebaseToken();
      } else if (loginState === LoginState.LOGGED_OUT) {
        this.loggedInSubject.next(LoginState.LOGGING_OUT);
        this._logout();
      } else {
        this.loggedInSubject.next(loginState);
      }
    }, (error) => this._onError(error));
  }

  public login(redirect?: string) {
    this.auth0Service.login(redirect);
  }

  public logout() {
    this.auth0Service.logout();
  }

  public getLoginState(): LoginState {
    if (this.auth0Service.getLoginState() === LoginState.LOGGING_IN ||
        this.auth0Service.getLoginState() === LoginState.LOGGING_OUT) {
      return this.auth0Service.getLoginState();
    }
    return this.loggedInSubject.value;
  }

  public handleLoginCallback(): void {
    this.auth0Service.handleLoginCallback();
  }

  public getLoginStateSubject(): BehaviorSubject<LoginState> {
    return this.loggedInSubject;
  }

  public getUserProfile(): any {
    return this.auth0Service.getUserProfile();
  }

  public getAccessToken(): Promise<string> {
    return this.auth0Service.getAccessToken();
  }

  public getIdToken(): Promise<string> {
    return this.auth0Service.getIdToken();
  }

  public nagivateToRedirect() {
    return this.auth0Service.nagivateToRedirect();
  }

  public userHasPermissions(scopes: Array<string>): boolean {
    return this.auth0Service.userHasPermissions(scopes);
  }

  public getIdTokenExpiryDate(): Date {
    return this.auth0Service.getIdTokenExpiryDate();
  }

  private _getFirebaseToken() {
    // Prompt for login if no access token
    if (this.auth0Service.getLoginState() === LoginState.LOGGED_OUT ||
        this.auth0Service.getLoginState() === LoginState.LOGGING_OUT) {
      this.auth0Service.login();
    }
    const getToken$ = () => {
      return this.http
        .get(`${this.config.apiUrl}auth/firebase`, {
          headers: new HttpHeaders().set('Authorization', `Bearer ${this.auth0Service.getAccessToken()}`)
        });
    };
    this.firebaseGetTokenSub = getToken$().subscribe(
      res => this._firebaseAuth(res),
      err => {
        this._onError(`An error occurred fetching Firebase token: ${err.message}`);
    });
  }

  private _logout() {
    console.log('Logging out of firebase');
    this.loginRenewer.unschedule();
    // Sign out of Firebase
    this.afAuth.auth.signOut().then((v) => {
      this.loggedInSubject.next(LoginState.LOGGED_OUT);
    }).catch((error) => {
      this.loggedInSubject.next(LoginState.LOGGED_OUT);
    });
  }

  private _firebaseAuth(tokenObj) {
    this.afAuth.auth.signInWithCustomToken(tokenObj.firebaseToken)
      .then(res => {
        console.log('Successfully authenticated with Firebase!');
        const expiresAt = ((3600 - 10) * 1000) + new Date().getTime();
        this.loggedInSubject.next(LoginState.LOGGED_IN);
        this.loginRenewer.schedule(expiresAt);
      })
      .catch(err => {
        const errorCode = err.code;
        const errorMessage = err.message;
        console.error(`${errorCode} Could not log into Firebase: ${errorMessage}`);
        this._onError('Could not log into firebase');
      });
  }

  private _onError(error) {
    this.loginRenewer.unschedule();
    if (this.getLoginState() !== LoginState.LOGGED_OUT) {
      this.loggedInSubject.next(LoginState.LOGGED_OUT);
      this.auth0Service.logout();
    }
    this.loggedInSubject.error(error);
  }
}
