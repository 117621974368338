import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as dutch } from './i18n/nl';
import { ProjectParent } from '../parent/project.parent';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { maintenanceData } from './data'
import { ProjectService } from 'app/lib/services/project/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SmartlabService } from 'app/lib/services/smartlab/smartlab.service';
import { AuthService } from 'app/lib/services/auth/auth.service';
import { BrandingService } from 'app/lib/services/branding/branding.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'wtl-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent extends ProjectParent implements OnInit {

  public maintenances: any;

  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    _brandingService: BrandingService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    _activatedRoute: ActivatedRoute,
    _router: Router,
    _fuseNavigationService: FuseNavigationService,
    _projectService: ProjectService,
    _spinner: NgxSpinnerService,
    _smartlabService: SmartlabService,
    _authService: AuthService,
    protected $gaService: GoogleAnalyticsService) {
    super(_brandingService, _activatedRoute, _router, _fuseNavigationService, _projectService, _spinner, _smartlabService, _authService)
    this._fuseTranslationLoaderService.loadTranslations(english, dutch);
    this.maintenances = maintenanceData;
  }

  ngOnInit() {

    this.$gaService.pageView(this._router.url, 'Maintenance')

    super.initProject().subscribe(
      project => {
        // load your dashboard here
      },
      err => {
        // error shown in super
      }
    );
  }
}
