export const environment = {
    production: true,
    hmr: false,
    permission: 'user',
    url: 'https://smartlab.waterleau.com/',
    apiUrl: 'https://smartlab-api.waterleau.cloud/api/',
    operatorSignalRUrl: 'https://smartlab-api.waterleau.cloud/operator',
    graphQLEndpointUrl: 'wss://smartlab-graphql.waterleau.cloud/v1/graphql',
    graphQLEndpointSecret: 'xH7Cq7al3kzw',

    gaMeasurementId: 'G-QJLQYE3Y7M',

    wep: {
        host: 'https://wep.waterleau.com/',
        token: 'bcd30b61f69f41cdd61723be2ab342d7ab66c1d0'
    },
    firebase: {
        apiKey: 'AIzaSyDc7AdY0c9K1xROjaC6zdD9Vmx-pZOq6kU',
        authDomain: 'wtlsmartlab.firebaseapp.com',
        databaseURL: 'https://wtlsmartlab.firebaseio.com',
        projectId: 'wtlsmartlab',
        storageBucket: 'wtlsmartlab.appspot.com',
        messagingSenderId: '729009742836',
        gateway_influx: {
            collection: 'gateway_influx'
        },
        database_raw_signal: {
            collection: 'database_raw_signal'
        },
        logs: {
            collection: 'logs'
        }
    },
    auth0: {
        clientID: 'iDAXqWm96vNh5xYHX6CxYfP2MXf3LxZS', // application clientID
        domain: 'waterleau.eu.auth0.com',
        audience: 'smartlab-api',
        callbackURL: 'auth/callback',
        permissions: 'user upcomingfeatures read:labo read:labo_overview read:labo_input read:labo_states create:labo update:labo update_7_days:labo update_60_days:labo read:reports demomode'
    }
};
