<ng-container *ngIf="isChatButtonDisplayed">
    <button [ngClass]="isChatOpened ? 'chat-button-active' : 'chat-button'" (click)="openChat()">
        <span class="chat-notification" *ngIf="chatState?.unreadMessages?.length">
            <span>{{chatState?.unreadMessages?.length}}</span>
        </span>
    </button>
    <ng-container *ngIf="isChatOpened">
        <div class="chat-wrapper" [@inOutAnimation]>
            <span class="chat-notification-big" *ngIf="chatState?.unreadMessages?.length">
                <span>{{chatState?.unreadMessages?.length}}</span>
            </span>
            <app-header (isChatOpened)="closeChat($event)"></app-header>
            <app-content-wrapper></app-content-wrapper>
        </div>
    </ng-container>
</ng-container>

