export const locale = {
    lang: 'en',
    data: {
        'MAINTENANCE': {
            'MAINTENANCE': 'Maintenance',
            'SERVICE_ORDER_NR': 'Service order nr.',
            'VISIT_TYPE': 'Visit type',
            'PERFORMED_BY': 'Performed by',
            'TAG': 'Tag',
            'DATE': 'Date',
        }
    }
};
