<ng-container *ngIf="!isDisplayedSubcategory">
    <div class="select-subject">
        <div class="select-subjects-wrapper">
            <h1 class="select-subject-title">SELECT YOUR SUBJECT</h1>
            <ul class="select-subject-list">
                <li class="select-subject-list-item" *ngFor="let subject of subjects" (click)="selectSubject(subject)">
                    <span>{{subject.Title}}</span>
                </li>
            </ul>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isDisplayedSubcategory">
    <div class="select-subject">
        <div class="select-subjects-wrapper">
            <app-breadcrumbs [breadcrumbs]="breadcrumbs" [currentBreadcrumb]="currentBreadcrumb"></app-breadcrumbs>
            <h1 class="select-subject-title">{{currentSubject.Title || currentSubcategory.Title}}</h1>
            <ul class="select-subject-list">
                <li class="select-subject-list-item" *ngFor="let subject of currentSubcategory.Children" (click)="selectSubcategorySubject(subject)">
                    <span>{{subject.Title}}</span>
                </li>
            </ul>
        </div>
        <div class="select-subject-back-btn-wrapper">
            <button class="select-subject-back-btn" (click)="goBackClick(currentSubcategory.Children[0].ParentId, currentBreadcrumb)">GO BACK</button>
        </div>
    </div>
</ng-container>

