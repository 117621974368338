<app-messages  
    [subjectInfo]="subjectInfo" 
    [fileUploading]="fileUploading"
    [fileState]="fileState"
    (fileSelected)="saveFiles($event)"></app-messages>
<app-send-location 
    *ngIf="isSendLocationWindowOpened" (location)="sendLocation($event)"></app-send-location>    
<app-send-message 
    [messageMetaData]="messageMetaData" [location]="_location" *ngIf="!chatState?.isSearchOpened && !_searchValue && !chatState?.isShowSearchMessagesWhenSubjectClosed"
    (fileSelected)="saveFiles($event)" (mouseHover)="onMouseHover($event)"></app-send-message>
<app-search-input *ngIf="chatState?.isSearchOpened || _searchValue || chatState?.isShowSearchMessagesWhenSubjectClosed"></app-search-input>    
