export const parseMessage = (message: string, searchValue: string): any => {
  let sentenceMessageArray = message.split('.');
  let sentencesFromCoincidence = '';
  let searchValueInLowerCase = searchValue.toLowerCase();

  sentenceMessageArray.forEach(sentence => {
    let coincidenceIndex = sentence.toLowerCase().indexOf(searchValueInLowerCase);

    if(coincidenceIndex >= 0) {
      sentencesFromCoincidence += sentence;
    }
  });

  const textLowerCase = sentencesFromCoincidence.toLowerCase();
  const textDefault = sentencesFromCoincidence;
  
  let highlightMessage = ``;
  let coincidenceIndex = textLowerCase.indexOf(searchValueInLowerCase);
  let coincidenceNextIndex = textLowerCase.slice(coincidenceIndex + searchValue.length).indexOf(searchValueInLowerCase);

  if(coincidenceIndex === 0) {

    if(coincidenceNextIndex >= 0) {
      highlightMessage += `<span class="search-message-text highlight">${textDefault.slice(coincidenceIndex, coincidenceIndex + searchValue.length)}</span>${parseMessage(textDefault.slice(coincidenceIndex + searchValue.length), searchValue)}`;
    } else {
      highlightMessage += `<span class="search-message-text highlight">${textDefault.slice(coincidenceIndex, coincidenceIndex + searchValue.length)}</span>${textDefault.slice(coincidenceIndex + searchValue.length)}`;
    }
  } else {

    if(coincidenceNextIndex >= 0) {
      highlightMessage += `${textDefault.slice(0, coincidenceIndex)}<span class="search-message-text highlight"> ${textDefault.slice(coincidenceIndex, coincidenceIndex + searchValue.length)}</span>${parseMessage(textDefault.slice(coincidenceIndex + searchValue.length), searchValue)}`;
    } else {
      highlightMessage += `${textDefault.slice(0, coincidenceIndex)}<span class="search-message-text highlight"> ${textDefault.slice(coincidenceIndex, coincidenceIndex + searchValue.length)}</span>${textDefault.slice(coincidenceIndex + searchValue.length)}`;
    }
  }

  return highlightMessage;
};