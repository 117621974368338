<div class="map" fxFlex="1 1 100%" #map>

    <agm-map #gm [style.height.px]="getMapHeight()" [latitude]="lat" [longitude]="lng" [minZoom]="zoom - 1" [zoom]="zoom" [disableDefaultUI]="true" (mapClick)="mapClicked($event)" [styles]="smartlabStyle">

        <agm-marker-cluster
            imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m"
            [styles]="clusterStyles" [calculator]="markerCalc">

            <agm-marker class="smartlab-marker" *ngFor="let m of markers; let i = index"
                (markerClick)="clickedMarker(m, i)" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label"
                [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)" [iconUrl]="m.icon"
                (mouseOver)="infoWindowOnMouseOver(infoWindow,gm)" (mouseOut)="infoWindowOnMouseOut(infoWindow,gm)">

                <agm-info-window class="agm-info-window" #infoWindow>
                    <div class="agm-label">{{ m.label.text }}</div>
                    <div class="agm-location">{{ m.location }}</div>
                    <!-- <div>kpi: xx,xx</div> -->
                </agm-info-window>

            </agm-marker>
        </agm-marker-cluster>

    </agm-map>

</div>