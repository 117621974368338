import { mergeMap } from 'rxjs/operators';
import { Subscription, of, timer } from 'rxjs';

export class LoginRenewer {
  // Subscribe to Firebase renewal timer stream
  private refreshSub: Subscription;
  private refreshCb: () => void;

  constructor(refreshCb: () => void) {
    this.refreshCb = refreshCb;
  }

  public schedule(time: number) {
    // Unsubscribe from previous expiration observable
    this.unschedule();
    // Create and subscribe to expiration observable
    // Custom Firebase tokens minted by Firebase
    const expiresIn$ = of(time)
      .pipe(
        mergeMap(
          expires => {
            const now = Date.now();
            // Use timer to track delay until expiration
            // to run the refresh at the proper time
            return timer(Math.max(1, expires - now));
          }
        )
      );

    this.refreshSub = expiresIn$
      .subscribe(
        () => {
          console.log('Token expired; fetching a new one');
          this.refreshCb();
        }
      );
  }

  public unschedule() {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }
}
