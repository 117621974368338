import { Component, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SupportSubjects } from '../../graphql';
import { IChatStateModel } from '../../models/chat-state.model';
import { IMessageData } from '../../models/message-data-model';
import { ChatStateService } from '../../services/chat-state.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() public isChatOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public supportSubjectName: string;
  public isChatHistoryOpened: boolean;
  public isReturnToSubjectSelect: boolean;
  public _supportSubject: SupportSubjects;
  public _searchValue: string;
  public _isSearchOpened = false;
  public headerButtonsState = {
    showHistoryButton: false,
    showSearchButton: false
  };
  public _isShowSearchMessagesWhenSubjectClosed: boolean;
  public chatState: IChatStateModel;
  public messages: IMessageData[];

  constructor(private chatService: ChatService, private chatStateService: ChatStateService) {
    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;

      if(state.isShowSearchMessagesWhenSubjectClosed) {
        this.chatState.isSearchOpened = true;
      }

      this.updateHeaderButtonsState();
    });

    this.chatStateService.messages$.pipe((takeUntil(this._unsubscribeAll))).subscribe((_messages: IMessageData[]) => {
      this.messages = _messages;
    });
  }

  public updateHeaderButtonsState(): void {
    if(this._supportSubject
      || (!this.chatState.isChatHistoryOpened && (!this.chatState.isReturnToSubjectSelect && this.chatState.isReturnToSubjectSelect !== undefined))
      || (!this.chatState.currentSubject && this.chatState.isSearchOpened)
      || this.chatState.isShowSearchMessagesWhenSubjectClosed) {

      this.headerButtonsState.showSearchButton = true;
    } else {
      this.headerButtonsState.showSearchButton = false;
    }
  }

  public closeChat(): void {
    this.isChatOpened.emit(false);
  }

  public openChatHistory(): void {
    this.chatState.isChatHistoryOpened = true;

    this.chatStateService.state = this.chatState;
  }

  public toggleSearchWindow(): void {
    this.chatState.isSearchOpened = !this.chatState.isSearchOpened;

    if(!this.chatState.isSearchOpened) {
      this.chatService.setSearchValue('');
    }

    this.chatState.isShowSearchMessagesWhenSubjectClosed = false;

    this.chatStateService.state = this.chatState;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
