<div class="search-messages-wrapper"
        infinite-scroll
        [scrollWindow]="false"
        [infiniteScrollDistance]="2"
        (scrolled)="onScrollDown($event)"
        (scroll)="onScroll($event)"
        #scrollMe
        *ngIf="messages.length"
        >
    <app-search-message *ngFor="let message of messages" [message]="message" [searchValue]="searchValue" (click)="selectSearchMessage(message)"></app-search-message>
</div>
<button class="back-to-up" *ngIf="_isBackToTopDisplayed && messages?.length" (click)="scrollToTop()">
    <mat-icon>arrow_drop_up</mat-icon>
</button>

<div class="search-messages-wrapper" *ngIf="!messages.length">
    <div class="search-messages-inner">
        <img class="search-messages-img" src="assets\icons\Nothing Found.svg" alt="nothing_found">
        <p class="search-messages-no-results">NO RESULTS FOUND</p>
    </div>
</div>
