export const locale = {
    lang: 'nl',
    data: {
        'MAINTENANCE': {
            'MAINTENANCE': 'Onderhoud',
            'SERVICE_ORDER_NR': 'Service order nr.',
            'VISIT_TYPE': 'Onderhoud type',
            'TAG': 'Tag',
            'DATE': 'Datum',
        }
    }
};
