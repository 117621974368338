export const FileInputValidator = (files: FileList): FilleValidationResult => {
    if(!files || !files.length) return { 
        valid: false,
        error: FileValidationErrors.NoFilesProvided
    };

    if(files.length > 1) return {
        valid: false,
        error: FileValidationErrors.OnlyOneFile
    }

    const file = files[0];
    const fileTypeValid: boolean = validateFileType(file);

    if(!fileTypeValid) return {
        valid: false,
        error: FileValidationErrors.NotSupportedType
    }

    if(file.size > MAX_FILE_SIZE) return {
        valid: false,
        error: FileValidationErrors.SizeExceeded
    }

    return {
        valid: true
    }
} 

export interface FilleValidationResult {
    valid: boolean
    error?: string,
}

enum FileValidationErrors {
    OnlyOneFile = 'Only one file at the time is allowed',
    NoFilesProvided = 'No files was provided',
    NotSupportedType = 'File type is not supported. Choose another one',
    SizeExceeded = 'File size is too big - Maximum file size is 50 MB.Please upload a new file.'
}

const FileTypesAllowed = {
    PDF: 'application/pdf',
    JPG: 'image/jpeg',
    PNG: 'image/png', 
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    DOC: 'application/msword', 
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

const validateFileType = (file: File): boolean => {
    const fileExtension = extensionParser(file.name);

    if(fileExtension === 'doc' || fileExtension === 'docx') return true;

    if(file.type === '') return false;
    
    for (const [key, value] of Object.entries(FileTypesAllowed)) {
        if(file.type === value) {
            return true;
        }
    }

    return false;
}

const extensionParser = (fileName: string): string => {
    const period = fileName.lastIndexOf('.');
    const fileExtension = fileName.substring(period + 1);
    
    return fileExtension;
};

const MAX_FILE_SIZE: number = 52428800; // 50Mb