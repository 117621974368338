<div *ngIf="_authService.userHasPermissions(['create:labo'])" [style.margin-bottom]="'20px'">
     <button nz-button [style.background]="brandingService.getPrimaryColor()"nzType="primary" (click)="showModalCopyDay()">Copy from another day</button>
</div>

<div class="daily_analysis_table">
    <div fxFlex="20" class="daily_analysis_table_header_left" fxLayout="row" fxLayoutAlign="start center">
        Sampling Point
    </div>
    <div fxFlex="80" class="daily_analysis_table_header_right" fxLayout="row" fxLayoutAlign="start left">

        <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
            Analysis
        </div>
        <div *ngIf="_authService.userHasPermissions(['create:labo'])" fxFlex="50" fxLayout="row"
            fxLayoutAlign="end center">
             <button nz-button [style.background]="brandingService.getPrimaryColor()" nzType="primary" (click)="showModalAddSamplingPoint()">Add Sampling Point</button>
        </div>
    </div>
</div>

<div class="daily_analysis_table" *ngFor="let daySamplingPoint of daySamplingPoints; let i = index">
    <div fxFlex="20" class="daily_analysis_table_cell_left" fxLayout="row" fxLayoutAlign="start center">
        <div>
            <div>
                {{ getSamplingPointName(daySamplingPoint.samplingPointId) }}
            </div>
            <div *ngIf="_authService.userHasPermissions(['create:labo'])" [style.display]="'block'"
                [style.margin-top]="'15px'" [style.width]="'100%'">
                <input nz-input [style.width]="'150px'" placeholder="Optional Label"
                    [(ngModel)]="daySamplingPoint.label" />
            </div>
            <div *ngIf="!_authService.userHasPermissions(['create:labo'])" [style.display]="'block'"
                [style.margin-top]="'15px'" [style.width]="'100%'">
                {{ daySamplingPoint.label }}
            </div>
        </div>
    </div>

    <div fxFlex="auto" class="daily_analysis_table_cell_center" fxLayout="row wrap" fxLayoutAlign="start left"
        fxLayoutGap="10px">
        <ng-container *ngFor="let analysisPoint of getSamplingPointAnalysis(daySamplingPoint.samplingPointId)">
            <ng-container *ngIf="_authService.userHasPermissions(['create:labo'])">
                <div *ngIf="daySamplingPoint.analysisIds.includes(analysisPoint)" class="analysisPointTag" [style.background]="brandingService.getPrimaryColor()"
                    (click)="handleAnalysisPointChange(i, daySamplingPoint.samplingPointId, false, analysisPoint)">
                    {{ getTag(analysisPoint) }}</div>
                <div *ngIf="!daySamplingPoint.analysisIds.includes(analysisPoint)" class="analysisPointTagDeselected"
                    (click)="handleAnalysisPointChange(i, daySamplingPoint.samplingPointId, true, analysisPoint)">
                    {{ getTag(analysisPoint) }}</div>
            </ng-container>
            <ng-container *ngIf="!_authService.userHasPermissions(['create:labo'])">
                <div *ngIf="daySamplingPoint.analysisIds.includes(analysisPoint)" class="analysisPointTagNoHover" [style.background]="brandingService.getPrimaryColor()">
                    {{ getTag(analysisPoint) }}</div>
                <div *ngIf="!daySamplingPoint.analysisIds.includes(analysisPoint)"
                    class="analysisPointTagDeselectedNoHover">
                    {{ getTag(analysisPoint) }}</div>
            </ng-container>
        </ng-container>

        <!-- <nz-tag class="daily_analysis_tag" nzMode="checkable"
            *ngFor="let analysisPoint of getSamplingPointAnalysis(daySamplingPoint.samplingPointId)"
            [nzChecked]="daySamplingPoint.analysisIds.includes(analysisPoint)"
            (nzCheckedChange)="handleAnalysisPointChange(i, daySamplingPoint.samplingPointId, $event, analysisPoint)">
            {{ getTag(analysisPoint) }}
        </nz-tag> -->
    </div>

    <div *ngIf="_authService.userHasPermissions(['create:labo'])" fxFlex="10" class="daily_analysis_table_cell_right"
        fxLayout="row" fxLayoutAlign="center center">
        <button mat-mini-fab class="add-event-button mat-warn" aria-label="Remove Sampling Point"
            (click)="deleteSamplingPoint(i)" [style.background]="brandingService.getPrimaryColor()">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
</div>

<nz-modal [(nzVisible)]="isAddingSamplingPoint" nzTitle="Select the Sampling Point you want to add"
    (nzOnCancel)="cancelAddSamplingPoint()" (nzOnOk)="confirmAddSamplingPoint()">
    <p>
        <nz-select [style.width]="'100%'" nzShowSearch nzAllowClear nzPlaceHolder="Select a Sampling Point"
            [(ngModel)]="addedSamplingPoint">
            <nz-option *ngFor="let o of samplingPoints" [nzValue]="o" [nzLabel]="o.name"></nz-option>
        </nz-select>
    </p>
    <p>
        <input nz-input [style.width]="'100%'" placeholder="Optional Label" [(ngModel)]="addedSamplingPointLabel" />
    </p>
</nz-modal>

<nz-modal [(nzVisible)]="isCopyingDay" nzTitle="Select the day you want to copy from"
    (nzOnCancel)="cancelCopyDay()" (nzOnOk)="confirmCopyDay()">
    <p>
        <nz-select [style.width]="'100%'" nzShowSearch nzAllowClear nzPlaceHolder="Select a day"
            [(ngModel)]="selectedDayToCopy">
            <nz-option *ngIf="selectedDayIndex != 0" [nzValue]="schedule.monday" nzLabel="Monday"></nz-option>
            <nz-option *ngIf="selectedDayIndex != 1" [nzValue]="schedule.tuesday" nzLabel="Tuesday"></nz-option>
            <nz-option *ngIf="selectedDayIndex != 2" [nzValue]="schedule.wednesday" nzLabel="Wednesday"></nz-option>
            <nz-option *ngIf="selectedDayIndex != 3" [nzValue]="schedule.thursday" nzLabel="Thursday"></nz-option>
            <nz-option *ngIf="selectedDayIndex != 4" [nzValue]="schedule.friday" nzLabel="Friday"></nz-option>
            <nz-option *ngIf="selectedDayIndex != 5" [nzValue]="schedule.saturday" nzLabel="Saturday"></nz-option>
            <nz-option *ngIf="selectedDayIndex != 6" [nzValue]="schedule.sunday" nzLabel="Sunday"></nz-option>
        </nz-select>
    </p>
</nz-modal>