import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AlertLevel } from './../level';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss']
})
export class AlertListComponent implements OnInit {

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any>;
  @Input() alerts: any;
  @Input() resolved: boolean;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.alerts = this.alerts.filter(x => {
      return this.resolved == ('resolved' in x && 'date' in x.resolved && x.resolved.date != null)
    });
    this.displayedColumns = this.resolved ? 
      ['level', 'resolved_date', 'resolved_user', 'event',  'action', 'location'] :
      ['level', 'date', 'event',  'action', 'location'];
    this.dataSource = new MatTableDataSource(this.alerts);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'resolved_date': return item.resolved.date;
        case 'resolved_user': return item.resolved.user;
        default: return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return !filter || filter.indexOf('' + data.level) >= 0;
    };
  }

  toggleSeverity(filter: AlertLevel[]) {
    this.dataSource.filter = '' + filter;
  } 
}
