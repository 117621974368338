<mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button mat-icon-button class="secondary-text">
                <mat-icon class="s-20">edit</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <!-- Pdf Column -->
    <ng-container matColumnDef="pdf">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
            <div *ngIf='row.resolved.pdf != null'>
                <button mat-icon-button class="secondary-text" (click)="downloadPdf(row.resolved.pdf)">
                    <mat-icon class="s-20">cloud_download</mat-icon>
                </button>
            </div>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="resolved_date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAINTENANCE.DATE' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.resolved.date | date:'shortDate'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAINTENANCE.DATE' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date | date:'shortDate'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tag">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAINTENANCE.TAG' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.tag}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="performed_by">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAINTENANCE.PERFORMED_BY' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.performed_by}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="visit_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAINTENANCE.VISIT_TYPE' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.visit_type}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="service_order_nr">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'MAINTENANCE.SERVICE_ORDER_NR' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.service_order_nr}} </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>