import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingService } from 'app/lib/services/branding/branding.service';

@Component({
  selector: 'projects-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnChanges {

  @Input() projects: any;
  // displayedColumns = ['code', 'name', 'location', 'type', 'kpi'];
  // displayedColumns = ['code', 'name', 'location', 'type'];
  displayedColumns = ['code', 'name', 'location'];

  constructor(private _router: Router, public brandingService: BrandingService) { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  openProject(project) {
    this._router.navigate(['project/' + project.code + '/plant/' + 'p'], { state: project});
  }
}
