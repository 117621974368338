export const maintenanceData: any[] = [
    // upcoming maintenances
    { date: new Date(2019, 9, 24), tag:'P658-03', visit_type:'Preventive maintenance', service_order_nr:'SEVPM270611' },
    { date: new Date(2019, 9, 26), tag:'AV642-05', visit_type:'Preventive maintenance', service_order_nr:'SEVPM270612' },
    { date: new Date(2019, 9, 27), tag:'AIT454', visit_type:'Instrument calibration', service_order_nr:'SEVPM270613' },
    { date: new Date(2019, 9, 24), tag:'P658-02', visit_type:'Preventive maintenance', service_order_nr:'SEVPM270789' },
    { date: new Date(2019, 9, 26), tag:'M687-05', visit_type:'Preventive maintenance', service_order_nr:'SEVPM277691' },
    { date: new Date(2019, 9, 27), tag:'M154-11', visit_type:'Preventive maintenance', service_order_nr:'SEVPM273597' },
    { date: new Date(2019, 9, 24), tag:'T254', visit_type:'Damage reparation', service_order_nr:'SEVPM259742' },
    { date: new Date(2019, 9, 26), tag:'FIT656', visit_type:'Instrument calibration', service_order_nr:'SEVPM278612' },
    { date: new Date(2019, 9, 27), tag:'LIT125', visit_type:'Instrument calibration', service_order_nr:'SEVPM254993' },
    // maintenances log 
    { date: new Date(2019, 9, 24), tag:'TAG126', performed_by:'Oscar Parada', visit_type:'Preventive maintenance', service_order_nr:'SEVPM370614', resolved:{ date: new Date(2019, 9, 24), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 22), tag:'TAG127', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Preventive maintenance', service_order_nr:'SEVPM370615', resolved:{ date: new Date(2019, 9, 22), pdf:'something.pdf'} },
    { date: new Date(2019, 9, 19), tag:'TAG128', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Instrument calibration', service_order_nr:'SEVPM370616', resolved:{ date: new Date(2019, 9, 19), pdf:'something.pdf'} },
    { date: new Date(2019, 9, 18), tag:'TAG129', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Instrument calibration', service_order_nr:'SEVPM370617', resolved:{ date: new Date(2019, 9, 18), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 24), tag:'TAG126', performed_by:'Oscar Parada', visit_type:'Instrument calibration', service_order_nr:'SEVPM345692', resolved:{ date: new Date(2019, 9, 24), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 22), tag:'TAG127', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Damage reparation', service_order_nr:'SEVPM456384', resolved:{ date: new Date(2019, 9, 22), pdf:'something.pdf'} },
    { date: new Date(2019, 9, 19), tag:'TAG128', performed_by:'Oscar Parada', visit_type:'Preventive maintenance', service_order_nr:'SEVPM446384', resolved:{ date: new Date(2019, 9, 19), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 18), tag:'TAG129', performed_by:'Oscar Parada', visit_type:'Preventive maintenance', service_order_nr:'SEVPM458384', resolved:{ date: new Date(2019, 9, 18), pdf:'something.pdf'} },
    { date: new Date(2019, 9, 24), tag:'TAG126', performed_by:'Oscar Parada', visit_type:'Preventive maintenance', service_order_nr:'SEVPM370614', resolved:{ date: new Date(2019, 9, 24), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 22), tag:'TAG127', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Damage reparation', service_order_nr:'SEVPM370615', resolved:{ date: new Date(2019, 9, 22), pdf:'something.pdf'} },
    { date: new Date(2019, 9, 19), tag:'TAG128', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Damage reparation', service_order_nr:'SEVPM370616', resolved:{ date: new Date(2019, 9, 19), pdf:'something.pdf'} },
    { date: new Date(2019, 9, 18), tag:'TAG129', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Preventive maintenance', service_order_nr:'SEVPM370617', resolved:{ date: new Date(2019, 9, 18), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 24), tag:'TAG126', performed_by:'Oscar Parada', visit_type:'Preventive maintenance', service_order_nr:'SEVPM345692', resolved:{ date: new Date(2019, 9, 24), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 22), tag:'TAG127', performed_by:'Jan Bos Van Hemelrijck', visit_type:'Damage reparation', service_order_nr:'SEVPM456384', resolved:{ date: new Date(2019, 9, 22), pdf:'something.pdf'} },
    { date: new Date(2019, 9, 19), tag:'TAG128', performed_by:'Oscar Parada', visit_type:'Preventive maintenance', service_order_nr:'SEVPM446384', resolved:{ date: new Date(2019, 9, 19), pdf: 'something.pdf'} },
    { date: new Date(2019, 9, 18), tag:'TAG129', performed_by:'Oscar Parada', visit_type:'Preventive maintenance', service_order_nr:'SEVPM458384', resolved:{ date: new Date(2019, 9, 18), pdf:'something.pdf'} }
];