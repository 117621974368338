<div class="dropzone-wrapper" ngClass="{{dragAreaClass}}" *ngIf="!_searchValue">
    <div class="messages-wrapper"
        (mouseenter)="onMessagesSeen($event)"
        infinite-scroll
        [scrollWindow]="false"
        [infiniteScrollUpDistance]="5"
        [infiniteScrollThrottle]="150"
        (scrolledUp)="onScrollUp($event)"
        (scroll)="onScroll($event)"
        #scrollMe [scrollTop]="scrolltop"
        >
        <ng-container *ngFor="let message of _messages, let i = index; trackBy: trackById" >
            <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
            <div class="chat-subject-info" *ngIf="message.subjectInfoState === subjectInfoState.showOpenedInfo || message.subjectInfoState === subjectInfoState.showBothInfo || i === firstMessageIndex">
                <span class="chat-subject-info-text">SUBJECT '{{message?.message?.SupportChat?.SupportSubject?.Title}}' WAS OPENED BY {{message?.message?.SupportChat?.AuthorInfo}}</span>
            </div>

            <div class="unread-messages" *ngIf="showNewMessagesSection(message)">
                <span>YOU HAVE UNREAD MESSAGES</span>
            </div>
            <app-message [message]="message"></app-message>

            <div class="chat-subject-info" *ngIf="(message.subjectInfoState === subjectInfoState.showClosedInfo || message.subjectInfoState === subjectInfoState.showBothInfo) && message?.message?.SupportChat?.StopDate">
                <span class="chat-subject-info-text">SUBJECT '{{message?.message?.SupportChat?.SupportSubject?.Title}}' WAS CLOSED BY {{message?.message?.SupportChat?.ClosedBy}}</span>
            </div>
        </ng-container>

        
        <ng-container *ngFor="let message of newMessages">
            <app-message [message]="message"></app-message>
        </ng-container>
        <app-message-file-upload *ngIf="isFileUploading" [state]="fileState"></app-message-file-upload>

    </div>
    <div class="subject-back-btn-wrapper" *ngIf="chatState?.isChatHistoryOpened">
        <button class="subject-back-btn" (click)="goToSubjectsSelect()">GO BACK</button>
    </div>
    <button class="back-to-bottom" *ngIf="_isBackToBottomDisplayed && _messages.length" (click)="scrollToBottom()">
        <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <div class="chat-spinner" *ngIf="isSpinnerShown">
        <mat-spinner *ngIf="isSpinnerShown" [diameter]="50"></mat-spinner>
    </div>
</div>

<app-search-messages *ngIf=" _searchValue" [searchMetaData]="_searchMetaData"></app-search-messages>