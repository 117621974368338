import { Injectable } from '@angular/core';
import { SmartlabPfdUnitLinksWhere, SmartlabPfdUnitLinksWhereGQL, OrderBy } from 'app/graphql';
import { SmartlabService } from '../smartlab/smartlab.service';
import { Block } from 'app/lib/interfaces';
import { LaboService } from '../labo/labo.service';
import * as moment from 'moment-timezone';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { Tag } from 'app/lib/interfaces/labo.interface';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    public cachedProject = null;
    public cachedPfdUnitLinks: SmartlabPfdUnitLinksWhere.SmartlabPfdUnitLinks[] = [];
    public cachedProjectUnits: Block[] = [];
    public cachedProjectPFDTags: Map<number, Tag> = new Map<number, Tag>();

    public currentUpdateIsRunning = true;

    public timezoneString = '';

    constructor(private smartlabPfdUnitLinksWhereGQL: SmartlabPfdUnitLinksWhereGQL,
        private _smartlabService: SmartlabService,
        private _laboService: LaboService) { }


    async updateAndCachePFDUnits(projectId: string) {

        this.currentUpdateIsRunning = true;

        this.cachedPfdUnitLinks = [];
        this.cachedProjectUnits = [];

        return new Promise<Array<Block>>((resolve, reject) => {

            const pfdProjectUnits = new Array<Block>();

            // Fetch all PFD For project

            this._smartlabService.getPFDForProject(projectId).toPromise().then(results => {

                console.log(`getPFDUnitsForProjectId results:`, results);

                const pfdUnitFilterOr = [];
                this.cachedProjectPFDTags = new Map<number, Tag>();
                
                // Get Project PFD Tags

                for (const [key, value] of Object.entries(results[`projectTags`])) {
                    const projectTag = value as Tag;
                    this.cachedProjectPFDTags.set(parseInt(key), projectTag);
                }

                console.log(`getPFDUnitsForProjectId projectTags:`, this.cachedProjectPFDTags);

                // Filter out PfdProjectUnits

                for (const [key, value] of Object.entries(results[`blocks`])) {
                    const block = value as Block;

                    // console.log(block);

                    pfdProjectUnits.push(block);
                    // Push a filter array for all definitionId's
                    pfdUnitFilterOr.push(
                        {
                            PFDUnitId: {
                                _eq: block.definitionId.toString()
                            }
                        });
                }

                const additionalPFDUnitIds = ["133", "170", "171"];

                additionalPFDUnitIds.forEach(id => {
                    pfdUnitFilterOr.push(
                        {
                            PFDUnitId: {
                                _eq: id
                            }
                        });
                });

                // console.log(`getPFDUnitsForProjectId filtered:`, pfdProjectUnits);
                // console.log(`pfdUnitFilterOr:`, pfdUnitFilterOr);

                // Fetch all links for given PFDUnitBlocks which are filtered

                this.smartlabPfdUnitLinksWhereGQL.fetch({
                    SmartlabPFDUnitLinks_bool_exp: {
                        _or: pfdUnitFilterOr
                    },
                    SmartlabPFDUnitLinks_order_by: [{
                        Order: OrderBy.Desc
                    }]
                }, {
                    fetchPolicy: "no-cache"
                }).toPromise().then(result => {
                    // Filter out PfdProjectUnits without Links
                    //   console.log(`smartlabPfdUnitLinksWhereGQL`, result.data.SmartlabPFDUnitLinks);
                    const tempPfdProjectUnitsFiltered = pfdProjectUnits.filter(x => result.data.SmartlabPFDUnitLinks.map(y => y.PFDUnitId).includes(x.definitionId.toString()));
                    //   console.log(`tempPfdProjectUnitsFiltered`, tempPfdProjectUnitsFiltered);

                    this.cachedPfdUnitLinks = result.data.SmartlabPFDUnitLinks;
                    this.cachedProjectUnits = tempPfdProjectUnitsFiltered;

                    this.currentUpdateIsRunning = false;
                    resolve(this.cachedProjectUnits);

                })
                    .catch(err => {
                        this.updateAndCachePFDUnitsHandleError(err, projectId);
                    });
            })
                .catch(err => {
                    this.updateAndCachePFDUnitsHandleError(err, projectId);
                });
        })
            .catch(err => {
                this.updateAndCachePFDUnitsHandleError(err, projectId);
            })

    }

    updateAndCachePFDUnitsHandleError(err, projectId: string) {
        console.log(`could not fetch PFD Units, trying again in 5 seconds...`, err);
        // todo: Better error handling
        setTimeout(() => {
            return this.updateAndCachePFDUnits(projectId);
        }, 5000);
    }

    getProjectUnit(technology: string) {

        console.log(`--- getCachedProjectUnits`, technology);
        console.log(`this.cachedPfdUnitLinks`, this.cachedProjectUnits);

        const result = this.cachedProjectUnits.filter(o => o.id.toString() == technology)[0];

        if (result) {
            return result;
        } else {
            return null;
        }
    }

    updateProjectLocalDateAndTime() {
        this.timezoneString = this.cachedProject[`locations`][0][`timeZone`];

        // weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
        moment.updateLocale('en', {
            week: {
                dow: DAYS_OF_WEEK.MONDAY,
                doy: 0,
            },
        });

        moment.tz.setDefault(this.timezoneString);
    }

    getTimezoneOffsetMinutes(date: Date) {
        // This puts the default timezone back to local
        // This is needed because otherwise moment() would not give back the correct results,
        // when this function has been called already
        moment.tz.setDefault();

        var timezoneOffsetMinutes = moment.tz(this.timezoneString).utcOffset() - moment(date).utcOffset();

        // weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
        moment.updateLocale('en', {
            week: {
                dow: DAYS_OF_WEEK.MONDAY,
                doy: 0,
            },
        });

        moment.tz.setDefault(this.timezoneString);

        return timezoneOffsetMinutes;
    }

    
    public addProjectTimeZoneOffset(date: Date) {
        return moment(date).add(this.getTimezoneOffsetMinutes(date), 'm').toDate();
    }

    public removeProjectTimeZoneOffset(date: Date) {
        return moment(date).add(-this.getTimezoneOffsetMinutes(date), 'm').toDate();
    }

    public getTimeZoneString() {
        return this.timezoneString;
    }
    
    getProjectLocalDateAndTime() {
        return moment(new Date()).format("dddd LLL");
    }

    getPFDUnitLinks(pfdUnitId: string) {

        console.log(`--- getPFDUnitLinks`, pfdUnitId);
        console.log(`this.cachedPfdUnitLinks`, this.cachedPfdUnitLinks);

        const filteredUnitLinks = this.cachedPfdUnitLinks.filter(o => o.PFDUnitId == pfdUnitId);

        console.log(`filteredUnitLinks`, filteredUnitLinks);

        return filteredUnitLinks;
    }

    getProjectUnitsForTarget(target: string) {

        const filteredProjectUnitIds = [];

        this.cachedPfdUnitLinks.forEach(pfdUnitLink => {
            if (pfdUnitLink.Targets !== null) {
                const targets = pfdUnitLink.Targets as Array<string>;
                if (targets.includes(target)) {
                    if (!filteredProjectUnitIds.includes(pfdUnitLink.PFDUnitId)) {
                        filteredProjectUnitIds.push(pfdUnitLink.PFDUnitId);
                    }
                }
            }
        });

        // console.log(`getProjectUnitsForTarget: ${target}`, filteredProjectUnitIds);

        return this.cachedProjectUnits.filter(x => filteredProjectUnitIds.includes(x.definitionId.toString()));

    }

    updateLaboService(projectCode: string) {
        this._laboService.updateSamplingPoints(projectCode);
    }
}
