import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'maintenance-list',
  templateUrl: './maintenance-list.component.html',
  styleUrls: ['./maintenance-list.component.scss']
})
export class MaintenanceListComponent implements OnInit {

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any>;
  @Input() maintenances: any;
  @Input() resolved: boolean;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() { }

  ngOnInit() {
    this.maintenances = this.maintenances.filter(x => {
      return this.resolved == ('resolved' in x && 'date' in x.resolved && x.resolved.date != null)
    });
    this.displayedColumns =  this.resolved ?
        ['pdf', 'resolved_date', 'performed_by',  'visit_type', 'service_order_nr']:
        ['edit', 'date', 'tag',  'visit_type', 'service_order_nr'];
    this.dataSource = new MatTableDataSource(this.maintenances);
    this.dataSource.sort = this.sort;
  }

  downloadPdf(pdf) {
    // NYI
  }
}
