<div class="page-layout simple fullwidth sl-page-tab-container" fusePerfectScrollbar>
    <nz-tabset class="sl-tab-fullwidth" [nzType]="'card'" *ngIf="dataLoaded">
        <nz-tab #brandedTab *ngFor="let pfdUnitLink of pfdUnitLinks" [nzTitle]="titleTemplate">

            <ng-template #titleTemplate>
                <span
                    [style.color]="(brandedTab.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">{{ pfdUnitLink.Name }}</span>
            </ng-template>

            <cumulio-dashboard [dashboard]="pfdUnitLink.Link" [language]="'en'"
                [key]="currentKeys.get(pfdUnitLink.Link + technology)"
                [token]="currentTokens.get(pfdUnitLink.Link + technology)" [screenmode]="'largeScreen'">
            </cumulio-dashboard>
        </nz-tab>

    </nz-tabset>

    <div class="center-spinner-container" *ngIf="!dataLoaded && !projectService.currentUpdateIsRunning">
        <!-- <mat-spinner color="accent" diameter="50"></mat-spinner>
        <div class="center-spinner-text">{{ loadingText }}</div> -->

        <ngx-spinner name="technologiesSpinner"
            bdColor="rgba(0,0,0,0)" size="medium" [color]="brandingService.getPrimaryColor()" type="ball-clip-rotate" fullscreen="false">
            <div class="center-spinner-text">{{ loadingText }}</div>
        </ngx-spinner>
    </div>


</div>