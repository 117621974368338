import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SupportChatMessages, SupportChatMessagesWhereGQL, Order_By, SupportSubjectsWhereGQL, SupportSubjects, ProjectGroupsSmartlabUsers, ProjectGroupsSmartlabUsersWhereGQL, SmartlabUsersWhereGQL, SupportChats, SupportChatMessagesSubscriptionGQL, SupportChatMessagesSubscriptionSubscription, SmartlabUsers, ProjectGroupsSmartlabUsersSubscriprionGQL, SupportChatsWhereGQL } from '../graphql';
import { IRequestChat } from '../models/request-chat.model';
import { IRequestChatMessages } from '../models/request-chat-messages.model';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
  private _chatMessages: Subject<SupportChatMessages[]> = new Subject<SupportChatMessages[]>();
  private _supportSubjects: ReplaySubject<SupportSubjects[]> = new ReplaySubject<SupportSubjects[]>(1);
  private _projectGroupSmartlabUsers: ReplaySubject<ProjectGroupsSmartlabUsers[]> = new ReplaySubject<ProjectGroupsSmartlabUsers[]>(1);
  private _supportChat: Subject<SupportChats[]> = new Subject<SupportChats[]>();
  private _supportChatMessagesSubscription: Subject<SupportChatMessagesSubscriptionSubscription> = new Subject<SupportChatMessagesSubscriptionSubscription>();
  private _supportChatMessagesBeforeFilterDateSubscription: Subject<SupportChatMessagesSubscriptionSubscription> = new Subject<SupportChatMessagesSubscriptionSubscription>();
  private _supportChatMessagesAfterFilterDateSubscription: Subject<SupportChatMessagesSubscriptionSubscription> = new Subject<SupportChatMessagesSubscriptionSubscription>();
  private _smartlabUsers: ReplaySubject<SmartlabUsers[]> = new ReplaySubject<SmartlabUsers[]>(1);
  private _projectGroupSmartlabUsersSubscriprion: Subject<ProjectGroupsSmartlabUsers[]> = new Subject<ProjectGroupsSmartlabUsers[]>();

  constructor( private _supportChatMessagesWhereGQL: SupportChatMessagesWhereGQL,
        private _supportSubjectsWhereGQL: SupportSubjectsWhereGQL,
        private _projectGroupSmartlabUsersGQL: ProjectGroupsSmartlabUsersWhereGQL,
        private _supportChatGQL: SupportChatsWhereGQL,
        private _supportChatMessagesSubscriptionGQL: SupportChatMessagesSubscriptionGQL,
        private _smartlabUsersWhereGQL: SmartlabUsersWhereGQL,
        private _projectGroupSmartlabUsersSubscriprionGQL: ProjectGroupsSmartlabUsersSubscriprionGQL,
        ) { }

   set chatMessages(value: SupportChatMessages[]) {
    // Store the value
    this._chatMessages.next(value);
  }

  get chatMessages$(): Observable<SupportChatMessages[]> {
    return this._chatMessages.asObservable();
  }

  get supportChatSubjects(): Observable<SupportSubjects[]> {
    return this._supportSubjects.asObservable()
  }

  get projectGroupSmartlabUsers$(): Observable<ProjectGroupsSmartlabUsers[]> {
    return this._projectGroupSmartlabUsers.asObservable();
  }

  get projectGroupSmartlabUsersSubscription$(): Observable<ProjectGroupsSmartlabUsers[]> {
    return this._projectGroupSmartlabUsersSubscriprion.asObservable();
  }

  get supportChat$(): Observable<SupportChats[]> {
    return this._supportChat.asObservable();
  }

  get chatMessagesSubscription$(): Observable<SupportChatMessagesSubscriptionSubscription> {
    return this._supportChatMessagesSubscription.asObservable();
  }

  get supportChatMessagesBeforeFilterDateSubscription(): Observable<SupportChatMessagesSubscriptionSubscription> {
    return this._supportChatMessagesBeforeFilterDateSubscription.asObservable();
  }

  get supportChatMessagesAfterFilterDateSubscription(): Observable<SupportChatMessagesSubscriptionSubscription> {
    return this._supportChatMessagesAfterFilterDateSubscription.asObservable();
  }

  get smartlabUsers$(): Observable<SmartlabUsers[]> {
    return this._smartlabUsers.asObservable();
  };


  getSearchMessages(request: IRequestChatMessages): Observable<any> {
    return this._supportChatMessagesWhereGQL
    .fetch(
      {
        SupportChatMessages_bool_exp: {SupportChat: {ProjectGroupId: {_eq: request.projectId}}, Deleted: {_eq: request.isDeleted}, TextValue: {_iregex: request.searchString}},
        SupportChatMessages_order_by: [{CreatedDate: Order_By.Desc}],
        limit: request.limit,
        offset: request.offsetMessages
      },
      { fetchPolicy: "no-cache" })
    .pipe(
      tap((response) => {
        console.log(`getSearchMessages: `, response);
        this._chatMessages.next(response.data.SupportChatMessages as any);
      })
    );
  }

  getSupportSubjects(): Observable<any> {
    return this._supportSubjectsWhereGQL
    .fetch({
      SupportSubjects_bool_exp: {},
      SupportSubjects_order_by: {
        Order: Order_By.Asc
      },
      SupportChats_bool_exp: {},
      SupportChats_order_by: [{}]
    },
    { fetchPolicy: "no-cache" })
    .pipe(
      tap((response) => {
        console.log(`getSupportSubjects: `, response);
        this._supportSubjects.next(response.data.SupportSubjects as any);
      })
    );
  }

  getOperatorProjectsWithSubjects(userId: string): Observable<any> {
    return this._projectGroupSmartlabUsersGQL
    .fetch(
      {
        ProjectGroupsSmartlabUsers_bool_exp: {SmartlabUserId: {_eq: userId}},
        ProjectGroupSupportChatMessages_bool_exp: {}
      },
      { fetchPolicy: "no-cache" })
    .pipe(
      tap((response) => {
        console.log(`getOperatorProjectsWithSubjects: `, response);
        this._projectGroupSmartlabUsers.next(response.data.ProjectGroupsSmartlabUsers as any);
      })
    );
  }

  getProjectsSubscription(projectGroupId: string) {
    return this._projectGroupSmartlabUsersSubscriprionGQL.subscribe({
      ProjectGroupsSmartlabUsers_bool_exp: {ProjectGroupId: {_eq: projectGroupId}},
      SupportChats_bool_exp: { StopDate: {_is_null: true } },
      ProjectGroupSupportChatMessages_bool_exp: {}
    }, {
      fetchPolicy: "no-cache"
    }).subscribe((responce: any) => {
      console.log("_projectGroupSmartlabUsersSubscription:",  responce.data.ProjectGroupsSmartlabUsers)
      this._projectGroupSmartlabUsersSubscriprion.next(responce.data.ProjectGroupsSmartlabUsers)
    })
  }

  getSupportChat(request: IRequestChat): Observable<any> {
    return this._supportChatGQL.fetch({
      SupportChats_bool_exp:  { _and: [ 
        {ProjectGroupId: {_eq: request.projectGroupId}}, 
        {SupportSubjectId: {_eq: request.subjectId}}
      ]},
      SupportChatMessages_order_by: [{CreatedDate: Order_By.Desc}],
      ChatMessages_bool_exp: {Deleted: {_eq: request.isDeleted}},
      limit: request.limit,
      offset: request.offset
    },
      { fetchPolicy: "no-cache" })
      .pipe(
        tap((response) => {
          console.log("getSupportChat", response.data.SupportChats);
          this._supportChat.next(response.data.SupportChats as any)
        })
    );
  }

  getChatmessagesSubscription(request: IRequestChatMessages) {
    let boolExpr;
    let orderBy;

    if (request.filterDate) {
      boolExpr = {SupportChatMember: {SupportChat: {ProjectGroupId: {_eq: request.projectId}}}, Deleted: {_eq: request.isDeleted}, CreatedDate: {_gte: request.filterDate}};
      orderBy = [{CreatedDate: Order_By.Desc}];
    } else {
      boolExpr = {SupportChatMember: {SupportChat: {ProjectGroupId: {_eq: request.projectId}}}, Deleted: {_eq: request.isDeleted}};
      orderBy = [{CreatedDate: Order_By.Desc}];
    }
    
    return this._supportChatMessagesSubscriptionGQL.subscribe({
      SupportChatMessages_bool_exp: boolExpr,
      SupportChatMessages_order_by: orderBy,
      limit: request.limit
    }, {
      fetchPolicy: "no-cache"
    }).subscribe((responce: any) => {
      console.log("getChatmessagesSubscription:",  responce.data)
      this._supportChatMessagesSubscription.next(responce.data.SupportChatMessages)
    })
  }

  getChatmessagesBeforeFilterDateSubscription(request: IRequestChatMessages) {
    return this._supportChatMessagesWhereGQL.fetch({
      SupportChatMessages_bool_exp: {SupportChatMember: {SupportChat: {ProjectGroupId: {_eq: request.projectId}}}, Deleted: {_eq: request.isDeleted}, CreatedDate: {_lte: request.filterDate}},
      SupportChatMessages_order_by: [{CreatedDate: Order_By.Desc}],
      limit: request.limit
    }, {
      fetchPolicy: "no-cache"
    }).pipe(
      tap((responce: any) => {
      console.log("getChatmessagesBeforeFilterDateSubscription:",  responce.data)
      this._supportChatMessagesBeforeFilterDateSubscription.next(responce.data.SupportChatMessages)
      })
    );
  }

  getChatmessagesAfterFilterDateSubscription(request: IRequestChatMessages) {
    return this._supportChatMessagesSubscriptionGQL.subscribe({
      SupportChatMessages_bool_exp: {SupportChatMember: {SupportChat: {ProjectGroupId: {_eq: request.projectId}}}, Deleted: {_eq: request.isDeleted}, CreatedDate: {_gte: request.filterDate}},
      SupportChatMessages_order_by: [{CreatedDate: Order_By.Desc}]
    }, {
      fetchPolicy: "no-cache"
    }).subscribe((responce: any) => {
      console.log("getChatmessagesAfterFilterDateSubscription:",  responce.data)
      this._supportChatMessagesAfterFilterDateSubscription.next(responce.data.SupportChatMessages)
    })
  }

  getFinishedSubjectChatMessagesSubscription(request: IRequestChatMessages) {
    return this._supportChatMessagesSubscriptionGQL.subscribe({
        SupportChatMessages_bool_exp: {SupportChatMember: {SupportChat: {ProjectGroupId: {_eq: request.projectId}}}, Deleted: {_eq: request.isDeleted}},
        SupportChatMessages_order_by: [{CreatedDate: Order_By.Desc}],
        limit: request.limit
      }, {
        fetchPolicy: "no-cache"
      }).subscribe((responce: any) => {
        console.log("getFinishedSubjectChatMessagesSubscription:",  responce.data);
        this._supportChatMessagesSubscription.next(responce.data.SupportChatMessages);
      })
  }

  getUserByAuthId(authId: string) {
    return this._smartlabUsersWhereGQL.fetch(
      {
        SmartlabUsers_bool_exp: {UserId: {_eq: authId}}
      },
      { fetchPolicy: "no-cache" })
    .pipe(
      tap((response) => {
        console.log(`getUserByAuthId: `, response);
        this._smartlabUsers.next(response.data.SmartlabUsers as any);
      })
    )
  }
}
