<div class="sl-page-tab-content card-container">

    <div class="date_select_container">

        <div class="overview-selection-desktop">
            <mat-button-toggle-group name="overviewSelection" [(value)]="overviewSelection"
                aria-label="Overview Selection">
                <mat-button-toggle value="sp">Sampling Point</mat-button-toggle>
                <mat-button-toggle value="analysis">Analysis</mat-button-toggle>
            </mat-button-toggle-group>

            <!-- <input nz-input class="overview-selection-filter"  placeholder="Filter" [(ngModel)]="overviewFilter"
                (ngModelChange)="onSearch()" /> -->

            <nz-input-group [nzSuffix]="inputClearTpl" class="overview-selection-filter">
                <input type="text" nz-input [(ngModel)]="overviewFilter" (ngModelChange)="onSearch()"
                    placeholder="Filter" />
            </nz-input-group>
            <ng-template #inputClearTpl><i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle"
                    *ngIf="overviewFilter" (click)="clearSearch()"></i></ng-template>


        </div>

        <div class="overview-date-select">
            <button mat-mini-fab color="accent" aria-label="previous" (click)="reloadDayEvent.emit(-1)"
                [style.background]="brandingService.getPrimaryColor()">
                <mat-icon class="date_button_icon">chevron_left</mat-icon>
            </button>

            <span class="date-select-title">
                <!-- {{ selectedDateString | date : 'EEEE'}} -->
                <!-- {{ selectedDateString | date : 'dd-MM-yyyy'}}  -->

                <nz-date-picker nzSize="large" class="date-picker" nzAllowClear="false" [(ngModel)]="laboData == null ? '' : laboData.selectedDateString"
                    (ngModelChange)="reloadDayEvent.emit($event)" nzFormat="EEEE dd-MM-yyyy '(Week' ww')'"></nz-date-picker>

                <!-- (Week {{ selectedDateString | week }}) -->
            </span>

            <button mat-mini-fab color="accent" aria-label="previous" (click)="reloadDayEvent.emit(1)"
                [style.background]="brandingService.getPrimaryColor()">
                <mat-icon class="date_button_icon">chevron_right</mat-icon>
            </button>
        </div>      
    </div>

    <div *ngIf="laboData == null || !laboData.dataLoaded" class="center-spinner-tab" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>

    <ng-container *ngIf="laboData && laboData.dataLoaded">

        <div #laboOverviewContainer class="lo-content">

            <ng-container *ngIf="overviewSelection === 'sp'">

                <nz-tabset style="height:auto;" [nzShowPagination]="deviceService.isDesktop() ? false : true"
                    *ngIf="laboData && laboData.dataLoaded" id="sl-vertical-tabset"
                    [nzTabPosition]="deviceService.isDesktop() ? 'left' : 'top'"
                    [(nzSelectedIndex)]="selectedTabIndexSP">
                    <nz-tab #brandedTab [nzTitle]="slLeftTabsetTabTitle"
                        *ngFor="let samplingPoint of samplingPointColumnsFiltered | keyvalue: sortColumnByOrder">
                        <ng-template #slLeftTabsetTabTitle>
                            <ng-container *ngIf="deviceService.isDesktop()">
                                <div class="sl-vertical-tab">
                                    <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center"
                                        [style.color]="(brandedTab.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">
                                        {{ samplingPoint.value.name }}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!deviceService.isDesktop()">
                                {{ samplingPoint.value.name }}
                            </ng-container>
                        </ng-template>

                        <div></div>

                        <div class="sl-right-content">
                            <span class="sl-right-content-title">{{ samplingPoint.value.name }}</span>

                            <nz-card class="sl-card" [style.margin-top]="'20px'" [nzBordered]="true"
                                [nzTitle]="slCardTitleTemplateSP">
                                <ng-template #slCardTitleTemplateSP>
                                    <div class="sl-card-title">
                                        Value per scheduled Analysis
                                    </div>
                                    <!-- <div class="sl-card-subtitle">
                                        Lorem Ipsum
                                    </div> -->
                                </ng-template>

                                <div class="value-per-scheduled-row">

                                    <div class="value-per-scheduled value-per-scheduled-title">
                                        <div fxFlex="55" fxLayout="row" fxLayoutAlign="start center">
                                            Name
                                        </div>
                                        <div fxFlex="25" fxLayout="row" fxLayoutAlign="start end">
                                            Value
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            LL
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            L
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            H
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            HH
                                        </div>
                                    </div>
                                </div>

                                <div class="value-per-scheduled-row"
                                    *ngFor="let analysisId of samplingPoint.value.planned.analysisIds">
                                    <ng-container *ngIf="getTag(analysisId) as tag">

                                        <div class="value-per-scheduled">
                                            <div fxFlex="55" fxLayout="row" fxLayoutAlign="start center">
                                                {{ tag.name }}
                                            </div>
                                            <div class="value-per-scheduled-value" fxFlex="25" fxLayout="row"
                                                fxLayoutAlign="start end">
                                                <span
                                                    *ngIf="getSamplingPointData(samplingPoint.value.idProj, samplingPoint.value.planned, laboData.selectedDate, analysisId);else hasNoAnalysisPointData; let spData">

                                                    <ng-container
                                                        *ngIf="checkHasTagLimits(tag); else noLimits">

                                                        <span [class]="getTagLimitClass(spData.value, tag)">
                                                            <b>{{ spData.value }}&nbsp;</b>{{ tag.unit }}
                                                        </span>

                                                    </ng-container>

                                                    <ng-template #noLimits>
                                                        <span class="true">
                                                            <b>{{ spData.value }}&nbsp;</b>
                                                            {{ tag.unit }}
                                                        </span>
                                                    </ng-template>

                                                </span>
                                                <ng-template #hasNoAnalysisPointData>
                                                    <!-- Has no data -->
                                                    <b>-&nbsp;</b>
                                                    {{ tag.unit }}
                                                </ng-template>
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_ll }}
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_l }}
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_h }}
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_hh }}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </nz-card>
                        </div>

                    </nz-tab>

                </nz-tabset>
            </ng-container>

            <ng-container *ngIf="overviewSelection === 'analysis'">

                <nz-tabset *ngIf="laboData && laboData.dataLoaded" id="sl-vertical-tabset"
                    [nzTabPosition]="deviceService.isDesktop() ? 'left' : 'top'"
                    [(nzSelectedIndex)]="selectedTabIndexAnalysis">
                    <nz-tab #brandedTab [nzTitle]="slLeftTabsetTabTitle" *ngFor="let tag of tagsArrayFiltered">
                        <ng-template #slLeftTabsetTabTitle>
                            <ng-container *ngIf="deviceService.isDesktop()">
                                <div class="sl-vertical-tab">
                                    <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center"
                                        [style.color]="(brandedTab.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">
                                        {{ tag.name }}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!deviceService.isDesktop()">
                                {{ tag.name }}
                            </ng-container>
                        </ng-template>

                        <div></div>

                        <div class="sl-right-content">
                            <span class="sl-right-content-title">{{ tag.name }}</span>

                            <nz-card class="sl-card" [style.margin-top]="'20px'" [nzBordered]="true"
                                [nzTitle]="slCardTitleTemplateAnalysis">
                                <ng-template #slCardTitleTemplateAnalysis>
                                    <div class="sl-card-title">
                                        Value per scheduled Sampling Point
                                    </div>
                                    <!-- <div class="sl-card-subtitle">
                                    Lorem Ipsum
                                </div> -->
                                </ng-template>

                                <div class="value-per-scheduled-row">

                                    <div class="value-per-scheduled value-per-scheduled-title">
                                        <div fxFlex="55" fxLayout="row" fxLayoutAlign="start center">
                                            Name
                                        </div>
                                        <div fxFlex="25" fxLayout="row" fxLayoutAlign="start end">
                                            Value
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            LL
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            L
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            H
                                        </div>
                                        <div fxFlex="12" fxLayout="row" fxLayoutAlign="start center">
                                            HH
                                        </div>
                                    </div>
                                </div>

                                <div class="value-per-scheduled-row"
                                    *ngFor="let samplingPoint of laboData.samplingPointColumns | keyvalue: sortColumnByOrder">
                                    <ng-container
                                        *ngIf="samplingPoint.value.planned.analysisIds.includes(tag.id)">

                                        <div class="value-per-scheduled">
                                            <div fxFlex="55" fxLayout="row" fxLayoutAlign="start center">
                                                {{ samplingPoint.value.name }}
                                            </div>
                                            <div class="value-per-scheduled-value" fxFlex="25" fxLayout="row"
                                                fxLayoutAlign="start end">
                                                <span
                                                    *ngIf="getSamplingPointData(samplingPoint.value.idProj, samplingPoint.value.planned, laboData.selectedDate, tag.id);else hasNoAnalysisPointData; let spData">

                                                    <ng-container *ngIf="checkHasTagLimits(tag); else noLimits">

                                                        <span [class]="getTagLimitClass(spData.value, tag)">
                                                            <b>{{ spData.value }}&nbsp;</b>{{ tag.unit }}
                                                        </span>

                                                    </ng-container>

                                                    <ng-template #noLimits>
                                                        <span class="true">
                                                            <b>{{ spData.value }}&nbsp;</b>
                                                            {{ tag.unit }}
                                                        </span>
                                                    </ng-template>

                                                </span>
                                                <ng-template #hasNoAnalysisPointData>
                                                    <!-- Has no data -->
                                                    <b>-&nbsp;</b>
                                                    {{ tag.unit }}
                                                </ng-template>
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_ll }}
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_l }}
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_h }}
                                            </div>
                                            <div fxFlex="12" class="value-per-scheduled-limit" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ tag.limit_hh }}
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>

                            </nz-card>
                        </div>

                    </nz-tab>

                </nz-tabset>
            </ng-container>
        </div>

    </ng-container>

</div>