import { SupportChatMessages, SupportChatMessagesSubscriptionSubscription } from "../graphql";
import { IMessageData } from "../models/message-data-model";
import { SUBJECT_INFO_STATE } from "./constants";

export const MessagesMapper = (messages: SupportChatMessagesSubscriptionSubscription[]): IMessageData[] => {
    let resultMessages: IMessageData[] = [];

    messages.forEach((message: any, i: number) => {
      let checkMessageStopDate = !resultMessages.length && message.SupportChat?.StopDate;
      let compareMessageIdBool = message.SupportChatId !== resultMessages[0]?.message?.SupportChatId || checkMessageStopDate;
                
      let _isImage = filterMessageByFileItemType(message);

      if(compareMessageIdBool && resultMessages.length) {
        let firstSubjectMessage = resultMessages[0].message;
        let _isFirstMessageImage = filterMessageByFileItemType(<SupportChatMessages><unknown>resultMessages[0]);
        let checkIfBothInfo = resultMessages[0].subjectInfoState === SUBJECT_INFO_STATE.showClosedInfo && message.SupportChat?.StopDate ? SUBJECT_INFO_STATE.showBothInfo : SUBJECT_INFO_STATE.showOpenedInfo;

        let firstSubjectMessageInfo: IMessageData = {
          subjectInfoState: checkIfBothInfo,
          isImage: _isFirstMessageImage,
          message: firstSubjectMessage
        };

        let lastSubjectMessageInfo: IMessageData = {
          subjectInfoState: SUBJECT_INFO_STATE.showClosedInfo,
          isImage: _isImage,
          message: message
        };

        resultMessages[0] = firstSubjectMessageInfo;
        resultMessages.unshift(lastSubjectMessageInfo);

      } else {
        
        if(checkMessageStopDate && i === 0) {
          let lastSubjectMessageInfo: IMessageData = {
            subjectInfoState: SUBJECT_INFO_STATE.showClosedInfo,
            isImage: _isImage,
            message: message
          };

          resultMessages.unshift(lastSubjectMessageInfo);
        } else {
          let messageInfo: IMessageData = {
            isImage: _isImage,
            message: message
          };

          resultMessages.unshift(messageInfo);
        }
      }
    });

    return resultMessages;
};

export const filterMessageByFileItemType = (message: SupportChatMessages): boolean => {
    if(!message.FileItem) return false;
    
    if(message.FileItem.FileTypeId === 0) {
      return true;
    }

    return false; 
}