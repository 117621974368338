<div class="page-layout simple fullwidth sl-page-tab-container" fusePerfectScrollbar>
    <nz-tabset class="sl-tab-fullwidth" [nzType]="'card'" nzSelectedIndex="0">
        <nz-tab *ngIf="_authService.userHasPermissions(['read:labo_overview'])" #brandedTab [nzTitle]="titleTemplate">

            <ng-template #titleTemplate>
                <span [style.color]="(brandedTab.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Overview</span>
            </ng-template>

            <app-labo-overview [dataChanged]="dataReloadedSubject.asObservable()" (silentReloadEvent)="silentLoadDataForDay()" (reloadDayEvent)="loadDataForDay($event)"></app-labo-overview>
        </nz-tab>
        <nz-tab *ngIf="_authService.userHasPermissions(['read:labo_input'])" #brandedTab [nzTitle]="titleTemplate">

            <ng-template #titleTemplate>
                <span [style.color]="(brandedTab.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Input</span>
            </ng-template>

            <app-labo-input [dataChanged]="dataReloadedSubject.asObservable()" (silentReloadEvent)="silentLoadDataForDay()" (reloadDayEvent)="loadDataForDay($event)"></app-labo-input>
        </nz-tab>
        <nz-tab *ngIf="_authService.userHasPermissions(['read:labo_states'])" #brandedTab [nzTitle]="titleTemplate">

            <ng-template #titleTemplate>
                <span [style.color]="(brandedTab.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Schedule</span>
            </ng-template>

            <app-labo-schedule [dataChanged]="dataReloadedSubject.asObservable()" (silentReloadEvent)="silentLoadDataForDay()" (reloadDayEvent)="loadDataForDay($event)"></app-labo-schedule>
        </nz-tab>
    </nz-tabset>
</div>