import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class ChatService {
  private _isSendLocationOpened$: Subject<boolean> = new Subject<boolean>();
  private _searchValue$: ReplaySubject<string> = new ReplaySubject<string>();
  private _firstMessageFilterDate: Subject<Date> = new Subject<Date>();
  private _sendMessageHovered: Subject<boolean> = new Subject<boolean>();

  public searchMessageId$ = new Subject<string>();
  
  constructor() { }

  public get isSendLocationOpened(): Observable<boolean> {
    return this._isSendLocationOpened$;
  }

  public get searchValue(): Observable<string> {
    return this._searchValue$;
  }

  public get firstMessageFilterDate(): Observable<Date> {
    return this._firstMessageFilterDate;
  }

  public get isSendMessageHovered(): Observable<boolean> {
    return this._sendMessageHovered;
  }

  public setIsSendLocationOpened(value: boolean) {
    this._isSendLocationOpened$.next(value);
  }

  public setSearchValue(value: string) {
    this._searchValue$.next(value);
  }

  public setFirstMessageFilterDate(date: Date): void {
    this._firstMessageFilterDate.next(date);
  }

  public setSendMessageHovered(value: boolean) {
    this._sendMessageHovered.next(value);
  }
}
