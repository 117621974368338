<!-- <ngx-spinner name="loadProjectSpinner" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 16px; color: white">Loading project data...</p>
</ngx-spinner> -->


<div class="page-layout simple fullwidth sl-page-tab-container" fusePerfectScrollbar>

    <!-- <projects-list [projects]=projects></projects-list> -->

    <nz-tabset *ngIf="this.projects" class="sl-tab-fullwidth" [nzType]="'card'" nzSelectedIndex="0">
        <nz-tab [nzTitle]="titleTemplate1" #brandedTab1>
            <ng-template #titleTemplate1>
                <span
                    [style.color]="(brandedTab1.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Map View</span>
            </ng-template>
            <app-map [projects]=projects></app-map>
        </nz-tab>
        <nz-tab [nzTitle]="titleTemplate2" #brandedTab2>
            <ng-template #titleTemplate2>
                <span
                    [style.color]="(brandedTab2.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">List View</span>
            </ng-template>
            <projects-list [projects]=projects></projects-list>
        </nz-tab>
    </nz-tabset>

</div>  