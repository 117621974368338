export const URLReplacer = (str: string) => {
    if(!str) return '';

    let match: any = str.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);

    if(match) {
        let final=str;
        match.map( (url: string) => {
            final=final.replace(url,"<a href=\""+url+"\" class=\"link\" target=\"_BLANK\">"+url+"</a>")
        })
        return final;
    } else {
        return str;
    }
  }