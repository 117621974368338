export function extractPhoneNumbers(message: string) {
    if(!message) return '';

    let messageText = <string>message;
    let highlightMessage = ``;
    const phones = message.match(/[0-9\-\+-\.\(\)]{8,}/g);

    if(phones) {
        
        phones.map((phones, i) => {
            let coincidenceIndex = messageText.indexOf(phones);
    
            if(coincidenceIndex === 0) {
                highlightMessage += `<a class="link" href="tel:${phones}"> ${phones}</a> ${messageText.slice(coincidenceIndex + phones.length)}`;
            } else {
                highlightMessage += ` ${messageText.slice(0, coincidenceIndex)} <a class="link" href="tel:${phones}"> ${phones}</a> `;
            }

            messageText = messageText.slice(coincidenceIndex + phones.length);
        });
        
        return highlightMessage += messageText;  
    } else {
        return message;
    }
}