import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ChatComponent } from './components/chat/chat.component';
import { ContentWrapperComponent } from './components/content-wrapper/content-wrapper.component';
import { HeaderComponent } from './components/header/header.component';
import { MessageComponent } from './components/message/message.component';
import { MessageWrapperComponent } from './components/message-wrapper/message-wrapper.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SelectSubjectComponent } from './components/select-subject/select-subject.component';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { SubjectWrapperComponent } from './components/subject-wrapper/subject-wrapper.component';
import { ChatService } from './services/chat.service';
import { MessageService } from './services/message.service';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SupportChatService } from './services/support-chats.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AzureBlobService } from './services/azure-blob.service';
import { MessageFileUploadComponent } from './components/message/message-file-upload/message-file-upload.component';
import { SubjectService } from './services/subjects.service';

import { SendLocationComponent } from './components/send-location/send-location.component';
import { ImageDialogWindowComponent } from './components/image-dialog-window/image-dialog-window.component';
import { MatDialogModule } from '@angular/material/dialog';

import { SearchMessageComponent } from './components/search-messages/search-message/search-message.component';
import { SearchMessagesComponent } from './components/search-messages/search-messages.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { MessageViewersService } from './services/message-viewers.service';
import { NotificationPushService } from './services/notification-push.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';


export function playerFactory() {
  return player;
}


@NgModule({
  declarations: [
    ChatComponent,
    ContentWrapperComponent,
    HeaderComponent,
    MessageComponent,
    MessageWrapperComponent,
    MessagesComponent,
    SelectSubjectComponent,
    SendMessageComponent,
    SubjectWrapperComponent,
    BreadcrumbsComponent,
    MessageFileUploadComponent,
    SendLocationComponent,
    ImageDialogWindowComponent,
    SearchMessageComponent,
    SearchMessagesComponent,
    SearchInputComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxSpinnerModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [ 
    ChatComponent,
    NgxSpinnerModule
  ],
  providers: [
    ChatService,
    MessageService,
    SupportChatService,
    AzureBlobService,
    SubjectService,
    MessageViewersService,
    NotificationPushService
  ]
}
)
export class ChatModule { }
