import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-image-dialog-window',
  templateUrl: './image-dialog-window.component.html',
  styleUrls: ['./image-dialog-window.component.scss']
})
export class ImageDialogWindowComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {src: string}) { }

  ngOnInit(): void {
  }

}
