<ng-container *ngIf="(!chatState?.currentSubject || chatState?.isReturnToSubjectSelect) && !chatState?.isChatHistoryOpened && !chatState?.isSearchOpened && !chatState?.isShowSearchMessagesWhenSubjectClosed && chatState?.isProjectsData">
    <app-subject-wrapper></app-subject-wrapper>
</ng-container>

<ng-container *ngIf="(chatState?.currentSubject && !chatState?.isReturnToSubjectSelect && chatState?.isProjectsData) || chatState?.isChatHistoryOpened || chatState?.isSearchOpened || chatState?.isShowSearchMessagesWhenSubjectClosed">
    <app-message-wrapper [subject]="chatState?.currentSubject"></app-message-wrapper>
</ng-container>

<div class="no-data-wrapper" *ngIf="!chatState?.isProjectsData">
    <img src="assets\icons\no-subject.svg" alt="no-data" class="no-data-img">
    <h3 class="no-data-title">THERE ARE NO SUBJECTS</h3>
</div>
