export function extractEmails(message: string) {
    if(!message) return '';

    let messageText = <string>message;
    let highlightMessage = ``;
    
    const emails = message.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);

    if(emails) {
        
        emails.map(email => {
            let coincidenceIndex = messageText.indexOf(email);
    
            if(coincidenceIndex === 0) {
                highlightMessage += `<a class="link" href="mailto:${email}" target="_blank"> ${email}</a> ${messageText.slice(coincidenceIndex + email.length)}`;
            } else {
                highlightMessage += `${messageText.slice(0, coincidenceIndex)} <a class="link" href="mailto:${email}" target="_blank"> ${email}</a> `;
            }

            messageText = messageText.slice(coincidenceIndex + email.length);
        });
        
        return highlightMessage += messageText;  
    } else {
        return message;
    }
}



