<div class="send-message" (mouseenter)="onMouseEnter($event)">
    <div class="send-message-content" *ngIf="!chatState?.isChatHistoryOpened">
        <button class="send-message-attach-icon" (click)="fileDropRef.click()">
            <input type="file" #fileDropRef (change)="onFileSelected($event)">
        </button>
        <button class="send-message-location-icon" (click)="openSendLocationWindow()"></button>
        <input type="text" class="send-message-input" placeholder="Chat..."  [formControl]="messageInput" (keydown.enter)="sendMessage(messageInput.value)">
        <button class="send-message-button" (click)="sendMessage(messageInput.value)" [disabled]="messageInput.errors?.maxlength"></button>
    </div>
    <div *ngIf="messageInput.errors?.maxlength" class="error-message">
        Message must be shorter {{maxMessageLength}} characters long.
    </div>
    <div *ngIf="isErrorShown" class="error-message">
        Something went wrong. Try again.
    </div>
</div>