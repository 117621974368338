import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { RestBase } from '../rest/rest.base';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
/*
    Service implementing the Profile,
*/
export class SmartlabUsersService extends RestBase {

    // TODO: add real e-mail!
    // TODO: add image

    public profileLoaded = false;
    public sub: string = ``;
    public givenName: string = ``;
    public familyName: string = ``;
    public fullName: string = ``;
    public profileName: string = ``;
    public nickName: string = ``;
    public picture: string = `assets/images/avatars/empty.bmp`;

    constructor(private _authService: AuthService, private http: HttpClient
    ) {
        super(environment.apiUrl + `v1/msgraph/`);
        this.setupUserProfile();
    }

    /**
     * User Profile
     *
     * @param key
     */

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private setupUserProfile() {
        // Test the user object

        // console.group(`Profile`);

        const profile = this._authService.getUserProfile();

        if (!profile) {
            // console.log(`profile not found, trying again`);
            setTimeout(() => {
                this.setupUserProfile();
            }, 100);
        } else {
            // console.log(`profile:`, profile);

            this.sub = profile[`sub`];
            this.givenName = profile[`given_name`];
            this.familyName = profile[`family_name`];
            this.nickName = profile[`nickname`];
            if (this.givenName && this.familyName) {
                this.fullName = `${this.givenName} ${this.familyName}`;
            }
            else {
                this.fullName = this.nickName;
            }
            this.profileName = profile[`name`];
            if (`https://waterleau.cloud/app_metadata` in profile &&
                `azureTenantId` in profile[`https://waterleau.cloud/app_metadata`]) {
              this.picture = this.getUserPicture(profile[`https://waterleau.cloud/app_metadata`][`oid`]);
            } else {
              this.picture = undefined;
            }            
            this.profileLoaded = true;
        }

        // console.groupEnd();
    }

    getUsers(): Observable<any> {
        return this.http.get(this.endpoint + 'users')
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    getUser(userId: string): Observable<any> {
        return this.http.get(this.endpoint + 'user/' + userId)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    getUserPicture(azureId: string) {
        const userPictureURL = `${environment.apiUrl}v1/msgraph/user/${azureId}/picture`;
        return userPictureURL;
    }
}
