<div ngClass="{{fileState?.valid ? 'chat-message-user-block' : 'chat-message-invalid'}}">
    <img src="assets\icons\no-avatar.svg" alt="avatar" class="user-avatar-img">
    <div class="chat-message-text-block">
        <span class="user-name" *ngIf="!fileState?.valid">{{chatState?.user?.data?.NameFirst}}</span>
        <div class="spinner-block" *ngIf="fileState?.valid">
            <ng-lottie 
                [options]="options" 
                (animationCreated)="animationCreated($event)"
                [styles]="styles"
            ></ng-lottie>
        </div>
        <p [ngClass]="fileState?.valid ? 'chat-message-text' : 'chat-message-text-warning'">{{fileState?.message}}</p>
    </div>
</div>