import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject-wrapper',
  templateUrl: './subject-wrapper.component.html',
  styleUrls: ['./subject-wrapper.component.scss']
})
export class SubjectWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
