import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ProjectGroupsSmartlabUsers, SupportChatMessageViewers, SupportSubjects } from '../graphql';
import { CHAT_CONSTANTS } from '../helpers/constants';
import { MessagesMapper } from '../helpers/messages-mapper';
import { IChatStateModel } from '../models/chat-state.model';
import { IGetMessagesStateRequest } from '../models/get-messages-state-request.model';
import { IInitStateModel } from '../models/init-state.model';
import { IMessageData } from '../models/message-data-model';
import { IRequestChatMessages } from '../models/request-chat-messages.model';
import { GraphqlService } from './qraphql.service';


@Injectable({
  providedIn: 'root'
})
export class ChatStateService {
  private chatMessagesSubscription: Subscription;
  private messagesResponseSubscription: Subscription;
  private messagesBeforeFilterDateSubscription: Subscription;
  private projectsSubscription: Subscription;
  private initSubscription: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private defaultChatState: IChatStateModel = {
    isChatHistoryOpened: false,
    isReturnToSubjectSelect: false,
    isSearchOpened: false,
    isShowSearchMessagesWhenSubjectClosed: false,
    isProjectsData: true,
    unreadMessages: []
  };

  private chatState: IChatStateModel = this.defaultChatState;
  private chatMessages: IMessageData[] = [];
  private _messages = new BehaviorSubject<IMessageData[]>(this.chatMessages);
  private _state = new BehaviorSubject<IChatStateModel>(this.chatState);
  private subjectSubscription: Subscription;

  constructor(private _graphqlService: GraphqlService) { }

  public init(initModel: IInitStateModel): void {
    this.unsubscribe();

    this.chatState = this.deepClone(this.defaultChatState);

    this.initSubscription = forkJoin([
      this._graphqlService.getUserByAuthId(<string>initModel.userId),
      this._graphqlService.smartlabUsers$.pipe(
        tap((user) => {
          if(!user) return;

          const _user = user[0];

          this.chatState.user = {
            data: _user,
            role: 'SL user'
          };
    
          this.projectsSubscription = this._graphqlService.getProjectsSubscription(initModel.projectId);
        })
      ),
      this._graphqlService.projectGroupSmartlabUsersSubscription$.pipe(
        tap((users: ProjectGroupsSmartlabUsers[]) => {
          
          if(!users || !users.length) {
            this.chatState.isProjectsData = false;
          };

          const user: ProjectGroupsSmartlabUsers = users[0];
          const chat = user?.ProjectGroup?.SupportChats;

          if(chat && chat?.length) {
            this.chatState.currentSubject = <SupportSubjects>chat[0].SupportSubject;
            this.chatState.isChatHistoryOpened = false;
          } else {
            this.chatState.currentSubject = undefined;
            this.chatState.isChatHistoryOpened = true;
          }

          this.chatState.plantName = <string>user?.ProjectGroup?.PfdProject?.Name;
          this.chatState.projectId = initModel.projectId;
          
          const chatStateRequest: IGetMessagesStateRequest = {
            projectId: this.chatState.projectId,
            limit: CHAT_CONSTANTS.CHAT_MESSAGES.limit
          };

          this.getMessagesRequest(chatStateRequest);
          this.subjectSubscription = this._graphqlService.getSupportSubjects().subscribe(); 
        })
      ),
      this._graphqlService.supportChatSubjects.pipe(
        tap((subjects: SupportSubjects[]) => {
          if(!subjects || !subjects.length) {
            this.chatState.supportSubjects = [];

            return;
          };
    
          this.chatState.supportSubjects = subjects;
        })
      )
    ]
    ).subscribe(() => {
      this.updateState();
    });
  }

  public get state$(): Observable<IChatStateModel> {
    return this._state.asObservable();
  }

  public set state(state: IChatStateModel) {
    this.chatState = this.deepClone(state);

    if(this.chatState.isChatHistoryOpened) this.getFinishedSubjectChatMessages();

    this._state.next(this.chatState);
  }

  public get messages$(): Observable<IMessageData[]> {
    return this._messages.asObservable();
  }

  public getMessages(request: IGetMessagesStateRequest): void {
    this.getMessagesRequest(request);
  }

  public getMessagesBeforeFilterDate(startDate: Date): void {
    this.getMessagesBeforeFilterDateRequest(startDate);
  }

  public getFinishedSubjectChatMessages(): void {
    this.getFinishedSubjectChatMessagesRequest();
  }

  private getFinishedSubjectChatMessagesRequest(): void {
    this.chatMessagesSubscription?.unsubscribe();

    let finishedSubjectMessagesRequest: IRequestChatMessages = {
      projectId: this.chatState.projectId,
      isDeleted: CHAT_CONSTANTS.CHAT_MESSAGES.Deleted,
      limit: CHAT_CONSTANTS.CHAT_MESSAGES.limit,
      order_by: 'Desc'
    };

    this.chatMessagesSubscription = this._graphqlService.getFinishedSubjectChatMessagesSubscription(finishedSubjectMessagesRequest);
  }

  private getMessagesBeforeFilterDateRequest(startDate: Date): void {
    let chatMessagesRequest = {
      projectId: this.chatState.projectId,
      isDeleted: CHAT_CONSTANTS.CHAT_MESSAGES.Deleted,
      filterDate: startDate
    };

    this.messagesBeforeFilterDateSubscription = this._graphqlService.getChatmessagesBeforeFilterDateSubscription(chatMessagesRequest).subscribe();

    this._graphqlService.supportChatMessagesBeforeFilterDateSubscription.pipe((takeUntil(this._unsubscribeAll))).subscribe((messagesBefore: any) => {
      chatMessagesRequest.filterDate = messagesBefore[messagesBefore.length - 1].CreatedDate;

      this.chatMessagesSubscription?.unsubscribe();
      this.chatMessagesSubscription = this._graphqlService.getChatmessagesSubscription(chatMessagesRequest);
    });
  }

  private getMessagesRequest(request: IGetMessagesStateRequest) {
    this.chatMessagesSubscription?.unsubscribe();
    this.messagesResponseSubscription?.unsubscribe();

    const chatMessagesRequest: IRequestChatMessages = {
      projectId: request.projectId,
      isDeleted: CHAT_CONSTANTS.CHAT_MESSAGES.Deleted,
      limit: request.limit
    };

    this.chatMessagesSubscription = this._graphqlService.getChatmessagesSubscription(chatMessagesRequest);

    this.messagesResponseSubscription = this._graphqlService.chatMessagesSubscription$
    .pipe((takeUntil(this._unsubscribeAll)))
    .subscribe((messages: any) => {

        if(!messages.length) {
          this.chatState.isChatHistoryOpened = false;
        }

        this.chatMessages = [];
        this.chatMessages = MessagesMapper(messages);

        this.chatState.unreadMessages = this._filterNewMessages();
        this.updateState();

        this._messages.next(this.chatMessages);
    });
  }

  private _filterNewMessages(): IMessageData[] {
    const unread: IMessageData[] = [];

     this.chatMessages?.forEach(item => {
      const view: SupportChatMessageViewers[] = item.message?.SupportChatMessageViewers.filter((x: any) => x.MessageId === item.message.Id)
      
      if(!view || !view.length) {
      if(item.message.SupportChatMember.SmartlabUser.Id !== this.chatState?.user?.data.Id)
      unread.push(item);
      } else {

        const userView = view.find(x => x.SupportChatMember.SmartlabUser.Id === this.chatState?.user?.data.Id);

        if(!userView) unread.push(item);
      }
    });

    return unread;
  }

  private updateState(): void {
    this._state.next(this.chatState);
  }

  private deepClone(state: IChatStateModel): IChatStateModel {
    return JSON.parse(JSON.stringify(state));
  }

  private unsubscribe(): void {
    this.messagesBeforeFilterDateSubscription?.unsubscribe();
    this.subjectSubscription?.unsubscribe();
    this.chatMessagesSubscription?.unsubscribe();
    this.messagesResponseSubscription?.unsubscribe();
    this.projectsSubscription?.unsubscribe();
    this.initSubscription?.unsubscribe();
  }
}