export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'ADMIN': {
                'TITLE': 'Admin',
            },
            'LOG': {
                'TITLE': 'Log',
            },
            'REQUESTS': {
                'TITLE': 'Requests',
            },
            'PLANT': {
                'TITLE': 'Plant',
            },
            'TECHNOLOGIES': {
                'TITLE': 'Technologies',
            },
            'BUFFERING': {
                'TITLE': 'Buffering',
            },
            'UASB': {
                'TITLE': 'UASB',
            },
            'BIOGAS': {
                'TITLE': 'Biogas',
            },
            'LUCAS3': {
                'TITLE': 'LUCAS-3',
            },
            'SLUDGE': {
                'TITLE': 'Sludge',
            },
            'UF': {
                'TITLE': 'AQUALITY UF',
            },
            'UTILITIES': {
                'TITLE': 'Utilities',
            },
            'MAINTENANCE': {
                'TITLE': 'Maintenance',
            },
            'LABORATORY': {
                'TITLE': 'Laboratory',
            },
            'WTLOPERATOR': {
                'TITLE': 'WTL operator',
            },
            'REPORTS': {
                'TITLE': 'Reports',
            },
            'ALERTS': {
                'TITLE': 'Alerts',
            },
            'TOOLS': {
                'TITLE': 'Tools',
            },
            'CALENDAR': {
                'TITLE': 'Calendar',
            },
            'MAIL': {
                'TITLE': 'Mail',
            },
            'CONTACTS': {
                'TITLE': 'Contacts',
            },
            'TODO': {
                'TITLE': 'Logbook',
            },
            'PROJECTS': {
                'TITLE': 'My Plants',
            },

        }
    }
};
