import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: any;
  bigint: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "ClientGroups" */
export type ClientGroups = {
  __typename?: 'ClientGroups';
  ClientIds?: Maybe<Scalars['_text']>;
  Id: Scalars['uuid'];
  Name?: Maybe<Scalars['String']>;
  /** An array relationship */
  ProjectGroups: Array<ProjectGroups>;
  /** An aggregate relationship */
  ProjectGroups_aggregate: ProjectGroups_Aggregate;
  WepId: Scalars['Int'];
};


/** columns and relationships of "ClientGroups" */
export type ClientGroupsProjectGroupsArgs = {
  distinct_on?: Maybe<Array<ProjectGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroups_Order_By>>;
  where?: Maybe<ProjectGroups_Bool_Exp>;
};


/** columns and relationships of "ClientGroups" */
export type ClientGroupsProjectGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroups_Order_By>>;
  where?: Maybe<ProjectGroups_Bool_Exp>;
};

/** columns and relationships of "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers = {
  __typename?: 'ClientGroupsSmartlabUsers';
  /** An object relationship */
  ClientGroup: ClientGroups;
  ClientGroupId: Scalars['uuid'];
  Id: Scalars['uuid'];
  /** An object relationship */
  SmartlabUser: SmartlabUsers;
  SmartlabUserId: Scalars['uuid'];
};

/** aggregated selection of "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Aggregate = {
  __typename?: 'ClientGroupsSmartlabUsers_aggregate';
  aggregate?: Maybe<ClientGroupsSmartlabUsers_Aggregate_Fields>;
  nodes: Array<ClientGroupsSmartlabUsers>;
};

/** aggregate fields of "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Aggregate_Fields = {
  __typename?: 'ClientGroupsSmartlabUsers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ClientGroupsSmartlabUsers_Max_Fields>;
  min?: Maybe<ClientGroupsSmartlabUsers_Min_Fields>;
};


/** aggregate fields of "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientGroupsSmartlabUsers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ClientGroupsSmartlabUsers_Max_Order_By>;
  min?: Maybe<ClientGroupsSmartlabUsers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Arr_Rel_Insert_Input = {
  data: Array<ClientGroupsSmartlabUsers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<ClientGroupsSmartlabUsers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ClientGroupsSmartlabUsers". All fields are combined with a logical 'AND'. */
export type ClientGroupsSmartlabUsers_Bool_Exp = {
  ClientGroup?: Maybe<ClientGroups_Bool_Exp>;
  ClientGroupId?: Maybe<Uuid_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  SmartlabUser?: Maybe<SmartlabUsers_Bool_Exp>;
  SmartlabUserId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<ClientGroupsSmartlabUsers_Bool_Exp>>;
  _not?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
  _or?: Maybe<Array<ClientGroupsSmartlabUsers_Bool_Exp>>;
};

/** unique or primary key constraints on table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsers_Constraint {
  /** unique or primary key constraint */
  PkClientGroupsSmartlabUsers = 'PK_ClientGroupsSmartlabUsers'
}

/** input type for inserting data into table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Insert_Input = {
  ClientGroup?: Maybe<ClientGroups_Obj_Rel_Insert_Input>;
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Id?: Maybe<Scalars['uuid']>;
  SmartlabUser?: Maybe<SmartlabUsers_Obj_Rel_Insert_Input>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClientGroupsSmartlabUsers_Max_Fields = {
  __typename?: 'ClientGroupsSmartlabUsers_max_fields';
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Id?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Max_Order_By = {
  ClientGroupId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientGroupsSmartlabUsers_Min_Fields = {
  __typename?: 'ClientGroupsSmartlabUsers_min_fields';
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Id?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Min_Order_By = {
  ClientGroupId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Mutation_Response = {
  __typename?: 'ClientGroupsSmartlabUsers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientGroupsSmartlabUsers>;
};

/** on conflict condition type for table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_On_Conflict = {
  constraint: ClientGroupsSmartlabUsers_Constraint;
  update_columns?: Array<ClientGroupsSmartlabUsers_Update_Column>;
  where?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
};

/** Ordering options when selecting data from "ClientGroupsSmartlabUsers". */
export type ClientGroupsSmartlabUsers_Order_By = {
  ClientGroup?: Maybe<ClientGroups_Order_By>;
  ClientGroupId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  SmartlabUser?: Maybe<SmartlabUsers_Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: ClientGroupsSmartlabUsers */
export type ClientGroupsSmartlabUsers_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsers_Select_Column {
  /** column name */
  ClientGroupId = 'ClientGroupId',
  /** column name */
  Id = 'Id',
  /** column name */
  SmartlabUserId = 'SmartlabUserId'
}

/** input type for updating data in table "ClientGroupsSmartlabUsers" */
export type ClientGroupsSmartlabUsers_Set_Input = {
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Id?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ClientGroupsSmartlabUsers" */
export enum ClientGroupsSmartlabUsers_Update_Column {
  /** column name */
  ClientGroupId = 'ClientGroupId',
  /** column name */
  Id = 'Id',
  /** column name */
  SmartlabUserId = 'SmartlabUserId'
}

/** aggregated selection of "ClientGroups" */
export type ClientGroups_Aggregate = {
  __typename?: 'ClientGroups_aggregate';
  aggregate?: Maybe<ClientGroups_Aggregate_Fields>;
  nodes: Array<ClientGroups>;
};

/** aggregate fields of "ClientGroups" */
export type ClientGroups_Aggregate_Fields = {
  __typename?: 'ClientGroups_aggregate_fields';
  avg?: Maybe<ClientGroups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ClientGroups_Max_Fields>;
  min?: Maybe<ClientGroups_Min_Fields>;
  stddev?: Maybe<ClientGroups_Stddev_Fields>;
  stddev_pop?: Maybe<ClientGroups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientGroups_Stddev_Samp_Fields>;
  sum?: Maybe<ClientGroups_Sum_Fields>;
  var_pop?: Maybe<ClientGroups_Var_Pop_Fields>;
  var_samp?: Maybe<ClientGroups_Var_Samp_Fields>;
  variance?: Maybe<ClientGroups_Variance_Fields>;
};


/** aggregate fields of "ClientGroups" */
export type ClientGroups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientGroups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ClientGroups_Avg_Fields = {
  __typename?: 'ClientGroups_avg_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ClientGroups". All fields are combined with a logical 'AND'. */
export type ClientGroups_Bool_Exp = {
  ClientIds?: Maybe<_Text_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  ProjectGroups?: Maybe<ProjectGroups_Bool_Exp>;
  WepId?: Maybe<Int_Comparison_Exp>;
  _and?: Maybe<Array<ClientGroups_Bool_Exp>>;
  _not?: Maybe<ClientGroups_Bool_Exp>;
  _or?: Maybe<Array<ClientGroups_Bool_Exp>>;
};

/** unique or primary key constraints on table "ClientGroups" */
export enum ClientGroups_Constraint {
  /** unique or primary key constraint */
  IxClientGroupsWepId = 'IX_ClientGroups_WepId',
  /** unique or primary key constraint */
  PkClientGroups = 'PK_ClientGroups'
}

/** input type for incrementing numeric columns in table "ClientGroups" */
export type ClientGroups_Inc_Input = {
  WepId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ClientGroups" */
export type ClientGroups_Insert_Input = {
  ClientIds?: Maybe<Scalars['_text']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  ProjectGroups?: Maybe<ProjectGroups_Arr_Rel_Insert_Input>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ClientGroups_Max_Fields = {
  __typename?: 'ClientGroups_max_fields';
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ClientGroups_Min_Fields = {
  __typename?: 'ClientGroups_min_fields';
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ClientGroups" */
export type ClientGroups_Mutation_Response = {
  __typename?: 'ClientGroups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientGroups>;
};

/** input type for inserting object relation for remote table "ClientGroups" */
export type ClientGroups_Obj_Rel_Insert_Input = {
  data: ClientGroups_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<ClientGroups_On_Conflict>;
};

/** on conflict condition type for table "ClientGroups" */
export type ClientGroups_On_Conflict = {
  constraint: ClientGroups_Constraint;
  update_columns?: Array<ClientGroups_Update_Column>;
  where?: Maybe<ClientGroups_Bool_Exp>;
};

/** Ordering options when selecting data from "ClientGroups". */
export type ClientGroups_Order_By = {
  ClientIds?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ProjectGroups_aggregate?: Maybe<ProjectGroups_Aggregate_Order_By>;
  WepId?: Maybe<Order_By>;
};

/** primary key columns input for table: ClientGroups */
export type ClientGroups_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "ClientGroups" */
export enum ClientGroups_Select_Column {
  /** column name */
  ClientIds = 'ClientIds',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name',
  /** column name */
  WepId = 'WepId'
}

/** input type for updating data in table "ClientGroups" */
export type ClientGroups_Set_Input = {
  ClientIds?: Maybe<Scalars['_text']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ClientGroups_Stddev_Fields = {
  __typename?: 'ClientGroups_stddev_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClientGroups_Stddev_Pop_Fields = {
  __typename?: 'ClientGroups_stddev_pop_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClientGroups_Stddev_Samp_Fields = {
  __typename?: 'ClientGroups_stddev_samp_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ClientGroups_Sum_Fields = {
  __typename?: 'ClientGroups_sum_fields';
  WepId?: Maybe<Scalars['Int']>;
};

/** update columns of table "ClientGroups" */
export enum ClientGroups_Update_Column {
  /** column name */
  ClientIds = 'ClientIds',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name',
  /** column name */
  WepId = 'WepId'
}

/** aggregate var_pop on columns */
export type ClientGroups_Var_Pop_Fields = {
  __typename?: 'ClientGroups_var_pop_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClientGroups_Var_Samp_Fields = {
  __typename?: 'ClientGroups_var_samp_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClientGroups_Variance_Fields = {
  __typename?: 'ClientGroups_variance_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "FileItems" */
export type FileItems = {
  __typename?: 'FileItems';
  FileName: Scalars['String'];
  FileReference: Scalars['String'];
  FileTypeId: Scalars['Int'];
  Id: Scalars['bigint'];
  IsDeleted?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  SupportChatMessages: Array<SupportChatMessages>;
  /** An aggregate relationship */
  SupportChatMessages_aggregate: SupportChatMessages_Aggregate;
};


/** columns and relationships of "FileItems" */
export type FileItemsSupportChatMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};


/** columns and relationships of "FileItems" */
export type FileItemsSupportChatMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};

/** aggregated selection of "FileItems" */
export type FileItems_Aggregate = {
  __typename?: 'FileItems_aggregate';
  aggregate?: Maybe<FileItems_Aggregate_Fields>;
  nodes: Array<FileItems>;
};

/** aggregate fields of "FileItems" */
export type FileItems_Aggregate_Fields = {
  __typename?: 'FileItems_aggregate_fields';
  avg?: Maybe<FileItems_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FileItems_Max_Fields>;
  min?: Maybe<FileItems_Min_Fields>;
  stddev?: Maybe<FileItems_Stddev_Fields>;
  stddev_pop?: Maybe<FileItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FileItems_Stddev_Samp_Fields>;
  sum?: Maybe<FileItems_Sum_Fields>;
  var_pop?: Maybe<FileItems_Var_Pop_Fields>;
  var_samp?: Maybe<FileItems_Var_Samp_Fields>;
  variance?: Maybe<FileItems_Variance_Fields>;
};


/** aggregate fields of "FileItems" */
export type FileItems_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<FileItems_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FileItems" */
export type FileItems_Aggregate_Order_By = {
  avg?: Maybe<FileItems_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<FileItems_Max_Order_By>;
  min?: Maybe<FileItems_Min_Order_By>;
  stddev?: Maybe<FileItems_Stddev_Order_By>;
  stddev_pop?: Maybe<FileItems_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<FileItems_Stddev_Samp_Order_By>;
  sum?: Maybe<FileItems_Sum_Order_By>;
  var_pop?: Maybe<FileItems_Var_Pop_Order_By>;
  var_samp?: Maybe<FileItems_Var_Samp_Order_By>;
  variance?: Maybe<FileItems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FileItems" */
export type FileItems_Arr_Rel_Insert_Input = {
  data: Array<FileItems_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<FileItems_On_Conflict>;
};

/** aggregate avg on columns */
export type FileItems_Avg_Fields = {
  __typename?: 'FileItems_avg_fields';
  FileTypeId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FileItems" */
export type FileItems_Avg_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FileItems". All fields are combined with a logical 'AND'. */
export type FileItems_Bool_Exp = {
  FileName?: Maybe<String_Comparison_Exp>;
  FileReference?: Maybe<String_Comparison_Exp>;
  FileTypeId?: Maybe<Int_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  IsDeleted?: Maybe<Boolean_Comparison_Exp>;
  SupportChatMessages?: Maybe<SupportChatMessages_Bool_Exp>;
  _and?: Maybe<Array<FileItems_Bool_Exp>>;
  _not?: Maybe<FileItems_Bool_Exp>;
  _or?: Maybe<Array<FileItems_Bool_Exp>>;
};

/** unique or primary key constraints on table "FileItems" */
export enum FileItems_Constraint {
  /** unique or primary key constraint */
  PkFileItems = 'PK_FileItems'
}

/** input type for incrementing numeric columns in table "FileItems" */
export type FileItems_Inc_Input = {
  FileTypeId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "FileItems" */
export type FileItems_Insert_Input = {
  FileName?: Maybe<Scalars['String']>;
  FileReference?: Maybe<Scalars['String']>;
  FileTypeId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['bigint']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  SupportChatMessages?: Maybe<SupportChatMessages_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type FileItems_Max_Fields = {
  __typename?: 'FileItems_max_fields';
  FileName?: Maybe<Scalars['String']>;
  FileReference?: Maybe<Scalars['String']>;
  FileTypeId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "FileItems" */
export type FileItems_Max_Order_By = {
  FileName?: Maybe<Order_By>;
  FileReference?: Maybe<Order_By>;
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type FileItems_Min_Fields = {
  __typename?: 'FileItems_min_fields';
  FileName?: Maybe<Scalars['String']>;
  FileReference?: Maybe<Scalars['String']>;
  FileTypeId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "FileItems" */
export type FileItems_Min_Order_By = {
  FileName?: Maybe<Order_By>;
  FileReference?: Maybe<Order_By>;
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** response of any mutation on the table "FileItems" */
export type FileItems_Mutation_Response = {
  __typename?: 'FileItems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FileItems>;
};

/** input type for inserting object relation for remote table "FileItems" */
export type FileItems_Obj_Rel_Insert_Input = {
  data: FileItems_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<FileItems_On_Conflict>;
};

/** on conflict condition type for table "FileItems" */
export type FileItems_On_Conflict = {
  constraint: FileItems_Constraint;
  update_columns?: Array<FileItems_Update_Column>;
  where?: Maybe<FileItems_Bool_Exp>;
};

/** Ordering options when selecting data from "FileItems". */
export type FileItems_Order_By = {
  FileName?: Maybe<Order_By>;
  FileReference?: Maybe<Order_By>;
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsDeleted?: Maybe<Order_By>;
  SupportChatMessages_aggregate?: Maybe<SupportChatMessages_Aggregate_Order_By>;
};

/** primary key columns input for table: FileItems */
export type FileItems_Pk_Columns_Input = {
  Id: Scalars['bigint'];
};

/** select columns of table "FileItems" */
export enum FileItems_Select_Column {
  /** column name */
  FileName = 'FileName',
  /** column name */
  FileReference = 'FileReference',
  /** column name */
  FileTypeId = 'FileTypeId',
  /** column name */
  Id = 'Id',
  /** column name */
  IsDeleted = 'IsDeleted'
}

/** input type for updating data in table "FileItems" */
export type FileItems_Set_Input = {
  FileName?: Maybe<Scalars['String']>;
  FileReference?: Maybe<Scalars['String']>;
  FileTypeId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['bigint']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type FileItems_Stddev_Fields = {
  __typename?: 'FileItems_stddev_fields';
  FileTypeId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FileItems" */
export type FileItems_Stddev_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FileItems_Stddev_Pop_Fields = {
  __typename?: 'FileItems_stddev_pop_fields';
  FileTypeId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FileItems" */
export type FileItems_Stddev_Pop_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FileItems_Stddev_Samp_Fields = {
  __typename?: 'FileItems_stddev_samp_fields';
  FileTypeId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FileItems" */
export type FileItems_Stddev_Samp_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type FileItems_Sum_Fields = {
  __typename?: 'FileItems_sum_fields';
  FileTypeId?: Maybe<Scalars['Int']>;
  Id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "FileItems" */
export type FileItems_Sum_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** update columns of table "FileItems" */
export enum FileItems_Update_Column {
  /** column name */
  FileName = 'FileName',
  /** column name */
  FileReference = 'FileReference',
  /** column name */
  FileTypeId = 'FileTypeId',
  /** column name */
  Id = 'Id',
  /** column name */
  IsDeleted = 'IsDeleted'
}

/** aggregate var_pop on columns */
export type FileItems_Var_Pop_Fields = {
  __typename?: 'FileItems_var_pop_fields';
  FileTypeId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FileItems" */
export type FileItems_Var_Pop_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FileItems_Var_Samp_Fields = {
  __typename?: 'FileItems_var_samp_fields';
  FileTypeId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FileItems" */
export type FileItems_Var_Samp_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type FileItems_Variance_Fields = {
  __typename?: 'FileItems_variance_fields';
  FileTypeId?: Maybe<Scalars['Float']>;
  Id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FileItems" */
export type FileItems_Variance_Order_By = {
  FileTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** columns and relationships of "FileTypes" */
export type FileTypes = {
  __typename?: 'FileTypes';
  /** An array relationship */
  FileItems: Array<FileItems>;
  /** An aggregate relationship */
  FileItems_aggregate: FileItems_Aggregate;
  Id: Scalars['Int'];
  IsDeleted?: Maybe<Scalars['Boolean']>;
  Name: Scalars['String'];
};


/** columns and relationships of "FileTypes" */
export type FileTypesFileItemsArgs = {
  distinct_on?: Maybe<Array<FileItems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileItems_Order_By>>;
  where?: Maybe<FileItems_Bool_Exp>;
};


/** columns and relationships of "FileTypes" */
export type FileTypesFileItems_AggregateArgs = {
  distinct_on?: Maybe<Array<FileItems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileItems_Order_By>>;
  where?: Maybe<FileItems_Bool_Exp>;
};

/** aggregated selection of "FileTypes" */
export type FileTypes_Aggregate = {
  __typename?: 'FileTypes_aggregate';
  aggregate?: Maybe<FileTypes_Aggregate_Fields>;
  nodes: Array<FileTypes>;
};

/** aggregate fields of "FileTypes" */
export type FileTypes_Aggregate_Fields = {
  __typename?: 'FileTypes_aggregate_fields';
  avg?: Maybe<FileTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FileTypes_Max_Fields>;
  min?: Maybe<FileTypes_Min_Fields>;
  stddev?: Maybe<FileTypes_Stddev_Fields>;
  stddev_pop?: Maybe<FileTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FileTypes_Stddev_Samp_Fields>;
  sum?: Maybe<FileTypes_Sum_Fields>;
  var_pop?: Maybe<FileTypes_Var_Pop_Fields>;
  var_samp?: Maybe<FileTypes_Var_Samp_Fields>;
  variance?: Maybe<FileTypes_Variance_Fields>;
};


/** aggregate fields of "FileTypes" */
export type FileTypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<FileTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FileTypes_Avg_Fields = {
  __typename?: 'FileTypes_avg_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FileTypes". All fields are combined with a logical 'AND'. */
export type FileTypes_Bool_Exp = {
  FileItems?: Maybe<FileItems_Bool_Exp>;
  Id?: Maybe<Int_Comparison_Exp>;
  IsDeleted?: Maybe<Boolean_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<FileTypes_Bool_Exp>>;
  _not?: Maybe<FileTypes_Bool_Exp>;
  _or?: Maybe<Array<FileTypes_Bool_Exp>>;
};

/** unique or primary key constraints on table "FileTypes" */
export enum FileTypes_Constraint {
  /** unique or primary key constraint */
  PkFileTypes = 'PK_FileTypes'
}

/** input type for incrementing numeric columns in table "FileTypes" */
export type FileTypes_Inc_Input = {
  Id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FileTypes" */
export type FileTypes_Insert_Input = {
  FileItems?: Maybe<FileItems_Arr_Rel_Insert_Input>;
  Id?: Maybe<Scalars['Int']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type FileTypes_Max_Fields = {
  __typename?: 'FileTypes_max_fields';
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type FileTypes_Min_Fields = {
  __typename?: 'FileTypes_min_fields';
  Id?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "FileTypes" */
export type FileTypes_Mutation_Response = {
  __typename?: 'FileTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FileTypes>;
};

/** on conflict condition type for table "FileTypes" */
export type FileTypes_On_Conflict = {
  constraint: FileTypes_Constraint;
  update_columns?: Array<FileTypes_Update_Column>;
  where?: Maybe<FileTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "FileTypes". */
export type FileTypes_Order_By = {
  FileItems_aggregate?: Maybe<FileItems_Aggregate_Order_By>;
  Id?: Maybe<Order_By>;
  IsDeleted?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
};

/** primary key columns input for table: FileTypes */
export type FileTypes_Pk_Columns_Input = {
  Id: Scalars['Int'];
};

/** select columns of table "FileTypes" */
export enum FileTypes_Select_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  IsDeleted = 'IsDeleted',
  /** column name */
  Name = 'Name'
}

/** input type for updating data in table "FileTypes" */
export type FileTypes_Set_Input = {
  Id?: Maybe<Scalars['Int']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type FileTypes_Stddev_Fields = {
  __typename?: 'FileTypes_stddev_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FileTypes_Stddev_Pop_Fields = {
  __typename?: 'FileTypes_stddev_pop_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FileTypes_Stddev_Samp_Fields = {
  __typename?: 'FileTypes_stddev_samp_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FileTypes_Sum_Fields = {
  __typename?: 'FileTypes_sum_fields';
  Id?: Maybe<Scalars['Int']>;
};

/** update columns of table "FileTypes" */
export enum FileTypes_Update_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  IsDeleted = 'IsDeleted',
  /** column name */
  Name = 'Name'
}

/** aggregate var_pop on columns */
export type FileTypes_Var_Pop_Fields = {
  __typename?: 'FileTypes_var_pop_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FileTypes_Var_Samp_Fields = {
  __typename?: 'FileTypes_var_samp_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FileTypes_Variance_Fields = {
  __typename?: 'FileTypes_variance_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "NotificationTypes" */
export type NotificationTypes = {
  __typename?: 'NotificationTypes';
  Deleted: Scalars['Boolean'];
  Id: Scalars['uuid'];
  Name: Scalars['String'];
};

/** aggregated selection of "NotificationTypes" */
export type NotificationTypes_Aggregate = {
  __typename?: 'NotificationTypes_aggregate';
  aggregate?: Maybe<NotificationTypes_Aggregate_Fields>;
  nodes: Array<NotificationTypes>;
};

/** aggregate fields of "NotificationTypes" */
export type NotificationTypes_Aggregate_Fields = {
  __typename?: 'NotificationTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<NotificationTypes_Max_Fields>;
  min?: Maybe<NotificationTypes_Min_Fields>;
};


/** aggregate fields of "NotificationTypes" */
export type NotificationTypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<NotificationTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "NotificationTypes". All fields are combined with a logical 'AND'. */
export type NotificationTypes_Bool_Exp = {
  Deleted?: Maybe<Boolean_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<NotificationTypes_Bool_Exp>>;
  _not?: Maybe<NotificationTypes_Bool_Exp>;
  _or?: Maybe<Array<NotificationTypes_Bool_Exp>>;
};

/** unique or primary key constraints on table "NotificationTypes" */
export enum NotificationTypes_Constraint {
  /** unique or primary key constraint */
  PkNotificationTypes = 'PK_NotificationTypes'
}

/** input type for inserting data into table "NotificationTypes" */
export type NotificationTypes_Insert_Input = {
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type NotificationTypes_Max_Fields = {
  __typename?: 'NotificationTypes_max_fields';
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type NotificationTypes_Min_Fields = {
  __typename?: 'NotificationTypes_min_fields';
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "NotificationTypes" */
export type NotificationTypes_Mutation_Response = {
  __typename?: 'NotificationTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NotificationTypes>;
};

/** on conflict condition type for table "NotificationTypes" */
export type NotificationTypes_On_Conflict = {
  constraint: NotificationTypes_Constraint;
  update_columns?: Array<NotificationTypes_Update_Column>;
  where?: Maybe<NotificationTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "NotificationTypes". */
export type NotificationTypes_Order_By = {
  Deleted?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
};

/** primary key columns input for table: NotificationTypes */
export type NotificationTypes_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "NotificationTypes" */
export enum NotificationTypes_Select_Column {
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name'
}

/** input type for updating data in table "NotificationTypes" */
export type NotificationTypes_Set_Input = {
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
};

/** update columns of table "NotificationTypes" */
export enum NotificationTypes_Update_Column {
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name'
}

/** columns and relationships of "Notifications" */
export type Notifications = {
  __typename?: 'Notifications';
  Id: Scalars['uuid'];
  MessageId?: Maybe<Scalars['uuid']>;
  NotificationTypeId?: Maybe<Scalars['uuid']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  SubjectId?: Maybe<Scalars['uuid']>;
  UserFromId?: Maybe<Scalars['uuid']>;
  UserToId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "Notifications" */
export type Notifications_Aggregate = {
  __typename?: 'Notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

/** aggregate fields of "Notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'Notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "Notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Notifications_Max_Order_By>;
  min?: Maybe<Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  Id?: Maybe<Uuid_Comparison_Exp>;
  MessageId?: Maybe<Uuid_Comparison_Exp>;
  NotificationTypeId?: Maybe<Uuid_Comparison_Exp>;
  ProjectId?: Maybe<Uuid_Comparison_Exp>;
  SubjectId?: Maybe<Uuid_Comparison_Exp>;
  UserFromId?: Maybe<Uuid_Comparison_Exp>;
  UserToId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Notifications_Bool_Exp>>;
  _not?: Maybe<Notifications_Bool_Exp>;
  _or?: Maybe<Array<Notifications_Bool_Exp>>;
};

/** unique or primary key constraints on table "Notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint */
  PkNotifications = 'PK_Notifications'
}

/** input type for inserting data into table "Notifications" */
export type Notifications_Insert_Input = {
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  NotificationTypeId?: Maybe<Scalars['uuid']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  SubjectId?: Maybe<Scalars['uuid']>;
  UserFromId?: Maybe<Scalars['uuid']>;
  UserToId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'Notifications_max_fields';
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  NotificationTypeId?: Maybe<Scalars['uuid']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  SubjectId?: Maybe<Scalars['uuid']>;
  UserFromId?: Maybe<Scalars['uuid']>;
  UserToId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Notifications" */
export type Notifications_Max_Order_By = {
  Id?: Maybe<Order_By>;
  MessageId?: Maybe<Order_By>;
  NotificationTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  SubjectId?: Maybe<Order_By>;
  UserFromId?: Maybe<Order_By>;
  UserToId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'Notifications_min_fields';
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  NotificationTypeId?: Maybe<Scalars['uuid']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  SubjectId?: Maybe<Scalars['uuid']>;
  UserFromId?: Maybe<Scalars['uuid']>;
  UserToId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Notifications" */
export type Notifications_Min_Order_By = {
  Id?: Maybe<Order_By>;
  MessageId?: Maybe<Order_By>;
  NotificationTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  SubjectId?: Maybe<Order_By>;
  UserFromId?: Maybe<Order_By>;
  UserToId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'Notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on conflict condition type for table "Notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: Maybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "Notifications". */
export type Notifications_Order_By = {
  Id?: Maybe<Order_By>;
  MessageId?: Maybe<Order_By>;
  NotificationTypeId?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  SubjectId?: Maybe<Order_By>;
  UserFromId?: Maybe<Order_By>;
  UserToId?: Maybe<Order_By>;
};

/** primary key columns input for table: Notifications */
export type Notifications_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "Notifications" */
export enum Notifications_Select_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  MessageId = 'MessageId',
  /** column name */
  NotificationTypeId = 'NotificationTypeId',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  SubjectId = 'SubjectId',
  /** column name */
  UserFromId = 'UserFromId',
  /** column name */
  UserToId = 'UserToId'
}

/** input type for updating data in table "Notifications" */
export type Notifications_Set_Input = {
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  NotificationTypeId?: Maybe<Scalars['uuid']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  SubjectId?: Maybe<Scalars['uuid']>;
  UserFromId?: Maybe<Scalars['uuid']>;
  UserToId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "Notifications" */
export enum Notifications_Update_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  MessageId = 'MessageId',
  /** column name */
  NotificationTypeId = 'NotificationTypeId',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  SubjectId = 'SubjectId',
  /** column name */
  UserFromId = 'UserFromId',
  /** column name */
  UserToId = 'UserToId'
}

/** columns and relationships of "PfdProjects" */
export type PfdProjects = {
  __typename?: 'PfdProjects';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  Coordinates?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ErrorType: Scalars['Int'];
  Errors?: Maybe<Scalars['String']>;
  Id: Scalars['uuid'];
  Name?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  WepClientId?: Maybe<Scalars['Int']>;
  WepId: Scalars['Int'];
};

/** aggregated selection of "PfdProjects" */
export type PfdProjects_Aggregate = {
  __typename?: 'PfdProjects_aggregate';
  aggregate?: Maybe<PfdProjects_Aggregate_Fields>;
  nodes: Array<PfdProjects>;
};

/** aggregate fields of "PfdProjects" */
export type PfdProjects_Aggregate_Fields = {
  __typename?: 'PfdProjects_aggregate_fields';
  avg?: Maybe<PfdProjects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PfdProjects_Max_Fields>;
  min?: Maybe<PfdProjects_Min_Fields>;
  stddev?: Maybe<PfdProjects_Stddev_Fields>;
  stddev_pop?: Maybe<PfdProjects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PfdProjects_Stddev_Samp_Fields>;
  sum?: Maybe<PfdProjects_Sum_Fields>;
  var_pop?: Maybe<PfdProjects_Var_Pop_Fields>;
  var_samp?: Maybe<PfdProjects_Var_Samp_Fields>;
  variance?: Maybe<PfdProjects_Variance_Fields>;
};


/** aggregate fields of "PfdProjects" */
export type PfdProjects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<PfdProjects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PfdProjects_Avg_Fields = {
  __typename?: 'PfdProjects_avg_fields';
  ErrorType?: Maybe<Scalars['Float']>;
  WepClientId?: Maybe<Scalars['Float']>;
  WepId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "PfdProjects". All fields are combined with a logical 'AND'. */
export type PfdProjects_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  City?: Maybe<String_Comparison_Exp>;
  Code?: Maybe<String_Comparison_Exp>;
  Coordinates?: Maybe<String_Comparison_Exp>;
  Country?: Maybe<String_Comparison_Exp>;
  ErrorType?: Maybe<Int_Comparison_Exp>;
  Errors?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  TimeZone?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  WepClientId?: Maybe<Int_Comparison_Exp>;
  WepId?: Maybe<Int_Comparison_Exp>;
  _and?: Maybe<Array<PfdProjects_Bool_Exp>>;
  _not?: Maybe<PfdProjects_Bool_Exp>;
  _or?: Maybe<Array<PfdProjects_Bool_Exp>>;
};

/** unique or primary key constraints on table "PfdProjects" */
export enum PfdProjects_Constraint {
  /** unique or primary key constraint */
  IxPfdProjectsCode = 'IX_PfdProjects_Code',
  /** unique or primary key constraint */
  PkPfdProjects = 'PK_PfdProjects'
}

/** input type for incrementing numeric columns in table "PfdProjects" */
export type PfdProjects_Inc_Input = {
  ErrorType?: Maybe<Scalars['Int']>;
  WepClientId?: Maybe<Scalars['Int']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "PfdProjects" */
export type PfdProjects_Insert_Input = {
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Coordinates?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ErrorType?: Maybe<Scalars['Int']>;
  Errors?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  WepClientId?: Maybe<Scalars['Int']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PfdProjects_Max_Fields = {
  __typename?: 'PfdProjects_max_fields';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Coordinates?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ErrorType?: Maybe<Scalars['Int']>;
  Errors?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  WepClientId?: Maybe<Scalars['Int']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PfdProjects_Min_Fields = {
  __typename?: 'PfdProjects_min_fields';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Coordinates?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ErrorType?: Maybe<Scalars['Int']>;
  Errors?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  WepClientId?: Maybe<Scalars['Int']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "PfdProjects" */
export type PfdProjects_Mutation_Response = {
  __typename?: 'PfdProjects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PfdProjects>;
};

/** input type for inserting object relation for remote table "PfdProjects" */
export type PfdProjects_Obj_Rel_Insert_Input = {
  data: PfdProjects_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<PfdProjects_On_Conflict>;
};

/** on conflict condition type for table "PfdProjects" */
export type PfdProjects_On_Conflict = {
  constraint: PfdProjects_Constraint;
  update_columns?: Array<PfdProjects_Update_Column>;
  where?: Maybe<PfdProjects_Bool_Exp>;
};

/** Ordering options when selecting data from "PfdProjects". */
export type PfdProjects_Order_By = {
  Address?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Coordinates?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  ErrorType?: Maybe<Order_By>;
  Errors?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  TimeZone?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WepClientId?: Maybe<Order_By>;
  WepId?: Maybe<Order_By>;
};

/** primary key columns input for table: PfdProjects */
export type PfdProjects_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "PfdProjects" */
export enum PfdProjects_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  City = 'City',
  /** column name */
  Code = 'Code',
  /** column name */
  Coordinates = 'Coordinates',
  /** column name */
  Country = 'Country',
  /** column name */
  ErrorType = 'ErrorType',
  /** column name */
  Errors = 'Errors',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name',
  /** column name */
  TimeZone = 'TimeZone',
  /** column name */
  Type = 'Type',
  /** column name */
  WepClientId = 'WepClientId',
  /** column name */
  WepId = 'WepId'
}

/** input type for updating data in table "PfdProjects" */
export type PfdProjects_Set_Input = {
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Coordinates?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ErrorType?: Maybe<Scalars['Int']>;
  Errors?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  WepClientId?: Maybe<Scalars['Int']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PfdProjects_Stddev_Fields = {
  __typename?: 'PfdProjects_stddev_fields';
  ErrorType?: Maybe<Scalars['Float']>;
  WepClientId?: Maybe<Scalars['Float']>;
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PfdProjects_Stddev_Pop_Fields = {
  __typename?: 'PfdProjects_stddev_pop_fields';
  ErrorType?: Maybe<Scalars['Float']>;
  WepClientId?: Maybe<Scalars['Float']>;
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PfdProjects_Stddev_Samp_Fields = {
  __typename?: 'PfdProjects_stddev_samp_fields';
  ErrorType?: Maybe<Scalars['Float']>;
  WepClientId?: Maybe<Scalars['Float']>;
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PfdProjects_Sum_Fields = {
  __typename?: 'PfdProjects_sum_fields';
  ErrorType?: Maybe<Scalars['Int']>;
  WepClientId?: Maybe<Scalars['Int']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** update columns of table "PfdProjects" */
export enum PfdProjects_Update_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  City = 'City',
  /** column name */
  Code = 'Code',
  /** column name */
  Coordinates = 'Coordinates',
  /** column name */
  Country = 'Country',
  /** column name */
  ErrorType = 'ErrorType',
  /** column name */
  Errors = 'Errors',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name',
  /** column name */
  TimeZone = 'TimeZone',
  /** column name */
  Type = 'Type',
  /** column name */
  WepClientId = 'WepClientId',
  /** column name */
  WepId = 'WepId'
}

/** aggregate var_pop on columns */
export type PfdProjects_Var_Pop_Fields = {
  __typename?: 'PfdProjects_var_pop_fields';
  ErrorType?: Maybe<Scalars['Float']>;
  WepClientId?: Maybe<Scalars['Float']>;
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PfdProjects_Var_Samp_Fields = {
  __typename?: 'PfdProjects_var_samp_fields';
  ErrorType?: Maybe<Scalars['Float']>;
  WepClientId?: Maybe<Scalars['Float']>;
  WepId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PfdProjects_Variance_Fields = {
  __typename?: 'PfdProjects_variance_fields';
  ErrorType?: Maybe<Scalars['Float']>;
  WepClientId?: Maybe<Scalars['Float']>;
  WepId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ProjectGroups" */
export type ProjectGroups = {
  __typename?: 'ProjectGroups';
  /** An object relationship */
  ClientGroup?: Maybe<ClientGroups>;
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Code?: Maybe<Scalars['String']>;
  Id: Scalars['uuid'];
  Name?: Maybe<Scalars['String']>;
  /** An object relationship */
  PfdProject?: Maybe<PfdProjects>;
  PfdProjectId?: Maybe<Scalars['uuid']>;
  ProjectCodes?: Maybe<Scalars['_text']>;
  /** An array relationship */
  ProjectGroupsSmartlabUsers: Array<ProjectGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ProjectGroupsSmartlabUsers_aggregate: ProjectGroupsSmartlabUsers_Aggregate;
  /** An array relationship */
  SupportChatAutoMessages: Array<SupportChatAutoMessages>;
  /** An aggregate relationship */
  SupportChatAutoMessages_aggregate: SupportChatAutoMessages_Aggregate;
  /** An array relationship */
  SupportChats: Array<SupportChats>;
  /** An aggregate relationship */
  SupportChats_aggregate: SupportChats_Aggregate;
  WepId: Scalars['Int'];
};


/** columns and relationships of "ProjectGroups" */
export type ProjectGroupsProjectGroupsSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


/** columns and relationships of "ProjectGroups" */
export type ProjectGroupsProjectGroupsSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


/** columns and relationships of "ProjectGroups" */
export type ProjectGroupsSupportChatAutoMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatAutoMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatAutoMessages_Order_By>>;
  where?: Maybe<SupportChatAutoMessages_Bool_Exp>;
};


/** columns and relationships of "ProjectGroups" */
export type ProjectGroupsSupportChatAutoMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatAutoMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatAutoMessages_Order_By>>;
  where?: Maybe<SupportChatAutoMessages_Bool_Exp>;
};


/** columns and relationships of "ProjectGroups" */
export type ProjectGroupsSupportChatsArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};


/** columns and relationships of "ProjectGroups" */
export type ProjectGroupsSupportChats_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};

/** columns and relationships of "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers = {
  __typename?: 'ProjectGroupsSmartlabUsers';
  Id: Scalars['uuid'];
  /** An object relationship */
  ProjectGroup: ProjectGroups;
  ProjectGroupId: Scalars['uuid'];
  /** An object relationship */
  SmartlabUser: SmartlabUsers;
  SmartlabUserId: Scalars['uuid'];
};

/** aggregated selection of "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Aggregate = {
  __typename?: 'ProjectGroupsSmartlabUsers_aggregate';
  aggregate?: Maybe<ProjectGroupsSmartlabUsers_Aggregate_Fields>;
  nodes: Array<ProjectGroupsSmartlabUsers>;
};

/** aggregate fields of "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Aggregate_Fields = {
  __typename?: 'ProjectGroupsSmartlabUsers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProjectGroupsSmartlabUsers_Max_Fields>;
  min?: Maybe<ProjectGroupsSmartlabUsers_Min_Fields>;
};


/** aggregate fields of "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ProjectGroupsSmartlabUsers_Max_Order_By>;
  min?: Maybe<ProjectGroupsSmartlabUsers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Arr_Rel_Insert_Input = {
  data: Array<ProjectGroupsSmartlabUsers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectGroupsSmartlabUsers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ProjectGroupsSmartlabUsers". All fields are combined with a logical 'AND'. */
export type ProjectGroupsSmartlabUsers_Bool_Exp = {
  Id?: Maybe<Uuid_Comparison_Exp>;
  ProjectGroup?: Maybe<ProjectGroups_Bool_Exp>;
  ProjectGroupId?: Maybe<Uuid_Comparison_Exp>;
  SmartlabUser?: Maybe<SmartlabUsers_Bool_Exp>;
  SmartlabUserId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<ProjectGroupsSmartlabUsers_Bool_Exp>>;
  _not?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
  _or?: Maybe<Array<ProjectGroupsSmartlabUsers_Bool_Exp>>;
};

/** unique or primary key constraints on table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsers_Constraint {
  /** unique or primary key constraint */
  PkProjectGroupsSmartlabUsers = 'PK_ProjectGroupsSmartlabUsers'
}

/** input type for inserting data into table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Insert_Input = {
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroup?: Maybe<ProjectGroups_Obj_Rel_Insert_Input>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  SmartlabUser?: Maybe<SmartlabUsers_Obj_Rel_Insert_Input>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProjectGroupsSmartlabUsers_Max_Fields = {
  __typename?: 'ProjectGroupsSmartlabUsers_max_fields';
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Max_Order_By = {
  Id?: Maybe<Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProjectGroupsSmartlabUsers_Min_Fields = {
  __typename?: 'ProjectGroupsSmartlabUsers_min_fields';
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Min_Order_By = {
  Id?: Maybe<Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Mutation_Response = {
  __typename?: 'ProjectGroupsSmartlabUsers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectGroupsSmartlabUsers>;
};

/** on conflict condition type for table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_On_Conflict = {
  constraint: ProjectGroupsSmartlabUsers_Constraint;
  update_columns?: Array<ProjectGroupsSmartlabUsers_Update_Column>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};

/** Ordering options when selecting data from "ProjectGroupsSmartlabUsers". */
export type ProjectGroupsSmartlabUsers_Order_By = {
  Id?: Maybe<Order_By>;
  ProjectGroup?: Maybe<ProjectGroups_Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  SmartlabUser?: Maybe<SmartlabUsers_Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: ProjectGroupsSmartlabUsers */
export type ProjectGroupsSmartlabUsers_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsers_Select_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  ProjectGroupId = 'ProjectGroupId',
  /** column name */
  SmartlabUserId = 'SmartlabUserId'
}

/** input type for updating data in table "ProjectGroupsSmartlabUsers" */
export type ProjectGroupsSmartlabUsers_Set_Input = {
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ProjectGroupsSmartlabUsers" */
export enum ProjectGroupsSmartlabUsers_Update_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  ProjectGroupId = 'ProjectGroupId',
  /** column name */
  SmartlabUserId = 'SmartlabUserId'
}

/** aggregated selection of "ProjectGroups" */
export type ProjectGroups_Aggregate = {
  __typename?: 'ProjectGroups_aggregate';
  aggregate?: Maybe<ProjectGroups_Aggregate_Fields>;
  nodes: Array<ProjectGroups>;
};

/** aggregate fields of "ProjectGroups" */
export type ProjectGroups_Aggregate_Fields = {
  __typename?: 'ProjectGroups_aggregate_fields';
  avg?: Maybe<ProjectGroups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProjectGroups_Max_Fields>;
  min?: Maybe<ProjectGroups_Min_Fields>;
  stddev?: Maybe<ProjectGroups_Stddev_Fields>;
  stddev_pop?: Maybe<ProjectGroups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProjectGroups_Stddev_Samp_Fields>;
  sum?: Maybe<ProjectGroups_Sum_Fields>;
  var_pop?: Maybe<ProjectGroups_Var_Pop_Fields>;
  var_samp?: Maybe<ProjectGroups_Var_Samp_Fields>;
  variance?: Maybe<ProjectGroups_Variance_Fields>;
};


/** aggregate fields of "ProjectGroups" */
export type ProjectGroups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProjectGroups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProjectGroups" */
export type ProjectGroups_Aggregate_Order_By = {
  avg?: Maybe<ProjectGroups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ProjectGroups_Max_Order_By>;
  min?: Maybe<ProjectGroups_Min_Order_By>;
  stddev?: Maybe<ProjectGroups_Stddev_Order_By>;
  stddev_pop?: Maybe<ProjectGroups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ProjectGroups_Stddev_Samp_Order_By>;
  sum?: Maybe<ProjectGroups_Sum_Order_By>;
  var_pop?: Maybe<ProjectGroups_Var_Pop_Order_By>;
  var_samp?: Maybe<ProjectGroups_Var_Samp_Order_By>;
  variance?: Maybe<ProjectGroups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ProjectGroups" */
export type ProjectGroups_Arr_Rel_Insert_Input = {
  data: Array<ProjectGroups_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectGroups_On_Conflict>;
};

/** aggregate avg on columns */
export type ProjectGroups_Avg_Fields = {
  __typename?: 'ProjectGroups_avg_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ProjectGroups" */
export type ProjectGroups_Avg_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ProjectGroups". All fields are combined with a logical 'AND'. */
export type ProjectGroups_Bool_Exp = {
  ClientGroup?: Maybe<ClientGroups_Bool_Exp>;
  ClientGroupId?: Maybe<Uuid_Comparison_Exp>;
  Code?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  PfdProject?: Maybe<PfdProjects_Bool_Exp>;
  PfdProjectId?: Maybe<Uuid_Comparison_Exp>;
  ProjectCodes?: Maybe<_Text_Comparison_Exp>;
  ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
  SupportChatAutoMessages?: Maybe<SupportChatAutoMessages_Bool_Exp>;
  SupportChats?: Maybe<SupportChats_Bool_Exp>;
  WepId?: Maybe<Int_Comparison_Exp>;
  _and?: Maybe<Array<ProjectGroups_Bool_Exp>>;
  _not?: Maybe<ProjectGroups_Bool_Exp>;
  _or?: Maybe<Array<ProjectGroups_Bool_Exp>>;
};

/** unique or primary key constraints on table "ProjectGroups" */
export enum ProjectGroups_Constraint {
  /** unique or primary key constraint */
  IxProjectGroupsCode = 'IX_ProjectGroups_Code',
  /** unique or primary key constraint */
  IxProjectGroupsWepId = 'IX_ProjectGroups_WepId',
  /** unique or primary key constraint */
  PkProjectGroups = 'PK_ProjectGroups'
}

/** input type for incrementing numeric columns in table "ProjectGroups" */
export type ProjectGroups_Inc_Input = {
  WepId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ProjectGroups" */
export type ProjectGroups_Insert_Input = {
  ClientGroup?: Maybe<ClientGroups_Obj_Rel_Insert_Input>;
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Code?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  PfdProject?: Maybe<PfdProjects_Obj_Rel_Insert_Input>;
  PfdProjectId?: Maybe<Scalars['uuid']>;
  ProjectCodes?: Maybe<Scalars['_text']>;
  ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsers_Arr_Rel_Insert_Input>;
  SupportChatAutoMessages?: Maybe<SupportChatAutoMessages_Arr_Rel_Insert_Input>;
  SupportChats?: Maybe<SupportChats_Arr_Rel_Insert_Input>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProjectGroups_Max_Fields = {
  __typename?: 'ProjectGroups_max_fields';
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Code?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  PfdProjectId?: Maybe<Scalars['uuid']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ProjectGroups" */
export type ProjectGroups_Max_Order_By = {
  ClientGroupId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PfdProjectId?: Maybe<Order_By>;
  WepId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProjectGroups_Min_Fields = {
  __typename?: 'ProjectGroups_min_fields';
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Code?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  PfdProjectId?: Maybe<Scalars['uuid']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ProjectGroups" */
export type ProjectGroups_Min_Order_By = {
  ClientGroupId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PfdProjectId?: Maybe<Order_By>;
  WepId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ProjectGroups" */
export type ProjectGroups_Mutation_Response = {
  __typename?: 'ProjectGroups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectGroups>;
};

/** input type for inserting object relation for remote table "ProjectGroups" */
export type ProjectGroups_Obj_Rel_Insert_Input = {
  data: ProjectGroups_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<ProjectGroups_On_Conflict>;
};

/** on conflict condition type for table "ProjectGroups" */
export type ProjectGroups_On_Conflict = {
  constraint: ProjectGroups_Constraint;
  update_columns?: Array<ProjectGroups_Update_Column>;
  where?: Maybe<ProjectGroups_Bool_Exp>;
};

/** Ordering options when selecting data from "ProjectGroups". */
export type ProjectGroups_Order_By = {
  ClientGroup?: Maybe<ClientGroups_Order_By>;
  ClientGroupId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PfdProject?: Maybe<PfdProjects_Order_By>;
  PfdProjectId?: Maybe<Order_By>;
  ProjectCodes?: Maybe<Order_By>;
  ProjectGroupsSmartlabUsers_aggregate?: Maybe<ProjectGroupsSmartlabUsers_Aggregate_Order_By>;
  SupportChatAutoMessages_aggregate?: Maybe<SupportChatAutoMessages_Aggregate_Order_By>;
  SupportChats_aggregate?: Maybe<SupportChats_Aggregate_Order_By>;
  WepId?: Maybe<Order_By>;
};

/** primary key columns input for table: ProjectGroups */
export type ProjectGroups_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "ProjectGroups" */
export enum ProjectGroups_Select_Column {
  /** column name */
  ClientGroupId = 'ClientGroupId',
  /** column name */
  Code = 'Code',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name',
  /** column name */
  PfdProjectId = 'PfdProjectId',
  /** column name */
  ProjectCodes = 'ProjectCodes',
  /** column name */
  WepId = 'WepId'
}

/** input type for updating data in table "ProjectGroups" */
export type ProjectGroups_Set_Input = {
  ClientGroupId?: Maybe<Scalars['uuid']>;
  Code?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Name?: Maybe<Scalars['String']>;
  PfdProjectId?: Maybe<Scalars['uuid']>;
  ProjectCodes?: Maybe<Scalars['_text']>;
  WepId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProjectGroups_Stddev_Fields = {
  __typename?: 'ProjectGroups_stddev_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ProjectGroups" */
export type ProjectGroups_Stddev_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProjectGroups_Stddev_Pop_Fields = {
  __typename?: 'ProjectGroups_stddev_pop_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ProjectGroups" */
export type ProjectGroups_Stddev_Pop_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProjectGroups_Stddev_Samp_Fields = {
  __typename?: 'ProjectGroups_stddev_samp_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ProjectGroups" */
export type ProjectGroups_Stddev_Samp_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ProjectGroups_Sum_Fields = {
  __typename?: 'ProjectGroups_sum_fields';
  WepId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ProjectGroups" */
export type ProjectGroups_Sum_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** update columns of table "ProjectGroups" */
export enum ProjectGroups_Update_Column {
  /** column name */
  ClientGroupId = 'ClientGroupId',
  /** column name */
  Code = 'Code',
  /** column name */
  Id = 'Id',
  /** column name */
  Name = 'Name',
  /** column name */
  PfdProjectId = 'PfdProjectId',
  /** column name */
  ProjectCodes = 'ProjectCodes',
  /** column name */
  WepId = 'WepId'
}

/** aggregate var_pop on columns */
export type ProjectGroups_Var_Pop_Fields = {
  __typename?: 'ProjectGroups_var_pop_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ProjectGroups" */
export type ProjectGroups_Var_Pop_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProjectGroups_Var_Samp_Fields = {
  __typename?: 'ProjectGroups_var_samp_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ProjectGroups" */
export type ProjectGroups_Var_Samp_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ProjectGroups_Variance_Fields = {
  __typename?: 'ProjectGroups_variance_fields';
  WepId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ProjectGroups" */
export type ProjectGroups_Variance_Order_By = {
  WepId?: Maybe<Order_By>;
};

/** columns and relationships of "SmartlabUsers" */
export type SmartlabUsers = {
  __typename?: 'SmartlabUsers';
  /** An array relationship */
  ClientGroupsSmartlabUsers: Array<ClientGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ClientGroupsSmartlabUsers_aggregate: ClientGroupsSmartlabUsers_Aggregate;
  Email: Scalars['String'];
  Id: Scalars['uuid'];
  NameFirst?: Maybe<Scalars['String']>;
  NameLast?: Maybe<Scalars['String']>;
  /** An array relationship */
  ProjectGroupsSmartlabUsers: Array<ProjectGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ProjectGroupsSmartlabUsers_aggregate: ProjectGroupsSmartlabUsers_Aggregate;
  /** An array relationship */
  SupportChatMembers: Array<SupportChatMembers>;
  /** An aggregate relationship */
  SupportChatMembers_aggregate: SupportChatMembers_Aggregate;
  UserId: Scalars['String'];
};


/** columns and relationships of "SmartlabUsers" */
export type SmartlabUsersClientGroupsSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<ClientGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
};


/** columns and relationships of "SmartlabUsers" */
export type SmartlabUsersClientGroupsSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
};


/** columns and relationships of "SmartlabUsers" */
export type SmartlabUsersProjectGroupsSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


/** columns and relationships of "SmartlabUsers" */
export type SmartlabUsersProjectGroupsSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


/** columns and relationships of "SmartlabUsers" */
export type SmartlabUsersSupportChatMembersArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};


/** columns and relationships of "SmartlabUsers" */
export type SmartlabUsersSupportChatMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};

/** aggregated selection of "SmartlabUsers" */
export type SmartlabUsers_Aggregate = {
  __typename?: 'SmartlabUsers_aggregate';
  aggregate?: Maybe<SmartlabUsers_Aggregate_Fields>;
  nodes: Array<SmartlabUsers>;
};

/** aggregate fields of "SmartlabUsers" */
export type SmartlabUsers_Aggregate_Fields = {
  __typename?: 'SmartlabUsers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SmartlabUsers_Max_Fields>;
  min?: Maybe<SmartlabUsers_Min_Fields>;
};


/** aggregate fields of "SmartlabUsers" */
export type SmartlabUsers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SmartlabUsers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "SmartlabUsers". All fields are combined with a logical 'AND'. */
export type SmartlabUsers_Bool_Exp = {
  ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  NameFirst?: Maybe<String_Comparison_Exp>;
  NameLast?: Maybe<String_Comparison_Exp>;
  ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
  SupportChatMembers?: Maybe<SupportChatMembers_Bool_Exp>;
  UserId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<SmartlabUsers_Bool_Exp>>;
  _not?: Maybe<SmartlabUsers_Bool_Exp>;
  _or?: Maybe<Array<SmartlabUsers_Bool_Exp>>;
};

/** unique or primary key constraints on table "SmartlabUsers" */
export enum SmartlabUsers_Constraint {
  /** unique or primary key constraint */
  IxSmartlabUsersEmail = 'IX_SmartlabUsers_Email',
  /** unique or primary key constraint */
  IxSmartlabUsersUserId = 'IX_SmartlabUsers_UserId',
  /** unique or primary key constraint */
  PkSmartlabUsers = 'PK_SmartlabUsers'
}

/** input type for inserting data into table "SmartlabUsers" */
export type SmartlabUsers_Insert_Input = {
  ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsers_Arr_Rel_Insert_Input>;
  Email?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  NameFirst?: Maybe<Scalars['String']>;
  NameLast?: Maybe<Scalars['String']>;
  ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsers_Arr_Rel_Insert_Input>;
  SupportChatMembers?: Maybe<SupportChatMembers_Arr_Rel_Insert_Input>;
  UserId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SmartlabUsers_Max_Fields = {
  __typename?: 'SmartlabUsers_max_fields';
  Email?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  NameFirst?: Maybe<Scalars['String']>;
  NameLast?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SmartlabUsers_Min_Fields = {
  __typename?: 'SmartlabUsers_min_fields';
  Email?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  NameFirst?: Maybe<Scalars['String']>;
  NameLast?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "SmartlabUsers" */
export type SmartlabUsers_Mutation_Response = {
  __typename?: 'SmartlabUsers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmartlabUsers>;
};

/** input type for inserting object relation for remote table "SmartlabUsers" */
export type SmartlabUsers_Obj_Rel_Insert_Input = {
  data: SmartlabUsers_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<SmartlabUsers_On_Conflict>;
};

/** on conflict condition type for table "SmartlabUsers" */
export type SmartlabUsers_On_Conflict = {
  constraint: SmartlabUsers_Constraint;
  update_columns?: Array<SmartlabUsers_Update_Column>;
  where?: Maybe<SmartlabUsers_Bool_Exp>;
};

/** Ordering options when selecting data from "SmartlabUsers". */
export type SmartlabUsers_Order_By = {
  ClientGroupsSmartlabUsers_aggregate?: Maybe<ClientGroupsSmartlabUsers_Aggregate_Order_By>;
  Email?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  NameFirst?: Maybe<Order_By>;
  NameLast?: Maybe<Order_By>;
  ProjectGroupsSmartlabUsers_aggregate?: Maybe<ProjectGroupsSmartlabUsers_Aggregate_Order_By>;
  SupportChatMembers_aggregate?: Maybe<SupportChatMembers_Aggregate_Order_By>;
  UserId?: Maybe<Order_By>;
};

/** primary key columns input for table: SmartlabUsers */
export type SmartlabUsers_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "SmartlabUsers" */
export enum SmartlabUsers_Select_Column {
  /** column name */
  Email = 'Email',
  /** column name */
  Id = 'Id',
  /** column name */
  NameFirst = 'NameFirst',
  /** column name */
  NameLast = 'NameLast',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "SmartlabUsers" */
export type SmartlabUsers_Set_Input = {
  Email?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  NameFirst?: Maybe<Scalars['String']>;
  NameLast?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['String']>;
};

/** update columns of table "SmartlabUsers" */
export enum SmartlabUsers_Update_Column {
  /** column name */
  Email = 'Email',
  /** column name */
  Id = 'Id',
  /** column name */
  NameFirst = 'NameFirst',
  /** column name */
  NameLast = 'NameLast',
  /** column name */
  UserId = 'UserId'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "SupportChatAutoMessages" */
export type SupportChatAutoMessages = {
  __typename?: 'SupportChatAutoMessages';
  CreatedDate: Scalars['timestamp'];
  Deleted: Scalars['Boolean'];
  Id: Scalars['uuid'];
  /** An object relationship */
  ProjectGroup: ProjectGroups;
  ProjectGroupId: Scalars['uuid'];
  TextValue?: Maybe<Scalars['String']>;
};

/** aggregated selection of "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Aggregate = {
  __typename?: 'SupportChatAutoMessages_aggregate';
  aggregate?: Maybe<SupportChatAutoMessages_Aggregate_Fields>;
  nodes: Array<SupportChatAutoMessages>;
};

/** aggregate fields of "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Aggregate_Fields = {
  __typename?: 'SupportChatAutoMessages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SupportChatAutoMessages_Max_Fields>;
  min?: Maybe<SupportChatAutoMessages_Min_Fields>;
};


/** aggregate fields of "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SupportChatAutoMessages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SupportChatAutoMessages_Max_Order_By>;
  min?: Maybe<SupportChatAutoMessages_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Arr_Rel_Insert_Input = {
  data: Array<SupportChatAutoMessages_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChatAutoMessages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SupportChatAutoMessages". All fields are combined with a logical 'AND'. */
export type SupportChatAutoMessages_Bool_Exp = {
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  Deleted?: Maybe<Boolean_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  ProjectGroup?: Maybe<ProjectGroups_Bool_Exp>;
  ProjectGroupId?: Maybe<Uuid_Comparison_Exp>;
  TextValue?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<SupportChatAutoMessages_Bool_Exp>>;
  _not?: Maybe<SupportChatAutoMessages_Bool_Exp>;
  _or?: Maybe<Array<SupportChatAutoMessages_Bool_Exp>>;
};

/** unique or primary key constraints on table "SupportChatAutoMessages" */
export enum SupportChatAutoMessages_Constraint {
  /** unique or primary key constraint */
  PkSupportChatAutoMessages = 'PK_SupportChatAutoMessages'
}

/** input type for inserting data into table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Insert_Input = {
  CreatedDate?: Maybe<Scalars['timestamp']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroup?: Maybe<ProjectGroups_Obj_Rel_Insert_Input>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  TextValue?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SupportChatAutoMessages_Max_Fields = {
  __typename?: 'SupportChatAutoMessages_max_fields';
  CreatedDate?: Maybe<Scalars['timestamp']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  TextValue?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Max_Order_By = {
  CreatedDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  TextValue?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SupportChatAutoMessages_Min_Fields = {
  __typename?: 'SupportChatAutoMessages_min_fields';
  CreatedDate?: Maybe<Scalars['timestamp']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  TextValue?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Min_Order_By = {
  CreatedDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  TextValue?: Maybe<Order_By>;
};

/** response of any mutation on the table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Mutation_Response = {
  __typename?: 'SupportChatAutoMessages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SupportChatAutoMessages>;
};

/** on conflict condition type for table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_On_Conflict = {
  constraint: SupportChatAutoMessages_Constraint;
  update_columns?: Array<SupportChatAutoMessages_Update_Column>;
  where?: Maybe<SupportChatAutoMessages_Bool_Exp>;
};

/** Ordering options when selecting data from "SupportChatAutoMessages". */
export type SupportChatAutoMessages_Order_By = {
  CreatedDate?: Maybe<Order_By>;
  Deleted?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ProjectGroup?: Maybe<ProjectGroups_Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  TextValue?: Maybe<Order_By>;
};

/** primary key columns input for table: SupportChatAutoMessages */
export type SupportChatAutoMessages_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "SupportChatAutoMessages" */
export enum SupportChatAutoMessages_Select_Column {
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  ProjectGroupId = 'ProjectGroupId',
  /** column name */
  TextValue = 'TextValue'
}

/** input type for updating data in table "SupportChatAutoMessages" */
export type SupportChatAutoMessages_Set_Input = {
  CreatedDate?: Maybe<Scalars['timestamp']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  TextValue?: Maybe<Scalars['String']>;
};

/** update columns of table "SupportChatAutoMessages" */
export enum SupportChatAutoMessages_Update_Column {
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  ProjectGroupId = 'ProjectGroupId',
  /** column name */
  TextValue = 'TextValue'
}

/** columns and relationships of "SupportChatMembers" */
export type SupportChatMembers = {
  __typename?: 'SupportChatMembers';
  Deleted: Scalars['Boolean'];
  Id: Scalars['uuid'];
  IsActive?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  SmartlabUser: SmartlabUsers;
  SmartlabUserId: Scalars['uuid'];
  /** An object relationship */
  SupportChat: SupportChats;
  SupportChatId: Scalars['uuid'];
  /** An array relationship */
  SupportChatMessageViewers: Array<SupportChatMessageViewers>;
  /** An aggregate relationship */
  SupportChatMessageViewers_aggregate: SupportChatMessageViewers_Aggregate;
  /** An array relationship */
  SupportChatMessages: Array<SupportChatMessages>;
  /** An aggregate relationship */
  SupportChatMessages_aggregate: SupportChatMessages_Aggregate;
};


/** columns and relationships of "SupportChatMembers" */
export type SupportChatMembersSupportChatMessageViewersArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};


/** columns and relationships of "SupportChatMembers" */
export type SupportChatMembersSupportChatMessageViewers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};


/** columns and relationships of "SupportChatMembers" */
export type SupportChatMembersSupportChatMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};


/** columns and relationships of "SupportChatMembers" */
export type SupportChatMembersSupportChatMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};

/** aggregated selection of "SupportChatMembers" */
export type SupportChatMembers_Aggregate = {
  __typename?: 'SupportChatMembers_aggregate';
  aggregate?: Maybe<SupportChatMembers_Aggregate_Fields>;
  nodes: Array<SupportChatMembers>;
};

/** aggregate fields of "SupportChatMembers" */
export type SupportChatMembers_Aggregate_Fields = {
  __typename?: 'SupportChatMembers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SupportChatMembers_Max_Fields>;
  min?: Maybe<SupportChatMembers_Min_Fields>;
};


/** aggregate fields of "SupportChatMembers" */
export type SupportChatMembers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SupportChatMembers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SupportChatMembers" */
export type SupportChatMembers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SupportChatMembers_Max_Order_By>;
  min?: Maybe<SupportChatMembers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SupportChatMembers" */
export type SupportChatMembers_Arr_Rel_Insert_Input = {
  data: Array<SupportChatMembers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChatMembers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SupportChatMembers". All fields are combined with a logical 'AND'. */
export type SupportChatMembers_Bool_Exp = {
  Deleted?: Maybe<Boolean_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  IsActive?: Maybe<Boolean_Comparison_Exp>;
  SmartlabUser?: Maybe<SmartlabUsers_Bool_Exp>;
  SmartlabUserId?: Maybe<Uuid_Comparison_Exp>;
  SupportChat?: Maybe<SupportChats_Bool_Exp>;
  SupportChatId?: Maybe<Uuid_Comparison_Exp>;
  SupportChatMessageViewers?: Maybe<SupportChatMessageViewers_Bool_Exp>;
  SupportChatMessages?: Maybe<SupportChatMessages_Bool_Exp>;
  _and?: Maybe<Array<SupportChatMembers_Bool_Exp>>;
  _not?: Maybe<SupportChatMembers_Bool_Exp>;
  _or?: Maybe<Array<SupportChatMembers_Bool_Exp>>;
};

/** unique or primary key constraints on table "SupportChatMembers" */
export enum SupportChatMembers_Constraint {
  /** unique or primary key constraint */
  PkSupportChatMembers = 'PK_SupportChatMembers'
}

/** input type for inserting data into table "SupportChatMembers" */
export type SupportChatMembers_Insert_Input = {
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  SmartlabUser?: Maybe<SmartlabUsers_Obj_Rel_Insert_Input>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
  SupportChat?: Maybe<SupportChats_Obj_Rel_Insert_Input>;
  SupportChatId?: Maybe<Scalars['uuid']>;
  SupportChatMessageViewers?: Maybe<SupportChatMessageViewers_Arr_Rel_Insert_Input>;
  SupportChatMessages?: Maybe<SupportChatMessages_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type SupportChatMembers_Max_Fields = {
  __typename?: 'SupportChatMembers_max_fields';
  Id?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
  SupportChatId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "SupportChatMembers" */
export type SupportChatMembers_Max_Order_By = {
  Id?: Maybe<Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
  SupportChatId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SupportChatMembers_Min_Fields = {
  __typename?: 'SupportChatMembers_min_fields';
  Id?: Maybe<Scalars['uuid']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
  SupportChatId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "SupportChatMembers" */
export type SupportChatMembers_Min_Order_By = {
  Id?: Maybe<Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
  SupportChatId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SupportChatMembers" */
export type SupportChatMembers_Mutation_Response = {
  __typename?: 'SupportChatMembers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SupportChatMembers>;
};

/** input type for inserting object relation for remote table "SupportChatMembers" */
export type SupportChatMembers_Obj_Rel_Insert_Input = {
  data: SupportChatMembers_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChatMembers_On_Conflict>;
};

/** on conflict condition type for table "SupportChatMembers" */
export type SupportChatMembers_On_Conflict = {
  constraint: SupportChatMembers_Constraint;
  update_columns?: Array<SupportChatMembers_Update_Column>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};

/** Ordering options when selecting data from "SupportChatMembers". */
export type SupportChatMembers_Order_By = {
  Deleted?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsActive?: Maybe<Order_By>;
  SmartlabUser?: Maybe<SmartlabUsers_Order_By>;
  SmartlabUserId?: Maybe<Order_By>;
  SupportChat?: Maybe<SupportChats_Order_By>;
  SupportChatId?: Maybe<Order_By>;
  SupportChatMessageViewers_aggregate?: Maybe<SupportChatMessageViewers_Aggregate_Order_By>;
  SupportChatMessages_aggregate?: Maybe<SupportChatMessages_Aggregate_Order_By>;
};

/** primary key columns input for table: SupportChatMembers */
export type SupportChatMembers_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "SupportChatMembers" */
export enum SupportChatMembers_Select_Column {
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  IsActive = 'IsActive',
  /** column name */
  SmartlabUserId = 'SmartlabUserId',
  /** column name */
  SupportChatId = 'SupportChatId'
}

/** input type for updating data in table "SupportChatMembers" */
export type SupportChatMembers_Set_Input = {
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  SmartlabUserId?: Maybe<Scalars['uuid']>;
  SupportChatId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "SupportChatMembers" */
export enum SupportChatMembers_Update_Column {
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  IsActive = 'IsActive',
  /** column name */
  SmartlabUserId = 'SmartlabUserId',
  /** column name */
  SupportChatId = 'SupportChatId'
}

/** columns and relationships of "SupportChatMessageViewers" */
export type SupportChatMessageViewers = {
  __typename?: 'SupportChatMessageViewers';
  Deleted: Scalars['Boolean'];
  Id: Scalars['uuid'];
  MessageId: Scalars['uuid'];
  /** An object relationship */
  SupportChatMember: SupportChatMembers;
  /** An object relationship */
  SupportChatMessage: SupportChatMessages;
  ViewedDate: Scalars['timestamptz'];
  ViewerId: Scalars['uuid'];
};

/** aggregated selection of "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Aggregate = {
  __typename?: 'SupportChatMessageViewers_aggregate';
  aggregate?: Maybe<SupportChatMessageViewers_Aggregate_Fields>;
  nodes: Array<SupportChatMessageViewers>;
};

/** aggregate fields of "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Aggregate_Fields = {
  __typename?: 'SupportChatMessageViewers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SupportChatMessageViewers_Max_Fields>;
  min?: Maybe<SupportChatMessageViewers_Min_Fields>;
};


/** aggregate fields of "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SupportChatMessageViewers_Max_Order_By>;
  min?: Maybe<SupportChatMessageViewers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Arr_Rel_Insert_Input = {
  data: Array<SupportChatMessageViewers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChatMessageViewers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SupportChatMessageViewers". All fields are combined with a logical 'AND'. */
export type SupportChatMessageViewers_Bool_Exp = {
  Deleted?: Maybe<Boolean_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  MessageId?: Maybe<Uuid_Comparison_Exp>;
  SupportChatMember?: Maybe<SupportChatMembers_Bool_Exp>;
  SupportChatMessage?: Maybe<SupportChatMessages_Bool_Exp>;
  ViewedDate?: Maybe<Timestamptz_Comparison_Exp>;
  ViewerId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<SupportChatMessageViewers_Bool_Exp>>;
  _not?: Maybe<SupportChatMessageViewers_Bool_Exp>;
  _or?: Maybe<Array<SupportChatMessageViewers_Bool_Exp>>;
};

/** unique or primary key constraints on table "SupportChatMessageViewers" */
export enum SupportChatMessageViewers_Constraint {
  /** unique or primary key constraint */
  PkSupportChatMessageViewers = 'PK_SupportChatMessageViewers'
}

/** input type for inserting data into table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Insert_Input = {
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  SupportChatMember?: Maybe<SupportChatMembers_Obj_Rel_Insert_Input>;
  SupportChatMessage?: Maybe<SupportChatMessages_Obj_Rel_Insert_Input>;
  ViewedDate?: Maybe<Scalars['timestamptz']>;
  ViewerId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SupportChatMessageViewers_Max_Fields = {
  __typename?: 'SupportChatMessageViewers_max_fields';
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  ViewedDate?: Maybe<Scalars['timestamptz']>;
  ViewerId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Max_Order_By = {
  Id?: Maybe<Order_By>;
  MessageId?: Maybe<Order_By>;
  ViewedDate?: Maybe<Order_By>;
  ViewerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SupportChatMessageViewers_Min_Fields = {
  __typename?: 'SupportChatMessageViewers_min_fields';
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  ViewedDate?: Maybe<Scalars['timestamptz']>;
  ViewerId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Min_Order_By = {
  Id?: Maybe<Order_By>;
  MessageId?: Maybe<Order_By>;
  ViewedDate?: Maybe<Order_By>;
  ViewerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Mutation_Response = {
  __typename?: 'SupportChatMessageViewers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SupportChatMessageViewers>;
};

/** on conflict condition type for table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_On_Conflict = {
  constraint: SupportChatMessageViewers_Constraint;
  update_columns?: Array<SupportChatMessageViewers_Update_Column>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};

/** Ordering options when selecting data from "SupportChatMessageViewers". */
export type SupportChatMessageViewers_Order_By = {
  Deleted?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  MessageId?: Maybe<Order_By>;
  SupportChatMember?: Maybe<SupportChatMembers_Order_By>;
  SupportChatMessage?: Maybe<SupportChatMessages_Order_By>;
  ViewedDate?: Maybe<Order_By>;
  ViewerId?: Maybe<Order_By>;
};

/** primary key columns input for table: SupportChatMessageViewers */
export type SupportChatMessageViewers_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "SupportChatMessageViewers" */
export enum SupportChatMessageViewers_Select_Column {
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  MessageId = 'MessageId',
  /** column name */
  ViewedDate = 'ViewedDate',
  /** column name */
  ViewerId = 'ViewerId'
}

/** input type for updating data in table "SupportChatMessageViewers" */
export type SupportChatMessageViewers_Set_Input = {
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  MessageId?: Maybe<Scalars['uuid']>;
  ViewedDate?: Maybe<Scalars['timestamptz']>;
  ViewerId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "SupportChatMessageViewers" */
export enum SupportChatMessageViewers_Update_Column {
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  MessageId = 'MessageId',
  /** column name */
  ViewedDate = 'ViewedDate',
  /** column name */
  ViewerId = 'ViewerId'
}

/** columns and relationships of "SupportChatMessages" */
export type SupportChatMessages = {
  __typename?: 'SupportChatMessages';
  AuthorId: Scalars['uuid'];
  AuthorRole?: Maybe<Scalars['String']>;
  CreatedDate: Scalars['timestamptz'];
  Deleted: Scalars['Boolean'];
  /** An object relationship */
  FileItem?: Maybe<FileItems>;
  FileItemId?: Maybe<Scalars['bigint']>;
  Id: Scalars['uuid'];
  /** An array relationship */
  Notifications: Array<Notifications>;
  /** An aggregate relationship */
  Notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  SupportChat: SupportChats;
  SupportChatId: Scalars['uuid'];
  /** An object relationship */
  SupportChatMember: SupportChatMembers;
  /** An array relationship */
  SupportChatMessageViewers: Array<SupportChatMessageViewers>;
  /** An aggregate relationship */
  SupportChatMessageViewers_aggregate: SupportChatMessageViewers_Aggregate;
  TextValue?: Maybe<Scalars['String']>;
  ValueType: Scalars['smallint'];
};


/** columns and relationships of "SupportChatMessages" */
export type SupportChatMessagesNotificationsArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "SupportChatMessages" */
export type SupportChatMessagesNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "SupportChatMessages" */
export type SupportChatMessagesSupportChatMessageViewersArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};


/** columns and relationships of "SupportChatMessages" */
export type SupportChatMessagesSupportChatMessageViewers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};

/** aggregated selection of "SupportChatMessages" */
export type SupportChatMessages_Aggregate = {
  __typename?: 'SupportChatMessages_aggregate';
  aggregate?: Maybe<SupportChatMessages_Aggregate_Fields>;
  nodes: Array<SupportChatMessages>;
};

/** aggregate fields of "SupportChatMessages" */
export type SupportChatMessages_Aggregate_Fields = {
  __typename?: 'SupportChatMessages_aggregate_fields';
  avg?: Maybe<SupportChatMessages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SupportChatMessages_Max_Fields>;
  min?: Maybe<SupportChatMessages_Min_Fields>;
  stddev?: Maybe<SupportChatMessages_Stddev_Fields>;
  stddev_pop?: Maybe<SupportChatMessages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SupportChatMessages_Stddev_Samp_Fields>;
  sum?: Maybe<SupportChatMessages_Sum_Fields>;
  var_pop?: Maybe<SupportChatMessages_Var_Pop_Fields>;
  var_samp?: Maybe<SupportChatMessages_Var_Samp_Fields>;
  variance?: Maybe<SupportChatMessages_Variance_Fields>;
};


/** aggregate fields of "SupportChatMessages" */
export type SupportChatMessages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SupportChatMessages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SupportChatMessages" */
export type SupportChatMessages_Aggregate_Order_By = {
  avg?: Maybe<SupportChatMessages_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SupportChatMessages_Max_Order_By>;
  min?: Maybe<SupportChatMessages_Min_Order_By>;
  stddev?: Maybe<SupportChatMessages_Stddev_Order_By>;
  stddev_pop?: Maybe<SupportChatMessages_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SupportChatMessages_Stddev_Samp_Order_By>;
  sum?: Maybe<SupportChatMessages_Sum_Order_By>;
  var_pop?: Maybe<SupportChatMessages_Var_Pop_Order_By>;
  var_samp?: Maybe<SupportChatMessages_Var_Samp_Order_By>;
  variance?: Maybe<SupportChatMessages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "SupportChatMessages" */
export type SupportChatMessages_Arr_Rel_Insert_Input = {
  data: Array<SupportChatMessages_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChatMessages_On_Conflict>;
};

/** aggregate avg on columns */
export type SupportChatMessages_Avg_Fields = {
  __typename?: 'SupportChatMessages_avg_fields';
  FileItemId?: Maybe<Scalars['Float']>;
  ValueType?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Avg_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SupportChatMessages". All fields are combined with a logical 'AND'. */
export type SupportChatMessages_Bool_Exp = {
  AuthorId?: Maybe<Uuid_Comparison_Exp>;
  AuthorRole?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  Deleted?: Maybe<Boolean_Comparison_Exp>;
  FileItem?: Maybe<FileItems_Bool_Exp>;
  FileItemId?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Notifications?: Maybe<Notifications_Bool_Exp>;
  SupportChat?: Maybe<SupportChats_Bool_Exp>;
  SupportChatId?: Maybe<Uuid_Comparison_Exp>;
  SupportChatMember?: Maybe<SupportChatMembers_Bool_Exp>;
  SupportChatMessageViewers?: Maybe<SupportChatMessageViewers_Bool_Exp>;
  TextValue?: Maybe<String_Comparison_Exp>;
  ValueType?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<SupportChatMessages_Bool_Exp>>;
  _not?: Maybe<SupportChatMessages_Bool_Exp>;
  _or?: Maybe<Array<SupportChatMessages_Bool_Exp>>;
};

/** unique or primary key constraints on table "SupportChatMessages" */
export enum SupportChatMessages_Constraint {
  /** unique or primary key constraint */
  PkSupportChatMessages = 'PK_SupportChatMessages'
}

/** input type for incrementing numeric columns in table "SupportChatMessages" */
export type SupportChatMessages_Inc_Input = {
  FileItemId?: Maybe<Scalars['bigint']>;
  ValueType?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "SupportChatMessages" */
export type SupportChatMessages_Insert_Input = {
  AuthorId?: Maybe<Scalars['uuid']>;
  AuthorRole?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['timestamptz']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  FileItem?: Maybe<FileItems_Obj_Rel_Insert_Input>;
  FileItemId?: Maybe<Scalars['bigint']>;
  Id?: Maybe<Scalars['uuid']>;
  Notifications?: Maybe<Notifications_Arr_Rel_Insert_Input>;
  SupportChat?: Maybe<SupportChats_Obj_Rel_Insert_Input>;
  SupportChatId?: Maybe<Scalars['uuid']>;
  SupportChatMember?: Maybe<SupportChatMembers_Obj_Rel_Insert_Input>;
  SupportChatMessageViewers?: Maybe<SupportChatMessageViewers_Arr_Rel_Insert_Input>;
  TextValue?: Maybe<Scalars['String']>;
  ValueType?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type SupportChatMessages_Max_Fields = {
  __typename?: 'SupportChatMessages_max_fields';
  AuthorId?: Maybe<Scalars['uuid']>;
  AuthorRole?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['timestamptz']>;
  FileItemId?: Maybe<Scalars['bigint']>;
  Id?: Maybe<Scalars['uuid']>;
  SupportChatId?: Maybe<Scalars['uuid']>;
  TextValue?: Maybe<Scalars['String']>;
  ValueType?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Max_Order_By = {
  AuthorId?: Maybe<Order_By>;
  AuthorRole?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  FileItemId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  SupportChatId?: Maybe<Order_By>;
  TextValue?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SupportChatMessages_Min_Fields = {
  __typename?: 'SupportChatMessages_min_fields';
  AuthorId?: Maybe<Scalars['uuid']>;
  AuthorRole?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['timestamptz']>;
  FileItemId?: Maybe<Scalars['bigint']>;
  Id?: Maybe<Scalars['uuid']>;
  SupportChatId?: Maybe<Scalars['uuid']>;
  TextValue?: Maybe<Scalars['String']>;
  ValueType?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Min_Order_By = {
  AuthorId?: Maybe<Order_By>;
  AuthorRole?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  FileItemId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  SupportChatId?: Maybe<Order_By>;
  TextValue?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** response of any mutation on the table "SupportChatMessages" */
export type SupportChatMessages_Mutation_Response = {
  __typename?: 'SupportChatMessages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SupportChatMessages>;
};

/** input type for inserting object relation for remote table "SupportChatMessages" */
export type SupportChatMessages_Obj_Rel_Insert_Input = {
  data: SupportChatMessages_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChatMessages_On_Conflict>;
};

/** on conflict condition type for table "SupportChatMessages" */
export type SupportChatMessages_On_Conflict = {
  constraint: SupportChatMessages_Constraint;
  update_columns?: Array<SupportChatMessages_Update_Column>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};

/** Ordering options when selecting data from "SupportChatMessages". */
export type SupportChatMessages_Order_By = {
  AuthorId?: Maybe<Order_By>;
  AuthorRole?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Deleted?: Maybe<Order_By>;
  FileItem?: Maybe<FileItems_Order_By>;
  FileItemId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Notifications_aggregate?: Maybe<Notifications_Aggregate_Order_By>;
  SupportChat?: Maybe<SupportChats_Order_By>;
  SupportChatId?: Maybe<Order_By>;
  SupportChatMember?: Maybe<SupportChatMembers_Order_By>;
  SupportChatMessageViewers_aggregate?: Maybe<SupportChatMessageViewers_Aggregate_Order_By>;
  TextValue?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** primary key columns input for table: SupportChatMessages */
export type SupportChatMessages_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "SupportChatMessages" */
export enum SupportChatMessages_Select_Column {
  /** column name */
  AuthorId = 'AuthorId',
  /** column name */
  AuthorRole = 'AuthorRole',
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  FileItemId = 'FileItemId',
  /** column name */
  Id = 'Id',
  /** column name */
  SupportChatId = 'SupportChatId',
  /** column name */
  TextValue = 'TextValue',
  /** column name */
  ValueType = 'ValueType'
}

/** input type for updating data in table "SupportChatMessages" */
export type SupportChatMessages_Set_Input = {
  AuthorId?: Maybe<Scalars['uuid']>;
  AuthorRole?: Maybe<Scalars['String']>;
  CreatedDate?: Maybe<Scalars['timestamptz']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  FileItemId?: Maybe<Scalars['bigint']>;
  Id?: Maybe<Scalars['uuid']>;
  SupportChatId?: Maybe<Scalars['uuid']>;
  TextValue?: Maybe<Scalars['String']>;
  ValueType?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type SupportChatMessages_Stddev_Fields = {
  __typename?: 'SupportChatMessages_stddev_fields';
  FileItemId?: Maybe<Scalars['Float']>;
  ValueType?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Stddev_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SupportChatMessages_Stddev_Pop_Fields = {
  __typename?: 'SupportChatMessages_stddev_pop_fields';
  FileItemId?: Maybe<Scalars['Float']>;
  ValueType?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Stddev_Pop_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SupportChatMessages_Stddev_Samp_Fields = {
  __typename?: 'SupportChatMessages_stddev_samp_fields';
  FileItemId?: Maybe<Scalars['Float']>;
  ValueType?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Stddev_Samp_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SupportChatMessages_Sum_Fields = {
  __typename?: 'SupportChatMessages_sum_fields';
  FileItemId?: Maybe<Scalars['bigint']>;
  ValueType?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Sum_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** update columns of table "SupportChatMessages" */
export enum SupportChatMessages_Update_Column {
  /** column name */
  AuthorId = 'AuthorId',
  /** column name */
  AuthorRole = 'AuthorRole',
  /** column name */
  CreatedDate = 'CreatedDate',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  FileItemId = 'FileItemId',
  /** column name */
  Id = 'Id',
  /** column name */
  SupportChatId = 'SupportChatId',
  /** column name */
  TextValue = 'TextValue',
  /** column name */
  ValueType = 'ValueType'
}

/** aggregate var_pop on columns */
export type SupportChatMessages_Var_Pop_Fields = {
  __typename?: 'SupportChatMessages_var_pop_fields';
  FileItemId?: Maybe<Scalars['Float']>;
  ValueType?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Var_Pop_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SupportChatMessages_Var_Samp_Fields = {
  __typename?: 'SupportChatMessages_var_samp_fields';
  FileItemId?: Maybe<Scalars['Float']>;
  ValueType?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Var_Samp_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SupportChatMessages_Variance_Fields = {
  __typename?: 'SupportChatMessages_variance_fields';
  FileItemId?: Maybe<Scalars['Float']>;
  ValueType?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SupportChatMessages" */
export type SupportChatMessages_Variance_Order_By = {
  FileItemId?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
};

/** columns and relationships of "SupportChats" */
export type SupportChats = {
  __typename?: 'SupportChats';
  AuthorInfo?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  Deleted: Scalars['Boolean'];
  Id: Scalars['uuid'];
  /** An object relationship */
  ProjectGroup: ProjectGroups;
  ProjectGroupId: Scalars['uuid'];
  StartDate: Scalars['timestamptz'];
  StopDate?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  SupportChatMembers: Array<SupportChatMembers>;
  /** An aggregate relationship */
  SupportChatMembers_aggregate: SupportChatMembers_Aggregate;
  /** An array relationship */
  SupportChatMessages: Array<SupportChatMessages>;
  /** An aggregate relationship */
  SupportChatMessages_aggregate: SupportChatMessages_Aggregate;
  /** An object relationship */
  SupportSubject?: Maybe<SupportSubjects>;
  SupportSubjectId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "SupportChats" */
export type SupportChatsSupportChatMembersArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};


/** columns and relationships of "SupportChats" */
export type SupportChatsSupportChatMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};


/** columns and relationships of "SupportChats" */
export type SupportChatsSupportChatMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};


/** columns and relationships of "SupportChats" */
export type SupportChatsSupportChatMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};

/** aggregated selection of "SupportChats" */
export type SupportChats_Aggregate = {
  __typename?: 'SupportChats_aggregate';
  aggregate?: Maybe<SupportChats_Aggregate_Fields>;
  nodes: Array<SupportChats>;
};

/** aggregate fields of "SupportChats" */
export type SupportChats_Aggregate_Fields = {
  __typename?: 'SupportChats_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SupportChats_Max_Fields>;
  min?: Maybe<SupportChats_Min_Fields>;
};


/** aggregate fields of "SupportChats" */
export type SupportChats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SupportChats_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SupportChats" */
export type SupportChats_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SupportChats_Max_Order_By>;
  min?: Maybe<SupportChats_Min_Order_By>;
};

/** input type for inserting array relation for remote table "SupportChats" */
export type SupportChats_Arr_Rel_Insert_Input = {
  data: Array<SupportChats_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChats_On_Conflict>;
};

/** Boolean expression to filter rows from the table "SupportChats". All fields are combined with a logical 'AND'. */
export type SupportChats_Bool_Exp = {
  AuthorInfo?: Maybe<String_Comparison_Exp>;
  ClosedBy?: Maybe<String_Comparison_Exp>;
  Deleted?: Maybe<Boolean_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  ProjectGroup?: Maybe<ProjectGroups_Bool_Exp>;
  ProjectGroupId?: Maybe<Uuid_Comparison_Exp>;
  StartDate?: Maybe<Timestamptz_Comparison_Exp>;
  StopDate?: Maybe<Timestamptz_Comparison_Exp>;
  SupportChatMembers?: Maybe<SupportChatMembers_Bool_Exp>;
  SupportChatMessages?: Maybe<SupportChatMessages_Bool_Exp>;
  SupportSubject?: Maybe<SupportSubjects_Bool_Exp>;
  SupportSubjectId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<SupportChats_Bool_Exp>>;
  _not?: Maybe<SupportChats_Bool_Exp>;
  _or?: Maybe<Array<SupportChats_Bool_Exp>>;
};

/** unique or primary key constraints on table "SupportChats" */
export enum SupportChats_Constraint {
  /** unique or primary key constraint */
  PkSupportChats = 'PK_SupportChats'
}

/** input type for inserting data into table "SupportChats" */
export type SupportChats_Insert_Input = {
  AuthorInfo?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroup?: Maybe<ProjectGroups_Obj_Rel_Insert_Input>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamptz']>;
  StopDate?: Maybe<Scalars['timestamptz']>;
  SupportChatMembers?: Maybe<SupportChatMembers_Arr_Rel_Insert_Input>;
  SupportChatMessages?: Maybe<SupportChatMessages_Arr_Rel_Insert_Input>;
  SupportSubject?: Maybe<SupportSubjects_Obj_Rel_Insert_Input>;
  SupportSubjectId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SupportChats_Max_Fields = {
  __typename?: 'SupportChats_max_fields';
  AuthorInfo?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamptz']>;
  StopDate?: Maybe<Scalars['timestamptz']>;
  SupportSubjectId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "SupportChats" */
export type SupportChats_Max_Order_By = {
  AuthorInfo?: Maybe<Order_By>;
  ClosedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  StopDate?: Maybe<Order_By>;
  SupportSubjectId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SupportChats_Min_Fields = {
  __typename?: 'SupportChats_min_fields';
  AuthorInfo?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamptz']>;
  StopDate?: Maybe<Scalars['timestamptz']>;
  SupportSubjectId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "SupportChats" */
export type SupportChats_Min_Order_By = {
  AuthorInfo?: Maybe<Order_By>;
  ClosedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  StopDate?: Maybe<Order_By>;
  SupportSubjectId?: Maybe<Order_By>;
};

/** response of any mutation on the table "SupportChats" */
export type SupportChats_Mutation_Response = {
  __typename?: 'SupportChats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SupportChats>;
};

/** input type for inserting object relation for remote table "SupportChats" */
export type SupportChats_Obj_Rel_Insert_Input = {
  data: SupportChats_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<SupportChats_On_Conflict>;
};

/** on conflict condition type for table "SupportChats" */
export type SupportChats_On_Conflict = {
  constraint: SupportChats_Constraint;
  update_columns?: Array<SupportChats_Update_Column>;
  where?: Maybe<SupportChats_Bool_Exp>;
};

/** Ordering options when selecting data from "SupportChats". */
export type SupportChats_Order_By = {
  AuthorInfo?: Maybe<Order_By>;
  ClosedBy?: Maybe<Order_By>;
  Deleted?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ProjectGroup?: Maybe<ProjectGroups_Order_By>;
  ProjectGroupId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  StopDate?: Maybe<Order_By>;
  SupportChatMembers_aggregate?: Maybe<SupportChatMembers_Aggregate_Order_By>;
  SupportChatMessages_aggregate?: Maybe<SupportChatMessages_Aggregate_Order_By>;
  SupportSubject?: Maybe<SupportSubjects_Order_By>;
  SupportSubjectId?: Maybe<Order_By>;
};

/** primary key columns input for table: SupportChats */
export type SupportChats_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "SupportChats" */
export enum SupportChats_Select_Column {
  /** column name */
  AuthorInfo = 'AuthorInfo',
  /** column name */
  ClosedBy = 'ClosedBy',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  ProjectGroupId = 'ProjectGroupId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  StopDate = 'StopDate',
  /** column name */
  SupportSubjectId = 'SupportSubjectId'
}

/** input type for updating data in table "SupportChats" */
export type SupportChats_Set_Input = {
  AuthorInfo?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  Id?: Maybe<Scalars['uuid']>;
  ProjectGroupId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamptz']>;
  StopDate?: Maybe<Scalars['timestamptz']>;
  SupportSubjectId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "SupportChats" */
export enum SupportChats_Update_Column {
  /** column name */
  AuthorInfo = 'AuthorInfo',
  /** column name */
  ClosedBy = 'ClosedBy',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Id = 'Id',
  /** column name */
  ProjectGroupId = 'ProjectGroupId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  StopDate = 'StopDate',
  /** column name */
  SupportSubjectId = 'SupportSubjectId'
}

/** columns and relationships of "SupportSubjects" */
export type SupportSubjects = {
  __typename?: 'SupportSubjects';
  /** An array relationship */
  Children: Array<SupportSubjects>;
  /** An aggregate relationship */
  Children_aggregate: SupportSubjects_Aggregate;
  ContainsPFDUnitLinksForCurrentProject: Scalars['Boolean'];
  Deleted: Scalars['Boolean'];
  Description?: Maybe<Scalars['String']>;
  Id: Scalars['uuid'];
  IsActive: Scalars['Boolean'];
  IsEnabledForOperatorChat: Scalars['Boolean'];
  Order: Scalars['Int'];
  /** An object relationship */
  Parent?: Maybe<SupportSubjects>;
  ParentId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  SupportChats: Array<SupportChats>;
  /** An aggregate relationship */
  SupportChats_aggregate: SupportChats_Aggregate;
  Title: Scalars['String'];
};


/** columns and relationships of "SupportSubjects" */
export type SupportSubjectsChildrenArgs = {
  distinct_on?: Maybe<Array<SupportSubjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportSubjects_Order_By>>;
  where?: Maybe<SupportSubjects_Bool_Exp>;
};


/** columns and relationships of "SupportSubjects" */
export type SupportSubjectsChildren_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportSubjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportSubjects_Order_By>>;
  where?: Maybe<SupportSubjects_Bool_Exp>;
};


/** columns and relationships of "SupportSubjects" */
export type SupportSubjectsSupportChatsArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};


/** columns and relationships of "SupportSubjects" */
export type SupportSubjectsSupportChats_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};

/** aggregated selection of "SupportSubjects" */
export type SupportSubjects_Aggregate = {
  __typename?: 'SupportSubjects_aggregate';
  aggregate?: Maybe<SupportSubjects_Aggregate_Fields>;
  nodes: Array<SupportSubjects>;
};

/** aggregate fields of "SupportSubjects" */
export type SupportSubjects_Aggregate_Fields = {
  __typename?: 'SupportSubjects_aggregate_fields';
  avg?: Maybe<SupportSubjects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SupportSubjects_Max_Fields>;
  min?: Maybe<SupportSubjects_Min_Fields>;
  stddev?: Maybe<SupportSubjects_Stddev_Fields>;
  stddev_pop?: Maybe<SupportSubjects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SupportSubjects_Stddev_Samp_Fields>;
  sum?: Maybe<SupportSubjects_Sum_Fields>;
  var_pop?: Maybe<SupportSubjects_Var_Pop_Fields>;
  var_samp?: Maybe<SupportSubjects_Var_Samp_Fields>;
  variance?: Maybe<SupportSubjects_Variance_Fields>;
};


/** aggregate fields of "SupportSubjects" */
export type SupportSubjects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SupportSubjects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "SupportSubjects" */
export type SupportSubjects_Aggregate_Order_By = {
  avg?: Maybe<SupportSubjects_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SupportSubjects_Max_Order_By>;
  min?: Maybe<SupportSubjects_Min_Order_By>;
  stddev?: Maybe<SupportSubjects_Stddev_Order_By>;
  stddev_pop?: Maybe<SupportSubjects_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SupportSubjects_Stddev_Samp_Order_By>;
  sum?: Maybe<SupportSubjects_Sum_Order_By>;
  var_pop?: Maybe<SupportSubjects_Var_Pop_Order_By>;
  var_samp?: Maybe<SupportSubjects_Var_Samp_Order_By>;
  variance?: Maybe<SupportSubjects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "SupportSubjects" */
export type SupportSubjects_Arr_Rel_Insert_Input = {
  data: Array<SupportSubjects_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<SupportSubjects_On_Conflict>;
};

/** aggregate avg on columns */
export type SupportSubjects_Avg_Fields = {
  __typename?: 'SupportSubjects_avg_fields';
  Order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "SupportSubjects" */
export type SupportSubjects_Avg_Order_By = {
  Order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "SupportSubjects". All fields are combined with a logical 'AND'. */
export type SupportSubjects_Bool_Exp = {
  Children?: Maybe<SupportSubjects_Bool_Exp>;
  ContainsPFDUnitLinksForCurrentProject?: Maybe<Boolean_Comparison_Exp>;
  Deleted?: Maybe<Boolean_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  IsActive?: Maybe<Boolean_Comparison_Exp>;
  IsEnabledForOperatorChat?: Maybe<Boolean_Comparison_Exp>;
  Order?: Maybe<Int_Comparison_Exp>;
  Parent?: Maybe<SupportSubjects_Bool_Exp>;
  ParentId?: Maybe<Uuid_Comparison_Exp>;
  SupportChats?: Maybe<SupportChats_Bool_Exp>;
  Title?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<SupportSubjects_Bool_Exp>>;
  _not?: Maybe<SupportSubjects_Bool_Exp>;
  _or?: Maybe<Array<SupportSubjects_Bool_Exp>>;
};

/** unique or primary key constraints on table "SupportSubjects" */
export enum SupportSubjects_Constraint {
  /** unique or primary key constraint */
  PkSupportSubjects = 'PK_SupportSubjects'
}

/** input type for incrementing numeric columns in table "SupportSubjects" */
export type SupportSubjects_Inc_Input = {
  Order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "SupportSubjects" */
export type SupportSubjects_Insert_Input = {
  Children?: Maybe<SupportSubjects_Arr_Rel_Insert_Input>;
  ContainsPFDUnitLinksForCurrentProject?: Maybe<Scalars['Boolean']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsEnabledForOperatorChat?: Maybe<Scalars['Boolean']>;
  Order?: Maybe<Scalars['Int']>;
  Parent?: Maybe<SupportSubjects_Obj_Rel_Insert_Input>;
  ParentId?: Maybe<Scalars['uuid']>;
  SupportChats?: Maybe<SupportChats_Arr_Rel_Insert_Input>;
  Title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SupportSubjects_Max_Fields = {
  __typename?: 'SupportSubjects_max_fields';
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Order?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "SupportSubjects" */
export type SupportSubjects_Max_Order_By = {
  Description?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
  ParentId?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SupportSubjects_Min_Fields = {
  __typename?: 'SupportSubjects_min_fields';
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  Order?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "SupportSubjects" */
export type SupportSubjects_Min_Order_By = {
  Description?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
  ParentId?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
};

/** response of any mutation on the table "SupportSubjects" */
export type SupportSubjects_Mutation_Response = {
  __typename?: 'SupportSubjects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SupportSubjects>;
};

/** input type for inserting object relation for remote table "SupportSubjects" */
export type SupportSubjects_Obj_Rel_Insert_Input = {
  data: SupportSubjects_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<SupportSubjects_On_Conflict>;
};

/** on conflict condition type for table "SupportSubjects" */
export type SupportSubjects_On_Conflict = {
  constraint: SupportSubjects_Constraint;
  update_columns?: Array<SupportSubjects_Update_Column>;
  where?: Maybe<SupportSubjects_Bool_Exp>;
};

/** Ordering options when selecting data from "SupportSubjects". */
export type SupportSubjects_Order_By = {
  Children_aggregate?: Maybe<SupportSubjects_Aggregate_Order_By>;
  ContainsPFDUnitLinksForCurrentProject?: Maybe<Order_By>;
  Deleted?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsActive?: Maybe<Order_By>;
  IsEnabledForOperatorChat?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
  Parent?: Maybe<SupportSubjects_Order_By>;
  ParentId?: Maybe<Order_By>;
  SupportChats_aggregate?: Maybe<SupportChats_Aggregate_Order_By>;
  Title?: Maybe<Order_By>;
};

/** primary key columns input for table: SupportSubjects */
export type SupportSubjects_Pk_Columns_Input = {
  Id: Scalars['uuid'];
};

/** select columns of table "SupportSubjects" */
export enum SupportSubjects_Select_Column {
  /** column name */
  ContainsPfdUnitLinksForCurrentProject = 'ContainsPFDUnitLinksForCurrentProject',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  IsActive = 'IsActive',
  /** column name */
  IsEnabledForOperatorChat = 'IsEnabledForOperatorChat',
  /** column name */
  Order = 'Order',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "SupportSubjects" */
export type SupportSubjects_Set_Input = {
  ContainsPFDUnitLinksForCurrentProject?: Maybe<Scalars['Boolean']>;
  Deleted?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['uuid']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsEnabledForOperatorChat?: Maybe<Scalars['Boolean']>;
  Order?: Maybe<Scalars['Int']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SupportSubjects_Stddev_Fields = {
  __typename?: 'SupportSubjects_stddev_fields';
  Order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "SupportSubjects" */
export type SupportSubjects_Stddev_Order_By = {
  Order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SupportSubjects_Stddev_Pop_Fields = {
  __typename?: 'SupportSubjects_stddev_pop_fields';
  Order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "SupportSubjects" */
export type SupportSubjects_Stddev_Pop_Order_By = {
  Order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SupportSubjects_Stddev_Samp_Fields = {
  __typename?: 'SupportSubjects_stddev_samp_fields';
  Order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "SupportSubjects" */
export type SupportSubjects_Stddev_Samp_Order_By = {
  Order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SupportSubjects_Sum_Fields = {
  __typename?: 'SupportSubjects_sum_fields';
  Order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "SupportSubjects" */
export type SupportSubjects_Sum_Order_By = {
  Order?: Maybe<Order_By>;
};

/** update columns of table "SupportSubjects" */
export enum SupportSubjects_Update_Column {
  /** column name */
  ContainsPfdUnitLinksForCurrentProject = 'ContainsPFDUnitLinksForCurrentProject',
  /** column name */
  Deleted = 'Deleted',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  IsActive = 'IsActive',
  /** column name */
  IsEnabledForOperatorChat = 'IsEnabledForOperatorChat',
  /** column name */
  Order = 'Order',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Title = 'Title'
}

/** aggregate var_pop on columns */
export type SupportSubjects_Var_Pop_Fields = {
  __typename?: 'SupportSubjects_var_pop_fields';
  Order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "SupportSubjects" */
export type SupportSubjects_Var_Pop_Order_By = {
  Order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SupportSubjects_Var_Samp_Fields = {
  __typename?: 'SupportSubjects_var_samp_fields';
  Order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "SupportSubjects" */
export type SupportSubjects_Var_Samp_Order_By = {
  Order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SupportSubjects_Variance_Fields = {
  __typename?: 'SupportSubjects_variance_fields';
  Order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "SupportSubjects" */
export type SupportSubjects_Variance_Order_By = {
  Order?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "ClientGroups" */
  delete_ClientGroups?: Maybe<ClientGroups_Mutation_Response>;
  /** delete data from the table: "ClientGroupsSmartlabUsers" */
  delete_ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsers_Mutation_Response>;
  /** delete single row from the table: "ClientGroupsSmartlabUsers" */
  delete_ClientGroupsSmartlabUsers_by_pk?: Maybe<ClientGroupsSmartlabUsers>;
  /** delete single row from the table: "ClientGroups" */
  delete_ClientGroups_by_pk?: Maybe<ClientGroups>;
  /** delete data from the table: "FileItems" */
  delete_FileItems?: Maybe<FileItems_Mutation_Response>;
  /** delete single row from the table: "FileItems" */
  delete_FileItems_by_pk?: Maybe<FileItems>;
  /** delete data from the table: "FileTypes" */
  delete_FileTypes?: Maybe<FileTypes_Mutation_Response>;
  /** delete single row from the table: "FileTypes" */
  delete_FileTypes_by_pk?: Maybe<FileTypes>;
  /** delete data from the table: "NotificationTypes" */
  delete_NotificationTypes?: Maybe<NotificationTypes_Mutation_Response>;
  /** delete single row from the table: "NotificationTypes" */
  delete_NotificationTypes_by_pk?: Maybe<NotificationTypes>;
  /** delete data from the table: "Notifications" */
  delete_Notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "Notifications" */
  delete_Notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "PfdProjects" */
  delete_PfdProjects?: Maybe<PfdProjects_Mutation_Response>;
  /** delete single row from the table: "PfdProjects" */
  delete_PfdProjects_by_pk?: Maybe<PfdProjects>;
  /** delete data from the table: "ProjectGroups" */
  delete_ProjectGroups?: Maybe<ProjectGroups_Mutation_Response>;
  /** delete data from the table: "ProjectGroupsSmartlabUsers" */
  delete_ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsers_Mutation_Response>;
  /** delete single row from the table: "ProjectGroupsSmartlabUsers" */
  delete_ProjectGroupsSmartlabUsers_by_pk?: Maybe<ProjectGroupsSmartlabUsers>;
  /** delete single row from the table: "ProjectGroups" */
  delete_ProjectGroups_by_pk?: Maybe<ProjectGroups>;
  /** delete data from the table: "SmartlabUsers" */
  delete_SmartlabUsers?: Maybe<SmartlabUsers_Mutation_Response>;
  /** delete single row from the table: "SmartlabUsers" */
  delete_SmartlabUsers_by_pk?: Maybe<SmartlabUsers>;
  /** delete data from the table: "SupportChatAutoMessages" */
  delete_SupportChatAutoMessages?: Maybe<SupportChatAutoMessages_Mutation_Response>;
  /** delete single row from the table: "SupportChatAutoMessages" */
  delete_SupportChatAutoMessages_by_pk?: Maybe<SupportChatAutoMessages>;
  /** delete data from the table: "SupportChatMembers" */
  delete_SupportChatMembers?: Maybe<SupportChatMembers_Mutation_Response>;
  /** delete single row from the table: "SupportChatMembers" */
  delete_SupportChatMembers_by_pk?: Maybe<SupportChatMembers>;
  /** delete data from the table: "SupportChatMessageViewers" */
  delete_SupportChatMessageViewers?: Maybe<SupportChatMessageViewers_Mutation_Response>;
  /** delete single row from the table: "SupportChatMessageViewers" */
  delete_SupportChatMessageViewers_by_pk?: Maybe<SupportChatMessageViewers>;
  /** delete data from the table: "SupportChatMessages" */
  delete_SupportChatMessages?: Maybe<SupportChatMessages_Mutation_Response>;
  /** delete single row from the table: "SupportChatMessages" */
  delete_SupportChatMessages_by_pk?: Maybe<SupportChatMessages>;
  /** delete data from the table: "SupportChats" */
  delete_SupportChats?: Maybe<SupportChats_Mutation_Response>;
  /** delete single row from the table: "SupportChats" */
  delete_SupportChats_by_pk?: Maybe<SupportChats>;
  /** delete data from the table: "SupportSubjects" */
  delete_SupportSubjects?: Maybe<SupportSubjects_Mutation_Response>;
  /** delete single row from the table: "SupportSubjects" */
  delete_SupportSubjects_by_pk?: Maybe<SupportSubjects>;
  /** insert data into the table: "ClientGroups" */
  insert_ClientGroups?: Maybe<ClientGroups_Mutation_Response>;
  /** insert data into the table: "ClientGroupsSmartlabUsers" */
  insert_ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsers_Mutation_Response>;
  /** insert a single row into the table: "ClientGroupsSmartlabUsers" */
  insert_ClientGroupsSmartlabUsers_one?: Maybe<ClientGroupsSmartlabUsers>;
  /** insert a single row into the table: "ClientGroups" */
  insert_ClientGroups_one?: Maybe<ClientGroups>;
  /** insert data into the table: "FileItems" */
  insert_FileItems?: Maybe<FileItems_Mutation_Response>;
  /** insert a single row into the table: "FileItems" */
  insert_FileItems_one?: Maybe<FileItems>;
  /** insert data into the table: "FileTypes" */
  insert_FileTypes?: Maybe<FileTypes_Mutation_Response>;
  /** insert a single row into the table: "FileTypes" */
  insert_FileTypes_one?: Maybe<FileTypes>;
  /** insert data into the table: "NotificationTypes" */
  insert_NotificationTypes?: Maybe<NotificationTypes_Mutation_Response>;
  /** insert a single row into the table: "NotificationTypes" */
  insert_NotificationTypes_one?: Maybe<NotificationTypes>;
  /** insert data into the table: "Notifications" */
  insert_Notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "Notifications" */
  insert_Notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "PfdProjects" */
  insert_PfdProjects?: Maybe<PfdProjects_Mutation_Response>;
  /** insert a single row into the table: "PfdProjects" */
  insert_PfdProjects_one?: Maybe<PfdProjects>;
  /** insert data into the table: "ProjectGroups" */
  insert_ProjectGroups?: Maybe<ProjectGroups_Mutation_Response>;
  /** insert data into the table: "ProjectGroupsSmartlabUsers" */
  insert_ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsers_Mutation_Response>;
  /** insert a single row into the table: "ProjectGroupsSmartlabUsers" */
  insert_ProjectGroupsSmartlabUsers_one?: Maybe<ProjectGroupsSmartlabUsers>;
  /** insert a single row into the table: "ProjectGroups" */
  insert_ProjectGroups_one?: Maybe<ProjectGroups>;
  /** insert data into the table: "SmartlabUsers" */
  insert_SmartlabUsers?: Maybe<SmartlabUsers_Mutation_Response>;
  /** insert a single row into the table: "SmartlabUsers" */
  insert_SmartlabUsers_one?: Maybe<SmartlabUsers>;
  /** insert data into the table: "SupportChatAutoMessages" */
  insert_SupportChatAutoMessages?: Maybe<SupportChatAutoMessages_Mutation_Response>;
  /** insert a single row into the table: "SupportChatAutoMessages" */
  insert_SupportChatAutoMessages_one?: Maybe<SupportChatAutoMessages>;
  /** insert data into the table: "SupportChatMembers" */
  insert_SupportChatMembers?: Maybe<SupportChatMembers_Mutation_Response>;
  /** insert a single row into the table: "SupportChatMembers" */
  insert_SupportChatMembers_one?: Maybe<SupportChatMembers>;
  /** insert data into the table: "SupportChatMessageViewers" */
  insert_SupportChatMessageViewers?: Maybe<SupportChatMessageViewers_Mutation_Response>;
  /** insert a single row into the table: "SupportChatMessageViewers" */
  insert_SupportChatMessageViewers_one?: Maybe<SupportChatMessageViewers>;
  /** insert data into the table: "SupportChatMessages" */
  insert_SupportChatMessages?: Maybe<SupportChatMessages_Mutation_Response>;
  /** insert a single row into the table: "SupportChatMessages" */
  insert_SupportChatMessages_one?: Maybe<SupportChatMessages>;
  /** insert data into the table: "SupportChats" */
  insert_SupportChats?: Maybe<SupportChats_Mutation_Response>;
  /** insert a single row into the table: "SupportChats" */
  insert_SupportChats_one?: Maybe<SupportChats>;
  /** insert data into the table: "SupportSubjects" */
  insert_SupportSubjects?: Maybe<SupportSubjects_Mutation_Response>;
  /** insert a single row into the table: "SupportSubjects" */
  insert_SupportSubjects_one?: Maybe<SupportSubjects>;
  /** update data of the table: "ClientGroups" */
  update_ClientGroups?: Maybe<ClientGroups_Mutation_Response>;
  /** update data of the table: "ClientGroupsSmartlabUsers" */
  update_ClientGroupsSmartlabUsers?: Maybe<ClientGroupsSmartlabUsers_Mutation_Response>;
  /** update single row of the table: "ClientGroupsSmartlabUsers" */
  update_ClientGroupsSmartlabUsers_by_pk?: Maybe<ClientGroupsSmartlabUsers>;
  /** update single row of the table: "ClientGroups" */
  update_ClientGroups_by_pk?: Maybe<ClientGroups>;
  /** update data of the table: "FileItems" */
  update_FileItems?: Maybe<FileItems_Mutation_Response>;
  /** update single row of the table: "FileItems" */
  update_FileItems_by_pk?: Maybe<FileItems>;
  /** update data of the table: "FileTypes" */
  update_FileTypes?: Maybe<FileTypes_Mutation_Response>;
  /** update single row of the table: "FileTypes" */
  update_FileTypes_by_pk?: Maybe<FileTypes>;
  /** update data of the table: "NotificationTypes" */
  update_NotificationTypes?: Maybe<NotificationTypes_Mutation_Response>;
  /** update single row of the table: "NotificationTypes" */
  update_NotificationTypes_by_pk?: Maybe<NotificationTypes>;
  /** update data of the table: "Notifications" */
  update_Notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "Notifications" */
  update_Notifications_by_pk?: Maybe<Notifications>;
  /** update data of the table: "PfdProjects" */
  update_PfdProjects?: Maybe<PfdProjects_Mutation_Response>;
  /** update single row of the table: "PfdProjects" */
  update_PfdProjects_by_pk?: Maybe<PfdProjects>;
  /** update data of the table: "ProjectGroups" */
  update_ProjectGroups?: Maybe<ProjectGroups_Mutation_Response>;
  /** update data of the table: "ProjectGroupsSmartlabUsers" */
  update_ProjectGroupsSmartlabUsers?: Maybe<ProjectGroupsSmartlabUsers_Mutation_Response>;
  /** update single row of the table: "ProjectGroupsSmartlabUsers" */
  update_ProjectGroupsSmartlabUsers_by_pk?: Maybe<ProjectGroupsSmartlabUsers>;
  /** update single row of the table: "ProjectGroups" */
  update_ProjectGroups_by_pk?: Maybe<ProjectGroups>;
  /** update data of the table: "SmartlabUsers" */
  update_SmartlabUsers?: Maybe<SmartlabUsers_Mutation_Response>;
  /** update single row of the table: "SmartlabUsers" */
  update_SmartlabUsers_by_pk?: Maybe<SmartlabUsers>;
  /** update data of the table: "SupportChatAutoMessages" */
  update_SupportChatAutoMessages?: Maybe<SupportChatAutoMessages_Mutation_Response>;
  /** update single row of the table: "SupportChatAutoMessages" */
  update_SupportChatAutoMessages_by_pk?: Maybe<SupportChatAutoMessages>;
  /** update data of the table: "SupportChatMembers" */
  update_SupportChatMembers?: Maybe<SupportChatMembers_Mutation_Response>;
  /** update single row of the table: "SupportChatMembers" */
  update_SupportChatMembers_by_pk?: Maybe<SupportChatMembers>;
  /** update data of the table: "SupportChatMessageViewers" */
  update_SupportChatMessageViewers?: Maybe<SupportChatMessageViewers_Mutation_Response>;
  /** update single row of the table: "SupportChatMessageViewers" */
  update_SupportChatMessageViewers_by_pk?: Maybe<SupportChatMessageViewers>;
  /** update data of the table: "SupportChatMessages" */
  update_SupportChatMessages?: Maybe<SupportChatMessages_Mutation_Response>;
  /** update single row of the table: "SupportChatMessages" */
  update_SupportChatMessages_by_pk?: Maybe<SupportChatMessages>;
  /** update data of the table: "SupportChats" */
  update_SupportChats?: Maybe<SupportChats_Mutation_Response>;
  /** update single row of the table: "SupportChats" */
  update_SupportChats_by_pk?: Maybe<SupportChats>;
  /** update data of the table: "SupportSubjects" */
  update_SupportSubjects?: Maybe<SupportSubjects_Mutation_Response>;
  /** update single row of the table: "SupportSubjects" */
  update_SupportSubjects_by_pk?: Maybe<SupportSubjects>;
};


/** mutation root */
export type Mutation_RootDelete_ClientGroupsArgs = {
  where: ClientGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClientGroupsSmartlabUsersArgs = {
  where: ClientGroupsSmartlabUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ClientGroupsSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ClientGroups_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FileItemsArgs = {
  where: FileItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FileItems_By_PkArgs = {
  Id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_FileTypesArgs = {
  where: FileTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FileTypes_By_PkArgs = {
  Id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationTypesArgs = {
  where: NotificationTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_NotificationTypes_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PfdProjectsArgs = {
  where: PfdProjects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PfdProjects_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectGroupsArgs = {
  where: ProjectGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProjectGroupsSmartlabUsersArgs = {
  where: ProjectGroupsSmartlabUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProjectGroupsSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectGroups_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SmartlabUsersArgs = {
  where: SmartlabUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SupportChatAutoMessagesArgs = {
  where: SupportChatAutoMessages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SupportChatAutoMessages_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SupportChatMembersArgs = {
  where: SupportChatMembers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SupportChatMembers_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SupportChatMessageViewersArgs = {
  where: SupportChatMessageViewers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SupportChatMessageViewers_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SupportChatMessagesArgs = {
  where: SupportChatMessages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SupportChatMessages_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SupportChatsArgs = {
  where: SupportChats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SupportChats_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SupportSubjectsArgs = {
  where: SupportSubjects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_SupportSubjects_By_PkArgs = {
  Id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_ClientGroupsArgs = {
  objects: Array<ClientGroups_Insert_Input>;
  on_conflict?: Maybe<ClientGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientGroupsSmartlabUsersArgs = {
  objects: Array<ClientGroupsSmartlabUsers_Insert_Input>;
  on_conflict?: Maybe<ClientGroupsSmartlabUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientGroupsSmartlabUsers_OneArgs = {
  object: ClientGroupsSmartlabUsers_Insert_Input;
  on_conflict?: Maybe<ClientGroupsSmartlabUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientGroups_OneArgs = {
  object: ClientGroups_Insert_Input;
  on_conflict?: Maybe<ClientGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileItemsArgs = {
  objects: Array<FileItems_Insert_Input>;
  on_conflict?: Maybe<FileItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileItems_OneArgs = {
  object: FileItems_Insert_Input;
  on_conflict?: Maybe<FileItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileTypesArgs = {
  objects: Array<FileTypes_Insert_Input>;
  on_conflict?: Maybe<FileTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileTypes_OneArgs = {
  object: FileTypes_Insert_Input;
  on_conflict?: Maybe<FileTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationTypesArgs = {
  objects: Array<NotificationTypes_Insert_Input>;
  on_conflict?: Maybe<NotificationTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationTypes_OneArgs = {
  object: NotificationTypes_Insert_Input;
  on_conflict?: Maybe<NotificationTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: Maybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: Maybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PfdProjectsArgs = {
  objects: Array<PfdProjects_Insert_Input>;
  on_conflict?: Maybe<PfdProjects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PfdProjects_OneArgs = {
  object: PfdProjects_Insert_Input;
  on_conflict?: Maybe<PfdProjects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectGroupsArgs = {
  objects: Array<ProjectGroups_Insert_Input>;
  on_conflict?: Maybe<ProjectGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectGroupsSmartlabUsersArgs = {
  objects: Array<ProjectGroupsSmartlabUsers_Insert_Input>;
  on_conflict?: Maybe<ProjectGroupsSmartlabUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectGroupsSmartlabUsers_OneArgs = {
  object: ProjectGroupsSmartlabUsers_Insert_Input;
  on_conflict?: Maybe<ProjectGroupsSmartlabUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectGroups_OneArgs = {
  object: ProjectGroups_Insert_Input;
  on_conflict?: Maybe<ProjectGroups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmartlabUsersArgs = {
  objects: Array<SmartlabUsers_Insert_Input>;
  on_conflict?: Maybe<SmartlabUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SmartlabUsers_OneArgs = {
  object: SmartlabUsers_Insert_Input;
  on_conflict?: Maybe<SmartlabUsers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatAutoMessagesArgs = {
  objects: Array<SupportChatAutoMessages_Insert_Input>;
  on_conflict?: Maybe<SupportChatAutoMessages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatAutoMessages_OneArgs = {
  object: SupportChatAutoMessages_Insert_Input;
  on_conflict?: Maybe<SupportChatAutoMessages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatMembersArgs = {
  objects: Array<SupportChatMembers_Insert_Input>;
  on_conflict?: Maybe<SupportChatMembers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatMembers_OneArgs = {
  object: SupportChatMembers_Insert_Input;
  on_conflict?: Maybe<SupportChatMembers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatMessageViewersArgs = {
  objects: Array<SupportChatMessageViewers_Insert_Input>;
  on_conflict?: Maybe<SupportChatMessageViewers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatMessageViewers_OneArgs = {
  object: SupportChatMessageViewers_Insert_Input;
  on_conflict?: Maybe<SupportChatMessageViewers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatMessagesArgs = {
  objects: Array<SupportChatMessages_Insert_Input>;
  on_conflict?: Maybe<SupportChatMessages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatMessages_OneArgs = {
  object: SupportChatMessages_Insert_Input;
  on_conflict?: Maybe<SupportChatMessages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChatsArgs = {
  objects: Array<SupportChats_Insert_Input>;
  on_conflict?: Maybe<SupportChats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportChats_OneArgs = {
  object: SupportChats_Insert_Input;
  on_conflict?: Maybe<SupportChats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportSubjectsArgs = {
  objects: Array<SupportSubjects_Insert_Input>;
  on_conflict?: Maybe<SupportSubjects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SupportSubjects_OneArgs = {
  object: SupportSubjects_Insert_Input;
  on_conflict?: Maybe<SupportSubjects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientGroupsArgs = {
  _inc?: Maybe<ClientGroups_Inc_Input>;
  _set?: Maybe<ClientGroups_Set_Input>;
  where: ClientGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClientGroupsSmartlabUsersArgs = {
  _set?: Maybe<ClientGroupsSmartlabUsers_Set_Input>;
  where: ClientGroupsSmartlabUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ClientGroupsSmartlabUsers_By_PkArgs = {
  _set?: Maybe<ClientGroupsSmartlabUsers_Set_Input>;
  pk_columns: ClientGroupsSmartlabUsers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClientGroups_By_PkArgs = {
  _inc?: Maybe<ClientGroups_Inc_Input>;
  _set?: Maybe<ClientGroups_Set_Input>;
  pk_columns: ClientGroups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FileItemsArgs = {
  _inc?: Maybe<FileItems_Inc_Input>;
  _set?: Maybe<FileItems_Set_Input>;
  where: FileItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FileItems_By_PkArgs = {
  _inc?: Maybe<FileItems_Inc_Input>;
  _set?: Maybe<FileItems_Set_Input>;
  pk_columns: FileItems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FileTypesArgs = {
  _inc?: Maybe<FileTypes_Inc_Input>;
  _set?: Maybe<FileTypes_Set_Input>;
  where: FileTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FileTypes_By_PkArgs = {
  _inc?: Maybe<FileTypes_Inc_Input>;
  _set?: Maybe<FileTypes_Set_Input>;
  pk_columns: FileTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationTypesArgs = {
  _set?: Maybe<NotificationTypes_Set_Input>;
  where: NotificationTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationTypes_By_PkArgs = {
  _set?: Maybe<NotificationTypes_Set_Input>;
  pk_columns: NotificationTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: Maybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: Maybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PfdProjectsArgs = {
  _inc?: Maybe<PfdProjects_Inc_Input>;
  _set?: Maybe<PfdProjects_Set_Input>;
  where: PfdProjects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PfdProjects_By_PkArgs = {
  _inc?: Maybe<PfdProjects_Inc_Input>;
  _set?: Maybe<PfdProjects_Set_Input>;
  pk_columns: PfdProjects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectGroupsArgs = {
  _inc?: Maybe<ProjectGroups_Inc_Input>;
  _set?: Maybe<ProjectGroups_Set_Input>;
  where: ProjectGroups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectGroupsSmartlabUsersArgs = {
  _set?: Maybe<ProjectGroupsSmartlabUsers_Set_Input>;
  where: ProjectGroupsSmartlabUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectGroupsSmartlabUsers_By_PkArgs = {
  _set?: Maybe<ProjectGroupsSmartlabUsers_Set_Input>;
  pk_columns: ProjectGroupsSmartlabUsers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectGroups_By_PkArgs = {
  _inc?: Maybe<ProjectGroups_Inc_Input>;
  _set?: Maybe<ProjectGroups_Set_Input>;
  pk_columns: ProjectGroups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SmartlabUsersArgs = {
  _set?: Maybe<SmartlabUsers_Set_Input>;
  where: SmartlabUsers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SmartlabUsers_By_PkArgs = {
  _set?: Maybe<SmartlabUsers_Set_Input>;
  pk_columns: SmartlabUsers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatAutoMessagesArgs = {
  _set?: Maybe<SupportChatAutoMessages_Set_Input>;
  where: SupportChatAutoMessages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatAutoMessages_By_PkArgs = {
  _set?: Maybe<SupportChatAutoMessages_Set_Input>;
  pk_columns: SupportChatAutoMessages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatMembersArgs = {
  _set?: Maybe<SupportChatMembers_Set_Input>;
  where: SupportChatMembers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatMembers_By_PkArgs = {
  _set?: Maybe<SupportChatMembers_Set_Input>;
  pk_columns: SupportChatMembers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatMessageViewersArgs = {
  _set?: Maybe<SupportChatMessageViewers_Set_Input>;
  where: SupportChatMessageViewers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatMessageViewers_By_PkArgs = {
  _set?: Maybe<SupportChatMessageViewers_Set_Input>;
  pk_columns: SupportChatMessageViewers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatMessagesArgs = {
  _inc?: Maybe<SupportChatMessages_Inc_Input>;
  _set?: Maybe<SupportChatMessages_Set_Input>;
  where: SupportChatMessages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatMessages_By_PkArgs = {
  _inc?: Maybe<SupportChatMessages_Inc_Input>;
  _set?: Maybe<SupportChatMessages_Set_Input>;
  pk_columns: SupportChatMessages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChatsArgs = {
  _set?: Maybe<SupportChats_Set_Input>;
  where: SupportChats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SupportChats_By_PkArgs = {
  _set?: Maybe<SupportChats_Set_Input>;
  pk_columns: SupportChats_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SupportSubjectsArgs = {
  _inc?: Maybe<SupportSubjects_Inc_Input>;
  _set?: Maybe<SupportSubjects_Set_Input>;
  where: SupportSubjects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_SupportSubjects_By_PkArgs = {
  _inc?: Maybe<SupportSubjects_Inc_Input>;
  _set?: Maybe<SupportSubjects_Set_Input>;
  pk_columns: SupportSubjects_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "ClientGroups" */
  ClientGroups: Array<ClientGroups>;
  /** An array relationship */
  ClientGroupsSmartlabUsers: Array<ClientGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ClientGroupsSmartlabUsers_aggregate: ClientGroupsSmartlabUsers_Aggregate;
  /** fetch data from the table: "ClientGroupsSmartlabUsers" using primary key columns */
  ClientGroupsSmartlabUsers_by_pk?: Maybe<ClientGroupsSmartlabUsers>;
  /** fetch aggregated fields from the table: "ClientGroups" */
  ClientGroups_aggregate: ClientGroups_Aggregate;
  /** fetch data from the table: "ClientGroups" using primary key columns */
  ClientGroups_by_pk?: Maybe<ClientGroups>;
  /** An array relationship */
  FileItems: Array<FileItems>;
  /** An aggregate relationship */
  FileItems_aggregate: FileItems_Aggregate;
  /** fetch data from the table: "FileItems" using primary key columns */
  FileItems_by_pk?: Maybe<FileItems>;
  /** fetch data from the table: "FileTypes" */
  FileTypes: Array<FileTypes>;
  /** fetch aggregated fields from the table: "FileTypes" */
  FileTypes_aggregate: FileTypes_Aggregate;
  /** fetch data from the table: "FileTypes" using primary key columns */
  FileTypes_by_pk?: Maybe<FileTypes>;
  /** fetch data from the table: "NotificationTypes" */
  NotificationTypes: Array<NotificationTypes>;
  /** fetch aggregated fields from the table: "NotificationTypes" */
  NotificationTypes_aggregate: NotificationTypes_Aggregate;
  /** fetch data from the table: "NotificationTypes" using primary key columns */
  NotificationTypes_by_pk?: Maybe<NotificationTypes>;
  /** An array relationship */
  Notifications: Array<Notifications>;
  /** An aggregate relationship */
  Notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "Notifications" using primary key columns */
  Notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "PfdProjects" */
  PfdProjects: Array<PfdProjects>;
  /** fetch aggregated fields from the table: "PfdProjects" */
  PfdProjects_aggregate: PfdProjects_Aggregate;
  /** fetch data from the table: "PfdProjects" using primary key columns */
  PfdProjects_by_pk?: Maybe<PfdProjects>;
  /** An array relationship */
  ProjectGroups: Array<ProjectGroups>;
  /** An array relationship */
  ProjectGroupsSmartlabUsers: Array<ProjectGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ProjectGroupsSmartlabUsers_aggregate: ProjectGroupsSmartlabUsers_Aggregate;
  /** fetch data from the table: "ProjectGroupsSmartlabUsers" using primary key columns */
  ProjectGroupsSmartlabUsers_by_pk?: Maybe<ProjectGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ProjectGroups_aggregate: ProjectGroups_Aggregate;
  /** fetch data from the table: "ProjectGroups" using primary key columns */
  ProjectGroups_by_pk?: Maybe<ProjectGroups>;
  /** fetch data from the table: "SmartlabUsers" */
  SmartlabUsers: Array<SmartlabUsers>;
  /** fetch aggregated fields from the table: "SmartlabUsers" */
  SmartlabUsers_aggregate: SmartlabUsers_Aggregate;
  /** fetch data from the table: "SmartlabUsers" using primary key columns */
  SmartlabUsers_by_pk?: Maybe<SmartlabUsers>;
  /** An array relationship */
  SupportChatAutoMessages: Array<SupportChatAutoMessages>;
  /** An aggregate relationship */
  SupportChatAutoMessages_aggregate: SupportChatAutoMessages_Aggregate;
  /** fetch data from the table: "SupportChatAutoMessages" using primary key columns */
  SupportChatAutoMessages_by_pk?: Maybe<SupportChatAutoMessages>;
  /** An array relationship */
  SupportChatMembers: Array<SupportChatMembers>;
  /** An aggregate relationship */
  SupportChatMembers_aggregate: SupportChatMembers_Aggregate;
  /** fetch data from the table: "SupportChatMembers" using primary key columns */
  SupportChatMembers_by_pk?: Maybe<SupportChatMembers>;
  /** An array relationship */
  SupportChatMessageViewers: Array<SupportChatMessageViewers>;
  /** An aggregate relationship */
  SupportChatMessageViewers_aggregate: SupportChatMessageViewers_Aggregate;
  /** fetch data from the table: "SupportChatMessageViewers" using primary key columns */
  SupportChatMessageViewers_by_pk?: Maybe<SupportChatMessageViewers>;
  /** An array relationship */
  SupportChatMessages: Array<SupportChatMessages>;
  /** An aggregate relationship */
  SupportChatMessages_aggregate: SupportChatMessages_Aggregate;
  /** fetch data from the table: "SupportChatMessages" using primary key columns */
  SupportChatMessages_by_pk?: Maybe<SupportChatMessages>;
  /** An array relationship */
  SupportChats: Array<SupportChats>;
  /** An aggregate relationship */
  SupportChats_aggregate: SupportChats_Aggregate;
  /** fetch data from the table: "SupportChats" using primary key columns */
  SupportChats_by_pk?: Maybe<SupportChats>;
  /** fetch data from the table: "SupportSubjects" */
  SupportSubjects: Array<SupportSubjects>;
  /** fetch aggregated fields from the table: "SupportSubjects" */
  SupportSubjects_aggregate: SupportSubjects_Aggregate;
  /** fetch data from the table: "SupportSubjects" using primary key columns */
  SupportSubjects_by_pk?: Maybe<SupportSubjects>;
};


export type Query_RootClientGroupsArgs = {
  distinct_on?: Maybe<Array<ClientGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroups_Order_By>>;
  where?: Maybe<ClientGroups_Bool_Exp>;
};


export type Query_RootClientGroupsSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<ClientGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
};


export type Query_RootClientGroupsSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
};


export type Query_RootClientGroupsSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootClientGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroups_Order_By>>;
  where?: Maybe<ClientGroups_Bool_Exp>;
};


export type Query_RootClientGroups_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootFileItemsArgs = {
  distinct_on?: Maybe<Array<FileItems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileItems_Order_By>>;
  where?: Maybe<FileItems_Bool_Exp>;
};


export type Query_RootFileItems_AggregateArgs = {
  distinct_on?: Maybe<Array<FileItems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileItems_Order_By>>;
  where?: Maybe<FileItems_Bool_Exp>;
};


export type Query_RootFileItems_By_PkArgs = {
  Id: Scalars['bigint'];
};


export type Query_RootFileTypesArgs = {
  distinct_on?: Maybe<Array<FileTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileTypes_Order_By>>;
  where?: Maybe<FileTypes_Bool_Exp>;
};


export type Query_RootFileTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<FileTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileTypes_Order_By>>;
  where?: Maybe<FileTypes_Bool_Exp>;
};


export type Query_RootFileTypes_By_PkArgs = {
  Id: Scalars['Int'];
};


export type Query_RootNotificationTypesArgs = {
  distinct_on?: Maybe<Array<NotificationTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationTypes_Order_By>>;
  where?: Maybe<NotificationTypes_Bool_Exp>;
};


export type Query_RootNotificationTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<NotificationTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationTypes_Order_By>>;
  where?: Maybe<NotificationTypes_Bool_Exp>;
};


export type Query_RootNotificationTypes_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootPfdProjectsArgs = {
  distinct_on?: Maybe<Array<PfdProjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PfdProjects_Order_By>>;
  where?: Maybe<PfdProjects_Bool_Exp>;
};


export type Query_RootPfdProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<PfdProjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PfdProjects_Order_By>>;
  where?: Maybe<PfdProjects_Bool_Exp>;
};


export type Query_RootPfdProjects_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootProjectGroupsArgs = {
  distinct_on?: Maybe<Array<ProjectGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroups_Order_By>>;
  where?: Maybe<ProjectGroups_Bool_Exp>;
};


export type Query_RootProjectGroupsSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


export type Query_RootProjectGroupsSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


export type Query_RootProjectGroupsSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootProjectGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroups_Order_By>>;
  where?: Maybe<ProjectGroups_Bool_Exp>;
};


export type Query_RootProjectGroups_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<SmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SmartlabUsers_Order_By>>;
  where?: Maybe<SmartlabUsers_Bool_Exp>;
};


export type Query_RootSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<SmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SmartlabUsers_Order_By>>;
  where?: Maybe<SmartlabUsers_Bool_Exp>;
};


export type Query_RootSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootSupportChatAutoMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatAutoMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatAutoMessages_Order_By>>;
  where?: Maybe<SupportChatAutoMessages_Bool_Exp>;
};


export type Query_RootSupportChatAutoMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatAutoMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatAutoMessages_Order_By>>;
  where?: Maybe<SupportChatAutoMessages_Bool_Exp>;
};


export type Query_RootSupportChatAutoMessages_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootSupportChatMembersArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};


export type Query_RootSupportChatMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};


export type Query_RootSupportChatMembers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootSupportChatMessageViewersArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};


export type Query_RootSupportChatMessageViewers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};


export type Query_RootSupportChatMessageViewers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootSupportChatMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};


export type Query_RootSupportChatMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};


export type Query_RootSupportChatMessages_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootSupportChatsArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};


export type Query_RootSupportChats_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};


export type Query_RootSupportChats_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Query_RootSupportSubjectsArgs = {
  distinct_on?: Maybe<Array<SupportSubjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportSubjects_Order_By>>;
  where?: Maybe<SupportSubjects_Bool_Exp>;
};


export type Query_RootSupportSubjects_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportSubjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportSubjects_Order_By>>;
  where?: Maybe<SupportSubjects_Bool_Exp>;
};


export type Query_RootSupportSubjects_By_PkArgs = {
  Id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "ClientGroups" */
  ClientGroups: Array<ClientGroups>;
  /** An array relationship */
  ClientGroupsSmartlabUsers: Array<ClientGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ClientGroupsSmartlabUsers_aggregate: ClientGroupsSmartlabUsers_Aggregate;
  /** fetch data from the table: "ClientGroupsSmartlabUsers" using primary key columns */
  ClientGroupsSmartlabUsers_by_pk?: Maybe<ClientGroupsSmartlabUsers>;
  /** fetch aggregated fields from the table: "ClientGroups" */
  ClientGroups_aggregate: ClientGroups_Aggregate;
  /** fetch data from the table: "ClientGroups" using primary key columns */
  ClientGroups_by_pk?: Maybe<ClientGroups>;
  /** An array relationship */
  FileItems: Array<FileItems>;
  /** An aggregate relationship */
  FileItems_aggregate: FileItems_Aggregate;
  /** fetch data from the table: "FileItems" using primary key columns */
  FileItems_by_pk?: Maybe<FileItems>;
  /** fetch data from the table: "FileTypes" */
  FileTypes: Array<FileTypes>;
  /** fetch aggregated fields from the table: "FileTypes" */
  FileTypes_aggregate: FileTypes_Aggregate;
  /** fetch data from the table: "FileTypes" using primary key columns */
  FileTypes_by_pk?: Maybe<FileTypes>;
  /** fetch data from the table: "NotificationTypes" */
  NotificationTypes: Array<NotificationTypes>;
  /** fetch aggregated fields from the table: "NotificationTypes" */
  NotificationTypes_aggregate: NotificationTypes_Aggregate;
  /** fetch data from the table: "NotificationTypes" using primary key columns */
  NotificationTypes_by_pk?: Maybe<NotificationTypes>;
  /** An array relationship */
  Notifications: Array<Notifications>;
  /** An aggregate relationship */
  Notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "Notifications" using primary key columns */
  Notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "PfdProjects" */
  PfdProjects: Array<PfdProjects>;
  /** fetch aggregated fields from the table: "PfdProjects" */
  PfdProjects_aggregate: PfdProjects_Aggregate;
  /** fetch data from the table: "PfdProjects" using primary key columns */
  PfdProjects_by_pk?: Maybe<PfdProjects>;
  /** An array relationship */
  ProjectGroups: Array<ProjectGroups>;
  /** An array relationship */
  ProjectGroupsSmartlabUsers: Array<ProjectGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ProjectGroupsSmartlabUsers_aggregate: ProjectGroupsSmartlabUsers_Aggregate;
  /** fetch data from the table: "ProjectGroupsSmartlabUsers" using primary key columns */
  ProjectGroupsSmartlabUsers_by_pk?: Maybe<ProjectGroupsSmartlabUsers>;
  /** An aggregate relationship */
  ProjectGroups_aggregate: ProjectGroups_Aggregate;
  /** fetch data from the table: "ProjectGroups" using primary key columns */
  ProjectGroups_by_pk?: Maybe<ProjectGroups>;
  /** fetch data from the table: "SmartlabUsers" */
  SmartlabUsers: Array<SmartlabUsers>;
  /** fetch aggregated fields from the table: "SmartlabUsers" */
  SmartlabUsers_aggregate: SmartlabUsers_Aggregate;
  /** fetch data from the table: "SmartlabUsers" using primary key columns */
  SmartlabUsers_by_pk?: Maybe<SmartlabUsers>;
  /** An array relationship */
  SupportChatAutoMessages: Array<SupportChatAutoMessages>;
  /** An aggregate relationship */
  SupportChatAutoMessages_aggregate: SupportChatAutoMessages_Aggregate;
  /** fetch data from the table: "SupportChatAutoMessages" using primary key columns */
  SupportChatAutoMessages_by_pk?: Maybe<SupportChatAutoMessages>;
  /** An array relationship */
  SupportChatMembers: Array<SupportChatMembers>;
  /** An aggregate relationship */
  SupportChatMembers_aggregate: SupportChatMembers_Aggregate;
  /** fetch data from the table: "SupportChatMembers" using primary key columns */
  SupportChatMembers_by_pk?: Maybe<SupportChatMembers>;
  /** An array relationship */
  SupportChatMessageViewers: Array<SupportChatMessageViewers>;
  /** An aggregate relationship */
  SupportChatMessageViewers_aggregate: SupportChatMessageViewers_Aggregate;
  /** fetch data from the table: "SupportChatMessageViewers" using primary key columns */
  SupportChatMessageViewers_by_pk?: Maybe<SupportChatMessageViewers>;
  /** An array relationship */
  SupportChatMessages: Array<SupportChatMessages>;
  /** An aggregate relationship */
  SupportChatMessages_aggregate: SupportChatMessages_Aggregate;
  /** fetch data from the table: "SupportChatMessages" using primary key columns */
  SupportChatMessages_by_pk?: Maybe<SupportChatMessages>;
  /** An array relationship */
  SupportChats: Array<SupportChats>;
  /** An aggregate relationship */
  SupportChats_aggregate: SupportChats_Aggregate;
  /** fetch data from the table: "SupportChats" using primary key columns */
  SupportChats_by_pk?: Maybe<SupportChats>;
  /** fetch data from the table: "SupportSubjects" */
  SupportSubjects: Array<SupportSubjects>;
  /** fetch aggregated fields from the table: "SupportSubjects" */
  SupportSubjects_aggregate: SupportSubjects_Aggregate;
  /** fetch data from the table: "SupportSubjects" using primary key columns */
  SupportSubjects_by_pk?: Maybe<SupportSubjects>;
};


export type Subscription_RootClientGroupsArgs = {
  distinct_on?: Maybe<Array<ClientGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroups_Order_By>>;
  where?: Maybe<ClientGroups_Bool_Exp>;
};


export type Subscription_RootClientGroupsSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<ClientGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
};


export type Subscription_RootClientGroupsSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ClientGroupsSmartlabUsers_Bool_Exp>;
};


export type Subscription_RootClientGroupsSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootClientGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientGroups_Order_By>>;
  where?: Maybe<ClientGroups_Bool_Exp>;
};


export type Subscription_RootClientGroups_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootFileItemsArgs = {
  distinct_on?: Maybe<Array<FileItems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileItems_Order_By>>;
  where?: Maybe<FileItems_Bool_Exp>;
};


export type Subscription_RootFileItems_AggregateArgs = {
  distinct_on?: Maybe<Array<FileItems_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileItems_Order_By>>;
  where?: Maybe<FileItems_Bool_Exp>;
};


export type Subscription_RootFileItems_By_PkArgs = {
  Id: Scalars['bigint'];
};


export type Subscription_RootFileTypesArgs = {
  distinct_on?: Maybe<Array<FileTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileTypes_Order_By>>;
  where?: Maybe<FileTypes_Bool_Exp>;
};


export type Subscription_RootFileTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<FileTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FileTypes_Order_By>>;
  where?: Maybe<FileTypes_Bool_Exp>;
};


export type Subscription_RootFileTypes_By_PkArgs = {
  Id: Scalars['Int'];
};


export type Subscription_RootNotificationTypesArgs = {
  distinct_on?: Maybe<Array<NotificationTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationTypes_Order_By>>;
  where?: Maybe<NotificationTypes_Bool_Exp>;
};


export type Subscription_RootNotificationTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<NotificationTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<NotificationTypes_Order_By>>;
  where?: Maybe<NotificationTypes_Bool_Exp>;
};


export type Subscription_RootNotificationTypes_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notifications_Order_By>>;
  where?: Maybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootPfdProjectsArgs = {
  distinct_on?: Maybe<Array<PfdProjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PfdProjects_Order_By>>;
  where?: Maybe<PfdProjects_Bool_Exp>;
};


export type Subscription_RootPfdProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<PfdProjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PfdProjects_Order_By>>;
  where?: Maybe<PfdProjects_Bool_Exp>;
};


export type Subscription_RootPfdProjects_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootProjectGroupsArgs = {
  distinct_on?: Maybe<Array<ProjectGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroups_Order_By>>;
  where?: Maybe<ProjectGroups_Bool_Exp>;
};


export type Subscription_RootProjectGroupsSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


export type Subscription_RootProjectGroupsSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectGroupsSmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By>>;
  where?: Maybe<ProjectGroupsSmartlabUsers_Bool_Exp>;
};


export type Subscription_RootProjectGroupsSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootProjectGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<ProjectGroups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectGroups_Order_By>>;
  where?: Maybe<ProjectGroups_Bool_Exp>;
};


export type Subscription_RootProjectGroups_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootSmartlabUsersArgs = {
  distinct_on?: Maybe<Array<SmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SmartlabUsers_Order_By>>;
  where?: Maybe<SmartlabUsers_Bool_Exp>;
};


export type Subscription_RootSmartlabUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<SmartlabUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SmartlabUsers_Order_By>>;
  where?: Maybe<SmartlabUsers_Bool_Exp>;
};


export type Subscription_RootSmartlabUsers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootSupportChatAutoMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatAutoMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatAutoMessages_Order_By>>;
  where?: Maybe<SupportChatAutoMessages_Bool_Exp>;
};


export type Subscription_RootSupportChatAutoMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatAutoMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatAutoMessages_Order_By>>;
  where?: Maybe<SupportChatAutoMessages_Bool_Exp>;
};


export type Subscription_RootSupportChatAutoMessages_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootSupportChatMembersArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};


export type Subscription_RootSupportChatMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMembers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMembers_Order_By>>;
  where?: Maybe<SupportChatMembers_Bool_Exp>;
};


export type Subscription_RootSupportChatMembers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootSupportChatMessageViewersArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};


export type Subscription_RootSupportChatMessageViewers_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessageViewers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessageViewers_Order_By>>;
  where?: Maybe<SupportChatMessageViewers_Bool_Exp>;
};


export type Subscription_RootSupportChatMessageViewers_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootSupportChatMessagesArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};


export type Subscription_RootSupportChatMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChatMessages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChatMessages_Order_By>>;
  where?: Maybe<SupportChatMessages_Bool_Exp>;
};


export type Subscription_RootSupportChatMessages_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootSupportChatsArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};


export type Subscription_RootSupportChats_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportChats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportChats_Order_By>>;
  where?: Maybe<SupportChats_Bool_Exp>;
};


export type Subscription_RootSupportChats_By_PkArgs = {
  Id: Scalars['uuid'];
};


export type Subscription_RootSupportSubjectsArgs = {
  distinct_on?: Maybe<Array<SupportSubjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportSubjects_Order_By>>;
  where?: Maybe<SupportSubjects_Bool_Exp>;
};


export type Subscription_RootSupportSubjects_AggregateArgs = {
  distinct_on?: Maybe<Array<SupportSubjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SupportSubjects_Order_By>>;
  where?: Maybe<SupportSubjects_Bool_Exp>;
};


export type Subscription_RootSupportSubjects_By_PkArgs = {
  Id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type ProjectGroupsSmartlabUsersWhereQueryVariables = Exact<{
  ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsers_Bool_Exp;
  ProjectGroupSupportChatMessages_bool_exp: SupportChatMessages_Bool_Exp;
  ProjectGroupsSmartlabUsers_order_by?: Maybe<Array<SupportChats_Order_By> | SupportChats_Order_By>;
  SupportChatMessages_order_by?: Maybe<Array<SupportChatMessages_Order_By> | SupportChatMessages_Order_By>;
  subjectLimit?: Maybe<Scalars['Int']>;
  messagesLimit?: Maybe<Scalars['Int']>;
  offsetSubject?: Maybe<Scalars['Int']>;
  offsetMessages?: Maybe<Scalars['Int']>;
}>;


export type ProjectGroupsSmartlabUsersWhereQuery = { __typename?: 'query_root', ProjectGroupsSmartlabUsers: Array<{ __typename?: 'ProjectGroupsSmartlabUsers', Id: any, ProjectGroupId: any, ProjectGroup: { __typename?: 'ProjectGroups', Id: any, PfdProject?: Maybe<{ __typename?: 'PfdProjects', Id: any, Name?: Maybe<string> }>, SupportChats: Array<{ __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Deleted: boolean, Id: any, ProjectGroupId: any, StartDate: any, StopDate?: Maybe<any>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', ContainsPFDUnitLinksForCurrentProject: boolean, Deleted: boolean, Description?: Maybe<string>, Id: any, IsActive: boolean, IsEnabledForOperatorChat: boolean, Order: number, Title: string }>, SupportChatMessages: Array<{ __typename?: 'SupportChatMessages', AuthorId: any, AuthorRole?: Maybe<string>, CreatedDate: any, Deleted: boolean, FileItemId?: Maybe<any>, Id: any, SupportChatId: any, TextValue?: Maybe<string>, ValueType: any, FileItem?: Maybe<{ __typename?: 'FileItems', FileName: string, FileReference: string, FileTypeId: number, Id: any }>, SupportChatMember: { __typename?: 'SupportChatMembers', SupportChat: { __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Id: any, StartDate: any, StopDate?: Maybe<any>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', Id: any, Title: string }> }, SmartlabUser: { __typename?: 'SmartlabUsers', Email: string, Id: any, NameFirst?: Maybe<string>, NameLast?: Maybe<string>, UserId: string } } }> }> } }> };

export type ProjectGroupsSmartlabUsersSubscriprionSubscriptionVariables = Exact<{
  ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsers_Bool_Exp;
  ProjectGroupSupportChatMessages_bool_exp: SupportChatMessages_Bool_Exp;
  SupportChatMessages_order_by?: Maybe<Array<SupportChatMessages_Order_By> | SupportChatMessages_Order_By>;
  SupportChats_bool_exp: SupportChats_Bool_Exp;
  ProjectGroupsSmartlabUsers_order_by?: Maybe<Array<ProjectGroupsSmartlabUsers_Order_By> | ProjectGroupsSmartlabUsers_Order_By>;
  subjectLimit?: Maybe<Scalars['Int']>;
  messagesLimit?: Maybe<Scalars['Int']>;
  offsetSubject?: Maybe<Scalars['Int']>;
  offsetMessages?: Maybe<Scalars['Int']>;
}>;


export type ProjectGroupsSmartlabUsersSubscriprionSubscription = { __typename?: 'subscription_root', ProjectGroupsSmartlabUsers: Array<{ __typename?: 'ProjectGroupsSmartlabUsers', Id: any, ProjectGroupId: any, ProjectGroup: { __typename?: 'ProjectGroups', Id: any, PfdProject?: Maybe<{ __typename?: 'PfdProjects', Id: any, Name?: Maybe<string> }>, SupportChats: Array<{ __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Deleted: boolean, Id: any, ProjectGroupId: any, StartDate: any, StopDate?: Maybe<any>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', ContainsPFDUnitLinksForCurrentProject: boolean, Deleted: boolean, Description?: Maybe<string>, Id: any, IsActive: boolean, IsEnabledForOperatorChat: boolean, Order: number, Title: string }>, SupportChatMessages: Array<{ __typename?: 'SupportChatMessages', AuthorId: any, AuthorRole?: Maybe<string>, CreatedDate: any, Deleted: boolean, FileItemId?: Maybe<any>, Id: any, SupportChatId: any, TextValue?: Maybe<string>, ValueType: any, FileItem?: Maybe<{ __typename?: 'FileItems', FileName: string, FileReference: string, FileTypeId: number, Id: any }>, SupportChatMember: { __typename?: 'SupportChatMembers', SupportChat: { __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Id: any, StartDate: any, StopDate?: Maybe<any>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', Id: any, Title: string }> }, SmartlabUser: { __typename?: 'SmartlabUsers', Email: string, Id: any, NameFirst?: Maybe<string>, NameLast?: Maybe<string>, UserId: string } } }> }> } }> };

export type ProjectGroupsWhereSubscriptionSubscriptionVariables = Exact<{
  ProjectGroups_bool_exp: ProjectGroups_Bool_Exp;
  ProjectGroups_order_by?: Maybe<Array<ProjectGroups_Order_By> | ProjectGroups_Order_By>;
  SupportChats_bool_exp: SupportChats_Bool_Exp;
  SupportChatMessages_bool_exp: SupportChatMessages_Bool_Exp;
  SupportChatMessages_order_by?: Maybe<Array<SupportChatMessages_Order_By> | SupportChatMessages_Order_By>;
}>;


export type ProjectGroupsWhereSubscriptionSubscription = { __typename?: 'subscription_root', ProjectGroups: Array<{ __typename?: 'ProjectGroups', Id: any, Name?: Maybe<string>, SupportChats: Array<{ __typename?: 'SupportChats', Id: any, SupportChatMessages: Array<{ __typename?: 'SupportChatMessages', Id: any, SupportChatMember: { __typename?: 'SupportChatMembers', SmartlabUser: { __typename?: 'SmartlabUsers', Id: any } }, SupportChatMessageViewers: Array<{ __typename?: 'SupportChatMessageViewers', MessageId: any, SupportChatMember: { __typename?: 'SupportChatMembers', Id: any, SmartlabUser: { __typename?: 'SmartlabUsers', Id: any } } }> }> }> }> };

export type SmartlabUsersWhereQueryVariables = Exact<{
  SmartlabUsers_bool_exp: SmartlabUsers_Bool_Exp;
}>;


export type SmartlabUsersWhereQuery = { __typename: 'query_root', SmartlabUsers: Array<{ __typename?: 'SmartlabUsers', Id: any, Email: string, NameFirst?: Maybe<string>, NameLast?: Maybe<string>, UserId: string }> };

export type SupportChatAutoMessagesWhereQueryVariables = Exact<{
  SupportChatAutoMessages_bool_exp: SupportChatAutoMessages_Bool_Exp;
  SupportChatAutoMessages_order_by?: Maybe<Array<SupportChatAutoMessages_Order_By> | SupportChatAutoMessages_Order_By>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type SupportChatAutoMessagesWhereQuery = { __typename: 'query_root', SupportChatAutoMessages: Array<{ __typename?: 'SupportChatAutoMessages', Id: any, TextValue?: Maybe<string> }> };

export type SupportChatMembersWhereQueryVariables = Exact<{
  SupportChatMembers_bool_exp: SupportChatMembers_Bool_Exp;
}>;


export type SupportChatMembersWhereQuery = { __typename: 'query_root', SupportChatMembers: Array<{ __typename?: 'SupportChatMembers', Deleted: boolean, Id: any, IsActive?: Maybe<boolean>, SupportChat: { __typename?: 'SupportChats', Id: any, ProjectGroup: { __typename?: 'ProjectGroups', Id: any, PfdProject?: Maybe<{ __typename?: 'PfdProjects', Id: any, Name?: Maybe<string> }> }, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', ParentId?: Maybe<any>, ContainsPFDUnitLinksForCurrentProject: boolean, Deleted: boolean, Description?: Maybe<string>, Id: any, IsActive: boolean, IsEnabledForOperatorChat: boolean, Order: number, Title: string, Children: Array<{ __typename?: 'SupportSubjects', ParentId?: Maybe<any>, ContainsPFDUnitLinksForCurrentProject: boolean, Deleted: boolean, Description?: Maybe<string>, Id: any, IsActive: boolean, IsEnabledForOperatorChat: boolean, Order: number, Title: string, Children: Array<{ __typename?: 'SupportSubjects', ParentId?: Maybe<any>, ContainsPFDUnitLinksForCurrentProject: boolean, Deleted: boolean, Description?: Maybe<string>, Id: any, IsActive: boolean, IsEnabledForOperatorChat: boolean, Order: number, Title: string }> }> }> } }> };

export type SupportChatMessagesCountSubscriptionSubscriptionVariables = Exact<{
  SupportChatMessages_bool_exp: SupportChatMessages_Bool_Exp;
}>;


export type SupportChatMessagesCountSubscriptionSubscription = { __typename?: 'subscription_root', SupportChatMessages_aggregate: { __typename?: 'SupportChatMessages_aggregate', aggregate?: Maybe<{ __typename?: 'SupportChatMessages_aggregate_fields', count: number }> } };

export type SupportChatMessagesWhereQueryVariables = Exact<{
  SupportChatMessages_bool_exp: SupportChatMessages_Bool_Exp;
  SupportChatMessages_order_by?: Maybe<Array<SupportChatMessages_Order_By> | SupportChatMessages_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type SupportChatMessagesWhereQuery = { __typename: 'query_root', SupportChatMessages: Array<{ __typename?: 'SupportChatMessages', AuthorId: any, AuthorRole?: Maybe<string>, CreatedDate: any, Deleted: boolean, Id: any, SupportChatId: any, TextValue?: Maybe<string>, ValueType: any, FileItemId?: Maybe<any>, SupportChat: { __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Id: any, StartDate: any, StopDate?: Maybe<any>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', Id: any, Title: string, IsActive: boolean }> }, SupportChatMember: { __typename?: 'SupportChatMembers', Id: any, IsActive?: Maybe<boolean>, Deleted: boolean, SmartlabUserId: any, SupportChatId: any, SmartlabUser: { __typename?: 'SmartlabUsers', Id: any, Email: string, NameFirst?: Maybe<string>, NameLast?: Maybe<string>, UserId: string } }, FileItem?: Maybe<{ __typename?: 'FileItems', FileName: string, FileReference: string, FileTypeId: number, Id: any }> }> };

export type SupportChatMessagesSubscriptionSubscriptionVariables = Exact<{
  SupportChatMessages_bool_exp: SupportChatMessages_Bool_Exp;
  SupportChatMessages_order_by?: Maybe<Array<SupportChatMessages_Order_By> | SupportChatMessages_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type SupportChatMessagesSubscriptionSubscription = { __typename?: 'subscription_root', SupportChatMessages: Array<{ __typename?: 'SupportChatMessages', AuthorId: any, AuthorRole?: Maybe<string>, CreatedDate: any, Deleted: boolean, Id: any, SupportChatId: any, TextValue?: Maybe<string>, ValueType: any, FileItemId?: Maybe<any>, SupportChatMessageViewers: Array<{ __typename?: 'SupportChatMessageViewers', Deleted: boolean, Id: any, MessageId: any, ViewedDate: any, ViewerId: any, SupportChatMember: { __typename?: 'SupportChatMembers', Id: any, SmartlabUser: { __typename?: 'SmartlabUsers', Id: any } } }>, SupportChat: { __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, ClosedBy?: Maybe<string>, Id: any, StartDate: any, StopDate?: Maybe<any>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', Id: any, Title: string }> }, SupportChatMember: { __typename?: 'SupportChatMembers', Id: any, IsActive?: Maybe<boolean>, Deleted: boolean, SmartlabUserId: any, SupportChatId: any, SmartlabUser: { __typename?: 'SmartlabUsers', Id: any, Email: string, NameFirst?: Maybe<string>, NameLast?: Maybe<string>, UserId: string } }, FileItem?: Maybe<{ __typename?: 'FileItems', FileName: string, FileReference: string, FileTypeId: number, Id: any }> }> };

export type SupportChatsWhereQueryVariables = Exact<{
  SupportChats_bool_exp: SupportChats_Bool_Exp;
  SupportChats_order_by?: Maybe<Array<SupportChats_Order_By> | SupportChats_Order_By>;
  SupportChatMessages_order_by?: Maybe<Array<SupportChatMessages_Order_By> | SupportChatMessages_Order_By>;
  ChatMessages_bool_exp: SupportChatMessages_Bool_Exp;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type SupportChatsWhereQuery = { __typename: 'query_root', SupportChats: Array<{ __typename?: 'SupportChats', Id: any, AuthorInfo?: Maybe<string>, Deleted: boolean, StartDate: any, StopDate?: Maybe<any>, SupportSubjectId?: Maybe<any>, ProjectGroupId: any, SupportChatMessages: Array<{ __typename?: 'SupportChatMessages', AuthorId: any, CreatedDate: any, AuthorRole?: Maybe<string>, Deleted: boolean, FileItemId?: Maybe<any>, Id: any, SupportChatId: any, TextValue?: Maybe<string>, ValueType: any, FileItem?: Maybe<{ __typename?: 'FileItems', FileName: string, FileReference: string, FileTypeId: number, Id: any }>, SupportChatMember: { __typename?: 'SupportChatMembers', Id: any, IsActive?: Maybe<boolean>, Deleted: boolean, SmartlabUserId: any, SupportChatId: any, SupportChat: { __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Id: any, StartDate: any, StopDate?: Maybe<any>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', Id: any, Title: string }> }, SmartlabUser: { __typename?: 'SmartlabUsers', Id: any, Email: string, NameFirst?: Maybe<string>, NameLast?: Maybe<string>, UserId: string } } }>, SupportChatMembers: Array<{ __typename?: 'SupportChatMembers', Deleted: boolean, Id: any, IsActive?: Maybe<boolean>, SmartlabUserId: any, SupportChatId: any }>, SupportSubject?: Maybe<{ __typename?: 'SupportSubjects', Title: string, Id: any }> }> };

export type SupportSubjectsWhereQueryVariables = Exact<{
  SupportSubjects_bool_exp: SupportSubjects_Bool_Exp;
  SupportSubjects_order_by?: Maybe<Array<SupportSubjects_Order_By> | SupportSubjects_Order_By>;
  SupportChats_bool_exp: SupportChats_Bool_Exp;
  SupportChats_order_by?: Maybe<Array<SupportChats_Order_By> | SupportChats_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type SupportSubjectsWhereQuery = { __typename: 'query_root', SupportSubjects: Array<{ __typename?: 'SupportSubjects', Title: string, ParentId?: Maybe<any>, Order: number, IsEnabledForOperatorChat: boolean, IsActive: boolean, Id: any, Description?: Maybe<string>, Deleted: boolean, ContainsPFDUnitLinksForCurrentProject: boolean, SupportChats: Array<{ __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Deleted: boolean, Id: any, ProjectGroupId: any, StartDate: any, StopDate?: Maybe<any>, SupportSubjectId?: Maybe<any> }>, Children: Array<{ __typename?: 'SupportSubjects', Title: string, ParentId?: Maybe<any>, Order: number, IsEnabledForOperatorChat: boolean, IsActive: boolean, Id: any, Description?: Maybe<string>, Deleted: boolean, ContainsPFDUnitLinksForCurrentProject: boolean, SupportChats: Array<{ __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Deleted: boolean, Id: any, ProjectGroupId: any, StartDate: any, StopDate?: Maybe<any>, SupportSubjectId?: Maybe<any> }>, Children: Array<{ __typename?: 'SupportSubjects', Title: string, ParentId?: Maybe<any>, Order: number, IsEnabledForOperatorChat: boolean, IsActive: boolean, Id: any, Description?: Maybe<string>, Deleted: boolean, ContainsPFDUnitLinksForCurrentProject: boolean, SupportChats: Array<{ __typename?: 'SupportChats', AuthorInfo?: Maybe<string>, Deleted: boolean, Id: any, ProjectGroupId: any, StartDate: any, StopDate?: Maybe<any>, SupportSubjectId?: Maybe<any> }> }> }> }> };

export const ProjectGroupsSmartlabUsersWhereDocument = gql`
    query ProjectGroupsSmartlabUsersWhere($ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsers_bool_exp!, $ProjectGroupSupportChatMessages_bool_exp: SupportChatMessages_bool_exp!, $ProjectGroupsSmartlabUsers_order_by: [SupportChats_order_by!], $SupportChatMessages_order_by: [SupportChatMessages_order_by!], $subjectLimit: Int, $messagesLimit: Int, $offsetSubject: Int, $offsetMessages: Int) {
  ProjectGroupsSmartlabUsers(where: $ProjectGroupsSmartlabUsers_bool_exp) {
    Id
    ProjectGroupId
    ProjectGroup {
      Id
      PfdProject {
        Id
        Name
      }
      SupportChats {
        AuthorInfo
        Deleted
        Id
        ProjectGroupId
        StartDate
        StopDate
        SupportSubject {
          ContainsPFDUnitLinksForCurrentProject
          Deleted
          Description
          Id
          IsActive
          IsEnabledForOperatorChat
          Order
          Title
        }
        SupportChatMessages(
          where: $ProjectGroupSupportChatMessages_bool_exp
          limit: $messagesLimit
          offset: $offsetMessages
          order_by: $SupportChatMessages_order_by
        ) {
          AuthorId
          AuthorRole
          CreatedDate
          Deleted
          FileItemId
          Id
          SupportChatId
          TextValue
          ValueType
          FileItem {
            FileName
            FileReference
            FileTypeId
            Id
          }
          SupportChatMember {
            SupportChat {
              AuthorInfo
              Id
              StartDate
              StopDate
              SupportSubject {
                Id
                Title
              }
            }
            SmartlabUser {
              Email
              Id
              NameFirst
              NameLast
              UserId
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectGroupsSmartlabUsersWhereGQL extends Apollo.Query<ProjectGroupsSmartlabUsersWhereQuery, ProjectGroupsSmartlabUsersWhereQueryVariables> {
    document = ProjectGroupsSmartlabUsersWhereDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectGroupsSmartlabUsersSubscriprionDocument = gql`
    subscription ProjectGroupsSmartlabUsersSubscriprion($ProjectGroupsSmartlabUsers_bool_exp: ProjectGroupsSmartlabUsers_bool_exp!, $ProjectGroupSupportChatMessages_bool_exp: SupportChatMessages_bool_exp!, $SupportChatMessages_order_by: [SupportChatMessages_order_by!], $SupportChats_bool_exp: SupportChats_bool_exp!, $ProjectGroupsSmartlabUsers_order_by: [ProjectGroupsSmartlabUsers_order_by!], $subjectLimit: Int, $messagesLimit: Int, $offsetSubject: Int, $offsetMessages: Int) {
  ProjectGroupsSmartlabUsers(
    where: $ProjectGroupsSmartlabUsers_bool_exp
    order_by: $ProjectGroupsSmartlabUsers_order_by
  ) {
    Id
    ProjectGroupId
    ProjectGroup {
      Id
      PfdProject {
        Id
        Name
      }
      SupportChats(where: $SupportChats_bool_exp) {
        AuthorInfo
        Deleted
        Id
        ProjectGroupId
        StartDate
        StopDate
        SupportSubject {
          ContainsPFDUnitLinksForCurrentProject
          Deleted
          Description
          Id
          IsActive
          IsEnabledForOperatorChat
          Order
          Title
        }
        SupportChatMessages(
          where: $ProjectGroupSupportChatMessages_bool_exp
          limit: $messagesLimit
          offset: $offsetMessages
          order_by: $SupportChatMessages_order_by
        ) {
          AuthorId
          AuthorRole
          CreatedDate
          Deleted
          FileItemId
          Id
          SupportChatId
          TextValue
          ValueType
          FileItem {
            FileName
            FileReference
            FileTypeId
            Id
          }
          SupportChatMember {
            SupportChat {
              AuthorInfo
              Id
              StartDate
              StopDate
              SupportSubject {
                Id
                Title
              }
            }
            SmartlabUser {
              Email
              Id
              NameFirst
              NameLast
              UserId
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectGroupsSmartlabUsersSubscriprionGQL extends Apollo.Subscription<ProjectGroupsSmartlabUsersSubscriprionSubscription, ProjectGroupsSmartlabUsersSubscriprionSubscriptionVariables> {
    document = ProjectGroupsSmartlabUsersSubscriprionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectGroupsWhereSubscriptionDocument = gql`
    subscription ProjectGroupsWhereSubscription($ProjectGroups_bool_exp: ProjectGroups_bool_exp!, $ProjectGroups_order_by: [ProjectGroups_order_by!], $SupportChats_bool_exp: SupportChats_bool_exp!, $SupportChatMessages_bool_exp: SupportChatMessages_bool_exp!, $SupportChatMessages_order_by: [SupportChatMessages_order_by!]) {
  ProjectGroups(where: $ProjectGroups_bool_exp, order_by: $ProjectGroups_order_by) {
    Id
    Name
    SupportChats(where: $SupportChats_bool_exp) {
      Id
      SupportChatMessages(
        where: $SupportChatMessages_bool_exp
        order_by: $SupportChatMessages_order_by
      ) {
        Id
        SupportChatMember {
          SmartlabUser {
            Id
          }
        }
        SupportChatMessageViewers {
          MessageId
          SupportChatMember {
            Id
            SmartlabUser {
              Id
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectGroupsWhereSubscriptionGQL extends Apollo.Subscription<ProjectGroupsWhereSubscriptionSubscription, ProjectGroupsWhereSubscriptionSubscriptionVariables> {
    document = ProjectGroupsWhereSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SmartlabUsersWhereDocument = gql`
    query SmartlabUsersWhere($SmartlabUsers_bool_exp: SmartlabUsers_bool_exp!) {
  __typename
  SmartlabUsers(where: $SmartlabUsers_bool_exp) {
    Id
    Email
    NameFirst
    NameLast
    UserId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SmartlabUsersWhereGQL extends Apollo.Query<SmartlabUsersWhereQuery, SmartlabUsersWhereQueryVariables> {
    document = SmartlabUsersWhereDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportChatAutoMessagesWhereDocument = gql`
    query SupportChatAutoMessagesWhere($SupportChatAutoMessages_bool_exp: SupportChatAutoMessages_bool_exp!, $SupportChatAutoMessages_order_by: [SupportChatAutoMessages_order_by!], $limit: Int) {
  __typename
  SupportChatAutoMessages(
    where: $SupportChatAutoMessages_bool_exp
    order_by: $SupportChatAutoMessages_order_by
    limit: $limit
  ) {
    Id
    TextValue
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportChatAutoMessagesWhereGQL extends Apollo.Query<SupportChatAutoMessagesWhereQuery, SupportChatAutoMessagesWhereQueryVariables> {
    document = SupportChatAutoMessagesWhereDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportChatMembersWhereDocument = gql`
    query SupportChatMembersWhere($SupportChatMembers_bool_exp: SupportChatMembers_bool_exp!) {
  __typename
  SupportChatMembers(where: $SupportChatMembers_bool_exp) {
    Deleted
    Id
    IsActive
    SupportChat {
      Id
      ProjectGroup {
        Id
        PfdProject {
          Id
          Name
        }
      }
      SupportSubject {
        ParentId
        ContainsPFDUnitLinksForCurrentProject
        Deleted
        Description
        Id
        IsActive
        IsEnabledForOperatorChat
        Order
        Title
        Children {
          ParentId
          ContainsPFDUnitLinksForCurrentProject
          Deleted
          Description
          Id
          IsActive
          IsEnabledForOperatorChat
          Order
          Title
          Children {
            ParentId
            ContainsPFDUnitLinksForCurrentProject
            Deleted
            Description
            Id
            IsActive
            IsEnabledForOperatorChat
            Order
            Title
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportChatMembersWhereGQL extends Apollo.Query<SupportChatMembersWhereQuery, SupportChatMembersWhereQueryVariables> {
    document = SupportChatMembersWhereDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportChatMessagesCountSubscriptionDocument = gql`
    subscription SupportChatMessagesCountSubscription($SupportChatMessages_bool_exp: SupportChatMessages_bool_exp!) {
  SupportChatMessages_aggregate(where: $SupportChatMessages_bool_exp) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportChatMessagesCountSubscriptionGQL extends Apollo.Subscription<SupportChatMessagesCountSubscriptionSubscription, SupportChatMessagesCountSubscriptionSubscriptionVariables> {
    document = SupportChatMessagesCountSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportChatMessagesWhereDocument = gql`
    query SupportChatMessagesWhere($SupportChatMessages_bool_exp: SupportChatMessages_bool_exp!, $SupportChatMessages_order_by: [SupportChatMessages_order_by!], $limit: Int, $offset: Int) {
  __typename
  SupportChatMessages(
    where: $SupportChatMessages_bool_exp
    order_by: $SupportChatMessages_order_by
    limit: $limit
    offset: $offset
  ) {
    AuthorId
    AuthorRole
    CreatedDate
    Deleted
    Id
    SupportChatId
    TextValue
    ValueType
    FileItemId
    SupportChat {
      AuthorInfo
      Id
      StartDate
      StopDate
      SupportSubject {
        Id
        Title
        IsActive
      }
    }
    SupportChatMember {
      Id
      IsActive
      Deleted
      SmartlabUserId
      SupportChatId
      SmartlabUser {
        Id
        Email
        NameFirst
        NameLast
        UserId
      }
    }
    FileItem {
      FileName
      FileReference
      FileTypeId
      Id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportChatMessagesWhereGQL extends Apollo.Query<SupportChatMessagesWhereQuery, SupportChatMessagesWhereQueryVariables> {
    document = SupportChatMessagesWhereDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportChatMessagesSubscriptionDocument = gql`
    subscription SupportChatMessagesSubscription($SupportChatMessages_bool_exp: SupportChatMessages_bool_exp!, $SupportChatMessages_order_by: [SupportChatMessages_order_by!], $limit: Int, $offset: Int) {
  SupportChatMessages(
    where: $SupportChatMessages_bool_exp
    order_by: $SupportChatMessages_order_by
    limit: $limit
    offset: $offset
  ) {
    AuthorId
    AuthorRole
    CreatedDate
    Deleted
    Id
    SupportChatId
    TextValue
    ValueType
    FileItemId
    SupportChatMessageViewers {
      Deleted
      Id
      MessageId
      ViewedDate
      ViewerId
      SupportChatMember {
        Id
        SmartlabUser {
          Id
        }
      }
    }
    SupportChat {
      AuthorInfo
      ClosedBy
      Id
      StartDate
      StopDate
      SupportSubject {
        Id
        Title
      }
    }
    SupportChatMember {
      Id
      IsActive
      Deleted
      SmartlabUserId
      SupportChatId
      SmartlabUser {
        Id
        Email
        NameFirst
        NameLast
        UserId
      }
    }
    FileItem {
      FileName
      FileReference
      FileTypeId
      Id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportChatMessagesSubscriptionGQL extends Apollo.Subscription<SupportChatMessagesSubscriptionSubscription, SupportChatMessagesSubscriptionSubscriptionVariables> {
    document = SupportChatMessagesSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportChatsWhereDocument = gql`
    query SupportChatsWhere($SupportChats_bool_exp: SupportChats_bool_exp!, $SupportChats_order_by: [SupportChats_order_by!], $SupportChatMessages_order_by: [SupportChatMessages_order_by!], $ChatMessages_bool_exp: SupportChatMessages_bool_exp!, $limit: Int, $offset: Int) {
  __typename
  SupportChats(
    where: $SupportChats_bool_exp
    order_by: $SupportChats_order_by
    limit: $limit
    offset: $offset
  ) {
    Id
    AuthorInfo
    Deleted
    StartDate
    StopDate
    SupportSubjectId
    ProjectGroupId
    SupportChatMessages(
      where: $ChatMessages_bool_exp
      order_by: $SupportChatMessages_order_by
    ) {
      AuthorId
      CreatedDate
      AuthorRole
      Deleted
      FileItemId
      Id
      SupportChatId
      TextValue
      ValueType
      FileItem {
        FileName
        FileReference
        FileTypeId
        Id
      }
      SupportChatMember {
        Id
        IsActive
        Deleted
        SmartlabUserId
        SupportChatId
        SupportChat {
          AuthorInfo
          Id
          StartDate
          StopDate
          SupportSubject {
            Id
            Title
          }
        }
        SmartlabUser {
          Id
          Email
          NameFirst
          NameLast
          UserId
        }
      }
    }
    SupportChatMembers {
      Deleted
      Id
      IsActive
      SmartlabUserId
      SupportChatId
    }
    SupportSubject {
      Title
      Id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportChatsWhereGQL extends Apollo.Query<SupportChatsWhereQuery, SupportChatsWhereQueryVariables> {
    document = SupportChatsWhereDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportSubjectsWhereDocument = gql`
    query SupportSubjectsWhere($SupportSubjects_bool_exp: SupportSubjects_bool_exp!, $SupportSubjects_order_by: [SupportSubjects_order_by!], $SupportChats_bool_exp: SupportChats_bool_exp!, $SupportChats_order_by: [SupportChats_order_by!], $limit: Int, $offset: Int) {
  __typename
  SupportSubjects(
    where: $SupportSubjects_bool_exp
    order_by: $SupportSubjects_order_by
    limit: $limit
    offset: $offset
  ) {
    Title
    ParentId
    Order
    IsEnabledForOperatorChat
    IsActive
    Id
    Description
    Deleted
    ContainsPFDUnitLinksForCurrentProject
    SupportChats(where: $SupportChats_bool_exp, order_by: $SupportChats_order_by) {
      AuthorInfo
      Deleted
      Id
      ProjectGroupId
      StartDate
      StopDate
      SupportSubjectId
    }
    Children {
      Title
      ParentId
      Order
      IsEnabledForOperatorChat
      IsActive
      Id
      Description
      Deleted
      ContainsPFDUnitLinksForCurrentProject
      SupportChats(where: $SupportChats_bool_exp, order_by: $SupportChats_order_by) {
        AuthorInfo
        Deleted
        Id
        ProjectGroupId
        StartDate
        StopDate
        SupportSubjectId
      }
      Children {
        Title
        ParentId
        Order
        IsEnabledForOperatorChat
        IsActive
        Id
        Description
        Deleted
        ContainsPFDUnitLinksForCurrentProject
        SupportChats(where: $SupportChats_bool_exp, order_by: $SupportChats_order_by) {
          AuthorInfo
          Deleted
          Id
          ProjectGroupId
          StartDate
          StopDate
          SupportSubjectId
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportSubjectsWhereGQL extends Apollo.Query<SupportSubjectsWhereQuery, SupportSubjectsWhereQueryVariables> {
    document = SupportSubjectsWhereDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }