import { Component, Input, OnInit } from '@angular/core';
import { MESSAGE_TYPE } from '../../helpers/constants';
import { IMessageData } from '../../models/message-data-model';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogWindowComponent } from '../image-dialog-window/image-dialog-window.component';
import { URLReplacer } from '../../helpers/URLreplacer';
import { extractEmails } from '../../helpers/email-parser';
import { extractPhoneNumbers } from '../../helpers/phone-parser';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() public set message(value: any) {
    if(!value) {
      return;
    }

    this._message = value;
    this.messageTextValue = URLReplacer(value.message.TextValue);
    this.messageTextValue = extractEmails(this.messageTextValue);
    this.messageTextValue = extractPhoneNumbers(this.messageTextValue);
  }

  public message_type = MESSAGE_TYPE;
  public _message: IMessageData;
  public messageTextValue: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public openDialog() {
    const img = new Image();
    img.src = <string>this._message?.message?.FileItem?.FileReference;

    img.onload = (x => {
      this.dialog.open(ImageDialogWindowComponent, {
        data: {
          src: this._message?.message?.FileItem?.FileReference,
        }
      });
    });
  }
}
