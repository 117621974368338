import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { RestBase } from '../rest.base';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ProjectGroupsWhereGQL } from 'app/graphql';

@Injectable({
  providedIn: 'root'
})
export class SmartlabService extends RestBase {

  public firstGetProjectsDone = false;
  public firstNavigationToSingleProjectDone = false;
  private cachedProjects = [];
  public cachedProjectGroupId: string = null;

  constructor(private http: HttpClient,
    private _authService: AuthService,
    private _projectGroupsWhere: ProjectGroupsWhereGQL) { super(environment.apiUrl + `v1/smartlab/`); }

  getProjects(userId: string) {
    return new Promise<any>((resolve, reject) => {

      if (!this.firstGetProjectsDone) {
        this.http.get(this.endpoint + encodeURIComponent(userId) + '/projects')
          .pipe(map(this.extractData), catchError(this.handleError)).toPromise()
          .then(result => {
            this.firstGetProjectsDone = true;
            this.cachedProjects = result as Array<any>;
            console.log(`cached projects: `, this.cachedProjects);
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve(this.cachedProjects);
      }
    });
  }

  getPFDForProject(projectCode: string): Observable<any> {
    return this.http.get(this.endpoint + 'pfd/' + projectCode)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getProject(projectCode: string) {
    return new Observable<any>((observer) => {
      this.getProjectAfterFirstProjectsDone(observer, projectCode);
    });
  }

  private getProjectAfterFirstProjectsDone(observer, projectCode) {
    if (this.firstGetProjectsDone === true) {
      var project = this.cachedProjects.find(x => x.code == projectCode);
      if (project == null) {
        observer.error('Project does not exist');
      } else {
        // console.log(`getProject: `, this.cachedProjects);



        this._projectGroupsWhere.fetch({
          ProjectGroups_bool_exp: {
              PfdProject: {
                Code: {
                  _eq: projectCode
                }
              }
          }
        }, {
          fetchPolicy: "no-cache"
        }).toPromise().then(result => {
          console.log(`_projectGroupsSmartlabUsersWhere`, result);
          if (result.data.ProjectGroups.length > 0) {
            this.cachedProjectGroupId = result.data.ProjectGroups[0].Id;
          } else {
            this.cachedProjectGroupId = null;
          }
          observer.next(project);
          observer.complete();
        })
          .catch(err => {
            console.log(`err finding _projectGroupsSmartlabUsersWhere: `, err);
            observer.next(project);
            observer.complete();
          });
      }
    } else {
      setTimeout(() => {
        this.getProjectAfterFirstProjectsDone(observer, projectCode)
      }, 500);
    }
  }

  async getCumulioTokenForDashboard(projectCode: string, dashboardId: string, tags?: string, blocks?: string, skipBlocks?: boolean) {

    console.log(`getCumulioTokenForDashboard projectCode:`, projectCode);
    console.log(`getCumulioTokenForDashboard dashboardId:`, dashboardId);
    const userId = this._authService.getUserProfile()[`sub`];

    var requestEndpoint = this.endpoint + userId + '/cumuliotoken/' + projectCode + ``;
    var requestEndpoint = `${this.endpoint}${userId}/cumuliotoken/${projectCode}/${dashboardId}`;

    var hasBlocks = false;
    var firstURLComponentSet = true;

    if (blocks != null && blocks !== ``) {
      hasBlocks = true;
      console.log(`blocks:`, blocks);
      var splitBlocks = blocks.split(',');

      splitBlocks.forEach(block => {
        requestEndpoint = firstURLComponentSet ? `${requestEndpoint}?filterBlock=${block}` : `${requestEndpoint}&filterBlock=${block}`;
        firstURLComponentSet = false;
      });
    }

    if (tags != null && tags !== ``) {
      console.log(`tags:`, tags);
      var splitTags = blocks.split(',');

      splitTags.forEach(block => {
        requestEndpoint = firstURLComponentSet && !hasBlocks ? `${requestEndpoint}?filterTag=${block}` : `${requestEndpoint}&filterTag=${block}`;
        firstURLComponentSet = false;
      });
    }

    if (skipBlocks != null) {
      requestEndpoint = firstURLComponentSet ? `${requestEndpoint}?skipBlocks=${skipBlocks}` : `${requestEndpoint}&skipBlocks=${skipBlocks}`;
      firstURLComponentSet = false;
    }


    return new Promise<any>((resolve, reject) => {
      this.http.get(requestEndpoint)
        .pipe(map(this.extractData), catchError(this.handleError)).toPromise()
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
