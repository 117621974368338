export const locale = {
    lang: 'nl',
    data: {
        ALERTS: {
            ALERTS: 'Waarschuwingen',
            LEVEL: 'Ernst',
            ACTION: 'Actie',
            DATE: 'Datum',
            EVENT: 'Gebeurtenis',
            LOCATION: 'Locatie',
            USER: 'User'
        }
    }
};
