<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/logo.png">
                </div>
            </div>
            <!-- <ng-container *ngIf="authService.userHasPermissions(['upcomingfeatures'])">
                <div class="px-8 px-md-16">
                    <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
                </div>
    
                <div class="toolbar-separator"></div>    
            </ng-container> -->

            <div class="toolbar-selected-plant" *ngIf="projectService.cachedProject && !authService.userHasPermissions(['demomode'])">
                <div class="toolbar-selected-plant-title">{{ projectService.cachedProject.name }}</div>
                <div class="toolbar-selected-plant-local-time"><span class="toolbar-local-time">Local Time: </span>{{ projectService.getProjectLocalDateAndTime() }}</div>
            </div>

            <div class="toolbar-selected-plant" *ngIf="projectService.cachedProject && authService.userHasPermissions(['demomode'])">
                <div class="toolbar-selected-plant-title">Demo Plant</div>
                <div class="toolbar-selected-plant-local-time"><span class="toolbar-local-time">Local Time: </span>{{ projectService.getProjectLocalDateAndTime() }}</div>
            </div>

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <!-- <kpi-indicator *ngIf="authService.userHasPermissions(['upcomingfeatures'])" class="kpi-indicator" [project]="{kpi:0.85}" [showDot]="false"></kpi-indicator> -->

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <!-- <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button> -->

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button *ngIf="authService.userHasPermissions(['upcomingfeatures'])" mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>