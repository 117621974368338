import { Injectable } from '@angular/core';
import { Auth0Service } from '../auth/auth0/auth0.service';
import { BrandingWhereGQL, BrandingWhere } from 'app/graphql';
import { Title } from '@angular/platform-browser';
import { AngularFaviconService } from 'angular-favicon';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {

    public brand: BrandingWhere.Branding = {
        Name: `SMARTLAB`,
        Domain: `https://smartlab.waterleau.com`,
        Logo: `assets/images/logos/logo.png`,
        EnableBigLogo: false,
        AlertsEnabled: true,
        AlertsTitle: null,
        CalendarEnabled: true,
        CalendarTitle: null,
        LaboratoryEnabled: true,
        LaboratoryTitle: null,
        LogbookEnabled: true,
        LogbookTitle: null,
        MaintenanceEnabled: true,
        MaintenanceTitle: null,
        MyPlantsTitle: null,
        MyPlantsTableTitle: null,
        OperatorEnabled: true,
        OperatorTitle: null,
        PlantEnabled: true,
        PlantTitle: null,
        PrimaryColor: `#1890ff`,
        ReportsEnabled: true,
        ReportsTitle: null,
        SecondaryColor: null,
        TechnologiesEnabled: true,
        TechnologiesTitle: null,
        UtilitiesEnabled: true,
        UtilitiesTitle: null,
        FavIcon: null,
        PlantOverrideWithPFDUnitId: null,
        AllowedProjects: null
    }

    constructor(private _brandingWhereGQL: BrandingWhereGQL, private titleService: Title, private ngxFavicon: AngularFaviconService) { }

    async loadBrand(origin: string) {
        return new Promise<BrandingWhere.Branding>((resolve, reject) => {
            this._brandingWhereGQL.fetch({
                Branding_bool_exp: {
                    Domain: {
                        _eq: origin
                    }
                }
            }, {
                fetchPolicy: "no-cache"
            }).toPromise().then(result => {
                // console.log(`_LaboScheduleStatesWhereGQL`, result);

                if (result.data.Branding.length > 0) {

                    this.brand = result.data.Branding[0];
                    this.updateSite();

                    setTimeout(() => {
                        this.updateCSS();
                    }, 1000);
                    resolve(this.brand)
                } else {
                    this.updateSite();
                    resolve(this.brand);
                }

            })
                .catch(err => {
                    console.log(`err: `, err);
                    reject(err);
                });
        });
    }

    private updateSite() {
        this.titleService.setTitle(this.brand.Name);
        if (this.brand.FavIcon) {
            this.ngxFavicon.setFavicon(this.brand.FavIcon);
        }
    }

    updateCSS() {

        const element = document.querySelector('.nav-link-title')

        console.log(`selected Element Style`, element);

        // document.documentElement.style.setProperty(`--${your-variable}`, value + suffix); //suffix may be px or ''
    }

    getPrimaryColor() {
        if (this.brand.PrimaryColor && this.brand.PrimaryColor !== '') {
            return this.brand.PrimaryColor;
        } else {
            // return '#1890ff';
            return '#ff0000';
        }
    }
}
