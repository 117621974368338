import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ObservableInput, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { SupportChats } from "../graphql";
import { IViewMessageModel } from "../models/view-message.model";

@Injectable()
export class MessageViewersService {
  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  public setAsViewed(request: IViewMessageModel): Observable<SupportChats> {
    return this.http.post(`${environment.apiUrl}chatmessageviewers`, request).pipe(
      catchError(
        (err: any, caught: Observable<object>): ObservableInput<any> => {
          console.log('catch:', err);

          return of(err);
      }
    ))
  }
}