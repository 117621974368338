<div class="header">
    <div class="header-inner" *ngIf="chatState?.isProjectsData">
        <div class="header-title-block">
            <span class="header-title">{{chatState?.plantName}}</span>
            <span class="header-subtitle" *ngIf="chatState?.isReturnToSubjectSelect || chatState?.isChatHistoryOpened; else subjectName">SELECT SUBJECT</span>
            <ng-template #subjectName>
                <span class="header-subtitle">{{chatState?.currentSubject?.Title}}</span>
            </ng-template>
    
        </div>
        <div class="header-buttons-block">
            <button class="header-history-btn" (click)="openChatHistory()" [disabled]="!messages.length" *ngIf="!chatState?.isChatHistoryOpened && chatState?.isReturnToSubjectSelect && !chatState?.isSearchOpened && !chatState?.isShowSearchMessagesWhenSubjectClosed"></button>
            <button [ngClass]="(chatState?.isSearchOpened || chatState?.isShowSearchMessagesWhenSubjectClosed)? 'header-search-btn active' : 'header-search-btn'" *ngIf="headerButtonsState.showSearchButton" (click)="toggleSearchWindow()">
                <mat-icon class="header-search-btn-icon">search</mat-icon>
            </button>
            <div class="header-close-btn-wrapper" (click)="closeChat()">
                <button class="header-close-btn"></button>
            </div>
        </div>
    </div>
    <div class="header-empty" *ngIf="!chatState?.isProjectsData">
        <div class="header-close-btn-wrapper" (click)="closeChat()">
            <button class="header-close-btn"></button>
        </div>
    </div>
</div>

