export const locale = {
    lang: 'en',
    data: {
        ALERTS: {
            ALERTS: 'Alerts',
            LEVEL: 'Severity',
            ACTION: 'Action',
            DATE: 'Date',
            EVENT: 'Event',
            LOCATION: 'Location',
            USER: 'Resolved by'
        }
    }
};
