import { Component, OnInit } from '@angular/core'; 
import { SupportChats, SupportSubjects } from '../../graphql';
import { IChatStateModel } from '../../models/chat-state.model';
import { IOpenSubjectRequest } from '../../models/close-subject-request.model';
import { ChatStateService } from '../../services/chat-state.service';
import { SubjectService } from '../../services/subjects.service';

@Component({
  selector: 'app-select-subject',
  templateUrl: './select-subject.component.html',
  styleUrls: ['./select-subject.component.scss']
})
export class SelectSubjectComponent implements OnInit {

  public subjects: SupportSubjects[] | undefined; 
  public isDisplayedSubcategory = false;
  public currentSubject: SupportSubjects;
  public currentSubcategory: SupportSubjects;
  public currentBreadcrumb: string;
  public breadcrumbs: Array<string> = [];
  public chatState: IChatStateModel = {};
  
  constructor(private subjectService: SubjectService, private chatStateService: ChatStateService) { }

  ngOnInit(): void {
    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;
      this.subjects = this.chatState.supportSubjects?.filter(x => !x.ParentId);
    });
  }

  public selectSubject(subject: SupportSubjects): void {
    if (subject.Children?.length) {
      this.currentSubject = subject; 
      this.currentSubcategory = subject;
      this.breadcrumbs.push(subject.Title);
      this.currentBreadcrumb = subject.Title;
      this.isDisplayedSubcategory = true;     
    } else {
      this.breadcrumbs = [];
      this.openSubject(subject);
    }
  }

  public selectSubcategorySubject(subject: any) {
    if (subject.Children.length) {
      this.currentSubcategory = subject;
      this.breadcrumbs.push(subject.Title);
      this.currentBreadcrumb = subject.Title;    
    } else {
      this.openSubject(subject);
    }
  }

  public goBackClick(ParentId: number, breadcrumb: string): void {
    this.findObjectById(this.currentSubject, ParentId);
    this.breadcrumbs = this.breadcrumbs.filter(item => item!== breadcrumb);
    this.currentBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
  }

  private openSubject(subject: SupportSubjects) {
    const request: IOpenSubjectRequest = {
      projectGroupId: this.chatState.projectId,
      authorId: this.chatState?.user?.data.Id
    }

    this.subjectService.open(subject.Id, request).subscribe((res: SupportChats | any) => { 
      if(res.error) return;
      
      this.chatState.currentSubject = subject;
      this.chatState.isReturnToSubjectSelect = false;

      this.chatStateService.state = this.chatState;
    });
  }

  private findObjectById(obj: SupportSubjects, ParentId: number): any {
    if (obj.Id === ParentId) {
      if (obj.ParentId) {
        let parentObj = this.findParentObjById(this.currentSubject, obj.ParentId);
        this.currentSubcategory = parentObj;
      } else {
        this.currentSubject = obj;
        this.isDisplayedSubcategory = false;
      }
      return obj;
    }

    if (obj.Children) {

      for (let item of obj.Children) {
        this.findObjectById(item, ParentId);
      }
    }
    return null;
  }

  private findParentObjById(obj: any, ParentId: number): any {
    if (obj.Id === ParentId) {
      return obj;
    }

    if (obj.Children) {

      for (let item of obj.Children) {
        this.findObjectById(item, ParentId);
      }
    }
    return null;
  }
}
