import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ObservableInput, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { SupportChats } from "../graphql";
import { IOpenSubjectRequest } from "../models/close-subject-request.model";

@Injectable()
export class SubjectService {
  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  public open(subjectId: string, request: IOpenSubjectRequest): Observable<SupportChats> {
    return this.http.put(`${environment.apiUrl}subject/${subjectId}/open`, request).pipe(
      catchError(
        (err: any, caught: Observable<object>): ObservableInput<any> => {
          console.log('catch:', err);

          return of(err);
      }
    ))
  }
}