import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, ObservableInput, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICreateChat } from '../models/request-chat.model';

@Injectable()
export class SupportChatService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  public createChat(body: ICreateChat): Observable<any> {
    return this.http.post(environment.apiUrl + 'supportchats', body, this.httpOptions).pipe(
      catchError(
        (err: any, caught: Observable<object>): ObservableInput<any> => {
          console.log('catch:', err);

          return of(err);
        }
      )
    );
  }
}