export class FileManagerFakeDb
{
    public static files = [
        {
            'name'     : 'Plant analysis monthly report',
            'type'     : 'document',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '1.2 Mb',
            'modified' : 'July 8, 2019',
            'opened'   : 'September 8, 2019',
            'created'  : 'July 8, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report: Inlet UASB',
            'type'     : 'spreadsheet',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '980 Kb',
            'modified' : 'August 12, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report: Outlet UASB',
            'type'     : 'spreadsheet',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '750 Kb',
            'modified' : 'July 8, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report: UASB Reactor',
            'type'     : 'spreadsheet',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '980 Mb',
            'modified' : 'July 8, 2019',
            'opened'   : 'September 8, 2019',
            'created'  : 'July 8, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report: Inlet LUCAS-3',
            'type'     : 'spreadsheet',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '52 Kb',
            'modified' : 'July 8, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report: Outlet LUCAS-3',
            'type'     : 'spreadsheet',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '27 Mb',
            'modified' : 'July 8, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report: Inlet Aquality UF',
            'type'     : 'spreadsheet',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '1.1 Kb',
            'modified' : 'September 8, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report: Outlet Aquality UF',
            'type'     : 'spreadsheet',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '505 Kb',
            'modified' : 'July 8, 2019',
            'opened'   : 'September 8, 2019',
            'created'  : 'August 12, 2019',
            'tab'      : 'laboratory',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Laboratory',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Weekly report',
            'type'     : 'document',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '1.2 Mb',
            'modified' : 'September 8, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'August 12, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Monthly report',
            'type'     : 'document',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '980 Kb',
            'modified' : 'July 8, 2019',
            'opened'   : 'September 8, 2019',
            'created'  : 'August 12, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'FIT710 Failure report',
            'type'     : 'spreadsheet',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '750 Kb',
            'modified' : 'August 12, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'LUCAS3 Preventive intervention',
            'type'     : 'document',
            'owner'    : 'Jeroen De Lathouwer',
            'size'     : '980 Mb',
            'modified' : 'July 8, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'August 12, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Equalization tank reparation report',
            'type'     : 'document',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '52 Kb',
            'modified' : 'September 8, 2019',
            'opened'   : 'September 8, 2019',
            'created'  : 'August 12, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'LIT985 Calibration report',
            'type'     : 'spreadsheet',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '27 Mb',
            'modified' : 'July 8, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'July 8, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Biogas Balloon preventive intervention',
            'type'     : 'document',
            'owner'    : 'Jeroen De Lathouwer',
            'size'     : '1.1 Kb',
            'modified' : 'July 8, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Shift management',
            'type'     : 'spreadsheet',
            'owner'    : 'Jeroen De Lathouwer',
            'size'     : '505 Kb',
            'modified' : 'August 12, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'maintenance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Maintenance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Plant daily report',
            'type'     : 'spreadsheet',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '980 Kb',
            'modified' : 'September 10, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'July 8, 2019',
            'tab'      : 'performance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Performance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Technologies performance',
            'type'     : 'spreadsheet',
            'owner'    : 'Jeroen De Lathouwer',
            'size'     : '1.2 Mb',
            'modified' : 'September 8, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'July 8, 2019',
            'tab'      : 'performance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Performance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Plant weekly report',
            'type'     : 'document',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '750 Kb',
            'modified' : 'September 8, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'performance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Performance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Plant monthly report',
            'type'     : 'document',
            'owner'    : 'Jan Bos Van Hemelrijck',
            'size'     : '98 Mb',
            'modified' : 'July 8, 2019',
            'opened'   : 'July 8, 2019',
            'created'  : 'September 8, 2019',
            'tab'      : 'performance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Performance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Plant Q2 report',
            'type'     : 'document',
            'owner'    : 'Jeroen De Lathouwer',
            'size'     : '150 Mb',
            'modified' : 'August 12, 2019',
            'opened'   : 'September 8, 2019',
            'created'  : 'July 8, 2019',
            'tab'      : 'performance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Performance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
        {
            'name'     : 'Plant yearly report',
            'type'     : 'document',
            'owner'    : 'Zineb Mazouzi',
            'size'     : '980 Mb',
            'modified' : 'September 8, 2019',
            'opened'   : 'August 12, 2019',
            'created'  : 'July 8, 2019',
            'tab'      : 'performance',
            'extention': '',
            'location' : 'CCC Sesquile Reports > Performance',
            'offline'  : true,
            'preview'  : 'assets/images/file-manager/sample-file-preview.jpg'
        },
    ];

}
