import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { RestBase } from '../rest.base';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends RestBase {

  constructor(private http: HttpClient,
    private _authService: AuthService) { super(environment.apiUrl + `v1/reports/`); }

  getReportsForProject(projectId: string) {
    return new Promise<any>((resolve, reject) => {

        this.http.get(this.endpoint + encodeURIComponent(projectId))
          .pipe(map(this.extractData), catchError(this.handleError)).toPromise()
          .then(result => {
            // console.log(`reports for project: `, result);
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
    });
  }
}
