<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
    <div class="logo" *ngIf="!_brandingService.brand.EnableBigLogo">
        <img class="logo-icon" *ngIf="_brandingService.brand.Logo && _brandingService.brand.Logo !== ''"
            [src]="_brandingService.brand.Logo" />
        <span class="logo-text secondary-text">{{ _brandingService.brand.Name.toUpperCase() }}</span>
    </div>

    <div class="logo-big" *ngIf="_brandingService.brand.EnableBigLogo">
        <img class="logo-icon-big" *ngIf="_brandingService.brand.Logo && _brandingService.brand.Logo !== ''"
            [src]="_brandingService.brand.Logo" />
    </div>

    <div class="buttons">
        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>
    </div>
</div>

<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <!-- <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
      <div fxLayout="row" fxLayoutAlign="center center">
        <img class="avatar mr-0 mr-sm-16" [src]="profileService.picture" />
        <span class="username mr-12" fxHide fxShow.gt-sm
          >{{ profileService.fullName }}</span
        >
        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
      </div>
    </button> -->

        <button mat-flat-button [matMenuTriggerFor]="userMenu" class="user-button">
            <div class="h3 username">
                {{ profileService.fullName }}<mat-icon class="s-16 mat-icon-header" fxHide.xs>keyboard_arrow_down
                </mat-icon>
            </div>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="after">
                <!-- <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>
    
                <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <spa;ln>Inbox</span>
                </button> -->

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

        </button>

        <div class="h5 email mt-8">
            {{ profileService.profileName | lowercase }}
        </div>

        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
            [matMenuTriggerFor]="userMenu">
            <div *ngIf="profileService.picture; else noPicture">
                <img class="avatar" [src]="profileService.picture" />
            </div>
            <ng-template #noPicture>
                <img class="avatar" src="assets/images/avatars/empty.bmp" />
            </ng-template>
        </div>
    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

    <!-- <div *ngIf="authService.userHasPermissions(['upcomingfeatures']) && projectService.cachedProject && projectService.cachedProject.code" [ngClass]="{'bottom-tools-folded': showToolsFolded, 'bottom-tools-unfolded': !showToolsFolded }">
        <button mat-flat-button [ngClass]="{'bottom-tools-button-folded': showToolsFolded, 'bottom-tools-button-unfolded': !showToolsFolded  }" [routerLink]="['/apps/todo']" routerLinkActive="router-link-active" >
            <mat-icon [ngClass]="{'mat-icon-folded': showToolsFolded}" aria-hidden="false" aria-label="Example home icon">check_box</mat-icon>
        </button> -->
    <!-- <button mat-flat-button [ngClass]="{'bottom-tools-button-folded': showToolsFolded, 'bottom-tools-button-unfolded': !showToolsFolded  }"  [routerLink]="[ '/apps/' + projectService.cachedProject.code + '/calendar']" routerLinkActive="router-link-active">
            <mat-icon [ngClass]="{'mat-icon-folded': showToolsFolded}" aria-hidden="false" aria-label="Example home icon">today</mat-icon>
        </button> -->
    <!-- <button mat-flat-button [ngClass]="{'bottom-tools-button-folded': showToolsFolded, 'bottom-tools-button-unfolded': !showToolsFolded  }" [routerLink]="['/apps/chat']" routerLinkActive="router-link-active">
            <mat-icon [ngClass]="{'mat-icon-folded': showToolsFolded}" aria-hiddenfg="false" aria-label="Example home icon" >headset_mic</mat-icon>
        </button>
    </div>      -->


</div>