import { LoginState } from './../auth.service';
import { AuthService } from '../auth.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import createAuth0Client from '@auth0/auth0-spa-js';
import { Auth0Client } from '@auth0/auth0-spa-js';

// For Access control/scopes docs see:
// https://auth0.com/docs/quickstart/spa/angular2/04-authorization#protect-client-side-routes

@Injectable()
export class Auth0Service extends AuthService {
  private auth0: Auth0Client = null;
  private loggedInSubject: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState>(LoginState.LOGGED_OUT);
  private userProfile: any;
  private requestedScopes: string;
  private claims: string[];
  private expiryDate: Date;
  private hostLocation: string;

  constructor(@Inject('config') protected config: any,
              private router: Router) {
    super();
    this.requestedScopes = 'offline_access openid profile email ' + (config.auth0.permissions || '');
    this.loggedInSubject.next(LoginState.LOGGED_OUT);
    
    this.hostLocation = window.location.origin;
    if (!this.hostLocation) {
    	this.hostLocation = this.config.url;
    }
    if (this.hostLocation && !this.hostLocation.endsWith("/")) {
    	this.hostLocation = this.hostLocation + "/";
    }
    console.log(`Detected host location: `, this.hostLocation);
  }

  public login(redirect?: string) {
    this._createClient().then(() => {
      this._login();
    });
  }

  public logout() {
    this._createClient().then(() => {
      // Log out of Auth0 session. Ensure that returnTo URL is specified in Auth0 Application settings (Allowed Logout URLs)
      this.auth0.logout({returnTo: this.config.url, client_id: this.config.auth0.clientID});
      this.loggedInSubject.next(LoginState.LOGGED_OUT);
    });
  }

  public getLoginStateSubject(): BehaviorSubject<LoginState> {
    return this.loggedInSubject;
  }

  public getUserProfile(): any {
    return this.userProfile;
  }

  public getLoginState(): LoginState {
    return this.loggedInSubject.value;
  }

  // This function will fetch the access token
  // If it is expired it will refresh (refresh token),
  // and return the new one
  public getAccessToken(): Promise<string> {
    return new Promise(resolve => {
      this._createClient().then(() => {
        // getTokenSilently will automatically refresh the token
        // if it is expired
        this.auth0.getTokenSilently().then(token => {
          // store the raw id token
          resolve(token);
        }).catch(error => {
          console.error('Failed to get access token: ' + error);
          this._onError(error);
        });
      });
    });
  }

  // This function will fetch the id token
  // If it is expired it will refresh (refresh token),
  // and return the new one
  public getIdToken(): Promise<string> {
    return this.__getIdToken(0);
  }

  // This function will fetch the id token
  // If it is expired it will refresh (refresh token),
  // and return the new one
  public __getIdToken(retry): Promise<string> {
    return new Promise(resolve => {
      this._createClient().then(() => {
        // checkSession will refresh the session if expired (leeway of 60 secs).
        // we need to do this first, because getIdTokenClaims just returns null on expiry.
        this.auth0.checkSession().then(() => {
          this.auth0.getIdTokenClaims().then(claims => {
            if (!claims) {
              // This apparently happens for unknown reasons
              // Let's retry this a few times, until we have the token.
              // if not. logout the user !
              if (retry >= 10) {
                console.error('Failed to get id token: Token is null. Retried 10 times');
                resolve(null);
                this.logout();
              } else {
                console.warn('Failed to get id token: Token is null. Trying again after 1 second');
                // try again after 1 second, and keep doing this forever ??
                setTimeout(() => {
                  this.__getIdToken(retry + 1).then(token => {
                    resolve(token);
                  }).catch(() => {
                    console.error('Failed to get id token: Token is null');
                    this._onError('Failed to get id token: Token is null');
                    resolve(null);
                  });
                }, 1000);
              }
            } else {
              // store and print expiry date
              this.expiryDate = new Date(0);
              this.expiryDate.setUTCSeconds(claims.exp);
              console.log('ID Token will expire on ' + this.expiryDate.toLocaleString());
              // store the raw id token
              resolve(claims.__raw);
            }
          });
        }).catch(error => {
          console.error('Failed to get id token: ' + error);
          this._onError(error);
          resolve(null);
        });
      }).catch(error => {
        console.error('Failed to get id token: ' + error);
        this._onError(error);
        resolve(null);
      });
    });
  }

  public getIdTokenExpiryDate(): Date {
    return this.expiryDate;
  }

  public nagivateToRedirect() {
    const redirect = localStorage.getItem('auth_redirect');
    if (redirect) {
      console.log('Navigating to ' + redirect);
      this.router.navigateByUrl(redirect); // redirect after login error ??
    } else {
      console.log('Navigating to /');
      this.router.navigateByUrl('/'); // redirect after login error ??
    }
  }

  public handleLoginCallback() {
    this._createClient().then(() => {
      this.auth0.handleRedirectCallback().then(redirectResult => {
        this._fetchUserProfile();
      }).catch(error => {
        const errorStr = error?.toString();
        if (errorStr?.includes('Invalid state') === true) {
          console.warn('Failed to login. Trying again');
          this._login(localStorage.getItem('auth_redirect'));
        }
        else {
          console.error('error handleRedirectCallback: ' + error);
          this._onError(error);
        }
      });
    });
  }

  public userHasPermissions(scopes: Array<string>): boolean {
    if (this.claims) {
      return scopes.every(scope => this.claims.includes(scope));
    }
    return false;
  }

  private _fetchUserProfile() {
    if (this.getLoginState() !== LoginState.LOGGING_IN) {
      this.loggedInSubject.next(LoginState.LOGGING_IN);
    }
    this.auth0.getUser().then(profile => {
      if (profile) {
        // If the email of the user has changed in the past,
        // the name and nickname are not updated. Auth0 does not allow that
        // manually change the name and nickname here
        if (profile.email) {
          profile.name = profile.email;
          profile.nickname = profile.email.split('@')[0];
        }
        this.userProfile = profile;
        // The claims should be in here !!
        // because the token is too big, we need to use the hasura permissions ...
        this.claims = profile['https://hasura.io/jwt/claims']['x-hasura-user-permissions'].replace('{', '').replace('}', '').split(',');
        //this.claims = profile['https://waterleau.cloud/app_metadata'].permissions;
        this.loggedInSubject.next(LoginState.LOGGED_IN);
      } else {
        this._onError('Failed to get profile (null) ');
      }
    }).catch(error => {
      console.error('Failed to get profile: ' + error);
      this._onError(error);
    });
  }

  private _onError(error) {
    if (this.loggedInSubject.value !== LoginState.LOGGED_OUT) {
      this.loggedInSubject.next(LoginState.LOGGED_OUT);
    }
    this.loggedInSubject.error(error);
  }

  private _login(redirect?: string) {
    this.loggedInSubject.next(LoginState.LOGGING_IN);
    let red = redirect ? redirect : window.location.pathname;
    if (red && red.startsWith(this.config.auth0.callbackURL)) {
      red = '/';
    }
    localStorage.setItem('auth_redirect', red);
    // Start login process by opening the Auth0 login page
    this.auth0.loginWithRedirect({
      redirect_uri: this.hostLocation + this.config.auth0.callbackURL
    }).then(() => {
      console.log('logged in with redirect: ' + red);
    }).catch(error => {
      console.error('Error logging in with redirect' + error);
      this._onError(error);
    });
  }

  private _createClient() {
    return new Promise(resolve => {
      if (this.auth0 !== null) {
        resolve();
        return;
      }

      console.log('Creating Auth0 Client');

      createAuth0Client({
        domain: this.config.auth0.domain,
        client_id: this.config.auth0.clientID,
        redirect_uri: this.hostLocation + this.config.auth0.callbackURL,
        scope: this.requestedScopes,
        audience: this.config.auth0.audience,
        useRefreshTokens: true
      }).then(auth0 => {
        //console.log('Created Auth0 Client');
        this.auth0 = auth0;
        resolve();
      }).catch(error => {
        console.error('Failed to create auth0 client: ' + error);
        this._onError(error);
      });
    });
  }
}
