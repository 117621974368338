import { Component, Input, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { NgxSpinnerService } from 'ngx-spinner';
import { IChatStateModel } from '../../../models/chat-state.model';
import { ChatStateService } from '../../../services/chat-state.service';
import { FileUploadState } from '../../messages/messages.component';


@Component({
  selector: 'app-message-file-upload',
  templateUrl: './message-file-upload.component.html',
  styleUrls: ['./message-file-upload.component.scss']
})
export class MessageFileUploadComponent implements OnInit {
  @Input() public set state(value: FileUploadState) {
    if(!value) return;
    this.fileState = value;
  };

  public chatState: IChatStateModel = {};
  public fileState: FileUploadState;

  public options: AnimationOptions = {
    path: 'assets/animation/animation-spinner.json'
  };

  styles: Partial<CSSStyleDeclaration> = {
    margin: '0 auto',
  };

  private animationItem: AnimationItem;

  constructor(private chatStateService: ChatStateService, private spinner: NgxSpinnerService, private ngZone: NgZone) { }

  ngOnInit(): void {
    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;
    });
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }
}
