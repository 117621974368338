import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAX_MESSAGE_LENGTH } from '../../helpers/constants';
import { IChatStateModel } from '../../models/chat-state.model';
import { IMessageMetaData} from '../../models/message-meta-data.model';
import { ChatStateService } from '../../services/chat-state.service';
import { ChatService } from '../../services/chat.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {  
  @Input() set messageMetaData(value: IMessageMetaData) {
    if(!value) {
      return;
    }

    this._messageMetaData = value;
  };

  @Input() set location(value: {url: string}) {
    if(!value) {
      return;
    }

    this.sendMessage(value.url);
  };

  @Output() fileSelected: EventEmitter<FileList> = new EventEmitter<FileList>();

  private _messageMetaData: IMessageMetaData;
  private _isSendLocationOpened: boolean;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  public maxMessageLength = MAX_MESSAGE_LENGTH;
  public message: string;
  public messageInput = new FormControl('messageInput',
    Validators.maxLength(this.maxMessageLength)
  );
  public isErrorShown = false;
  public chatState: IChatStateModel;

  constructor(private messageService: MessageService, private chatService: ChatService, private chatStateService: ChatStateService) {
    this.messageInput.setValue("");
  }

  ngOnInit(): void {
    this.messageService.messageError$.pipe((takeUntil(this._unsubscribeAll))).subscribe((err: any) => {
      this.isErrorShown = true;
      
      setTimeout(() => {
        this.isErrorShown = false;
      }, 5000);
    });

    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;
    });

    this.chatService.isSendLocationOpened.pipe( (takeUntil(this._unsubscribeAll))).subscribe(value => this._isSendLocationOpened = value);
  }

  public onMouseEnter(e: any) {
    this.chatService.setSendMessageHovered(true);
  }

  public sendMessage(message: any): void {
    if(!message) return;
    if(message.length >= this.maxMessageLength) return;

    let body = {
      textValue: message,
      valueType: 0,
      authorId: this._messageMetaData.authorId,
      authorRole: this._messageMetaData.authorRole,
      chatId: this._messageMetaData.supportChatId
    };

    this.messageService.sendUImessage(body);
    this.messageService.send(body).subscribe();
    this.messageInput.setValue("");
  }

  public onFileSelected(e: any): void {
    this.fileSelected.emit(e.target.files)
    const file:File = e.target.files[0];
      
        if (file) {
            const formData = new FormData();
            formData.append("thumbnail", file);
        }
        console.log(file);
        
  }

  public openSendLocationWindow(): void {
    this._isSendLocationOpened = !this._isSendLocationOpened;
    this.chatService.setIsSendLocationOpened(this._isSendLocationOpened);
  }

  ngOnDestroy(): void {
  }

}
