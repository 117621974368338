import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ObservableInput, of, Subject } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from '../../../../environments/environment';

@Injectable()
export class NotificationPushService {
  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
  };
  public subjectError$ = new Subject<any>();

  constructor(private http: HttpClient) { }

  public addPushSubscriber(request: any) {
    return this.http.post(`${environment.apiUrl}notifications/subscriptions`, request).pipe(
      catchError(
        (err: any, caught: Observable<object>): ObservableInput<any> => {
          console.log('catch:', err);
          return of(err);
      }
    ))
  }

  public notify(message: string) {
    const pushNotification = {
        Notification: message
    };

    return this.http.post(`${environment.apiUrl}notifications/notify`,pushNotification).pipe(
        catchError(
            (err: any, caught: Observable<object>): ObservableInput<any> => {
            console.log('catch:', err);
            return of(err);
        }
    ))

  }
}