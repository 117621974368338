import { DefinitionTag } from './../../../../lib/interfaces/labo.interface';
import { Subscription, Observable } from 'rxjs';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, EventEmitter, Input, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DefaultDay } from 'app/lib/interfaces/laboScheduleState.interface';
import { BrandingService } from 'app/lib/services/branding/branding.service';

@Component({
    selector: 'app-labo-overview',
    templateUrl: './labo-overview.component.html',
    styleUrls: ['./labo-overview.component.scss']
})
export class LaboOverviewComponent implements OnInit {

    // @ViewChild('laboOverviewContainer') laboOverviewContainer: ElementRef;

    // private laboOverviewContainerNativeElement: HTMLElement;
    // private laboOverviewContainerHeight = 400;

    private dataChangedSubscription: Subscription;
    @Input() dataChanged: Observable<void>;
    @Output() silentReloadEvent = new EventEmitter();
    @Output() reloadDayEvent = new EventEmitter<any>();

    public laboData: any;

    public overviewSelection = "sp";
    // public overviewSelection = "analysis";

    public selectedTabIndexSP = 0;
    public selectedTabIndexAnalysis = 0;

    public samplingPointColumnsFiltered: any;
    public tagsArrayFiltered = new Array<DefinitionTag>();

    private search_timeout;

    public overviewFilter: string;

    public noSchedule = false;

    constructor(
        public brandingService: BrandingService,
        public deviceService: DeviceDetectorService) {
    }

    ngOnInit() {
        this.dataChangedSubscription = this.dataChanged.subscribe((data) => {
            this.laboData = data;
            this.onUpdate();
        });
    }

    // TODO: Finalize in Zinebs Holiday

    // ngAfterViewInit() {
    //     this.checkLaboOverviewLoaded();
    // }

    // checkLaboOverviewLoaded() {

    //     if (this.dataLoaded) {
    //         this.laboOverviewContainerNativeElement = this.laboOverviewContainer.nativeElement;

    //         const height = document.documentElement.clientHeight - 355;

    //         this.laboOverviewContainerHeight = this.laboOverviewContainerNativeElement.getBoundingClientRect().height;
    //         console.log(`laboOverviewContainerHeight`, this.laboOverviewContainerHeight);
    //         // this.tableViewLoaded = true;
    //     } else {
    //         setTimeout(() => {
    //             this.checkLaboOverviewLoaded();
    //         }, 500);
    //     }

    // }








    // ------------------------------------------------------ SEARCH
    onSearch() {
        clearTimeout(this.search_timeout);
        this.search_timeout = setTimeout(() => {
            this.onUpdate();
        }, 200);
    }

    clearSearch() {
        this.overviewFilter = null;
        this.onUpdate();
    }

    // ------------------------------------------------------ UPDATE
    onUpdate() {

        // searchterm:
        if (this.overviewFilter) {
            const searchterm = this.overviewFilter.toLowerCase();

            const tagsArray = this.laboData.tagsArrayFiltered.filter((item: DefinitionTag) => {
                return item.name ? item.name.toLowerCase().includes(searchterm) : 0
                    || item.id ? item.id.toString().toLowerCase().includes(searchterm) : 0;
            });

            const filteredMap: Map<string, { name: string, idProj: number, planned: DefaultDay, default: boolean, dates: Map<string, Date>, order: number }> = new Map<string, { name: string, idProj: number, planned: DefaultDay, default: boolean, dates: Map<string, Date>, order: number }>();

            this.laboData.samplingPointColumns.forEach((item: { name: string, idProj: number, planned: DefaultDay, default: boolean, dates: Map<string, Date>, order: number }, key: string) => {
                if (item.name ? item.name.toLowerCase().includes(searchterm) : 0
                    || item.idProj ? item.idProj === parseInt(searchterm) : 0) {
                    filteredMap.set(key, item);
                }
            });

            this.samplingPointColumnsFiltered = filteredMap;
            this.tagsArrayFiltered = tagsArray;
        }
        else {
            this.samplingPointColumnsFiltered = this.laboData?.samplingPointColumns == null ? {} : this.laboData.samplingPointColumns;
            this.tagsArrayFiltered = this.laboData?.tagsArrayFiltered == null ? [] : [...this.laboData.tagsArrayFiltered];
        }
    }

    checkSamplingPointContainsAnalysisPoint(sp: DefaultDay, ap: number) {
        // console.log(`inputCheck:  sp`, sp);
        // console.log(`inputCheck:  ap`, ap);
        if (sp.analysisIds.includes(ap)) {
            // console.log(`inputCheck: includes`, true);
            return true;
        } else {
            // console.log(`inputCheck: includes`, false);
            return false;
        }
    }

    getSamplingPointData(idProj: number, plannedSamplingPoint: DefaultDay, oldDate: Date, tagKey) {

        // return this.randomValueBetween(0, 100);
        // console.log(`getSamplingPointData: idProj`, idProj);
        // console.log(`getSamplingPointData: idProj`, tagKey);
        // console.log(`getSamplingPointData: this.samplingPointsData`, this.samplingPointsData);

        const returnValue = {
            found: false,
            value: `-`
        };


        if (this.laboData.samplingPointsData != null) {
            this.laboData.samplingPointsData.forEach(data => {
                if (data.idProject === idProj) {
                    data.labAnalysis.forEach(analysis => {
                        // const date = super.addProjectTimeZoneOffset(new Date(analysis.date));
                        const date = new Date(analysis.date);

                        if (analysis.id === tagKey && analysis.scheduleId === plannedSamplingPoint.id) {
                            returnValue.found = true;
                            returnValue.value = `${analysis.value}`;
                        }
                    });
                }
            });
        }

        if (returnValue.found) {
            return returnValue;
        } else {
            return null;
        }

    }

    checkHasTagLimits(tag: DefinitionTag) {
        if (tag.limit_l || tag.limit_ll || tag.limit_h || tag.limit_hh) {
            return true;
        } else {
            return false;
        }
    }

    // 
    getTagLimitClass(v: number, tag: DefinitionTag) {

        let tagClass = "true";

        if (tag.limit_l) {
            if (v < tag.limit_l) {
                tagClass = "limit-orange";
            }
        }

        if (tag.limit_ll) {
            if (v < tag.limit_ll) {
                tagClass = "limit-red";
                return tagClass;
            }
        }

        if (tag.limit_h) {
            if (v > tag.limit_h) {
                tagClass = "limit-orange";
            }
        }

        if (tag.limit_hh) {
            if (v > tag.limit_hh) {
                tagClass = "limit-red";
                return tagClass;
            }
        }

        return tagClass;

    }

    sortColumnByOrder = (a, b): number => {
        return a.value.order - b.value.order
    }

    getSamplingPointAnalysis(samplingPointId): Array<number> {
        // TODO: 
        const samplingpointNameEntry = this.laboData.samplingPoints.find(x => x.id === samplingPointId);
        if (samplingpointNameEntry === undefined) {
            return [];
        } else {
            return samplingpointNameEntry.labAnalysis.map(x => x.id);
        }
    }

    getTag(tagId: string): DefinitionTag {
        const tagNameEntry = this.laboData.tagsArray.find(x => x.id === parseInt(tagId));
        // console.log(`check: tag`, tagNameEntry);
        if (tagNameEntry === undefined) {
            return null;
        } else {
            return tagNameEntry;
        }
    }

}
