export class ChatFakeDb
{
    public static contacts = [
        {
            'id'    : '5725a680b3249760ea21de52',
            'name'  : 'Julien Grasset',
            'avatar': 'assets/images/avatars/empty.bmp',
            'status': 'busy',
            'mood'  : ''
        },
        {
            'id'    : '5725a68007920cf75051da64',
            'name'  : 'Zineb Mazouzi',
            'avatar': 'assets/images/avatars/Zineb.jpg',
            'status': 'online',
            'mood'  : ''
        }
    ];

    public static chats = [
        {
            'id'    : '1725a680b3249760ea21de52',
            'dialog': [
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'Sludge is washing out from the UASB reactor, what shall I do?',
                    'time'   : '2019-10-23T09:02:28.299Z'
                },
                {
                    'who'    : '5725a6802d10e277a0f35724',
                    'message': 'I see that the upflow velocity in the UASB is high, I suggest to lower it to 0.6 m/h',
                    'time'   : '2019-10-23T09:00:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'Okay thank you!',
                    'time'   : '2019-10-23T09:05:28.299Z'
                }
            ]
        }
    ];

    public static user = [
        {
            'id'      : '5725a6802d10e277a0f35724',
            'name'    : 'Jeroen De Lathouwer',
            'avatar'  : 'assets/images/avatars/empty.bmp',
            'status'  : 'online',
            'mood'    : '',
            'chatList': [
                {
                    'id'             : '1725a680b3249760ea21de52',
                    'contactId'      : '5725a680b3249760ea21de52',
                    'name'           : 'Mitch',
                    'unread'         : 1,
                    'lastMessage'    : 'Okay thanks !',
                    'time'           : '2019-10-23T09:05:28.299Z'
                }
            ]
        }
    ];

}
