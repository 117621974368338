<div fusePerfectScrollbar>
    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="level">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.level==0" class="material-icons md-red">error</mat-icon>
                <mat-icon *ngIf="element.level==1" class="material-icons md-yellow">warning</mat-icon>
                <mat-icon *ngIf="element.level==2" class="material-icons md-blue">info</mat-icon>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action" widr>
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERTS.ACTION' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.action}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="resolved_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERTS.DATE' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.resolved.date | date:'short'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="resolved_user">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERTS.USER' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.resolved.user}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERTS.DATE' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.date | date:'short'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="event">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERTS.EVENT' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" style="color: blue;"> {{element.event}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ALERTS.LOCATION' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.location}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="header-row-group">
            <mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="3"> 
                <mat-button-toggle-group multiple #group="matButtonToggleGroup" (change)=toggleSeverity(group.value) value="[0,1,2]">
                    <mat-button-toggle value="0">
                        <mat-icon class="material-icons md-red">error</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="1">
                        <mat-icon class="material-icons md-yellow">warning</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="2">
                        <mat-icon class="material-icons md-blue">info</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </mat-header-cell>
        </ng-container>
        
        <mat-header-row class="no-border-header" *matHeaderRowDef="['header-row-group']"></mat-header-row>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>