import { Component, OnInit } from '@angular/core';
import { IChatStateModel } from '../../models/chat-state.model';
import { ChatStateService } from '../../services/chat-state.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-content-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.scss']
})
export class ContentWrapperComponent implements OnInit {
  public chatSubject: any;
  public _searchValue: string;
  public chatState: IChatStateModel;

  constructor(private chatService: ChatService, private chatStateService: ChatStateService) { }

  ngOnInit(): void {
    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;
    });

    this.chatService.searchValue.subscribe(res => {
      this._searchValue = res;
    });
  }

}
