import { Component, Input, OnInit } from '@angular/core';
import { SupportChatMessages } from '../../../graphql';
import { MESSAGE_TYPE } from '../../../helpers/constants';
import { parseMessage } from '../../../helpers/highlight-message-parser';

@Component({
  selector: 'app-search-message',
  templateUrl: './search-message.component.html',
  styleUrls: ['./search-message.component.scss']
})
export class SearchMessageComponent implements OnInit {

  @Input() public set message(value: SupportChatMessages) {
    if(!value) return;

    this._message = value;
  };

  @Input() public set searchValue(value: string) {
    if(!value) return;

    this._searchValue = value;
  };

  private _searchValue: string;
  public _message: SupportChatMessages;
  public message_type = MESSAGE_TYPE;
  public highlightMessage: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.highlightMessage = parseMessage(<string>this._message.TextValue, this._searchValue);
  }

}
