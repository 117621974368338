export enum MESSAGE_TYPE {
    USER = 'SL user',
    OPERATOR = 'Operator'
}

export const MAX_MESSAGE_LENGTH = 500;

export const DEFAULT_USER = {
    avatar: "assets\icons\no-avatar.svg",
    name: "waterleau-ro-client",
    Id: "",
    authorRole: MESSAGE_TYPE.USER
}

export const CHAT_CONSTANTS = {

    CHAT_MESSAGES: {
        limit: 10,
        Deleted: false,
        offset: 0,
        offsetIncreaseNumber: 10
    },
    NEW_MESSAGES: {
        limit: 10,
        Deleted: false
    }
};

export enum SUBJECT_INFO_STATE {
    showOpenedInfo = 0,
    showClosedInfo = 1,
    showBothInfo = 2
};
