import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable()
export class AzureBlobService {
    constructor(private http: HttpClient) {}

    upload(formData: FormData) {
        return this.http.post<{ path: string }>(
          `${environment.apiUrl}attachments`,
          formData
        );
      }
}