import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { OperatorGlobalMessage } from './OperatorGlobalMessage.interface';

@Injectable({
    providedIn: 'root'
})
/*
    Service implementing the Operator SignalR Connection,
    Use this as an entrypoint or implement it as required, for example:
    - Use this service for Operator Data transmission (Get operators, Chat etc...)
    - Implement stubs here for testing
    - ...
*/
export class OperatorService {

    public hubConnection: signalR.HubConnection;
    private operatorInterval;

    constructor(
    ) {
    }

    operatorTest() {

        this.setupConnection();

    }

    private setupConnection = () => {

        if (!this.hubConnection) {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(environment.operatorSignalRUrl)
                .build();

            this.hubConnection
                .stop()
                .then(() => {
                    console.log('Connection stopped');
                    this.startConnection();
                });
        }

    }


    // TODO: Fix all disconnect logic
    // TODO: ConnectedClients are not yet returning, figure out why ;-)
    
    private startConnection = () => {

        console.log("state: ", this.hubConnection.state);

        if (!this.hubConnection.state) {

            console.log("startConnection");

            this.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started');

                    this.setupListeners();

                    this.broadcastAddClient();
                    this.broadcastSendGlobalMessage();

                    setInterval(() => {
                        this.broadcastSendGlobalMessage();
                    }, 20000);
                    
                    this.operatorInterval = setInterval(() => {
                        this.broadcastGetOperatorsTest();
                        this.broadcastGetClientsTest();
                    }, 10000);

                })
                .catch(err => {

                    // TODO: send to failure event

                    console.log('Error while starting connection: ', err);
                    // try again in 10 seconds

                    setTimeout(() => {
                        this.startConnection();
                    }, 1000);

                });

        }
    }

    private setupListeners() {
        this.hubConnection.on("ReceiveGlobalMessage", (data) => {
            console.log("Received Global Message: ", data);
        });
    }

    private broadcastSendGlobalMessage = () => {

        const currentDate = new Date();

        this.hubConnection.invoke('SendGlobalMessage', { message: "mock global message" + currentDate.toISOString() } )
            .then((res) => {
                console.log('SendGlobalMessage SignalR: OK', res);
            })
            .catch(err => {
                console.error(err);
                // this.startConnection();
            });

    }

    private broadcastGetOperatorsTest = () => {

        this.hubConnection.invoke('getAvailableOperators')
            .then((res) => {
                console.log('GetAvailableOperators SignalR: OK', res);
            })
            .catch(err => {
                console.error(err);
                // this.startConnection();
            });

    }

    private broadcastGetClientsTest = () => {

        this.hubConnection.invoke('GetAvailableClients')
            .then((res) => {
                console.log('GetAvailableClients SignalR: OK', res);
            })
            .catch(err => {
                console.error(err);
                // this.startConnection();
            });

    }

    private broadcastAddClient = () => {

        const currentDate = new Date();

        this.hubConnection.invoke('AddClient', "mock client" + currentDate.toISOString())
            .then(() => {
                console.log('AddClient SignalR: OK');
            })
            .catch(err => {
                console.error(err);
                // this.startConnection();
            });

    }

}
