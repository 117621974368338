<ng-container [ngSwitch]="_message?.message?.AuthorRole">
    <ng-container *ngSwitchCase="message_type.USER">
        <div class="chat-message-user-block" *ngIf="!_message?.isImage" [id]="_message?.message?.Id">
            <img src="assets\icons\no-avatar.svg" alt="avatar" class="user-avatar-img">
            <div class="chat-message-text-block">
                <span class="user-name">{{_message?.message?.SupportChatMember?.SmartlabUser?.NameFirst}}</span>
                <p *ngIf="_message?.message?.TextValue" class="chat-message-text" [innerHTML]="messageTextValue"></p>
                <div *ngIf="!_message?.message?.TextValue">
                    <a href="{{_message?.message?.FileItem?.FileReference}}" download class="chat-message-text">{{_message?.message?.FileItem?.FileName}}</a>
                </div>
            </div>
        </div>

        <div class="chat-message-user-image-block" *ngIf="_message?.isImage" (click)="openDialog()" [id]="_message?.message?.Id">
            <img src="assets\icons\no-avatar.svg" alt="avatar" class="chat-message-user-image-block-avatar">
            <img [src]="_message?.message?.FileItem?.FileReference" alt="picture" class="chat-message-user-image-block-picture">
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="message_type.OPERATOR">
        <div class="chat-message-operator-block" *ngIf="!_message?.isImage" [id]="_message?.message?.Id">
            <div class="chat-message-text-block right">
                <span class="operator-name">{{_message?.message?.SupportChatMember?.SmartlabUser?.NameFirst}} (OPERATOR)</span>
                <p *ngIf="_message?.message?.TextValue" class="chat-message-text" [innerHTML]="messageTextValue"></p>
                <div *ngIf="!_message?.message?.TextValue">
                    <a href="{{_message?.message?.FileItem?.FileReference}}" download class="chat-message-text">{{_message?.message?.FileItem?.FileName}}</a>
                </div>
            </div>
            <img src="assets\icons\no-avatar.svg" alt="avatar" class="operator-avatar-img">
        </div>

        <div class="chat-message-operator-image-block" *ngIf="_message?.isImage" (click)="openDialog()" [id]="_message?.message?.Id">
            <img src="assets\icons\no-avatar.svg" alt="avatar" class="chat-message-operator-image-block-avatar">
            <img [src]="_message?.message?.FileItem?.FileReference" alt="picture" class="chat-message-operator-image-block-picture">
        </div>
    </ng-container>
</ng-container>

