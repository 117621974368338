<!-- CHAT -->
<div [ngStyle]="{'z-index':9999999 }">
    <app-chat [userId]="profileService.sub" [projectGroupId]="smartlabService.cachedProjectGroupId" *ngIf="brandingLoaded && projectService.cachedProject && projectService.cachedProject.code && smartlabService.cachedProjectGroupId"></app-chat>
</div>
<!-- / CHAT -->

<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- SPINNERS https://github.com/ilDug/dag-mat-spinner -->
<ngx-spinner *ngIf="smartlabService.firstGetProjectsDone" name="loadProjectSpinner" bdColor="rgba(51,51,51,0.8)"
    size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 16px; color: white">Loading Plant data...</p>
</ngx-spinner>
<!-- SPINNER -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="brandingLoaded && fuseConfig && fuseConfig.layout">
    <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
        <vertical-layout-1></vertical-layout-1>
    </ng-container>

    <!-- / VERTICAL LAYOUT 1 -->

    <!-- VERTICAL LAYOUT 2 -->
    <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
        <vertical-layout-2></vertical-layout-2>
    </ng-container>
    <!-- / VERTICAL LAYOUT 2 -->

    <!-- VERTICAL LAYOUT 3 -->
    <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
        <vertical-layout-3></vertical-layout-3>
    </ng-container>
    <!-- / VERTICAL LAYOUT 3 -->

    <!-- HORIZONTAL LAYOUT 1 -->
    <ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
        <horizontal-layout-1></horizontal-layout-1>
    </ng-container>
    <!-- / HORIZONTAL LAYOUT 1 -->

    <!-- THEME OPTIONS PANEL -->
    <!--<button mat-icon-button class="warn mat-elevation-z2 theme-options-button"
        (click)="toggleSidebarOpen('themeOptionsPanel')">
    <mat-icon>settings</mat-icon>
</button>

<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>-->

</ng-container>

<!-- / THEME OPTIONS PANEL -->

<!-- OPERATOR CHAT -->

<!-- <div id="operator-button-container" class="hvr-wobble-vertical"
    *ngIf="brandingLoaded && authService.userHasPermissions(['upcomingfeatures']) && brandingService.brand.OperatorEnabled && projectService.cachedProject && projectService.cachedProject.code">
    <button mat-flat-button id="operator-button" [routerLink]="['/apps/chat']" routerLinkActive="router-link-active" [style.background-color]="brandingService.getPrimaryColor()">
        <mat-icon aria-hiddenfg="false" aria-label="Example home icon">headset_mic</mat-icon>
    </button>
    <div id="operator-button-triangle" [style.border-top-color]="brandingService.getPrimaryColor()">
    </div>
</div> -->