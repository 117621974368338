import { AlertLevel } from './level';

export const alertData: any[] = [
    { level: AlertLevel.CRITICAL,   resolved: { date: new Date(2019, 9, 22, 12, 11),   user: 'Oscar Parada' }, date: new Date(), event: 'Critical salt passage: perform CIP', location: 'AQUALITY UF', action: 'Support' },
    { level: AlertLevel.WARNING,    resolved: { date: new Date(2019, 9, 21, 11, 45),   user: 'Oscar Parada' }, date: new Date(), event: 'pH sensor calibration', location: 'IPP', action: 'Support' },
    { level: AlertLevel.WARNING,    resolved: { date: null,         user: 'Jan Bos Van Hemelrijck' }, date: new Date(2019, 9, 23, 8, 11), event: 'Low arearation level', location: 'LUCAS-3', action: 'Support' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 15, 36),   user: '-' }, date: new Date(), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 24, 9, 53),   user: '-' }, date: new Date(), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: null,         user: 'Oscar Parada' }, date: new Date(2019, 9, 24, 6, 11), event: 'Reactor temperature low', location: 'UASB', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 15, 24),   user: '-' }, date: new Date(), event: 'System restart succeeded', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: null,         user: 'Jan Bos Van Hemelrijck' }, date: new Date(2019, 9, 23, 23, 11), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 15, 35),   user: '-' }, date: new Date(), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: {  date: new Date(2019, 9, 22, 12, 11),  user: 'Jan Bos Van Hemelrijck' }, date: new Date(2019, 9, 24, 1, 11), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.CRITICAL,   resolved: { date: new Date(2019, 9, 22, 12, 11),   user: 'Oscar Parada' }, date: new Date(), event: 'VFA level very high', location: 'UASB', action: 'Support' },
    { level: AlertLevel.WARNING,    resolved: { date: new Date(2019, 9, 21, 11, 45),   user: 'Oscar Parada' }, date: new Date(), event: 'pH sensor calibration', location: 'Calamity', action: 'Support' },
    { level: AlertLevel.WARNING,    resolved: {  date: new Date(2019, 9, 22, 12, 11),  user: 'Jan Bos Van Hemelrijck' }, date: new Date(2019, 9, 23, 8, 11), event: 'Low arearation level', location: 'LUCAS-3', action: 'Support' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 15, 36),   user: '-' }, date: new Date(), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 24, 9, 53),   user: '-' }, date: new Date(), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: {  date: new Date(2019, 9, 22, 12, 11),  user: 'Oscar Parada' }, date: new Date(2019, 9, 24, 6, 11), event: 'Reactor temperature low', location: 'UASB', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 15, 24),   user: '-' }, date: new Date(), event: 'System restart succeeded', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 12, 11), user: 'Jan Bos Van Hemelrijck' }, date: new Date(2019, 9, 23, 23, 11), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 15, 35),   user: '-' }, date: new Date(), event: 'System started', location: 'system', action: 'Not Required' },
    { level: AlertLevel.INFO,       resolved: { date: new Date(2019, 9, 22, 12, 11),  user: 'Jan Bos Van Hemelrijck' }, date: new Date(2019, 9, 24, 1, 11), event: 'System started', location: 'system', action: 'Not Required' }
];