import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, ObservableInput, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IMessageModel } from '../models/message-model';
import { IUpdateStatusMessageModel } from '../models/update-status-message-model';

@Injectable()
export class MessageService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  public messageUI$ = new Subject<any>();
  public messageError$ = new Subject<any>();
  public messageDeleteID$ = new Subject<string>();

  constructor(private http: HttpClient) { }

  public send(body: IMessageModel): Observable<any> {

    return this.http.post(environment.apiUrl + 'chatmessages', body, this.httpOptions).pipe(
      catchError(
        (err: any, caught: Observable<object>): ObservableInput<any> => {
          console.log('catch:', err);
          this.messageError$.next(err);
          return of(err);
        }
      )
    );
  }

  public sendUImessage(message: any): void {
    this.messageUI$.next(message);
  }

  public updateStatus(body: IUpdateStatusMessageModel): Observable<any> {

    return this.http.post(environment.apiUrl + 'chatmessageviewers', body, this.httpOptions).pipe(
      catchError(
        (err: any, caught: Observable<object>): ObservableInput<any> => {
          console.log('catch:', err);

          return of(err);
        }
      )
    );
  }
}
