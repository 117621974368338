import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SupportChatMessages } from '../../graphql';
import { CHAT_CONSTANTS, MESSAGE_TYPE, SUBJECT_INFO_STATE } from '../../helpers/constants';
import { IRequestChatMessages } from '../../models/request-chat-messages.model';
import { ChatService } from '../../services/chat.service';
import { IMessageData } from '../../models/message-data-model';
import { MessageViewersService } from '../../services/message-viewers.service';
import { IViewMessageModel } from '../../models/view-message.model';
import { MessageService } from '../../services/message.service';
import { NotificationPushService } from '../../services/notification-push.service';
import { ChatStateService } from '../../services/chat-state.service';
import { IChatStateModel } from '../../models/chat-state.model';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, AfterViewChecked {

  @Input() public subjectInfo: {[key: string]: string};
  @Input() public set fileUploading(value: boolean) {
    this.isFileUploading = value;
  }
  @Input() fileState: FileUploadState;

  @Output() fileSelected: EventEmitter<FileList> = new EventEmitter<FileList>();

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private projectId: string|undefined; 
  private querySubscription: Subscription;
  private isScrolled = false;
  private chatMessagesRequest: IRequestChatMessages;
  private previousScrollPosition: number;
  private currentPosition = window.pageYOffset;
  private isScrollToTop: boolean;
  private showNewMessagesLine: boolean = false;
  private messagesBeforeFilterDateSubscription: Subscription;
  private messagesAfterFilterDateSubscription: Subscription;
  private isScrolledToMessage = false;
  private searchMessageId: string;
  private isScrolledUp = false;

  public userId: string;
  public _messages: any[] = [];
  public newMessages: SupportChatMessages[] = [];
  public scrolltop: number|null = null;
  public isDragAndDropOpened = false;
  public isHistoryOpened: boolean;
  public loading: boolean;
  public subjectInfoState = SUBJECT_INFO_STATE;
  public firstMessageIndex = 0;
  public _searchMetaData: IRequestChatMessages;
  public _searchValue: string;
  public _isSearchOpened: boolean;
  public _isBackToBottomDisplayed: boolean;
  public isSpinnerShown = false;
  public isFileUploading = false;
  public chatState: IChatStateModel;

  constructor(public chatService: ChatService,
              private messageViewersService: MessageViewersService,
              private messageService: MessageService,
              private notificationPushService: NotificationPushService,
              private chatStateService: ChatStateService) {
                
    this.isSpinnerShown = true; 

    this.chatStateService.state$.pipe((takeUntil(this._unsubscribeAll))).subscribe((state: IChatStateModel) => {
      this.chatState = state;
      this.projectId = this.chatState?.projectId;
    });

    setTimeout(() => {
      this.scrollToBottom();
    });

    this.chatService.isSendMessageHovered.subscribe((_) => this.onMessagesSeen())
  }

  ngOnInit(): void {
    this.querySubscription?.unsubscribe();

    this.messageService.messageUI$.pipe((takeUntil(this._unsubscribeAll))).subscribe((message: any) => {
      let messageUI = {
        SupportChatId: message.chatId,
        AuthorRole: message.authorRole,
        TextValue: message.textValue,
        SupportChatMember: {
          SmartlabUser: {
            NameFirst: this.chatState?.user?.data.NameFirst
          }
        }
      };

      let messageData = {
        message: messageUI
      };

      this._messages.push(messageData);

      setTimeout(() => {
        this.scrollToBottom();
      });
    });

    this.messageService.messageError$.pipe((takeUntil(this._unsubscribeAll))).subscribe((err: any) => {
        this._messages = this._messages.filter((x) => x.message.Id);
    });

    this.chatService.searchMessageId$
    .pipe((takeUntil(this._unsubscribeAll)))
    .subscribe( messageId => {
      this.searchMessageId = messageId;
      this.isScrolled = true;
    });

    this.chatService.searchValue.pipe((takeUntil(this._unsubscribeAll))).subscribe(res => {
      this._searchValue = res;

      this._searchMetaData = {
        projectId: this.projectId,
        isDeleted: CHAT_CONSTANTS.CHAT_MESSAGES.Deleted,
        searchString: res
      };
    });

    this.chatService.firstMessageFilterDate.pipe((takeUntil(this._unsubscribeAll))).subscribe((startDate: Date) => {
      this.subscribeOnMessagesFilterDate(startDate);
    });

    this.chatStateService.state$.pipe((takeUntil(this._unsubscribeAll))).subscribe((state: IChatStateModel) => {
      this.chatState = state;
    });

    this.chatStateService.messages$.pipe((takeUntil(this._unsubscribeAll))).subscribe((messages: IMessageData[]) => {
      if(this.searchMessageId && !this.isScrolled && !this.chatState.isChatHistoryOpened) return;

      this.loading = true;
      this.previousScrollPosition =  this.myScrollContainer?.nativeElement?.scrollHeight - this.myScrollContainer?.nativeElement?.scrollTop;
      this._messages = messages;

      this.scrollTo();
    });
  }

  ngAfterViewChecked(): void {
    if(this.isFileUploading) {
      this.scrollToBottom();
    }
  }

  public goToSubjectsSelect(): void {
    this.chatState.isChatHistoryOpened = false;
    this.chatState.isReturnToSubjectSelect = true;
    
    this.chatStateService.state = this.chatState;
  }

  public trackById(index: number, item: any): number {
    return item.Id;
  }

  public onMessagesSeen(e: any = {}) {
    const unreadMessages: IMessageData[] = <IMessageData[]>this.chatState?.unreadMessages;

    if(!unreadMessages || !unreadMessages.length) return;

    const body: IViewMessageModel = {
      chatId: unreadMessages[0].message.SupportChatId,
      messagesId: unreadMessages.map(x => x.message.Id),
      userId: <string>this.chatState?.user?.data.Id
    }

    this.messageViewersService.setAsViewed(body).subscribe((res) => console.log(res));
  }

  public showNewMessagesSection(message: IMessageData): boolean {
    if(!message.message.Id) return false;
    
    if(message?.message?.SupportChatMember?.SmartlabUser?.Id === this.chatState?.user?.data.Id) {
      this.showNewMessagesLine = false; 
      return false
    }; 

    if(!message?.message?.SupportChatMessageViewers?.find((x: any) => x.SupportChatMember?.SmartlabUser.Id === this.chatState?.user?.data.Id)) {
      if(this.showNewMessagesLine) {
        return false;
      } else {
        this.showNewMessagesLine = true;
        return true;
      }
    };

    return false;
  }

  private subscribeOnMessagesFilterDate(startDate: Date): void {
    this.isSpinnerShown = true;
    this.isScrolledToMessage = true;

    this.chatStateService.getMessagesBeforeFilterDate(startDate);
  }

  private scrollToMessage(id: string): void {
    let el = document.getElementById(id);
    el?.scrollIntoView();
    this.isScrolledToMessage = false;
    this.searchMessageId = '';
  }

  private scrollTo(): void {
    this.loading = false;
    this.isSpinnerShown = false;

    setTimeout(() => {

      if(this.isScrollToTop) {
        this.scrollToTop();
        return;
      }

      if(!this.isScrolledToMessage) {

        if(this.isScrolledUp) {
          this.scrolltop = this.myScrollContainer?.nativeElement?.scrollHeight - this.previousScrollPosition;
        } else {
          this.scrollToBottom();
        }

      } else {
        this.scrollToMessage(this.searchMessageId);
      }

      this.isScrolledUp = false;
    });
    
    const unreadCount = <number>this.chatState?.unreadMessages?.length;

    if(unreadCount > 0) this.notificationPushService.notify(`You have ${unreadCount} unread message(s)`).subscribe(() => console.log("Notification sent with " + unreadCount));

    console.log('mess', this._messages);
  }

  public scrollToBottom(): void {
    if(this.myScrollContainer) {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer?.nativeElement?.scrollHeight;
    }              
  }

  public scrollToTop(): void {
    this.myScrollContainer.nativeElement.scrollTop = 0;             
  }

  public onScrollUp(e: any): void {
    this.isScrolledUp = true;
    
    this.chatMessagesRequest = {
      projectId: this.projectId,
      limit: this._messages.length + CHAT_CONSTANTS.CHAT_MESSAGES.limit
    };

    this.chatStateService.getMessages(this.chatMessagesRequest);
  }

  public onScroll(e: Event): void {
    let scrollTop = this.myScrollContainer.nativeElement.scrollTop;
    let scrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
    let clientHeight = this.myScrollContainer.nativeElement.clientHeight;

    this._isBackToBottomDisplayed = true;

    if (scrollHeight - scrollTop === clientHeight) {
      this._isBackToBottomDisplayed = false;
    }

    this.currentPosition = scrollTop;
    this.scrolltop = this.myScrollContainer.nativeElement.scrollTop;
  }

  private _isNew(messageId: string): boolean {
    return !this._messages.find(x => x.Id === messageId);
  }

  private _isMyMessage(message: SupportChatMessages): boolean {
    return message.AuthorRole === MESSAGE_TYPE.USER;
  }

  // Drag-n-Drop logic
  // TODO: move to directive
  // Note: blicking overlay due to child (pseudo)elements in 'drop-zone-active' class when moved to directive
  error: string;
  dragAreaClass: string;
  dragTarget: any;

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "drop-zone-active";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragTarget = event.target;
    this.dragAreaClass = "drop-zone-active";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    if(this.dragTarget === event.target)
      this.dragAreaClass = "";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "";
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.fileSelected.emit(files);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.messagesBeforeFilterDateSubscription?.unsubscribe();
    this.messagesAfterFilterDateSubscription?.unsubscribe();
  }

}

export interface FileUploadState {
  valid: boolean;
  message?: string;
}
