import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SupportChats } from '../../graphql';
import { CHAT_CONSTANTS, MESSAGE_TYPE } from '../../helpers/constants';
import { FileInputValidator, FilleValidationResult } from '../../helpers/file-validator';
import { IChatStateModel } from '../../models/chat-state.model';
import { IMessageMetaData } from '../../models/message-meta-data.model';
import { IRequestChatMessages } from '../../models/request-chat-messages.model';
import { IRequestChat } from '../../models/request-chat.model';
import { AzureBlobService } from '../../services/azure-blob.service';
import { ChatStateService } from '../../services/chat-state.service';
import { ChatService } from '../../services/chat.service';
import { GraphqlService } from '../../services/qraphql.service';
import { FileUploadState } from '../messages/messages.component';

@Component({
  selector: 'app-message-wrapper',
  templateUrl: './message-wrapper.component.html',
  styleUrls: ['./message-wrapper.component.scss']
})
export class MessageWrapperComponent implements OnInit {
  @Input() set subject(value: any) {
    if(!value) {
      return;
    }

    this.subjectInfo.Title = value.Title;
    this.subjectId = value.Id;
    this._setRequestChatModel(this.subjectId, this.chatState.projectId);
    this.graphqlService.getSupportChat(this.request).pipe( (takeUntil(this._unsubscribeAll))).subscribe();
  }

  public messageMetaData : IMessageMetaData;
  public chatId: string;
  public subjectInfo: {[key: string]: string} = {};
  public fileUploading: boolean;
  public isSendLocationWindowOpened: boolean;
  public fileState: FileUploadState;
  public _location: any;
  public _searchMetaData: IRequestChatMessages;
  public _isSearchOpened: boolean;
  public _searchValue: string;
  public _isShowSearchMessagesWhenSubjectClosed: boolean;
  public chatState: IChatStateModel;

  private subjectId: string;
  private request: IRequestChat = {

  };
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private chatService: ChatService,
    private graphqlService: GraphqlService, 
    private blobService: AzureBlobService,
    private chatStateService: ChatStateService
    ) {

      this.chatStateService.state$.subscribe((state: IChatStateModel) => {
        this.chatState = state;
        this._location = null;
      });

      this.graphqlService.supportChat$
      .pipe(
        (takeUntil(this._unsubscribeAll)))
        .subscribe((supportChats: SupportChats[])  => {
            const activeChat = supportChats.find(x => !x.StopDate);

            if(!activeChat) return; 

            this.initMessagesData(activeChat);
      });

      this._location = null;
   }

   ngOnInit() {
     this.chatService.isSendLocationOpened.pipe( (takeUntil(this._unsubscribeAll))).subscribe(value => this.isSendLocationWindowOpened = value);

     this.chatService.searchValue.pipe((takeUntil(this._unsubscribeAll))).subscribe(res => {
       this._searchValue = res;
     });
   }

   public openSendLocationWindow(isOpened: boolean): void {
     this.isSendLocationWindowOpened = isOpened;
   }

   public saveFiles(files: FileList) {
    this.fileUploading = true;
    const validationResult: FilleValidationResult = FileInputValidator(files);

    if (!validationResult.valid) {
      this.fileState = {
        valid: validationResult.valid,
        message: validationResult.error 
      }

      setTimeout(() => {
        this.fileUploading = false;
      }, 2000);

      return;
    };
    
    const file = files[0];

    this.fileState = {
      valid: validationResult.valid,
      message: file.name
    }

    const formData = new FormData();
    formData.append(file.name, file);
    formData.append('userId', <string>this.chatState?.user?.data.Id);
    formData.append('supportChatId', this.chatId);
    formData.append('authorRole', MESSAGE_TYPE.USER);
  
    this.blobService.upload(formData).subscribe(res => {
      this.fileUploading = false;
    });

    return;
  }

  public onMouseHover(e: any) {
    console.log('hover');
  }

  public sendLocation(location: string): void {
    this._location = {
      url: location
    };
  }

  private _setRequestChatModel(subjectId: string, projectGroupId?: string) {
    if(subjectId) {
      this.request.subjectId = subjectId;
    }

    if(projectGroupId) {
      this.request.projectGroupId = projectGroupId;
      this.request.isDeleted = CHAT_CONSTANTS.CHAT_MESSAGES.Deleted;
    }
  }

  private initMessagesData(chat: any) {
    this.subjectInfo.AuthorInfo = chat.AuthorInfo || chat.authorInfo;
    this.chatId = chat.Id || chat.id;

    this.messageMetaData = {
      authorId: <string>this.chatState?.user?.data.Id,
      authorRole: <string>this.chatState?.user?.role,
      supportChatId: this.chatId
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
