import { AlertsComponent } from './main/alerts/alerts.component';
import { AuthGuard } from './lib/guard/auth.guard';
import { CallbackComponent } from './lib/views/auth/callback.component';
import { MaintenanceComponent } from './main/project/maintenance/maintenance.component';
import { TechnologiesComponent } from './main/project/technologies/technologies.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './main/projects/projects.component';
import { LaboratoryComponent } from './main/project/laboratory/laboratory.component';

const appRoutes: Routes = [
    // project selection
    { canActivate: [ AuthGuard ], path: 'projects', component: ProjectsComponent},

    // other
    { canActivate: [ AuthGuard ], path: 'project/:project/plant/:planttype', component: TechnologiesComponent},
    { canActivate: [ AuthGuard ], path: 'project/:project/utilities/:utilitytype', component: TechnologiesComponent},
    { canActivate: [ AuthGuard ], path: 'project/:project/technologies/:technology', component: TechnologiesComponent},
    { canActivate: [ AuthGuard ], path: 'project/:project/maintenance', component: MaintenanceComponent},
    { canActivate: [ AuthGuard ], path: 'project/:project/laboratory', component: LaboratoryComponent},
    { canActivate: [ AuthGuard ], path: 'project/:project/reports', loadChildren: () => import('./main/apps/file-manager/file-manager.module').then(m => m.FileManagerModule)},
    //{ canActivate: [ AuthGuard ], path: 'project/:project/wtloperator', component: WtlOperatorComponent},    
    { canActivate: [ AuthGuard ], path: 'alerts', component: AlertsComponent},  
    { canActivate: [ AuthGuard ], path: 'apps/chat', loadChildren: () => import('./main/apps/chat/chat.module').then(m => m.ChatModule)},
    { canActivate: [ AuthGuard ], path: 'apps/contacts', loadChildren: () => import('./main/apps/contacts/contacts.module').then(m => m.ContactsModule)},
    { canActivate: [ AuthGuard ], path: 'apps/:project/calendar', loadChildren: () => import('./main/apps/calendar/calendar.module').then(m => m.CalendarModule)},
    { canActivate: [ AuthGuard ], path: 'apps/todo', loadChildren: () => import('./main/apps/todo/todo.module').then(m => m.TodoModule)},
    //{ canActivate: [ AuthGuard ], path: 'apps/file-manager', loadChildren: './main/apps/file-manager/file-manager.module#FileManagerModule'},
    { path: 'auth/callback', component: CallbackComponent },
    { path: '**', redirectTo: 'projects' }
];

@NgModule({
  // useHash: false possibly fixes routing issues with auth0-spa-js. See: https://github.com/auth0/auth0-spa-js/issues/225
  imports: [RouterModule.forRoot(appRoutes, { useHash: false })], 
  exports: [RouterModule]
})
export class AppRoutingModule { }
