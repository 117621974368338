<div class="sl-page-tab-content card-container">

    <div *ngIf="!laboData || !dataLoaded" class="center-spinner-tab" fxLayout="row" fxLayoutAlign="center center">
        <mat-spinner color="accent" diameter="50"></mat-spinner>
    </div>

    <nz-tabset *ngIf="laboData && dataLoaded" id="sl-vertical-tabset" [nzTabPosition]="deviceService.isDesktop() ? 'left' : 'top'"
        [(nzSelectedIndex)]="selectedTabIndex" (nzSelectChange)="stateTabChange([$event])">
        <nz-tab *ngFor="let scheduleState of scheduleStates; let i = index;" (nzSelect)="log(['select', scheduleState])"
            (nzClick)="log(['click', scheduleState])" (nzDeselect)="log(['deselect', scheduleState])" #brandedTab [nzTitle]="slLeftTabsetTabTitle">

            <ng-template #slLeftTabsetTabTitle>
                <ng-container *ngIf="deviceService.isDesktop()">
                    <div class="sl-vertical-tab">
                        <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" [style.color]="(brandedTab.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">
                            {{ scheduleState.name }}
                        </div>

                        <div class="sl-vertical-tab-extra" *ngIf="isCurrent(scheduleState)" fxFlex="30" fxLayout="row"
                            fxLayoutAlign="start end">
                            Current
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!deviceService.isDesktop()">
                    {{ scheduleState.name }}
                </ng-container>
            </ng-template>

            <div></div>

            <div class="sl-right-content" *ngIf="scheduleStates[i].id == scheduleState.id">
                <span class="sl-right-content-title">{{ scheduleState.name }}</span>
                <!-- TODO -->
                <ng-container *ngIf="_authService.userHasPermissions(['create:labo'])">
                    <span [style.margin-left]="'20px'"></span><a (click)="showModalChangeStateName()"><i nz-icon
                            nzType="edit" nzTheme="outline"></i></a>
                </ng-container>

                <!-- {{ tab.content }} -->

                <nz-card class="sl-card" [style.margin-top]="'20px'" [nzBordered]="true"
                    [nzTitle]="slCardTitleTemplatePlanning">
                    <ng-template #slCardTitleTemplatePlanning>
                        <div class="sl-card-title">
                            Planning
                        </div>
                        <div class="sl-card-subtitle">
                            Plan when the state should be active. Changes are saved instantly.
                        </div>
                    </ng-template>

                    <!-- <div class="sl-card-option">
                        Set as Current
                        <span [style.margin-left]="'10px'"></span>
                        <nz-switch [(ngModel)]="currentEnabled"></nz-switch>
                    </div> -->
                    <div class="sl-card-option">
                        <!-- <span [style.margin-left]="'10px'"></span> -->

                        <ng-container *ngIf="_authService.userHasPermissions(['create:labo'])">
                            <nz-tag [style.margin-bottom]="'10px'"
                                *ngFor="let planning of selectedScheduleStatePlannings; let i = index"
                                [nzMode]="'closeable'" (nzOnClose)="handleDeletePlanning(planning)">
                                {{ planning.planned | date : 'EEEE'}} {{ planning.planned | date : 'dd-MM-yyyy'}} (Week
                                {{ planning.planned | week }})
                            </nz-tag>
                        </ng-container>

                        <ng-container *ngIf="!_authService.userHasPermissions(['create:labo'])">
                            <nz-tag [style.margin-bottom]="'10px'"
                                *ngFor="let planning of selectedScheduleStatePlannings; let i = index"
                                [nzMode]="'default'">
                                {{ planning.planned | date : 'EEEE'}} {{ planning.planned | date : 'dd-MM-yyyy'}} (Week
                                {{ planning.planned | week }})
                            </nz-tag>
                        </ng-container>

                        <!-- 
                    <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip *ngFor="let tag of tags; let i = index" [removable]="removable"
                            (removed)="remove(fruit)">
                            {{ sliceTagName(tag) }}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list> -->

                        <button *ngIf="_authService.userHasPermissions(['create:labo'])" mat-mini-fab
                            class="add-event-button mat-accent" aria-label="Add Planning"
                            (click)="showModalAddStatePlanning()"
                            [style.background]="brandingService.getPrimaryColor()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </nz-card>

                <nz-card class="sl-card" [style.margin-top]="'20px'" [nzBordered]="true"
                    [nzTitle]="slCardTitleTemplateDaily">
                    <ng-template #slCardTitleTemplateDaily>
                        <div class="sl-card-title">
                            Daily Analysis Schedule
                        </div>
                        <div class="sl-card-subtitle">
                            Organize lab analysis to be performed by day and by
                            sample point. Click the Save button to store the changes you made.
                        </div>
                    </ng-template>

                    <nz-tabset [(nzSelectedIndex)]="selectedDayIndex" nzAnimated="'false'">
                        <nz-tab #brandedTab1 [nzTitle]="titleTemplate1">

                            <ng-template #titleTemplate1>
                                <span [style.color]="(brandedTab1.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Monday</span>
                            </ng-template>

                            <app-labo-schedule-day *ngIf="selectedDayIndex == 0" selectedDayIndex="0"
                                [daySamplingPoints]="scheduleState.schedule.monday" [tagsArray]="laboData.tagsArray"
                                [schedule]="scheduleState.schedule" [samplingPoints]="laboData.samplingPoints">
                            </app-labo-schedule-day>
                        </nz-tab>
                        <nz-tab #brandedTab2 [nzTitle]="titleTemplate2">

                            <ng-template #titleTemplate2>
                                <span [style.color]="(brandedTab2.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Tuesday</span>
                            </ng-template>

                            <app-labo-schedule-day *ngIf="selectedDayIndex == 1" selectedDayIndex="1"
                                [daySamplingPoints]="scheduleState.schedule.tuesday" [tagsArray]="laboData.tagsArray"
                                [schedule]="scheduleState.schedule" [samplingPoints]="laboData.samplingPoints">
                            </app-labo-schedule-day>
                        </nz-tab>
                        <nz-tab #brandedTab3 [nzTitle]="titleTemplate3">

                            <ng-template #titleTemplate3>
                                <span [style.color]="(brandedTab3.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Wednesday</span>
                            </ng-template>
                           
                            <app-labo-schedule-day *ngIf="selectedDayIndex == 2" selectedDayIndex="2"
                                [daySamplingPoints]="scheduleState.schedule.wednesday" [tagsArray]="laboData.tagsArray"
                                [schedule]="scheduleState.schedule" [samplingPoints]="laboData.samplingPoints">
                            </app-labo-schedule-day>
                        </nz-tab>
                        <nz-tab #brandedTab4 [nzTitle]="titleTemplate4">

                            <ng-template #titleTemplate4>
                                <span [style.color]="(brandedTab4.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Thursday</span>
                            </ng-template>
                           
                            <app-labo-schedule-day *ngIf="selectedDayIndex == 3" selectedDayIndex="3"
                                [daySamplingPoints]="scheduleState.schedule.thursday" [tagsArray]="laboData.tagsArray"
                                [schedule]="scheduleState.schedule" [samplingPoints]="laboData.samplingPoints">
                            </app-labo-schedule-day>
                        </nz-tab>
                        <nz-tab #brandedTab5 [nzTitle]="titleTemplate5">

                            <ng-template #titleTemplate5>
                                <span [style.color]="(brandedTab5.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Friday</span>
                            </ng-template>
                           
                            <app-labo-schedule-day *ngIf="selectedDayIndex == 4" selectedDayIndex="4"
                                [daySamplingPoints]="scheduleState.schedule.friday" [tagsArray]="laboData.tagsArray"
                                [schedule]="scheduleState.schedule" [samplingPoints]="laboData.samplingPoints">
                            </app-labo-schedule-day>
                        </nz-tab>
                        <nz-tab #brandedTab6 [nzTitle]="titleTemplate6">

                            <ng-template #titleTemplate6>
                                <span [style.color]="(brandedTab6.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Saturday</span>
                            </ng-template>
                           
                            <app-labo-schedule-day *ngIf="selectedDayIndex == 5" selectedDayIndex="5"
                                [daySamplingPoints]="scheduleState.schedule.saturday" [tagsArray]="laboData.tagsArray"
                                [schedule]="scheduleState.schedule" [samplingPoints]="laboData.samplingPoints">
                            </app-labo-schedule-day>
                        </nz-tab>
                        <nz-tab #brandedTab7 [nzTitle]="titleTemplate7">

                            <ng-template #titleTemplate7>
                                <span [style.color]="(brandedTab7.isActive && brandingService.getPrimaryColor()) || 'rgba(0,0,0,.65)'">Sunday</span>
                            </ng-template>
                           
                            <app-labo-schedule-day *ngIf="selectedDayIndex == 6" selectedDayIndex="6"
                                [daySamplingPoints]="scheduleState.schedule.sunday" [tagsArray]="laboData.tagsArray"
                                [schedule]="scheduleState.schedule" [samplingPoints]="laboData.samplingPoints">
                            </app-labo-schedule-day>
                        </nz-tab>
                    </nz-tabset>


                    <!-- Save state -->
                    <div *ngIf="_authService.userHasPermissions(['create:labo'])" fxFlex="100" fxLayout="row"
                        fxLayoutAlign="end" [style.margin-top]="'20px'">
                         <button nz-button [style.background]="brandingService.getPrimaryColor()"nzType="primary" (click)="saveState(i)">Save Daily Analysis Schedule</button>
                    </div>

                </nz-card>
            </div>

            <!-- Delete -->
            <div *ngIf="_authService.userHasPermissions(['create:labo'])" fxFlex="100" fxLayout="row"
                fxLayoutAlign="start" [style.margin-top]="'20px'" [style.margin-bottom]="'20px'">
                 <button nz-button [style.background]="brandingService.getPrimaryColor()"nzType="danger" (click)="showModalDeleteState(i)">Delete State</button>
            </div>
        </nz-tab>

        <nz-tab [nzTitle]="slButtonTab" *ngIf="_authService.userHasPermissions(['create:labo'])">
            <ng-template #slButtonTab>
                <ng-container *ngIf="deviceService.isDesktop()">
                    <div class="sl-vertical-tab">
                        <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" [style.margin-bottom]="'10px'"
                            [style.text-align]="'center'">
                            <button mat-mini-fab class="add-event-button mat-accent" aria-label="Add Planning" [style.background]="brandingService.getPrimaryColor()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!deviceService.isDesktop()">
                    <button mat-mini-fab class="add-event-button mat-accent" aria-label="Add Planning" [style.background]="brandingService.getPrimaryColor()">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-container>

            </ng-template>

            <nz-card class="sl-card" [style.margin-top]="'20px'" [nzBordered]="true" [nzTitle]="slCardTitleTemplateNew">
                <ng-template #slCardTitleTemplateNew>
                    <div class="sl-card-title">
                        New state
                    </div>
                    <div class="sl-card-subtitle">
                        Set the name of the new state and save it to create the new state.
                    </div>
                </ng-template>

                <div class="sl-card-option">
                    <input nz-input placeholder="State Name" [(ngModel)]="newStateName" />
                </div>

                <div class="sl-card-option" *ngIf="scheduleStates.length > 0" [style.margin-top]="'15px'">

                    Optional: Copy the Daily Analysis Schedule from another state

                    <!-- <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a person">
                        <nz-option *ngFor="let scheduleState of scheduleStates; let i = index;" [nzLabel]="scheduleState.Name" [nzValue]="scheduleState.Name"></nz-option>
                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                    </nz-select> -->

                    <nz-select [style.margin-top]="'5px'" [style.width]="'100%'" nzShowSearch nzAllowClear
                        nzPlaceHolder="" [(ngModel)]="selectedScheduleStateTemplate">
                        <nz-option *ngFor="let o of scheduleStates" [nzValue]="o" [nzLabel]="o.name"></nz-option>
                    </nz-select>


                </div>
            </nz-card>
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end" [style.margin-top]="'20px'">
                 <button nz-button [style.background]="brandingService.getPrimaryColor()"nzType="primary" (click)="createNewState()">Create new State</button>
            </div>

        </nz-tab>

        <!-- <ng-template #extraTemplate>
      <i class="ant-tabs-new-tab" nz-icon nzType="plus" (click)="newTab()"></i>
    </ng-template> -->
    </nz-tabset>
</div>

<nz-modal [(nzVisible)]="isAddingStatePlanning" [style.width]="'100px'"
    nzTitle="Select the start-date of the state you want to add to the Planning" (nzOnCancel)="cancelAddStatePlanning()"
    (nzOnOk)="confirmAddStatePlanning()">
    <p>
        <nz-date-picker [(ngModel)]="addedStatePlanning"></nz-date-picker>
    </p>
</nz-modal>

<nz-modal [(nzVisible)]="isDeletingState" [style.width]="'100px'" nzTitle="Delete State"
    (nzOnCancel)="cancelDeleteState()" (nzOnOk)="confirmDeleteState()">
    <p>
        Are you sure you want to delete the State?
    </p>
</nz-modal>

<nz-modal [(nzVisible)]="isChangingStateName" [style.width]="'100px'" nzTitle="Edit State Name"
    (nzOnCancel)="cancelChangeStateName()" (nzOnOk)="confirmChangeStateName()">
    <p>
        <input nz-input placeholder="State Name" [(ngModel)]="editStateName" />
    </p>
</nz-modal>