import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-send-location',
  templateUrl: './send-location.component.html',
  styleUrls: ['./send-location.component.scss']
})
export class SendLocationComponent implements OnInit {
  @Output() public location: EventEmitter<string> = new EventEmitter<string>();

  constructor(private chatService: ChatService) { }

  ngOnInit(): void {
  }

  public sendLocation(): void {
    this.chatService.setIsSendLocationOpened(false);
    this.location.emit(window.location.href);
  }

}
