export class ChatPanelFakeDb
{
    public static contacts = [
        {
            'id'    : '5725a680b3249760ea21de52',
            'name'  : 'Mitch',
            'avatar': 'assets/images/avatars/Zineb.jpg',
            'status': 'online',
            'mood'  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
            'unread': '2'
        }
    ];

    public static chats = [
        {
            'id'    : '1725a680b3249760ea21de52',
            'dialog': [
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'Quickly come to the meeting room 1B, we have a big server issue',
                    'time'   : '2019-03-22T08:54:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2019-03-22T09:00:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'You are the worst!',
                    'time'   : '2019-03-22T09:05:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2019-03-22T09:15:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'You are the worst!',
                    'time'   : '2019-03-22T09:05:28.299Z'
                },
                {
                    'who'    : '5725a680b3249760ea21de52',
                    'message': 'We are losing money! Quick!',
                    'time'   : '2019-03-22T09:15:28.299Z'
                },
            ]
        }
    ];

    public static user = [
        {
            'id'      : '5725a6802d10e277a0f35724',
            'name'    : 'Zineb Mazouzi',
            'avatar'  : 'assets/images/avatars/Zineb.jpg',
            'status'  : 'online',
            'mood'    : '',
            'chatList': [
                {
                    'chatId'         : '1725a680b3249760ea21de52',
                    'contactId'      : '5725a680b3249760ea21de52',
                    'lastMessageTime': '2019-06-12T02:10:18.931Z'
                }
            ]
        }
    ];

}
