import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { SupportChatMessages } from '../../graphql';
import { CHAT_CONSTANTS } from '../../helpers/constants';
import { IChatStateModel } from '../../models/chat-state.model';
import { IRequestChatMessages } from '../../models/request-chat-messages.model';
import { ChatStateService } from '../../services/chat-state.service';
import { ChatService } from '../../services/chat.service';
import { GraphqlService } from '../../services/qraphql.service';


@Component({
  selector: 'app-search-messages',
  templateUrl: './search-messages.component.html',
  styleUrls: ['./search-messages.component.scss']
})
export class SearchMessagesComponent implements OnInit {

  @Input() public set searchMetaData(value: IRequestChatMessages) {
    if(!value) return;

    if(this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this._searchRequest = value;
    this.searchValue = <string>value.searchString;
    this.isScrolled = false;
    this.getMessages(CHAT_CONSTANTS.CHAT_MESSAGES.offset);
  };

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  private searchSubscription: Subscription;
  private isScrolled: boolean;
  private currentPosition = window.pageYOffset;

  public _searchRequest: IRequestChatMessages;
  public searchValue: string;
  public messages: SupportChatMessages[] = [];
  public _isBackToTopDisplayed: boolean;
  public chatState: IChatStateModel;

  constructor(private _graphqlService: GraphqlService, private chatService: ChatService, private chatStateService: ChatStateService) {
    this._graphqlService.chatMessages$.subscribe(res => {
      if(!res || !res.length) {

        if(!this.isScrolled) {
          this.messages = [];
        }
        
        return;
      };

      this.updateMessages(res);
    });
   }

  ngOnInit(): void {
    this.chatStateService.state$.subscribe((state: IChatStateModel) => {
      this.chatState = state;
    });
  }

  public scrollToTop(): void {
    this.myScrollContainer.nativeElement.scrollTop = 0;
    this._isBackToTopDisplayed = false;              
  }

  public onScrollDown(e: any): void {
    this.isScrolled = true;
    this.getMessages(this.messages.length);
  }

  public selectSearchMessage(message: SupportChatMessages): void {
    this.chatService.searchMessageId$.next(message.Id);
    this.chatService.setFirstMessageFilterDate(message.CreatedDate);
    this.chatService.setSearchValue('');

    this.chatState.isSearchOpened = false;
    
    if(!this.chatState.currentSubject) {
      this.chatState.isShowSearchMessagesWhenSubjectClosed = true;
    }

    this.chatStateService.state = this.chatState;
  }

  public onScroll(e: Event): void {
    let scrollTop = this.myScrollContainer.nativeElement.scrollTop;

    if (scrollTop > this.currentPosition) {
      
      console.log("down");
      this._isBackToTopDisplayed = true;
    } else {
      console.log("scrollUp");
    }

    if (scrollTop === 0) {
      this._isBackToTopDisplayed = false;
    }

    this.currentPosition = scrollTop;
  }

  private getMessages(offset: number): void {

    if(!this._searchRequest.searchString) {
      this.messages = [];
      return;
    };

    let searchRequest = {
      projectId: this._searchRequest.projectId,
      isDeleted: this._searchRequest.isDeleted,
      searchString: this._searchRequest.searchString,
      limit: CHAT_CONSTANTS.CHAT_MESSAGES.limit,
      offset
    };

    if(this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this._graphqlService.getSearchMessages(searchRequest).subscribe();
  }

  private updateMessages(incoming: SupportChatMessages[]): void {
    if(this.isScrolled) {
      this.messages = [...this.messages, ...incoming];
      this.isScrolled = false;
    } else {
     this.messages = incoming;
    }
  }

}
